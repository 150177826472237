import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Input, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import LineChartCard from "components/LineChart"
import { useToasts } from "react-toast-notifications"
import {
  getDashboardDataInsight,
  getFullFillment,
  getSuperAdimnRevenueSubTypesData,
  getSuperAdimnSubscriberEndingData,
  getSuperAdimnYoyData,
  getYerOverSale,
} from "store/action/dashboard"
import TopCard from "./TopCards"
import TopSellingProducts from "./TopSellingProducts"
import { SUPERADMIN, SUPERUSER } from "utils/constants"
import DataTable from "react-data-table-component"
import "../../assets/scss/custom/rdt.scss"

const LineChartRaw = ({ periodData, title, chartType, xAxis }) => {
  return (
    <div className="ai-Table" style={{marginBottom: "50px", marginTop: "50px"}}>
      <Col xl="12">
        <LineChartCard
          periodData={periodData}
          title={title}
          chartType={chartType}
          xAxis={xAxis}
        />
      </Col>
    </div>
  )
}

const dateOptions = { month: "2-digit", year: "numeric", day: "2-digit" }

const InventoryDashboard = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [periodData, setPeriodData] = useState([])
  const [periodDataFullfillment, setPeriodDataFullfillment] = useState([])
  const [subscriberEndingData, setSubscriberEndingData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(100)
  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalElements: 0,
  })
  const [revenueSubTypes, setRevenueSubTypes] = useState([])
  const [superAdminYearOverSale, setSuperAdminYearOverSale] = useState([])
  const [xAxis, setxAxis] = useState([])

  const column = [
    {
      name: "Name",
      selector: row => row.clientName,
    },
    {
      name: "Account Start Date",
      selector: row => row.startDate,
      cell: row =>
        new Date(row.startDate).toLocaleDateString("en-US", dateOptions),
    },
    {
      name: "Subscription End Date",
      selector: row => row.endDate,
      cell: row =>
        new Date(row.endDate).toLocaleDateString("en-US", dateOptions),
    },
    {
      name: "Paid Subscription",
      selector: row => row.isPaidSubscription,
      cell: row => {
        return (
          <span
            className={
              row.isPaidSubscription
                ? `text-success font-weight-bold`
                : `text-danger font-weight-bold`
            }
          >
            {row.isPaidSubscription}
          </span>
        )
      },
    },
  ]

  useEffect(() => {
    let role = localStorage.getItem("role")
    if (role === SUPERADMIN || role === SUPERUSER) {
      getSuperAdminYoy()
      getSuperAdimnRevenueSubTypes()
      getSuperAdimnSubscriberEnding()
    } else {
      getDashboardInsightData()
      getYearOverSalesData()
      getFullFillmentData()
    }
  }, [])

  const getSuperAdminYoy = () => {
    dispatch(
      getSuperAdimnYoyData(
        responseData => {
          let tempPeriodData = []
          Object.keys(responseData.data).map(item => {
            tempPeriodData.push({
              name: item,
              data: [
                responseData.data[item]["Jan"] === undefined
                  ? 0
                  : responseData.data[item]["Jan"] ?? 0,
                responseData.data[item]["Feb"] === undefined
                  ? 0
                  : responseData.data[item]["Feb"] ?? 0,
                responseData.data[item]["Mar"] === undefined
                  ? 0
                  : responseData.data[item]["Mar"] ?? 0,
                responseData.data[item]["Apr"] === undefined
                  ? 0
                  : responseData.data[item]["Apr"] ?? 0,
                responseData.data[item]["May"] === undefined
                  ? 0
                  : responseData.data[item]["May"] ?? 0,
                responseData.data[item]["Jun"] === undefined
                  ? 0
                  : responseData.data[item]["Jun"] ?? 0,
                responseData.data[item]["Jul"] === undefined
                  ? 0
                  : responseData.data[item]["Jul"] ?? 0,
                responseData.data[item]["Aug"] === undefined
                  ? 0
                  : responseData.data[item]["Aug"] ?? 0,
                responseData.data[item]["Sep"] === undefined
                  ? 0
                  : responseData.data[item]["Sep"] ?? 0,
                responseData.data[item]["Oct"] === undefined
                  ? 0
                  : responseData.data[item]["Oct"] ?? 0,
                responseData.data[item]["Nov"] === undefined
                  ? 0
                  : responseData.data[item]["Nov"] ?? 0,
                responseData.data[item]["Dec"] === undefined
                  ? 0
                  : responseData.data[item]["Dec"] ?? 0,
              ],
            })
          })
          setSuperAdminYearOverSale([...tempPeriodData])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const getSuperAdimnSubscriberEnding = () => {
    dispatch(
      getSuperAdimnSubscriberEndingData(
        responseData => {
          setSubscriberEndingData(responseData.data.content)
          setPagination({
            currentPage: responseData.data.number,
            totalPage: responseData.data.numberOfElements,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const getSuperAdimnRevenueSubTypes = () => {
    dispatch(
      getSuperAdimnRevenueSubTypesData(
        responseData => {
          let tempPeriodDataMonthly = []
          let tempPeriodDataYearly = []
          Object.keys(responseData.data).map(item => {
            tempPeriodDataMonthly.push({
              name: "Monthly",
              data: [
                responseData.data[2022]["Jan"] !== undefined
                  ? responseData.data[2022]["Jan"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Feb"] !== undefined
                  ? responseData.data[2022]["Feb"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Mar"] !== undefined
                  ? responseData.data[2022]["Mar"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Apr"] !== undefined
                  ? responseData.data[2022]["Apr"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["May"] !== undefined
                  ? responseData.data[2022]["May"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Jun"] !== undefined
                  ? responseData.data[2022]["Jun"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Jul"] !== undefined
                  ? responseData.data[2022]["Jul"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Aug"] !== undefined
                  ? responseData.data[2022]["Aug"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Sep"] !== undefined
                  ? responseData.data[2022]["Sep"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Oct"] !== undefined
                  ? responseData.data[2022]["Oct"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Nov"] !== undefined
                  ? responseData.data[2022]["Nov"]["MONTHLY"] ?? 0
                  : 0,
                responseData.data[2022]["Dec"] !== undefined
                  ? responseData.data[2022]["Dec"]["MONTHLY"] ?? 0
                  : 0,
              ],
            })
          })
          Object.keys(responseData.data).map(item => {
            tempPeriodDataYearly.push({
              name: "Yearly",
              data: [
                responseData.data[2022]["Jan"] !== undefined
                  ? responseData.data[2022]["Jan"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Feb"] !== undefined
                  ? responseData.data[2022]["Feb"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Mar"] !== undefined
                  ? responseData.data[2022]["Mar"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Apr"] !== undefined
                  ? responseData.data[2022]["Apr"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["May"] !== undefined
                  ? responseData.data[2022]["May"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Jun"] !== undefined
                  ? responseData.data[2022]["Jun"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Jul"] !== undefined
                  ? responseData.data[2022]["Jul"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Aug"] !== undefined
                  ? responseData.data[2022]["Aug"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Sep"] !== undefined
                  ? responseData.data[2022]["Sep"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Oct"] !== undefined
                  ? responseData.data[2022]["Oct"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Nov"] !== undefined
                  ? responseData.data[2022]["Nov"]["YEARLY"] ?? 0
                  : 0,
                responseData.data[2022]["Dec"] !== undefined
                  ? responseData.data[2022]["Dec"]["YEARLY"] ?? 0
                  : 0,
              ],
            })
          })
          setRevenueSubTypes([
            tempPeriodDataMonthly[0],
            tempPeriodDataYearly[0],
          ])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const getDashboardInsightData = () => {
    dispatch(
      getDashboardDataInsight(
        responseData => {},
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const getYearOverSalesData = () => {
    dispatch(
      getYerOverSale(
        responseData => {
          let tempPeriodData = []
          let tempXAxis = []
          Object.keys(responseData.data).map(item => {
            tempPeriodData.push({
              name: item,
              data: [
                responseData.data[item]["Jan"] === undefined
                  ? 0
                  : responseData.data[item]["Jan"] ?? 0,
                responseData.data[item]["Feb"] === undefined
                  ? 0
                  : responseData.data[item]["Feb"] ?? 0,
                responseData.data[item]["Mar"] === undefined
                  ? 0
                  : responseData.data[item]["Mar"] ?? 0,
                responseData.data[item]["Apr"] === undefined
                  ? 0
                  : responseData.data[item]["Apr"] ?? 0,
                responseData.data[item]["May"] === undefined
                  ? 0
                  : responseData.data[item]["May"] ?? 0,
                responseData.data[item]["Jun"] === undefined
                  ? 0
                  : responseData.data[item]["Jun"] ?? 0,
                responseData.data[item]["Jul"] === undefined
                  ? 0
                  : responseData.data[item]["Jul"] ?? 0,
                responseData.data[item]["Aug"] === undefined
                  ? 0
                  : responseData.data[item]["Aug"] ?? 0,
                responseData.data[item]["Sep"] === undefined
                  ? 0
                  : responseData.data[item]["Sep"] ?? 0,
                responseData.data[item]["Oct"] === undefined
                  ? 0
                  : responseData.data[item]["Oct"] ?? 0,
                responseData.data[item]["Nov"] === undefined
                  ? 0
                  : responseData.data[item]["Nov"] ?? 0,
                responseData.data[item]["Dec"] === undefined
                  ? 0
                  : responseData.data[item]["Dec"] ?? 0,
              ],
            })
            tempXAxis.push(Object.keys(responseData.data[item]))
          })
          setPeriodData([...tempPeriodData])
          setxAxis([...tempXAxis])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const getFullFillmentData = () => {
    dispatch(
      getFullFillment(
        responseData => {
          let tempPeriodDataFBA = []
          let tempPeriodDataFBM = []
          Object.keys(responseData.data).map(item => {
            tempPeriodDataFBA.push({
              name: "FBA",
              data: [
                responseData.data[2022]["Jan"] !== undefined
                  ? responseData.data[2022]["Jan"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Feb"] !== undefined
                  ? responseData.data[2022]["Feb"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Mar"] !== undefined
                  ? responseData.data[2022]["Mar"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Apr"] !== undefined
                  ? responseData.data[2022]["Apr"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["May"] !== undefined
                  ? responseData.data[2022]["May"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Jun"] !== undefined
                  ? responseData.data[2022]["Jun"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Jul"] !== undefined
                  ? responseData.data[2022]["Jul"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Aug"] !== undefined
                  ? responseData.data[2022]["Aug"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Sep"] !== undefined
                  ? responseData.data[2022]["Sep"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Oct"] !== undefined
                  ? responseData.data[2022]["Oct"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Nov"] !== undefined
                  ? responseData.data[2022]["Nov"]["FBA"] ?? 0
                  : 0,
                responseData.data[2022]["Dec"] !== undefined
                  ? responseData.data[2022]["Dec"]["FBA"] ?? 0
                  : 0,
              ],
            })
          })
          Object.keys(responseData.data).map(item => {
            tempPeriodDataFBM.push({
              name: "FBM",
              data: [
                responseData.data[2022]["Jan"] !== undefined
                  ? responseData.data[2022]["Jan"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Feb"] !== undefined
                  ? responseData.data[2022]["Feb"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Mar"] !== undefined
                  ? responseData.data[2022]["Mar"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Apr"] !== undefined
                  ? responseData.data[2022]["Apr"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["May"] !== undefined
                  ? responseData.data[2022]["May"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Jun"] !== undefined
                  ? responseData.data[2022]["Jun"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Jul"] !== undefined
                  ? responseData.data[2022]["Jul"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Aug"] !== undefined
                  ? responseData.data[2022]["Aug"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Sep"] !== undefined
                  ? responseData.data[2022]["Sep"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Oct"] !== undefined
                  ? responseData.data[2022]["Oct"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Nov"] !== undefined
                  ? responseData.data[2022]["Nov"]["FBM"] ?? 0
                  : 0,
                responseData.data[2022]["Dec"] !== undefined
                  ? responseData.data[2022]["Dec"]["FBM"] ?? 0
                  : 0,
              ],
            })
          })
          setPeriodDataFullfillment([
            tempPeriodDataFBA[0],
            tempPeriodDataFBM[0],
          ])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handlePageChange = e => {
    setCurrentPage(e - 1)
  }

  const handlePerRowsChange = e => {
    setPerPage(e)
  }
  return (
    <React.Fragment>
      <div className="page-content ai-mw-center">
        <MetaTags>
          <title>{`Dashboard | Active Inventory`}</title>
        </MetaTags>
        <Container fluid>
          <div
            className="justify-content-center"
            style={{ textAlign: "center" }}
          >
            <h2
              style={{
                font: "bold 25px novecento-sans",
                color: "#082C4E",
                margin: "0px",
              }}
            >
              DASHBOARD
            </h2>
            <br />
            <h4
              style={{
                font: "bold 25px nunito-sans",
                color: "#082C4E",
              }}
            >
              Welcome, {localStorage.getItem("name")?.split(" ")[0]}!
            </h4>
          </div>

          {localStorage.getItem("role") !== SUPERADMIN &&
            localStorage.getItem("role") !== SUPERUSER && (
              <>
                <TopCard />
                <TopSellingProducts />
                <LineChartRaw
                  isRigthSectionShouldAppear
                  periodData={periodData}
                  xAxis={xAxis}
                  title="Year Over Year Sales"
                  chartType="line" //line chart
                />
                <LineChartRaw
                  periodData={periodDataFullfillment}
                  title="FBA vs FBM Sales"
                  chartType="bar" //bar chart
                />
              </>
            )}
          {(localStorage.getItem("role") === SUPERADMIN ||
            localStorage.getItem("role") === SUPERUSER) && (
            <>
              <LineChartRaw
                periodData={revenueSubTypes}
                title="Revenue Sub Types"
                chartType="bar" //super admin bar chart
              />

              <LineChartRaw
                periodData={superAdminYearOverSale}
                title="Year Over Year Sales"
                chartType="bar" //super admin bar chart
              />

              <Card>
                <CardBody>
                  <div className="h5">Subscriber ending by this month</div>
                  <div className="d-flex flex-column">
                    <DataTable
                      columns={column}
                      data={subscriberEndingData}
                      pagination
                      paginationPerPage={100}
                      paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                      onChangeRsubscriberEndingDataowsPerPage={e =>
                        setRowsPerPage(e)
                      }
                      paginationTotalRows={subscriberEndingData.length}
                      onChangePage={e => handlePageChange(e)}
                      onChangeRowsPerPage={e => handlePerRowsChange(e)}
                      className="restock-history-data-table"
                      persistTableHead
                      fixedHeader
                      responsive
                      dense
                    />
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

LineChartRaw.propTypes = {
  periodData: PropTypes.any,
  isRigthSectionShouldAppear: PropTypes.bool,
  title: PropTypes.string,
  chartType: PropTypes.string,
  xAxis: PropTypes.any,
}

export default InventoryDashboard
