import { actionChannel, take, fork, call, put } from "redux-saga/effects"
import { setStartLoader, setStopLoader } from "../loader/action"
import getAxios from "./axiosAPI"
import { logoutUserSuccess } from "store/actions"
import { refreshToken } from "store/action/user"
import { useToasts } from "react-toast-notifications"

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const pendingRequests = {}
// const disableLoaderData=[statusProductTag]
const similarPendingRequestExist = (actionType, url) =>
  pendingRequests[actionType] && pendingRequests[actionType].url === url

function* invokeAPI(action) {
  const { payload } = action
  const {
    method,
    url,
    data,
    apiConfig,
    action: actionType,
    resolve,
    reject,
  } = payload

  if (apiConfig?.headers?.Authorization && apiConfig?.headers?.Authorization.includes("bearer")) {
    const tokenData = parseJwt(apiConfig?.headers?.Authorization)
    var date = new Date();
    var milliseconds = date.getTime();
    if (tokenData.exp - 120 < milliseconds * 0.001) {
      yield put(refreshToken({ grant_type: "refresh_token", refresh_token: localStorage.getItem('refresh_token') },
        resp => {
          localStorage.setItem('access_token', resp.access_token)
          localStorage.setItem('token_type', resp.token_type)
          localStorage.setItem('refresh_token', resp.refresh_token)
          apiConfig.headers.Authorization = resp.token_type + " " + resp.access_token
        }, error => {
          localStorage.clear();
          history.push("/login");
        }))
    }
  }

  try {
    let response = {}
    const api = getAxios()
    const actionArr = [
      "PRODUCT_STATUS_CHANGE",
      "PRODUCT_WITHOUT_LOADER_DATA",
      "GET_TAG_WITHOUT_LOADER",
      "GET_USERS_BY_ID",
      "USERS_EDIT",
      "USERS_LIST_WITHOUTLOADER",
      "DOWNLOAD_PRODUCT_FILE",
      "PRODUCT_TAG",
      "FILTER_PRODUCTS_BY_SKU",
      "FILTER_PRODUCTS_BY_NAME",
      "GET_RESTOCK_SHEET_ITEM",
      "ADD_ITEM_TO_RESTOCK_SHEET",
      "DELETE_ITEM_FROM_RESTOCK_SHEET",
      "ADD_NEW_CARD"
    ];

    if (!actionArr.includes(actionType)) {
      yield put(setStartLoader());
    }

    //setStartLoader here
    switch (method) {
      case "GET": {
        if (similarPendingRequestExist(actionType, url)) {
          throw new Error("Similar axios request detected!")
        } else {
          response = yield call([api, api.get], url, {
            ...apiConfig
          })
        }
        break
      }
      case "POST":
        response = yield call([api, api.post], url, data, { ...apiConfig })
        break

      case "PUT":
        response = yield call([api, api.put], url, data, { ...apiConfig })
        break

      case "PATCH":
        response = yield call([api, api.patch], url, data, { ...apiConfig })
        break

      case "DELETE":
        response = yield call(
          [api, api.delete],
          url,
          { ...apiConfig },
          { data },
        )
        break

      default:
        throw new Error(`API method ${method} is not supported!`)
    }
    if (
      response.status === Number(200) ||
      response.status === Number(201) ||
      response.status === Number(202)
    ) {
      yield* dispatchFulfilled(action, response?.data)
      yield put(setStopLoader());
    } else {
      yield* dispatchRejected(payload.action, action, "Internal server error")
      yield put(setStopLoader());
    }
    delete pendingRequests[actionType]
    if (resolve) {
      resolve(response?.data)
    }
  } catch (error) {
    //console.error(error);
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      yield put(setStopLoader());
      window.location.href = "/login"
      //yield put(logoutUserSuccess())
    }
    if (error.response && error.response.status === 403 && error.response.data.error.code === 'NO_ACTIVE_SUBSCRIPTION') {
      yield put(setStopLoader());
      //const addToast = useToasts()
      //addToast("Company does not have active Subscription to use the application. Please subscribe to use the application.", {
      //  appearance: "error",
      //  autoDismiss: false,
      //})
      window.location.href = "/billing"
      //yield put(logoutUserSuccess())
    }
    if (
      error.response &&
      error.response.status === 500 ||
      error.response.status === 405 ||
      error.response.status === 404 ||
      error.response.status === 403
    ) {
      yield put(setStopLoader());
    }
    if (reject) {
      reject(error)
    }
  } finally { yield put(setStopLoader()) }
}

function* dispatchFulfilled(action, response) {
  yield put({ type: `${action.payload.action}_SUCCESS`, payload: response })
}

function* dispatchRejected(actionType, action, error) {
  yield put({
    type: `${actionType}_ERROR`,
    actualAction: action,
    payload: { response: error },
  })
}

function* apiSaga() {
  const actionQueue = yield actionChannel("API_INVOCATION")
  while (true) {
    const action = yield take(actionQueue)
    yield fork(invokeAPI, action)
  }
}

export { invokeAPI }
export default apiSaga
