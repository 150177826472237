import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const TabTitle = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="d-sm-flex align-items-center justify-content-center">
          <h4
            style={{
              textAlign: "center",
              font: "normal normal bold 32px/39px novecento-sans",
              letterSpacing: "0.32px",
              color: "#082C4E",
              textTransform: "uppercase",
            }}
          >
            {props.title}
          </h4>
          {props.tooltip &&<span style={{position: "relative", top:"-10px"}} className="info-icon" data-toggle="tooltip"
                  data-tooltip-id="my-tooltip" data-tooltip-html={props.tooltip}>
                  <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
                  <Tooltip id="my-tooltip" place="top" style={{ zIndex: "9999", textAlign: "left", maxWidth: "600px", wordSpacing:"0.2em" }} />
                </span>}
        </div>
      </Col>
    </Row>
  )
}

TabTitle.propTypes = {
  title: PropTypes.string,
  tooltip:PropTypes.string,
}

export default TabTitle
