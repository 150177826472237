import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { toggleLeftmenu } from "../../store/actions"
import "../../components/HorizontalLayout/css/custome.css"
import Headerlogo from "../assets/images/dark-blue.png"
import { withTranslation } from "react-i18next"

const headerObj = [
  {
    id: 1,
    title: "Home",
    path: "/",
  },
  {
    id: 2,
    title: "Pricing",
    path: "/pricing",
  },
  {
    id: 3,
    title: "Our Story",
    path: "/ourstory",
  },
  {
    id: 4,
    title: "Affiliate Program",
    path: "/affiliate",
  },
  {
    id: 5,
    title: "Support",
    path: "/faq",
  },
  {
    id: 6,
    title: "14 Day Trial",
    path: "/14-days-trial"
  },
  {
    id: 7,
    title: "Log In",
    path: "/login",
  },
]
const MobileDropDownComp = ({ title, path, setHamberger, index }) => {
  const hs = useHistory()
  const [Open, isOpen] = useState(false)
  return (
    <li className="mobile-drop-down-li d-flex flex-column justify-content-center" key={index}>
      <a
        className="text-white mobile-dropDown d-flex justify-content-center align-items-center"
        onClick={() => {
          isOpen(!Open)
          title === "Support" ? window.open(
            'https://activeinventory.freshdesk.com/support/solutions',
            '_blank'
          ) : hs.push(path)
        }}
      >
        {`${title} `}
        {/* {path?.length > 0 && <span className="link"></span>} */}
      </a>
      {/* {Open && (
        <>
          {path && path?.length > 0 && (
            <div className="d-flex flex-column align-items-center">
              {path?.map((item, subIndex) => {
                return (
                  <>
                    <a
                      className="text-white mobile-dropDown"
                      // key={`header-dropdown-item-${item.id}`}
                      key={subIndex}
                      onClick={e => {
                        e.preventDefault()
                        setHamberger()
                        hs.push(item?.path)
                      }}
                    >
                      {item?.name}
                    </a>
                  </>
                )
              })}
            </div>
          )}
        </>
      )} */}
    </li>
  )
}
const HeaderItem = ({ title, path, subItems, id }) => {
  const hs = useHistory()
  return (
    <li className="dropdown" style={{ fontFamily: "DM Sans", fontSize: "0.75rem" }} key={id}>
      <a
        className={`nav-link text-white static-header-item`}
        onClick={e => {
          e.preventDefault()
          title === "Support" ? window.open(
            'https://activeinventory.freshdesk.com/support/solutions',
            '_blank'
          ) : hs.push(path)
        }}
      >
        <span className="main-nav-link-title">
          {`${title} `} {subItems?.length > 0 && <span className="link"></span>}
        </span>
      </a>

      {subItems && subItems?.length > 0 && (
        <div className="dropdownMenu">
          {subItems?.map((item, subIndex) => {
            return (
              <>
                <div className="dropdownMenu-child">
                  <a
                    className="text-white dropDownIem"
                    href="restock_history.html"
                    key={subIndex}
                    onClick={e => {
                      e.preventDefault()
                      hs.push(item?.path)
                    }}
                  >
                    {item?.name}
                  </a>
                </div>
              </>
            )
          })}
        </div>
      )}
    </li>
  )
}
const Header = () => {
  const [isHamberger, setHamberger] = useState(false)
  return (
    <React.Fragment>
      <div className="position-fixed w-100 main-container">
        <section
          style={{ backgroundColor: "#40b0bf" }}
          className="d-flex align-items-center justify-content-between px-5 header-section"
        >
          <div>
            <span className="navbar-logo">
              <span>
                <a href="/">
                  <img src={Headerlogo} alt="active-inventory-logo" style={{ height: "3rem", width: "8rem" }} />
                </a>
              </span>
            </span>
          </div>
          <div className="d-flex justify-content-end navigation-container">
            <ul
              className="d-flex align-items-center justify-content-end mb-0 header-ul flex-wrap"
              style={{ display: "hidden" }}
              data-app-modern-menu="true"
            >
              {headerObj?.map((item, index) => {
                return (
                  <HeaderItem
                    key={index}
                    {...item}
                  />
                )
              })}
            </ul>
          </div>
        </section>
      </div>

      <div className="position-fixed w-100 main-container mobile-container bg-light-green">
        <section className="d-flex justify-content-between align-items-center px-5 header-section bg-light-green">
          <div>
            <span className="navbar-logo">
              <span onClick={() => hs.push("/")}>
                <img src={Headerlogo} alt="" style={{ height: "3rem" }} />
              </span>
            </span>
          </div>
          <div>
            <div
              className={`hamburger ${isHamberger && "is-active"}`}
              id="hamburger-1"
              onClick={e => {
                setHamberger(!isHamberger)
              }}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </section>
        {isHamberger && (
          <ul className="d-flex flex-column align-items-center mobile-header-ul">
            {headerObj?.map((item, index) => {
              return (
                <MobileDropDownComp
                  {...item}
                  key={index}
                  setHamberger={() => {
                    setHamberger(!isHamberger)
                  }}
                />
              )
            })}
          </ul>
        )}
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

HeaderItem.propTypes = {
  title: PropTypes.any,
  subItems: PropTypes.any,
  path: PropTypes.any,
  id: PropTypes.any,
}
MobileDropDownComp.propTypes = {
  title: PropTypes.any,
  path: PropTypes.any,
  setHamberger: PropTypes.func,
  index: PropTypes.any,
}

const mapStatetoProps = state => {
  const { layoutType, leftMenu } = state.Layout
  return { layoutType, leftMenu }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(Header))
