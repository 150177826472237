import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { toggleLeftmenu } from "../../store/actions"
import Headerlogo from "../HorizontalLayout/img/light-green-1.svg"
import { withTranslation } from "react-i18next"
import "./custome.css"

const headerObj = [
  {
    title: "Home",
    path: "/"
  },
  {
    title: "Pricing",
    path: "pricing"
  },
  {
    title: "Our Story",
    path: "ourstory"
  },
  {
    title: "Affiliate Program",
    path: "affiliate"
  },

  { title: "Support", path: "faq" },
  { title: "14 Day Trial", path: "/14daytrial" },
  {
    title: "Sign Up",
    link: "https://app.myactiveinventory.com/register"
  },
  {
    title: "Login",
    link: "https://app.myactiveinventory.com/login"
  },
]

const MobileDropDownComp = ({ title, subItems, setHamberger }) => {
  const hs = useHistory()
  const [Open, isOpen] = useState(false)
  return (
    <li className="mobile-drop-down-li d-flex flex-column justify-content-center">
      <span
        className="text-white mobile-dropDown d-flex justify-content-center align-items-center"
        onClick={() => {
          isOpen(!Open)

        }}
      >
        {`${title} `} {subItems?.length > 0 && <span className="link"></span>}
      </span>
      {Open && (
        <>
          {subItems && subItems?.length > 0 && (
            <div className="d-flex flex-column align-items-center">
              {subItems?.map((item, index) => {
                return (
                  <>
                    <a
                      className="text-white mobile-dropDown"
                      key={`header-dropdown-item-${index}`}
                      onClick={e => {
                        e.preventDefault()
                        setHamberger()
                        hs.push(item?.path)
                      }}
                    >
                      {item?.name}
                    </a>
                  </>
                )
              })}
            </div>
          )}
        </>
      )}
    </li>
  )
}
const HeaderItem = ({ title, path, link, subItems }) => {
  const hs = useHistory()
  return (
    <li className="dropdown">
      <a className={`nav-link text-white `} onClick={e => {
        e.preventDefault()
        { path ? hs.push(path) : window.location.href = link }
      }}>
        <span className="main-nav-link-title">
          {`${title} `} {subItems?.length > 0 && <span className="link"></span>}
        </span>
      </a>

      {subItems && subItems?.length > 0 && (
        <div className="dropdownMenu">
          {subItems?.map((item, index) => {
            return (
              <>
                <div className="dropdownMenu-child">
                  <a
                    className="text-white dropDownIem"
                    href="restock_history.html"
                    key={`header-dropdown-item-${index}`}
                    onClick={e => {
                      e.preventDefault()
                      hs.push(item?.path)
                    }}
                  >
                    {item?.name}
                  </a>
                </div>
              </>
            )
          })}
        </div>
      )}
    </li>
  )
}
const AuthHeader = () => {
  const [isHamberger, setHamberger] = useState(false)
  return (
    <React.Fragment>
      <div className="position-fixed w-100 main-container">
        <section className="d-flex align-items-center px-5 header-section bg-light-green">
          <div>
            <span className="navbar-logo">
              <a href="#">
                <img src={Headerlogo} alt="" className="h-3" />
              </a>
            </span>
          </div>
          <div className="d-flex align-items-center navigation-container">
            <ul
              className="d-flex align-items-center justify-content-around mb-0 header-ul flex-wrap display-hidden"
              data-app-modern-menu="true"
            >
              {headerObj?.map((item, index) => {
                return (
                  <HeaderItem
                    key={`header-items-and-subitems-${index}`}
                    {...item}
                  />
                )
              })}
            </ul>
          </div>
        </section>
      </div>

      <div className="position-fixed w-100 main-container mobile-container">
        <section className="d-flex justify-content-between align-items-center px-5 header-section">
          <div>
            <span className="navbar-logo">
              <a href="#">
                <img src={Headerlogo} alt="" className="h-3" />
              </a>
            </span>
          </div>
          <div>
            <div
              className={`hamburger ${isHamberger && "is-active"}`}
              id="hamburger-1"
              onClick={() => {
                setHamberger(!isHamberger)
              }}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </section>
        {isHamberger && (
          <ul className="d-flex flex-column align-items-center mobile-header-ul">
            {headerObj?.map((item, index) => {
              return (
                <MobileDropDownComp
                  key={`header-items-and-subitems-${index}`}
                  {...item}
                  setHamberger={() => {
                    setHamberger(!isHamberger)
                  }}
                />
              )
            })}
          </ul>
        )}
      </div>
    </React.Fragment>
  )
}

AuthHeader.propTypes = {
  leftMenu: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

HeaderItem.propTypes = {
  title: PropTypes.any,
  subItems: PropTypes.any,
  path: PropTypes.any,
  link: PropTypes.any
}
MobileDropDownComp.propTypes = {
  title: PropTypes.any,
  subItems: PropTypes.any,
  setHamberger: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, leftMenu } = state.Layout
  return { layoutType, leftMenu }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(AuthHeader))
