import { BILLING_DATA_SUCCESS, SUBSCRIPTION_PLAN_DATA_SUCCESS } from "../actionType"

const INITIAL_STATE = {
data: null
}
const Billing = (state = INITIAL_STATE, action) => {
switch (action.type) {
    case BILLING_DATA_SUCCESS:
        return {
            ...state,
            data: action.payload,
        }
    case SUBSCRIPTION_PLAN_DATA_SUCCESS: 
        return {
            ...state,
            data: action.payload,
        }
    default:
        return state
}
}
export default Billing
