// export const BASE_URL = "http://localhost:8081/easy/v1.0/"
// export const BASE_URL = "https://stage-api.myactiveinventory.com/v1.0/"
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_URL_FOR_ORDERS = process.env.REACT_APP_BASE_URL
//export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://api.myactiveinventory.com/v1.0/"
//export const BASE_URL_FOR_ORDERS = process.env.REACT_APP_BASE_URL_FOR_ORDERS || "https://api.myactiveinventory.com/v1.0/"
export const LOGIN = BASE_URL + "login"
export const REFRESH_TOKEN = BASE_URL + "login/refresh-token"
export const SIGNUP = BASE_URL + "signup"
export const DEMO_SIGNUP = BASE_URL + "demo/signup"
export const GET_STATES = BASE_URL + "public/states"
export const GET_COUNTRY = BASE_URL + "public/countries"
export const CHANGE_PASSWORD = BASE_URL + "user/changePassword"
export const FORGOT_PASSWORD = BASE_URL + "user/reset-password"
export const CLIENT_CONFIG = BASE_URL + "signup/clients/marketplace/config"
export const USER_DATA = BASE_URL + "user/client"
export const GET_USER_BY_ID = BASE_URL + "client/users"
export const USER = BASE_URL + "client/users"
export const EDIT_USER = BASE_URL + "client/users"
export const DELETE_USER = BASE_URL + "client/users"
export const RESTOCK_DATA = BASE_URL + "restock/sku"
export const RESTOCK_SHEET = BASE_URL + "restock"
export const OUT_OF_STOCK_DATA = BASE_URL + "product/outOfStock"
export const PRODUCT_DATA = BASE_URL + "product/all"
export const PRODUCT_STATUS = BASE_URL + "product/all"
export const TAG = BASE_URL + "product/:productId/tag"
export const PRODUCT_ACTIVE_INACTIVE = BASE_URL + "product/:productId"
export const ADD_TAG_PRODUCT = BASE_URL + "product/:productId/tag"
export const GET_TAG = BASE_URL + "tags"
export const ADD_TAG = BASE_URL + "product"
export const GET_SETTINGS = BASE_URL + "client/setting"
export const UPDATE_SETTINGS = BASE_URL + "client/setting"
export const RESTOCK_ALL = BASE_URL + "restock/all"
export const RESTOCK_DONE = BASE_URL + "restock/done"
export const RESTOCK_DRAFT = BASE_URL + "restock/draft"
export const RESTOCK_UPDATE = BASE_URL + "restock"
export const CREATE_RESTOCK_SHEET = BASE_URL + "restock"
export const USER_PROFILE = BASE_URL + "user"
export const SHIPPING_QUEUE = BASE_URL + "shipment/all"
export const SHIPPING_ITEM = BASE_URL + "shipment"
export const RESET_PASSWORD = BASE_URL + "user/reset-password"
export const DELETE_RESTOCK_SHEET = BASE_URL + "restock"
export const DONE_RESTOCK_SHEET = BASE_URL + "restock"
export const UPDATE_RESTOCK_SHEET = BASE_URL + "restock"
export const ADD_ITEM_TO_RESTOCK_SHEET = BASE_URL + "restock"
export const DELETE_ITEM_FROM_RESTOCK_SHEET = BASE_URL + "restock"
export const GET_BUY_BOX_PRICE = BASE_URL + "restock/buyboxprice"
export const GET_RESTOCK_SHEET_ITEM = BASE_URL + "restock"
export const UPLOAD_PRODUCT_FILE = BASE_URL + "product/upload"
export const UPLOAD_PROFILE_PHOTO = BASE_URL + "user/upload/photo"
export const DOWNLOAD_PROFILE_PHOTO = BASE_URL + "downloadFile/"
export const UPLOAD_INVENTORY_LAB_FILE =
  BASE_URL + "product/upload/inventoryLab"
export const DOWNLOAD_PRODUCT_FILE = BASE_URL + "product/download"
export const FILTER_PRODUCTS_BY_NAME = BASE_URL + "product/name"
export const FILTER_PRODUCTS_BY_SKU = BASE_URL + "product/sku"
export const FILTER_PRODUCTS_BY_TAG = BASE_URL + "product/tagName"
export const SEARCH_PRODUCT_SHEET = BASE_URL + "product/all"
export const PRODUCT_EXPIRY_DATE = BASE_URL + "product/expiry"
export const PRODUCT_TAX_CODE = BASE_URL + "product/taxCode"
export const PRODUCT_UPDATE = BASE_URL + "product/"
export const AUTO_HIDE_PRODUCT = BASE_URL + "product/autohide"
export const BILLING_DATA = BASE_URL + "client/billing"
export const SUBSCRIPTION_PLAN_DATA = BASE_URL + "client/subscription-plan"
export const GET_STRIPE_PUBLIC_KEY = BASE_URL + "public/stripe-key"
export const CREATE_SUBSCRIPTION = BASE_URL + "client/subscription"
export const UPDATE_SUBSCRIPTION = BASE_URL + "client/subscription"
export const CANCEL_SUBSCRIPTION = BASE_URL + "client/subscription/cancel"
export const REACTIVE_SUBSCRIPTION = BASE_URL + "client/subscription/reactivate"
export const ADD_NEW_CARD = BASE_URL + "client/new-card"
export const PRINT_RESTOCK_SHEET = BASE_URL + "restock/print"
export const GET_ONLY_FBM_SKU_ITEM = BASE_URL + "restock/draft"
export const DASHBOARD_DATA = BASE_URL + "client/dashboard/yoy"
export const DASHBOARD_FULLFILLMENT = BASE_URL + "client/dashboard/fulfillment"
export const DASHBOARD_INSIGHT_DATA = BASE_URL + "client/dashboard/box"
export const UPDATE_NOTIFICATION_FOR_USER = BASE_URL + "client/notification"
export const HIDE_SKU = BASE_URL + "product/:productId"

//super admin endpoints
export const SUPER_ADMIN_YOY = BASE_URL + "admin/dashboard/yoy"
export const SUPER_ADMIN_REVENUE_SUB_TYPE =
  BASE_URL + "admin/dashboard/revenue-subs-type"
export const SUPER_ADMIN_SUB_ENDING_IN_MONTH =
  BASE_URL + "admin/dashboard/sub-ending-in-month"
export const SUPER_ADMIN_CLIENT_ALL = BASE_URL + "admin/client/all"
export const SUPER_ADMIN_CLIENT_BY_STATUS = BASE_URL + "admin/clients"
export const SUPER_ADMIN_CLIENT_BY_NAME = BASE_URL + "admin/search/client"
export const SUPER_ADMIN_CREATE_USER = BASE_URL + "admin/user"
export const GET_ALL_SUPER_USERS = BASE_URL + "admin/users"
export const GET_SUPER_USER_BY_ID = BASE_URL + "admin/user"
export const EDIT_SUPER_USER = BASE_URL + "admin/users"
export const DELETE_SUPER_USER = BASE_URL + "admin/users"

export const CREATE_NOTIFICATION = BASE_URL + "admin/notification"
export const UPDATE_NOTIFIACTION = BASE_URL + "admin/notification"
export const DELETE_NOTIFICATION = BASE_URL + "admin/notification"
export const GET_ALL_NOTIFICATION = BASE_URL + "admin/notifications"
export const GET_SINGLE_NOTIFICATION = BASE_URL + "admin/notification"

export const GET_ALL_NOTIFICATION_FOR_USER = BASE_URL + "client/notifications"

export const LOGOUT = BASE_URL + "logout"
export const PRODUCT = "product"
export const ADD_ADDRESS = "user/address"
export const GET_ALL_ADDRESS = "user/addresses"
export const PRODUCT_LIST = "product/list"
export const GET_PRODUCT_LIST = "get-product/list"
export const CATEGORY_LIST = "categories/list"
export const GET_CATEGORIES = "get-categories"
export const GET_CATEGORY_WITH_PAGINATION = "categories/list"
export const VERIFY_VOUCHER = "voucher/verify"
export const BANNER = "get-banners"
export const CONTACT = "enquiries"
export const GETRANGE = "delivery-range"
export const FAQ = "get-faq"
export const APP_LINK = "get-app-download-link"
export const PROFILE = BASE_URL + "user"
export const ADDFAVORITEPROFILE = "favorite-product"
export const GETFAVPRODUCT = "user-favorite-product"
export const PAYMENTSTATUS = "payment-track"
export const USERS = "users/"
export const CHECK_DELIVERY_REQUEST = "check-delivery"
export const TAKE_ORDERS = "orders"
export const CART = "cart"
export const ADD_PRODUCT_REVIEW = "product/reviews"
export const PRODUCTS_REVIEW = "products"

export const USER_SUGGESTION = BASE_URL + "public/suggestion"
export const CLIENT_INFO = BASE_URL + "signup/"