import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toggleLeftmenu } from "../../store/actions"
import Headerlogo from "./img/dark-green.png"
import { withTranslation } from "react-i18next"
import { logOutUser } from "store/action/user"
import { pages, SUPERADMIN, SUPERUSER } from "utils/constants"
import "./css/custome.css"
import { getSuperAdimnClientData } from "store/action/dashboard"
import Select from "react-select"
import {DOWNLOAD_PROFILE_PHOTO} from "../../utils/url"
import NoProfilePhoto from "../../assets/images/no-photo.svg"

const MobileDropDownComp = ({
  title,
  subItems,
  setHamberger,
  isOpenOrNot,
  openDropDown,
  id,
  path,
  pageName,
  logoutFun,
}) => {
  const hs = useHistory()
  const pagesPermitted =
    localStorage.getItem("pagepermitted") &&
    JSON.parse(localStorage.getItem("pagepermitted"))
  const pageShow = Object.values(pagesPermitted)?.filter(item => item.visible)
  const pageAccessData = Object.values(pagesPermitted)
    ?.filter(item => item.visible)
    .reduce((preValue, currValue) => {
      return [...preValue, currValue.pageName]
    }, [])
  const dashboard = pageShow.filter(
    item => item.pageName === "Dashboard" && item.visible
  )
  return (
    (pageName !== "DASHBOARD" || dashboard.length > 0) && (
      <li
        className="mobile-drop-down-li d-flex flex-column justify-content-center ml-3"
        key={id}
      >
        <span
          className="text-white mobile-dropDown d-flex justify-content-center align-items-center"
          onClick={
            subItems && subItems?.length > 0
              ? openDropDown
              : () => {
                  title === "Support"
                    ? window.open(
                        "https://activeinventory.freshdesk.com/support/solutions",
                        "_blank"
                      )
                    : path && hs.push(path)
                }
          }
        >
          {`${title} `} {subItems?.length > 0 && <span className="link"></span>}
        </span>
        {isOpenOrNot && (
          <>
            {subItems && subItems?.length > 0 && (
              <div className="d-flex flex-column align-items-center" key={id}>
                {pageAccessData &&
                  subItems
                    ?.filter(
                      pageItem =>
                        pageAccessData?.includes(pageItem.name) ||
                        pageItem?.pageName === "PROFILE" ||
                        pageItem?.name === "Restock Page Explained"
                    )
                    ?.map(item => {
                      return pages.includes(item.name) ? (
                        <a
                          className="text-white mobile-dropDown nav-link"
                          key={item.id}
                          href={item.path}
                          target={item.path}
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <a
                          className="text-white mobile-dropDown nav-link"
                          key={item.id}
                          onClick={e => {
                            e.preventDefault()
                            hs.push(item?.path)
                            logoutFun(item)
                          }}
                        >
                          {item?.name}
                        </a>
                      )
                    })}
              </div>
            )}
          </>
        )}
      </li>
    )
  )
}

const HeaderItem = ({ title, path, pageName, subItems, logoutFun, id }) => {
  const hs = useHistory()
  const pagesPermitted =
    localStorage.getItem("pagepermitted") &&
    JSON.parse(localStorage.getItem("pagepermitted"))
  const pageShow = Object.values(pagesPermitted)?.filter(item => item.visible)
  const pageAccessData = Object.values(pagesPermitted)
    ?.filter(item => item.visible)
    .reduce((preValue, currValue) => {
      return [...preValue, currValue.pageName]
    }, [])
  const dashboard = pageShow.filter(
    item => item.pageName === "Dashboard" && item.visible
  )
  const isActiveSubscription = localStorage.getItem("isSubscriptionActive") === "true"
  const isDemoUser = localStorage.getItem("duser") === "true"

  return (
    (pageName !== "DASHBOARD" || dashboard.length > 0) && (
      <li className="dropdown p-1 mr-4" key={id}>
        {title === "Profile" ? (
          <div className="rounded-circle d-flex justify-content-center align-items-center media user-name" style={{width: "60px", height: "60px"}}>
            <img src={"null" !== localStorage.getItem("profileImageUrl")?DOWNLOAD_PROFILE_PHOTO + localStorage.getItem("profileImageUrl"):NoProfilePhoto} alt="profile"/>
          </div>
        ):(
          <a
          className={`nav-link text-white`}
          onClick={e => {
            e.preventDefault()
            title === "Support"
              ? window.open(
                  "https://activeinventory.freshdesk.com/support/solutions",
                  "_blank"
                )
              : path && hs.push(path)
          }}
        >
          <span className="main-nav-link-title" key={id}>
            {`${title} `}{" "}
            {subItems?.length > 0 && <span className="link"></span>}
          </span>
        </a>
        )
        }
        {subItems && subItems?.length > 0 && (
          <div className="dropdownMenu">
            {pageAccessData &&
              subItems
                ?.filter(
                  pageItem =>
                    pageAccessData?.includes(pageItem.name) ||
                    pageItem?.pageName === "PROFILE" ||
                    pageItem?.name === "Restock Page Explained"
                )
                ?.map(item => {
                  return (
                    <div className="dropdownMenu-child" key={item.id}>
                      {pages.includes(item?.name) &&
                      (localStorage.getItem("role") === "ADMIN" ||
                        localStorage.getItem("role") === "USER") ? (
                        <a
                          className="text-white dropDownIem"
                          key={item.id}
                          href={
                            item && (isActiveSubscription || item.isViewWOSub || isDemoUser)
                              ? item.path
                              : "#"
                          }
                          target={
                            item && (isActiveSubscription || item.isViewWOSub || isDemoUser)
                              ? item.name
                              : "_self"
                          }
                          rel="noreferrer"
                        >
                          {item?.name}
                        </a>
                      ) : (
                        <a
                          className="text-white dropDownIem"
                          key={item.id}
                          onClick={
                            isActiveSubscription || item.isViewWOSub || isDemoUser
                              ? e => {
                                  e.preventDefault()
                                  hs.push(item?.path)
                                  logoutFun(item)
                                }
                              : e => {
                                  e.preventDefault()
                                }
                          }
                        >
                          {item?.name}
                        </a>
                      )}
                    </div>
                  )
                })}
          </div>
        )}
      </li>
    )
  )
}

const Header = () => {
  const [isHamberger, setHamberger] = useState(false)
  const [isScrolled, setScrolled] = useState(false)
  const [Open, isOpen] = useState()
  const dispatch = useDispatch()
  const history = useHistory()
  const [headerObj, setHeaderObj] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [clientData, setClientData] = useState([])
  const [selectedClient, setSelectedClient] = useState([])
  const [clientDataOriginal, setClientDataOriginal] = useState([])
  const toggle = () => setDropdownOpen(prevState => !prevState)

  const clientProfileSubMenu = (role, isDemoUser) => {
    if ("ADMIN" === role && isDemoUser === "false") {
      return [
        {
          id: 13,
          name: "Profile",
          path: "/profile",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        {
          id: 14,
          name: "Billing",
          path: "/billing",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        {
          id: 15,
          name: "Settings",
          path: "/settings",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        /* {
          id: 16,
          name: "Notification",
          path: "/notification",
          pageName: "PROFILE",
        } ,*/
        {
          id: 17,
          name: "Users",
          path: "/users",
          pageName: "PROFILE",
          isViewWOSub: false,
        },
        { id: 18, name: "Log Out", pageName: "PROFILE", isViewWOSub: true },
      ]
    } else if ("ADMIN" === role && isDemoUser === "true") {
      return [
        {
          id: 13,
          name: "Profile",
          path: "/profile",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        {
          id: 15,
          name: "Settings",
          path: "/settings",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        { id: 18, name: "Log Out", pageName: "PROFILE", isViewWOSub: true },
      ]
    } else {
      return [
        {
          id: 13,
          name: "Profile",
          path: "/profile",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        { id: 14, name: "Log Out", pageName: "PROFILE", isViewWOSub: true },
      ]
    }
  }

  const clientHeader = [
    {
      id: 1,
      title: "Dashboard",
      path: "/dashboard",
      pageName: "DASHBOARD",
      isViewWOSub: false,
    },
    {
      id: 2,
      title: "Restock",
      subItems: [
        {
          id: 3,
          name: "FBA Restock",
          path: "/restock-sheet",
          pageName: "RESTOCK_SHEET",
          isViewWOSub: false,
        },
        {
          id: 4,
          name: "Restock Page Explained",
          path: "/restock-explained",
          pageName: "PRODUCT_OOS",
          isViewWOSub: true,
        },
      ],
    },
    {
      id: 5,
      title: "History",
      subItems: [
        {
          id: 6,
          name: "FBA Restock History",
          path: "/restock-report-history",
          pageName: "RESTOCK_SHEET_HISTORY",
          isViewWOSub: false,
        },
        {
          id: 7,
          name: "Shipping Queue",
          path: "/shipping-queue",
          pageName: "SHIPPING_QUEUE",
          isViewWOSub: false,
        },
      ],
    },
    {
      id: 8,
      title: "Inventory",
      subItems: [
        {
          id: 9,
          name: "Inventory Status",
          path: "/products",
          pageName: "INVENTORY_STATUS",
          isViewWOSub: false,
        },
        {
          id: 10,
          name: "Out of Stock Report",
          path: "/out-of-stock",
          pageName: "PRODUCT_OOS",
          isViewWOSub: false,
        },
      ],
    },
    {
      id: 11,
      title: "Support",
      link: "https://activeinventory.freshdesk.com/support/solutions",
      isViewWOSub: true,
    },
    {
      id: 12,
      //title: localStorage.getItem("name"),
      title: "Profile",
      subItems: clientProfileSubMenu(localStorage.getItem("role"),localStorage.getItem("duser")),
      isViewWOSub: true,
    },
  ]

  const superAdminHeader = [
    {
      id: 1,
      title: "Dashboard",
      path: "/dashboard",
      pageName: "DASHBOARD",
      isViewWOSub: true,
    },
    {
      id: 2,
      title: "Clients",
      path: "/clients",
      pageName: "CLIENT",
      isViewWOSub: true,
    },
    {
      id: 3,
      title: localStorage.getItem("name"),
      subItems: [
        {
          id: 4,
          name: "Profile",
          path: "/profile",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        {
          id: 5,
          name: "Users",
          path: "/users",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        {
          id: 6,
          name: "Notification",
          path: "/notification",
          pageName: "PROFILE",
        },
        { id: 7, name: "Log Out", pageName: "PROFILE", isViewWOSub: true },
      ],
    },
  ]

  const superUserHeader = [
    {
      id: 1,
      title: "Dashboard",
      path: "/dashboard",
      pageName: "DASHBOARD",
      isViewWOSub: true,
    },
    {
      id: 2,
      title: "Restock",
      subItems: [
        {
          id: 3,
          name: "FBA Restock",
          path: "/restock-sheet",
          pageName: "RESTOCK_SHEET",
          isViewWOSub: true,
        },
        {
          id: 4,
          name: "Restock Page Explained",
          path: "/restock-explained",
          pageName: "PRODUCT_OOS",
          isViewWOSub: true,
        },
      ],
    },
    {
      id: 5,
      title: "History",
      subItems: [
        {
          id: 6,
          name: "FBA Restock History",
          path: "/restock-report-history",
          pageName: "RESTOCK_SHEET_HISTORY",
          isViewWOSub: true,
        },
        {
          id: 7,
          name: "Shipping Queue",
          path: "/shipping-queue",
          pageName: "SHIPPING_QUEUE",
          isViewWOSub: true,
        },
      ],
    },
    {
      id: 8,
      title: "Inventory",
      subItems: [
        {
          id: 9,
          name: "Inventory Status",
          path: "/products",
          pageName: "INVENTORY_STATUS",
          isViewWOSub: true,
        },
        {
          id: 10,
          name: "Out of Stock Report",
          path: "/out-of-stock",
          pageName: "PRODUCT_OOS",
          isViewWOSub: true,
        },
      ],
    },
    {
      id: 12,
      title: localStorage.getItem("name"),
      subItems: [
        {
          id: 13,
          name: "Profile",
          path: "/profile",
          pageName: "PROFILE",
          isViewWOSub: true,
        },
        { id: 17, name: "Log Out", pageName: "PROFILE", isViewWOSub: true },
      ],
    },
  ]

  useEffect(() => {
    let url = window.location.pathname
    if (url !== "/clients" && localStorage.getItem("role") === SUPERUSER) {
      dispatch(
        getSuperAdimnClientData(
          response => {
            let tempClientData = response.data.content.map(client => {
              return {
                label: client.name,
                value: client.id,
              }
            })
            setClientData(tempClientData)
            setClientDataOriginal(response.data.content)

            if (
              localStorage.getItem("selectedClientId") === undefined ||
              localStorage.getItem("selectedClientId") === ""
            ) {
              setSelectedClient(clientData[0])
              console.log("line 302")
              localStorage.setItem(
                "marketplaceId",
                response.data.content[0]?.marketplaces[0]?.id || []
              )
              localStorage.setItem("clientId", response.data.content[0]?.id)
            } else {
              console.log(localStorage.getItem("selectedClientId"))
              const client = response.data.content.find(
                client => client.id === localStorage.getItem("selectedClientId")
              )
              if (client === undefined) {
                setSelectedClient(clientData[0])
                console.log("line 309")
                localStorage.setItem(
                  "marketplaceId",
                  response.data.content[0]?.marketplaces[0]?.id || []
                )
                localStorage.setItem("clientId", response.data.content[0]?.id)
              } else {
                setSelectedClient({
                  label: client.name,
                  value: client.id,
                })
                console.log("line 314")
                localStorage.setItem(
                  "marketplaceId",
                  client.marketplaces[0]?.id || []
                )
                localStorage.setItem("clientId", client.id)
              }
            }
          },
          error => {}
        )
      )
    }
  }, [])

  useEffect(() => {
    localStorage.getItem("isSubscriptionActive") === "false" && localStorage.getItem("duser") === "false" &&
      history.push("/billing")
    let role = localStorage.getItem("role")
    switch (role) {
      case "SUPERADMIN":
        setHeaderObj(superAdminHeader)
        break
      case "SUPERUSER":
        setHeaderObj(superUserHeader)
        break
      default:
        setHeaderObj(clientHeader)
        break
    }
  }, [])

  const logout = item => {
    if (item.name === "Log Out") {
      dispatch(
        logOutUser(
          responseData => {
            localStorage.clear()
            history.push("/logout")
          },
          errorData => {
            localStorage.clear()
            history.push("/logout")
          }
        )
      )
    }
  }

  const stickyNavBar = () => {
    const offset = window.scrollY
    if (offset > 5) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", stickyNavBar)
  }, [])

  const handleClientChange = enteredClient => {
    let selectedClient = clientDataOriginal.filter(
      client => client.id === enteredClient.value
    )
    let clientId = selectedClient[0]?.id
    let clientName = selectedClient[0]?.name
    let marketPlaceId = selectedClient[0]?.marketplaces[0]?.id
    localStorage.setItem("selectedClient", clientName)
    localStorage.setItem("selectedClientId", clientId)
    localStorage.setItem("marketplaceId", marketPlaceId || [])
    localStorage.setItem("clientId", clientId)
    window.location.reload() //reload the page
  }

  return (
    <React.Fragment>
      <div className="position-fixed w-100 main-container top-0">
        {localStorage.getItem("duser") === "true" && (<a className="ai-account-reminder" href={`/14-days-trial?clientId=${localStorage.getItem("clientId")}`} >connect my amazon account & start my 14-day free trial &gt; </a>)}
        {localStorage.getItem("duser") === "false" && localStorage.getItem("isSubscriptionActive") === "false" && (<a className="ai-account-subscription" href={`/billing`}>START MY PAID SUBSCRIPTION &gt; </a>)}
        <section 
          className={`d-flex align-items-center justify-content-between pr-2 pl-2 header-section ${
            isScrolled && "header-sec-sticky-height"
          }`}
        >
          <div>
            <span className="navbar-logo">
              <span onClick={() => history.push("/")}>
                <img
                  style={{ width: "185px", height: "60px" }}
                  src={Headerlogo}
                  className={`nav-bar-logo ${
                    isScrolled && "sicky-nav-bar-logo"
                  }`}
                  alt=""
                />
              </span>
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-end w-100" style={{marginRight: "1%"}}>
            {/* <div className="d-flex justify-content-end w-100"> */}
            <ul className="d-flex align-items-center justify-content-end m-0 header-ul flex-wrap p-3">
              {localStorage.getItem("role") === SUPERUSER && (
                <>
                  <label className="text-white my-3 mx-2 h6">
                    Select Client
                  </label>
                  <Select
                    value={selectedClient}
                    defaultValue={localStorage.getItem("selectedClientId")}
                    //defaultInputValue={localStorage.getItem("selectedClientId")}
                    options={clientData}
                    onChange={value => handleClientChange(value)}
                    className="select-width"
                    menuPlacement={"bottom"}
                    menuPosition={"fixed"}
                    width="auto"
                    placeholder="Select Client"
                    isClearable={true}
                    isSearchable={true}
                  />
                </>
              )}
              {localStorage.getItem('duser') === 'true' && <a className={`nav-link text-white`}>
                <span className="tk-verveine" style={{font: 'normal normal normal 24px/34px verveine;' , color: '#ffee00', marginRight: '15px', fontSize: '32px'}}>
                  <a href={`/14-days-trial?clientId=${localStorage.getItem("clientId")}`}>
                    14-Day Trail
                  </a>
                </span>
              </a>}
              {headerObj?.map(item => {
                return <HeaderItem logoutFun={logout} key={item.id} {...item} />
              })}
            </ul>
            {/* </div> */}
          </div>
        </section>
      </div>

      {/* mobile screen navbar start */}
      <div className="position-fixed w-100 main-container mobile-container bg-dark-blue top-0">
        {localStorage.getItem("duser") === "true" && (<a className="ai-account-reminder d-none" href={`/14-days-trial?clientId=${localStorage.getItem("clientId")}`}>connect my amazon account & start my 14-day free trial &gt; </a>)}
        {localStorage.getItem("duser") === "false" && localStorage.getItem("isSubscriptionActive") === "false" && (<a className="ai-account-subscription" href={`/billing`}>START MY PAID SUBSCRIPTION &gt; </a>)}
        <section
          className={`d-flex justify-content-between align-items-center header-section ${
            isScrolled & "header-sec-sticky-height "
          }`}
        >
          <div>
            <span className="navbar-logo">
              <span onClick={() => history.push("/")}>
                <img
                  src={Headerlogo}
                  alt=""
                  className={`nav-bar-logo ${
                    isScrolled && "sicky-nav-bar-logo"
                  }`}
                />
              </span>
            </span>
          </div>
          <div>
            <div
              className={`hamburger ${isHamberger && "is-active"}`}
              id="hamburger-1"
              onClick={() => setHamberger(!isHamberger)}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </section>
        {isHamberger && (
          <ul
            className={`d-flex flex-column align-items-center mobile-header-ul ${
              isHamberger && "mobile-header-ul-height"
            }`}
          >
            {localStorage.getItem("role") === SUPERUSER && (
              <>
                <label className="text-white my-3 mx-2 h6">Select Client</label>
                <Select
                  value={selectedClient}
                  defaultValue={localStorage.getItem("selectedClient")}
                  defaultInputValue={localStorage.getItem("selectedClient")}
                  options={clientData}
                  onChange={value => handleClientChange(value)}
                  className="select-width"
                  menuPlacement={"bottom"}
                  menuPosition={"fixed"}
                  width="auto"
                  placeholder="Select Client"
                  isClearable={"true"}
                  isSearchable={"true"}
                />
              </>
            )}
            {headerObj?.map((item, index) => {
              return (
                <MobileDropDownComp
                  {...item}
                  key={item.id}
                  setHamberger={() => setHamberger(!isHamberger)}
                  isOpenOrNot={Open && Open === index}
                  openDropDown={() =>
                    Open === index ? isOpen(null) : isOpen(index)
                  }
                  logoutFun={logout}
                />
              )
            })}
          </ul>
        )}
      </div>
      {/* mobile screen navbar  end */}
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

HeaderItem.propTypes = {
  title: PropTypes.any,
  pageName: PropTypes.any,
  subItems: PropTypes.any,
  logoutFun: PropTypes.func,
  path: PropTypes.any,
  id: PropTypes.any,
}

MobileDropDownComp.propTypes = {
  title: PropTypes.any,
  subItems: PropTypes.any,
  setHamberger: PropTypes.func,
  isOpenOrNot: PropTypes.bool,
  openDropDown: PropTypes.func,
  id: PropTypes.any,
  logoutFun: PropTypes.func,
  path: PropTypes.any,
  pageName: PropTypes.any,
}

const mapStatetoProps = state => {
  const { layoutType, leftMenu } = state.Layout
  return { layoutType, leftMenu }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(Header))
