import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const LineChartComp = ({ periodData, chartType }) => {
  const options = {
    chart: {
      height: 350,
      type: chartType,
      // zoom: {
      //   enabled: false
      // },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toLocaleString('en-US')
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={periodData}
        type={chartType}
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

LineChartComp.propTypes = {
  periodData: PropTypes.any,
  chartType: PropTypes.any,
}
export default LineChartComp;
