import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { logoutUser } from "../../store/actions"
import { useDispatch } from "react-redux"
import Header from "components/AuthLayout/AuthHeader";
import Footer from "components/AuthLayout/AuthFooter";
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import "./authentication.scss"
import "../../assets/scss/style.scss"
import logo from "assets/images/logo-green.png"

const Logout = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(logoutUser(props.history))
  }, [dispatch])

  useEffect(() => {
    document.body.classList.add("ai-login-page")
    return () => {
      document.body.classList.remove("ai-login-page")
    }
  }, [])

  const messageArr = [
    "Have a Marvelous Day: causing wonder, supernatural, of highest kind or quality, notably superior",
    "Have an Amazing Day: causing great surprise or wonder, astonishment",
    "Have an Astounding Day: causing astonishment or amazement",
    "Have a Stupefying Day: causing a strong emotional reaction because of unexpectedness",
    "Have a Stunning Day: strikingly impressive especially in beauty or excellence",
    "Have an Uncommon Day: being out of the ordinary",
    "Have an Awesome Day: causing wonder or astonishment",
    "Have an Extraordinary Day: going beyond what is usual, regular or customary"
  ]

  let randomIndex = Math.floor(Math.random() * messageArr.length)
  let randomMessage = messageArr[randomIndex]
  useEffect(() => {
    document.body.classList.add("ai-login-page")
    return () => {
      document.body.classList.remove("ai-login-page")
    }
  }, [])
  return (
    <>
      <MetaTags>
        <title>Logout | Active Inventory</title>
      </MetaTags>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "70px 20px 20px 20px",
          }}
        >
          <img src={logo} alt="" style={{ width: "220px" }} />
        </div>
        <section className="header1 top_section" id="header01-0" style={{ backgroundColor: "#082c4e !important" }}>
          <Container fluid>
            <Row>
              <Col xs="12" className="align-center h2 ai-logout-text">
                You have successfully <br></br>logged out
              </Col>
              <Col className="align-center my-3 h4 text-white" style={{ font: "normal normal bold 20px nunito-sans;" }}>
                {randomMessage}
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="d-flex justify-content-center flex-column align-center my-3">
                <div className="w-100 text-center">
                  <button className="ai-btn" style={{ font: "normal normal bold 14px novecento-sans", textTransform: "uppercase", letterSpacing: "0.24px" }} type="button" onClick={() => history.push("/login")}>Login</button>
                </div>
                <a style={{ color: "#FFEE00", font: "normal normal bold 12px nunito-sans", letterSpacing: "0.24px" }} onClick={() => history.push("/")}>Back to Home Page</a>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
