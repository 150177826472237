import React from "react";
import PropTypes from "prop-types"
import "./billing.scss"

const SubscriptionPlan = ({ plans, handlePlanSelection }) => {
    return (
        <div className="d-flex flex-column h-auto align-items-center">
            <div className="card m-0 pt-3 px-8 pb-5" style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 0px 6px #00000048", borderRadius: "20px", width: "400px" }}>
            <span className="text-center" style={{font: "bold 15px nunito-sans", color: "#082C4E"}}>To activate your paid subscription, select a plan below.</span>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {
                        Object.entries(plans).map((item, index) => {
                            return <div key={index}>
                                <h5 className="card-title text-center" style={{font: "bold 20px novecento-sans", color: "#082C4E"}}>{item[0]}</h5>
                                <button className="btn btn-primary btn-block m-0" style={{ backgroundColor: "#2299AA", borderRadius: "20px", border: 0, width: "100px" }} onClick={() => handlePlanSelection(item, index)}>{`$${item[1].price}`}</button>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlan;

SubscriptionPlan.propTypes = {
    plans: PropTypes.any,
    handlePlanSelection: PropTypes.func
}