import { ENABLE_LOADER, DISABLE_LOADER } from './actionTypes';

const initialState = {
  loading: false,
}

const Loader = (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_LOADER:
      return {
        ...state,
        loading: true,
      }
    case DISABLE_LOADER:
      return {
        ...state,
        loading: false,
      }
    default:
      return state 
  }
}

export default Loader
