import React from "react"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/bootstrap/css/bootstrap-grid.min.css"
import "../assets/bootstrap/css/bootstrap-reboot.min.css"
import "../assets/theme/css/style.css"
import Header from "static-site/common/Header"
import Footer from "static-site/common/Footer"
import { MetaTags } from "react-meta-tags"

const Support = () => {
  return (
    <React.Fragment>
      <div className="static-site">
        <MetaTags>
          <title>Support | Active Inventory</title>
        </MetaTags>
        <Header />
        <section
          data-bs-version="5.1"
          className="header8 cid-t2fEPRI4gf"
          id="header8-35"
        >
          <div className="container" style={{ marginTop: "120px" }}>
            <div className="row align-items-center">
              <div className="col-12 col-md-4">
                <div className="image-wrapper">
                  <img src={MBRPNG} alt="" />
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="text-wrapper">
                  <p className="desc1 mbr-section-title mbr-fonts-style display-2">
                    Support
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content5 cid-t2gyRLOw4Z" id="content05-4o">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-4">
                <h2 className="mbr-title align-left mbr-fonts-style mb-5 display-5">
                  <p>
                    <strong>Add Twak script</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </h2>
              </div>

              <div className="col-12 col-md-12 col-lg-8 md-pb text-white">
                <div className="text-wrapper">
                  <h3 className="mbr-section-title align-left mbr-fonts-style mb-3 display-5">
                    <strong>
                      Put Twak Script here for support request - in
                      dropbox.&nbsp;&nbsp;
                    </strong>
                  </h3>
                  <p className="mbr-text mbr-fonts-style align-left mb-5 display-7"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Support
