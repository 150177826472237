import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getStripePublicKey } from "store/action/billing"
import PaymentForm from "./PaymentForm"

export default function StripeContainer() {

    const dispatch = useDispatch()
    const [stripePublicKey, setStripePublicKey] = useState("")
    const stripeTestPromise = loadStripe(stripePublicKey)

    useEffect(() => {
        dispatch(
            getStripePublicKey(
                responseData => {
                    setStripePublicKey(responseData)
                },
                errorData => {
                    setStripePublicKey("")
                }
            )
        )
    }, [])

    return (
        <Elements stripe={stripeTestPromise}>
            <PaymentForm />
        </Elements>
    )
}