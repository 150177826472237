import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Row } from "reactstrap"
import SubscriptionPlan from "./SubscriptionPlan"
import { useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import "../../assets/scss/style.scss"

const SubscriptionDetails = ({
  handleChangeSubscription,
  handleCancelSubscription,
  plan,
  handlePlanSelection,
  handleReactivateSubscription,
  handleAddNewCard,
  setChangePlan,
  changeSubscription,
  changeSubscriptionPlan,
}) => {
  const dateOptions = { month: "2-digit", year: "numeric", day: "2-digit" }

  const subscription = useSelector(state => state?.Subscription?.data)

  const [changeSubscriptionClicked, setChangeSubscriptionClicked] =
    useState(false)

  const subscriptionCancelDate = new Date(
    subscription?.subscriptionCancelDate
  ).toLocaleDateString("en-US", dateOptions)

  const subscriptionStartDate = new Date(
    subscription?.activeSubscription?.subscriptionStartDate
  ).toLocaleDateString("en-US", dateOptions)

  const subscriptionEndDate = new Date(
    subscription?.activeSubscription?.subscriptionEndDate
  ).toLocaleDateString("en-US", dateOptions)

  const planType =
    subscription?.activeSubscription?.subscriptionType === "MONTHLY"
      ? "YEARLY"
      : "MONTHLY"

  const planPrice =
    subscription?.activeSubscription?.subscriptionType === "MONTHLY"
      ? `$${plan?.YEARLY?.price}`
      : `$${plan?.MONTHLY?.price}`

  const changeSubscriptionModalText =
    `You are changing your plan to ` +
    `${planType}` +
    ` and this plan will cost you ` +
    `${planPrice}.` +
    ` Amount paid will be adjusted accordingly. Are you sure you want to continue?`

  const handleCancelButtonClick = () => {
    handleCancelSubscription()
  }

  const handleChangeButtonClick = () => {
    setChangeSubscriptionClicked(!changeSubscriptionClicked)
    handleChangeSubscription()
  }
  const confrimChange = () => {
    setChangePlan(true)
    setChangeSubscriptionClicked(false)
    changeSubscription(changeSubscriptionPlan)
  }
  return (
    <>
      {changeSubscriptionClicked && (
        <SweetAlert
          title={changeSubscriptionModalText}
          warning
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => confrimChange()}
          onCancel={() => setChangeSubscriptionClicked(false)}
        ></SweetAlert>
      )}
      {subscription?.activeSubscription &&
      localStorage.getItem("isSubscriptionActive") === "true" ? (
        <div className="text-center w-100">
          <Row
            className="mt-3 mb-4"
            style={{ borderRadius: "20px", backgroundColor: "#29FD2F" }}
          >
            <Col>
              <h5
                style={{ color: "#137B16", textTransform: "uppercase" }}
                className={
                  subscription?.subscriptionPaidPlan
                    ? "m-0 py-2 font-weight-bold"
                    : "m-0 py-2 font-weight-bold"
                }
              >
                {subscription?.subscriptionPaidPlan
                  ? `your subscription is active!`
                  : `You have canceled your active subscription on ${subscriptionCancelDate} !`}
              </h5>
            </Col>
          </Row>
          <div
            className="text-center border rounded-4 pt-3"
            style={{ boxShadow: "0px 0px 3px #00000047" }}
          >
            <h6
              className="ai-head-text py-1"
              style={{
                fontSize: " 14px",
              }}
            >
              SUBSCRIPTION DETAILS
            </h6>
            <div className="ai-billing-container">
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 fw-bold text-center"
                >
                  Card Number (last 4 digit)
                </h6>
              </div>
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 text-center"
                >
                  <span className="font-weight-bold">{`XXXX XXXX XXXX ${subscription?.activeSubscription?.cardLast4} `}</span>
                  <span className="pointer">
                    <i
                      title="Add new card"
                      onClick={() => handleAddNewCard()}
                      className="bx bx-edit ml-2"
                      style={{ fontSize: "1rem" }}
                    />
                  </span>
                </h6>
              </div>
            </div>
            <div className="ai-billing-container">
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 fw-bold text-center"
                >
                  Card Expiry Date
                </h6>
              </div>
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 text-center font-weight-bold"
                >
                  {subscription?.activeSubscription?.cardExpiry}
                </h6>
              </div>
            </div>
            <div className="ai-billing-container">
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 fw-bold text-center"
                >
                  Subscription Fee
                </h6>
              </div>
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 text-center font-weight-bold"
                >{`$${subscription?.activeSubscription?.subscriptionPrice} `}</h6>
              </div>
            </div>
            <div className="ai-billing-container">
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 fw-bold text-center"
                >
                  Subscription Type
                </h6>
              </div>
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 text-center font-weight-bold"
                >
                  {subscription?.activeSubscription?.subscriptionType}
                </h6>
              </div>
            </div>
            <div className="ai-billing-container">
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 fw-bold text-center"
                >
                  Subscription Start Date
                </h6>
              </div>
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 text-center font-weight-bold"
                >
                  {subscriptionStartDate}
                </h6>
              </div>
            </div>
            <div className="ai-billing-container">
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 fw-bold text-center"
                >
                  Subscription End Date
                </h6>
              </div>
              <div style={{ width: "180px" }}>
                <h6
                  style={{ fontFamily: "nunito-sans", color: "#082C4E" }}
                  className="py-1 text-center font-weight-bold"
                >
                  {subscriptionEndDate}
                </h6>
              </div>
            </div>
            <Row className="pb-3">
              {subscription?.subscriptionPaidPlan ? (
                <div
                  className="d-flex justify-content-center"
                  style={{
                    flexFlow: "row wrap",
                    gridTemplateColumns: "auto auto",
                  }}
                >
                  {!changeSubscriptionClicked && (
                    <div className="text-center">
                      <Button
                        color="danger"
                        style={{
                          borderRadius: "20px",
                          font: "normal normal bold 16px novecento-sans",
                        }}
                        onClick={() => handleCancelButtonClick()}
                      >
                        CANCEL SUBSCRIPTION
                      </Button>
                    </div>
                  )}
                  <div className="text-center">
                    <Button
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#2299AA",
                        font: "normal normal bold 16px novecento-sans",
                      }}
                      onClick={() => handleChangeButtonClick()}
                    >
                      {changeSubscriptionClicked
                        ? "You are about to change plan"
                        : subscription?.activeSubscription?.subscriptionType ===
                          "MONTHLY"
                        ? "CHANGE TO YEARLY PLAN"
                        : "CHANGE TO MONTHLY PLAN"}
                    </Button>
                  </div>
                </div>
              ) : (
                <Col className="d-flex justify-content-center">
                  <Button
                    style={{
                      borderRadius: "20px",
                      font: "normal normal bold 16px novecento-sans",
                    }}
                    color="primary"
                    onClick={() =>
                      handleReactivateSubscription(
                        subscription?.activeSubscription?.subscriptionId
                      )
                    }
                  >
                    {"Renew Subscription"}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      ) : (
        <div>
          <div className="rounded-3 p-2 w-100 shadow bg-white h-auto">
            <div
              className="h5 text-white font-weight-bold text-center"
              style={{
                backgroundColor: "#FC0D1B",
                borderRadius: "17px",
                height: "35px",
                textTransform: "uppercase",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No active subscription found
            </div>
            {/* <p className="h6 text-secondary">
                            {localStorage.getItem("isSubscriptionActive") === "false"
                                ? `You don't have active subscription yet. please activate your subscription to continue using this program.`
                                : `Please select subscription plan as per your need and enable all the feature of active inventory.`
                            }
                        </p> */}
            <SubscriptionPlan
              plans={plan}
              handlePlanSelection={handlePlanSelection}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SubscriptionDetails

SubscriptionDetails.propTypes = {
  plan: PropTypes.any,
  setChangePlan: PropTypes.any,
  changeSubscriptionPlan: PropTypes.any,
  handleChangeSubscription: PropTypes.func,
  changeSubscription: PropTypes.func,
  handleCancelSubscription: PropTypes.func,
  handlePlanSelection: PropTypes.func,
  handleReactivateSubscription: PropTypes.func,
  handleAddNewCard: PropTypes.func,
}
