import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Model from "../Model"

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import "./layout.scss"
//components
import Header from "./Header"
import Footer from "./Footer"
import { logOutUser } from "store/action/user"

const Layout = props => {
  const dispatch = useDispatch()
  const trialPendingdays = localStorage.getItem("tpd")
  const demoPendingdays = localStorage.getItem("dpd")
  const [isTrialAccount, setIsTrialAccount] = useState(localStorage.getItem("sp") === "true" && localStorage.getItem("ita") === "true" && (trialPendingdays === '7' || trialPendingdays === '3' || trialPendingdays === '2' || trialPendingdays === '0'))
  const [isTrialAccountExpired, setIsTrialAccountExpired] = useState(localStorage.getItem("sp") === "true" && localStorage.getItem("ita") === "true" && parseInt(trialPendingdays) < 0)
  const [isDemoAccount, setIsDemoAccount] = useState(localStorage.getItem("sp") === "true" && localStorage.getItem("duser") === "true" && demoPendingdays === "3")
  const [isDemoUserExpired, setIsDemoUserExpired] = useState(localStorage.getItem("sp") === "true" && localStorage.getItem("duser") === "true" && parseInt(demoPendingdays) <= 0)
  const { topbarTheme, layoutWidth } = useSelector(state => ({
    topbarTheme: state.Layout.topbarTheme,
    layoutWidth: state.Layout.layoutWidth,
    isPreloader: state.Layout.isPreloader,
  }))

  const { loader } = useSelector(state => ({
    loader: state.Loader.loading,
  }))

  const history = useHistory()

  const auth = useSelector(state => state.Login.authentication)

  useEffect(() => {
    if (!auth) {
      history.push("/login")
      localStorage.clear()
    }
  }, [auth])

  useEffect(() => {
    const title = props.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title = currentage + " | Active Inventory"
  }, [props.location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"))
  }, [dispatch])

  useEffect(() => {
    if (loader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [loader])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme))
    }
  }, [dispatch, topbarTheme])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [dispatch, layoutWidth])

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const openMenu = () => {setIsMenuOpened(!isMenuOpened)}
  
  const handleLogout = () => {
    dispatch(
      logOutUser(
        responseData => {
          history.push("/logout")
        },
        errorData => {
          history.push("/logout")
        }
      )
    )
    setOpen(false)
  }
  
  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Model
          modalOpen={isTrialAccount}
          title1={"Don’t Lose Access!"}
          title2={"Heads up!"}
          closeIconshow={true}
          handleClose={() => {
              setIsTrialAccount(false)
              localStorage.removeItem("sp")
            }
          }
          content={`Your free trial ends in ${trialPendingdays} days`}
          color={"#FFEE00"}
          className={"text-white"}
        />
        <Model
          modalOpen={isTrialAccountExpired}
          title1={"We’d hate to see you go!"}
          title2={"We’d hate to see you go!"}
          action={handleLogout}
          content={`Your free trial has ended`}
          closeIconshow={false}
          color={"#FFFFFF"}
          className={"text-danger"}
          handleclose={() =>
            setIsDemoUser(localStorage.removeItem("isDemouser"))
          }
        />
        <Model
          modalOpen={isDemoAccount}
          title1={"Your Demo Pass is About to Expire!"}
          threeDaysLeft={true}
          handleClose={() => {
              setIsDemoAccount(false)
              localStorage.removeItem("sp")
            }
          }
          action={handleLogout}
          content={`start your 14-day free trial`}
          closeIconshow={true}
          color={"#2299AA"}
          className={"text-warning-light"}
          forDemouser={true}
        />

        <Model
          modalOpen={isDemoUserExpired}
          title1={"Your Demo Pass Expired!"}
          threeDaysLeft={true}
          content={`start your 14-day free trial`}
          closeIconshow={false}
          color={"#2299AA"}
          className={"text-warning-light"}
          forDemouser={true}
        />
        <div className="main-content content-wrapper">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayout: PropTypes.func /*  */,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  topbarTheme: PropTypes.any,
}

export default withRouter(Layout)
