import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Modal, ModalBody } from "reactstrap"

const Model = ({
  content,
  modalOpen,
  title1,
  title2,
  handleClose,
  action,
  closeIconshow,
  color,
  className,
  forDemouser,
}) => {
  Model.propTypes = {
    content: PropTypes.any,
    title1: PropTypes.any,
    title2: PropTypes.any,
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.any,
    action: PropTypes.any,
    closeIconshow: PropTypes.bool,
    color: PropTypes.any,
    className: PropTypes.any,
    forDemouser: PropTypes.bool,
  }

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        size="lg"
        className="w-100 modal-wrapper-trial"
        autoFocus={true}
        toggle={() => <></>}
        style={{
          top: "139px",
          border: 0,
          backgroundColor: "#082C4E",
          borderRadius: "35px",
        }}
      >
        <ModalBody
          style={{
            backgroundColor: "#082C4E",
            border: 0,
            borderRadius: "35px",
          }}
        >
          {closeIconshow && (
            <Row className="d-flex justify-content-end w-100 m-0 text-center">
              <div
                className="ai-modal-close-btn-without-bg"
                onClick={handleClose}
              >
                <div>&#10005;</div>
              </div>
            </Row>
          )}
          <Row
            className={`d-flex justify-content-center w-100 m-0 text-center mb-3 ${
              !closeIconshow && "mt-5"
            }`}
          >
            <div
              style={{
                font: "normal normal normal 47px Verveine",
                color: `${color}`,
              }}
            >
              {title1}
            </div>
          </Row>
          <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
            <div
              className={className}
              style={{ font: "normal normal bold 51px/34px novecento-sans" }}
            >
              {content}
            </div>
          </Row>
          {forDemouser && (
            <Row className="text-center ">
              <div>
                <p className="text-white" style={{font: "normal normal normal 14px nunito-sans", letterSpacing: "0.24px" ,textAlign:"center"}}>
                  The free trial gives you the opportunity to connect your
                  actual Amazon account
                  <br />
                  and test out the software with your real data
                </p>
              </div>
            </Row>
          )}

          <div className="d-flex justify-content-center col-12 mt-4">
            <Row className="align-items-center">
              <Col className="d-flex justify-content-center">
                <a
                  className="sm-buttons"
                  href={(forDemouser?`/14-days-trial?clientId=${localStorage.getItem("clientId")}`:`/billing`)}
                  style={{
                    borderRadius: "40px",
                    backgroundColor: (forDemouser?'#FFEE00':'#2299AA'),
                    color:  (forDemouser?'#082C4E':'#FFFFFF'),
                    font: "normal normal bold 21px novecento-sans",
                    textTransform: "uppercase",
                    letterSpacing: "0.42px",
                    border: 0,
                    padding: "10px 50px",
                    marginBottom: "10px"
                  }}
                  color="primary"
                  onClick={action}
                >
                  {forDemouser?(
                    'START MY 14-DAY FREE TRIAL  >'
                  ): (
                    'activate my paid subscription now'
                  )}
                </a>
              </Col>
            </Row>
          </div>
          {forDemouser && (
            <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
              <div>
                <p className="text-white font-italic">100% Risk Free • No Card Required</p>
              </div>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Model
