import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Form,
  Input,
  Label,
  Col,
  Row,
  FormFeedback,
  InputGroupText,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import { changePassword } from "store/action/user"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import "./authentication.scss"

import "../../assets/scss/style.scss"
import "react-image-crop/dist/ReactCrop.css"
import ReactCrop from "react-image-crop"
import { UPLOAD_PROFILE_PHOTO, DOWNLOAD_PROFILE_PHOTO } from "../../utils/url"

const Settings = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const passwordRegExp =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
  const [, setChangePasswordModal] = useState(false)
  const [clientMarketPlaceId, setClientMarketPlaceId] = useState()
  const [disableButton, setDisableButton] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false)

  useEffect(() => {
    let getclientMarketPlaceId = localStorage.getItem("marketplaceId")
    setClientMarketPlaceId(getclientMarketPlaceId)
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Password must be of minimum 8 characters long")
        .matches(
          passwordRegExp,
          "Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case."
        ),
      newPassword: Yup.string()
        .required("Please Enter Your New Password")
        .min(8, "Password must be of minimum 8 characters long")
        .matches(
          passwordRegExp,
          "Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case."
        ),
    }),
    onSubmit: values => {
      dispatch(
        changePassword(
          { data: { ...values } },
          response => {
            setChangePasswordModal(false)
          },
          error => {
            addToast(error.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    },
  })

  const handleSubmit = event => {
    setDisableButton(true)
    event.preventDefault()
    window.location = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.c355d247-39dd-4ee0-9829-8d7b993538cc&state=${clientMarketPlaceId}_US_renew&version=beta`
    //window.location = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.0199b154-30f6-411d-aaa5-4e1eb4452ea1&state=${clientMarketPlaceId}_US_renew&version=beta`
  }

  const toggleVisibility = type => {
    switch (type) {
      case "passowrd":
        setPasswordVisibility(!passwordVisibility)
        break
      case "confirmPassword":
        setConfirmPasswordVisibility(!confirmPasswordVisibility)
        break
      default:
        break
    }
  }

  const [modal, setModal] = useState(false)
  const toggle = () => {
    setImage(null)
    setModal(!modal)
  }

  const [image, setImage] = useState(null)
  const [imageName, setImageName] = useState("")
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "px" })
  const [croppedImage, setCroppedImage] = useState(null)

  const handleImageChange = e => {
    e.preventDefault()
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
      setImageName(file.name)
    }
    reader.readAsDataURL(file)
  }

  const handleCropChange = crop => {
    setCrop(crop)
  }

  const handleCropComplete = (crop, pixelCrop) => {
    // console.log(crop, pixelCrop)
  }

  const handleCropSubmit = () => {
    const canvas = document.createElement("canvas")
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")
    ctx.beginPath()
    ctx.arc(
      crop.width / 2,
      crop.height / 2,
      crop.width / 2,
      0,
      2 * Math.PI,
      false
    )
    ctx.clip()
    const imageElement = new Image()
    imageElement.onload = () => {
      ctx.drawImage(
        imageElement,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      )

      canvas.toBlob(blob => {
        setCroppedImage(blob)
      })
    }
    imageElement.src = image
  }

  const [imageData, setImageData] = useState(null)

  const handleUpload = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      localStorage.getItem("token_type") +
        " " +
        localStorage.getItem("access_token")
    )
    var formdata = new FormData()
    formdata.append("file", croppedImage, imageName)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(UPLOAD_PROFILE_PHOTO, requestOptions)
      .then(response => response.json())
      .then(data => {
        setImageData(data)
      })
      .catch(error => console.log("error", error))
    setModal(!modal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Profile | Active Inventory</title>
        </MetaTags>
        <div className="ai-grid-container">
          <div
            className="ai-grid-item"
            style={{ padding: "10px 10px 0px 10px" }}
          >
            {imageData && imageData.data && (
              <>
                {localStorage.setItem(
                  "profileImageUrl",
                  imageData.data.imageName
                )}
              </>
            )}
            <div>
              {localStorage.getItem("profileImageUrl") !== "null" ? (
                <div
                  className="border rounded-circle d-flex justify-content-center align-items-center media user-name"
                  style={{
                    width: "160px",
                    height: "160px",
                  }}
                >
                  <img
                    src={
                      DOWNLOAD_PROFILE_PHOTO +
                      localStorage.getItem("profileImageUrl")
                    }
                    alt="profile"
                  />
                </div>
              ) : (
                <div
                  className="bg-secondary ai-head-text border rounded-circle d-flex justify-content-center align-items-center media user-name"
                  style={{
                    width: "160px",
                    height: "160px",
                    fontSize: "60px",
                    color: "#98DEE8",
                  }}
                >
                  {localStorage.getItem("name")?.split(" ")[0][0]}
                  {localStorage.getItem("name")?.split(" ")[1][0]}
                </div>
              )}

              <div>
                <a
                  onClick={toggle}
                  className="ai-user-link"
                  style={{
                    color: "#2299AA",
                    fontSize: "14px",
                  }}
                >
                  ADD PROFILE PHOTO
                </a>
              </div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                // backdrop={false}
                className="w-100 modal-wrapper"
                // backdropClassName="custom-backdrop"
                style={{
                  border: 0,
                  backgroundColor: "#FFFFFF",
                  borderRadius: "35px",
                }}
              >
                <ModalHeader toggle={toggle} charcode="close">
                  Upload Profile Photo
                </ModalHeader>
                <ModalBody className="pb-2">
                  <Input
                    className="mb-2"
                    type="file"
                    accept="image/*"
                    name="file"
                    onChange={handleImageChange}
                  />
                  {image && (
                    <div className="d-grid justify-content-center">
                      <ReactCrop
                        circularCrop
                        src={image}
                        onComplete={handleCropComplete}
                        crop={crop}
                        onChange={handleCropChange}
                        aspect={1}
                        maxWidth={160}
                        maxHeight={160}
                      >
                        <img src={image} />
                      </ReactCrop>
                      {crop && (
                        <Button onClick={handleCropSubmit}>Crop Image</Button>
                      )}
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={handleUpload}>
                    Confirm
                  </Button>
                  <Button color="primary" onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              <div>
                <p
                  className="ai-head-text"
                  style={{ fontSize: "40px", margin: "0px", height: "38px" }}
                >
                  {localStorage.getItem("name")}
                </p>
                <p className="ai-head-text" style={{ fontSize: "15px" }}>
                  USER PROFILE
                </p>
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                paddingBottom: "10px",
              }}
            >
              <div style={{ padding: "0px 15px 0px 0px", width: "100%" }}>
                <p
                  style={{
                    paddingBottom: "8px",
                    margin: "0px",
                    borderBottom: "1px solid #082C4E",
                    font: "bold nunito-sans",
                    color: "#082C4E",
                    fontSize: "30px",
                  }}
                >
                  {localStorage.getItem("name")?.split(" ")[0]}
                </p>
                <p
                  className="ai-head-text"
                  style={{
                    fontSize: "12px",
                    color: "#082C4E",
                    lineHeight: "28px",
                  }}
                >
                  {"First Name"}
                </p>
              </div>
              <div style={{ padding: "0px 0px 0px 15px", width: "100%" }}>
                <p
                  style={{
                    paddingBottom: "8px",
                    margin: "0px",
                    borderBottom: "1px solid #082C4E",
                    font: "bold nunito-sans",
                    color: "#082C4E",
                    fontSize: "30px",
                  }}
                >
                  {localStorage.getItem("name")?.split(" ")[1]}
                </p>
                <p
                  className="ai-head-text"
                  style={{
                    fontSize: "12px",
                    color: "#082C4E",
                    lineHeight: "28px",
                  }}
                >
                  {"Last Name"}
                </p>
              </div>
            </div>
            <div>
              <p
                style={{
                  paddingBottom: "8px",
                  margin: "0px",
                  borderBottom: "1px solid #082C4E",
                  font: "bold nunito-sans",
                  color: "#082C4E",
                  fontSize: "30px",
                }}
              >
                {localStorage.getItem("email")}
              </p>
              <p
                className="ai-head-text"
                style={{
                  fontSize: "12px",
                  color: "#082C4E",
                  marginBottom: "0px",
                  lineHeight: "28px",
                }}
              >
                {"Email"}
              </p>
            </div>
          </div>
          <div className="ai-grid-item" style={{ backgroundColor: "#F3F7FB" }}>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div>
                <div>
                  <h3
                    className="ai-text fw-bold pt-3 pb-4"
                    style={{ color: "#082C4E" }}
                  >
                    Change Password
                  </h3>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "300px",
                    }}
                  >
                    <Label
                      className="ai-text fw-bold pb-1"
                      style={{
                        float: "left",
                        fontSize: "12px",
                        color: "#082C4E",
                      }}
                    >
                      Current Password
                    </Label>
                    <InputGroup
                      style={{ width: "300px", paddingBottom: "35px" }}
                    >
                      <Input
                        name="currentPassword"
                        style={{
                          width: "250px",
                          height: "30px",
                          borderRadius: "5px 0px 0px 5px",
                        }}
                        placeholder="Enter Current Password"
                        type={passwordVisibility ? "text" : "password"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.currentPassword || ""}
                        invalid={
                          validation.touched.currentPassword &&
                          validation.errors.currentPassword
                            ? true
                            : false
                        }
                      />
                      <InputGroupText
                        className="pointer"
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                        onClick={() => toggleVisibility("passowrd")}
                      >
                        <span className="m-auto border">
                          <i
                            className={
                              passwordVisibility
                                ? "mdi mdi-eye"
                                : "mdi mdi-eye-off"
                            }
                          ></i>
                        </span>
                      </InputGroupText>
                      {validation.touched.currentPassword &&
                      validation.errors.currentPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.currentPassword}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                  <div style={{ width: "300px" }}>
                    <Label
                      className="ai-text fw-bold"
                      style={{
                        float: "left",
                        fontSize: "12px",
                        color: "#082C4E",
                      }}
                    >
                      New Password
                    </Label>
                    <InputGroup style={{ width: "300px" }}>
                      <Input
                        style={{
                          width: "250px",
                          height: "30px",
                          borderRadius: "5px 0px 0px 5px",
                        }}
                        name="newPassword"
                        value={validation.values.newPassword || ""}
                        type={confirmPasswordVisibility ? "text" : "password"}
                        placeholder="Enter New Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.newPassword &&
                          validation.errors.newPassword
                            ? true
                            : false
                        }
                      />
                      <InputGroupText
                        className="pointer "
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                        onClick={() => toggleVisibility("confirmPassword")}
                      >
                        <span className="m-auto border">
                          <i
                            className={
                              confirmPasswordVisibility
                                ? "mdi mdi-eye"
                                : "mdi mdi-eye-off"
                            }
                          ></i>
                        </span>
                      </InputGroupText>
                      {validation.touched.newPassword &&
                      validation.errors.newPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.newPassword}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                </div>
                <div style={{ paddingTop: "15px" }}>
                  <Label className="form-label">&nbsp;</Label>
                  <button
                    className="ai-text ai-btn fw-bold"
                    type="submit"
                    style={{
                      backgroundColor: "#2299AA",
                      fontSize: "16px",
                    }}
                  >
                    UPDATE PASSWORD
                  </button>
                </div>
              </div>
            </Form>
          </div>
          {'true' !== localStorage.getItem('demouser') & 'ADMIN' === localStorage.getItem('role')?
          (<div className="ai-grid-item" style={{ backgroundColor: "#2299AA" }}>
            <div>
              <div
                className="ai-text fw-bold pt-3"
                style={{ fontSize: "16px" }}
              >
                Amazon Authorization
              </div>
              <Row className="justify-content-center">
                <Col xs={9} md={9} lg={9}>
                  <p
                    className="card-text w-100"
                    style={{
                      fontFamily: "nunito-sans",
                      fontWeight: "600",
                      fontSize: "13px",
                      lineHeight: "1.5",
                      color: "#FFFFFF",
                      padding: "10px",
                    }}
                  >
                    To reauthorize the connection between Active Inventory and
                    Amazon data base through the API please click on the
                    Authorization button below.
                  </p>
                  <p
                    style={{
                      fontFamily: "nunito-sans",
                      fontWeight: "600",
                      color: "#FFEE00",
                      fontSize: "13px",
                    }}
                  >
                    You last Authorized on{" "}
                    {localStorage.getItem("authSuccessDate")}
                  </p>
                  <button
                    className="ai-btn ai-text fw-bold"
                    style={{
                      color: "#082C4E",
                      backgroundColor: "#FFEE00",
                      width: "270px",
                      height: "35px",
                      fontSize: "15px",
                    }}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={disableButton}
                  >
                    {disableButton && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mx-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                    {disableButton
                      ? "Redirecting..."
                      : null === localStorage.getItem("authSuccessDate")
                      ? "Authorize"
                      : "RE-AUTHORIZE"}
                  </button>
                </Col>
              </Row>
            </div>
          </div>):''}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Settings
