import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Alert, Form, Input, FormFeedback } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { loginUser, getUserProfile, getUserData } from "store/action/user"
import { useToasts } from "react-toast-notifications"
import "./authentication.scss"
import { SUPERADMIN, SUPERUSER } from "utils/constants"
import logo from "assets/images/logo-green.png"
import "../../assets/scss/style.scss"

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [disableButton, setDisableButton] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const { addToast } = useToasts()

  useEffect(() => {
    document.body.classList.add("ai-login-page")
    return () => {
      document.body.classList.remove("ai-login-page")
    }
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
      grant_type: "password",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setDisableButton(true)
      dispatch(
        loginUser(
          { data: { ...values } },
          response => {
            localStorage.setItem("token_type", response.token_type)
            localStorage.setItem("access_token", response.access_token)
            localStorage.setItem("refresh_token", response.refresh_token)
            localStorage.setItem("expires_in", response.expires_in)
            setDisableButton(false)
            dispatch(
              getUserProfile(
                responseData => {
                  localStorage.setItem("sp",true)
                  if (responseData.data.ita) {
                    localStorage.setItem("ita",responseData.data.ita)
                    localStorage.setItem("tpd", responseData.data.tpd)
                  }
                  if (responseData.data.duser === true) {
                    localStorage.setItem("dpd", responseData.data.dpd)
                    localStorage.setItem("remainingdays",responseData.data.duser)
                    localStorage.setItem("duser", responseData.data.duser)
                  }
                  localStorage.setItem("pagepermitted",JSON.stringify(responseData.data.pagePerm))
                  localStorage.setItem("isSubscriptionActive",responseData.data.subscriptionActive)
                  localStorage.setItem("marketplaceId",responseData.data.marketplaces[0]?.marketplaceId || [])
                  localStorage.setItem("clientId", responseData.data.clientId)
                  localStorage.setItem("name", responseData.data.fullName)
                  localStorage.setItem("email", responseData.data.email)
                  localStorage.setItem("profileImageUrl",responseData.data.profileImageUrl)
                  localStorage.setItem("role", responseData.data.role)
                  localStorage.setItem("duser", responseData.data.duser)
                  if (responseData.data.role !== "SUPERUSER" && responseData.data.role !== "SUPERADMIN") {
                    localStorage.setItem("authSuccessDate",responseData.data.marketplaces[0].authSuccessDate)
                  }
                  if (responseData.data.duser === false && !responseData.data.subscriptionActive && responseData.data.role === "ADMIN") {
                    addToast(
                      "Company does not have active Subscription to use the application. Please subscribe to use the application.",
                      {
                        appearance: "error",
                        autoDismiss: false,
                      }
                    )
                    history.push("/billing")
                  } else if (!responseData.data.subscriptionActive && responseData.data.role === "USER") {
                    addToast(
                      "Company does not have active Subscription to use the application. Please ask the Admin to subscribe.",
                      {
                        appearance: "error",
                        autoDismiss: false,
                      }
                    )
                  } else if (localStorage.getItem("marketplaceId")) {
                    history.push("/restock-sheet")
                  } else if (responseData.data.role === SUPERADMIN) {
                    history.push("/dashboard")
                  } else if (responseData.data.role === SUPERUSER) {
                    history.push("/restock-sheet")
                  } else {
                    localStorage.getItem("marketplaceId") === undefined ||
                      (localStorage.getItem("marketplaceId") === null &&
                        addToast("Something went wrong! Please try again", {
                          appearance: "error",
                          autoDismiss: true,
                        }))
                  }
                },
                errorData => {
                  addToast(errorData.response.data.error.displayMessage, {
                    appearance: "error",
                    autoDismiss: true,
                  })
                }
              )
            )
          },

          error => {
            setDisableButton(false)
            addToast(error.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const toggleVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Active Inventory</title>
      </MetaTags>
      <div className="ai-grid-container">
        <div className="ai-grid-item">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "70px 20px 90px 20px",
            }}
          >
            <img src={logo} alt="" style={{ width: "220px" }} />
          </div>
          <div>
            <h2 className="ai-head-text" style={{ color: "#FFFFFF" }}>
              Welcome Back!
            </h2>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              {error ? <Alert color="danger">{error}</Alert> : null}
              <div
                style={{
                  paddingTop: "5px",
                  display: "grid",
                  justifyContent: "center",
                }}
              >
                <Input
                  name="username"
                  style={{
                    width: "452px",
                    height: "44px",
                    margin: "10px 10px 7px 12px",
                    fontSize: "15px",
                    borderRadius: "10px",
                  }}
                  placeholder="Username/Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Input
                  name="password"
                  style={{
                    width: "452px",
                    height: "44px",
                    margin: "10px 10px 15px 12px",
                    fontSize: "15px",
                    borderRadius: "10px",
                  }}
                  value={validation.values.password || ""}
                  type={passwordVisibility ? "text" : "password"}
                  placeholder="Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  autoComplete="new-password"
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                <button
                  type="button"
                  id="btnToggle"
                  className={passwordVisibility ? "eye-icon open" : "eye-icon"}
                  onClick={() => toggleVisibility()}
                ></button>
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
              <div>
                <button
                  disabled={disableButton}
                  className="ai-btn ai-head-text"
                  type="submit"
                  style={{
                    width: "452px",
                    color: "#082C4E",
                    backgroundColor: "#FFEE00",
                    font: "bold 18px novecento-sans",
                  }}
                >
                  {disableButton && (
                    <>
                      <span
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </>
                  )}
                  {disableButton ? "Processing Log In..." : "LOGIN"}
                </button>
              </div>

              <div>
                <Link
                  to="/forgot-password"
                  className="ai-link-text text-danger"
                >
                  Forgot Password?
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <footer className="position-fixed w-100" style={{ bottom: "0px" }}>
        <p style={{ textAlign: "center" }} className="text-white">
          Copyright © {new Date().getFullYear()} Active Inventory
        </p>
        {/* <p  style={{textAlign: "center"}} className="text-white">Developed by: <a className="text-danger" href="https://tirnav.com" target="_new">Tirnav Solutions</a></p> */}
      </footer>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
