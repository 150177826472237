import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./store"
import { ToastProvider } from "react-toast-notifications"
import * as serviceWorker from "./serviceWorker"
import "./i18n"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ToastProvider>
        <App />
      </ToastProvider>
    </BrowserRouter>
  </Provider>
)

const root = createRoot(document.getElementById("root"))
root.render(app)
serviceWorker.unregister()
