export const faqData = [
    {
        id: "1",
        title: "What is Easy Inventory?  How will it benefit my business?",
        description: "<p>Easy Inventory is an app that will allow you to predict what to send into Amazon FBA Warehouses (FBA – Fulfilled by Amazon). This tool uses your previous sales to forecast and suggest the amount of each product to send into Amazon. The app not only generates suggested amounts, it will provide you the ability to adjust setting so you can develop a forecast that best fits your individual business goals.  It puts the relevant information all in one place to allow you efficient and accurate restock forecasts.  You can see your FBM (Fulfilled by Merchant) right next to your FBA orders – including fees. This gives insight into what additional products you should send to the Amazon Warehouses, and which are better for you to fulfill out of your own shop.  No more spread sheets or looking at multiple inventory pages trying to compare. Easy Inventory gives you all this at an affordable price that is a fraction of what similar apps on the market charge. If your new to Amazon, or an established seller, Easy Inventory will become an essential tool to streamline your inventory decision making.<p>"
    },
    {
        id: "2",
        title: "How do I make sure Easy Inventory is giving me great suggestions for the number of items I need to send into my FBA inventory?",
        description: "<p>There are three levers that you can use to adjust the algorithm to suit your individual needs.  These are your Target Stock Values and the Weight you assign to your  sales. Both will be found on the “Settings Page” (Under your name in the upper right corner after you apply for your trial)</p>  <ol><li><p>Your Target Stock Value will set the min and max days of inventory you want to have in the Amazon Warehouse.</p></li> <li><p>You will assign a weight to your orders based on the history of sales in 3, 7, 14, 30, 60, 90 days.This can be adjusted to give you richer(i.e.larger) or leaner(i.e.smaller) result in your restock suggestions.Easy Inventory suggests going leaner, so you do not find yourself overstocked at FBA.After you sign up for the Easy Inventory 14 Day Free Trial you can check out the settings page for more detailed information.</p></li> <li><p>Your lead time is the time you set from when you finalize your restock sheet, you pull and prep the inventory and is ready to ship.Then it includes transportation time, unpacking and transfer times at Amazon.You will need to determine this based on your individual processes and history of how long shipping and unpacking takes at Amazon.</p></li></ol>"
    },
    {
        id: "3",
        title: "How do I hide SKUs I am no longer using?",
        description: "<p>There are two ways for you to hide a SKU. First, if you delete the SKU in your amazon account it will automatically be set to inactive in Easy Inventory. You may use this SKU again in the future but may not want to see it currently. In that case you can set it to inactive in the app and it will be hidden. After you sign up for the trial you will see an Inventory Status page where you can set any item to inactive status.</p>"
    },
    {
        id: "4",
        title: "Can I see my previous FBA Restock Sheets?",
        description: "<p>Absolutely. They are available on the FBA Restock Sheets History page. You will see that when you sign up for the 14 Day Free Trial.</p>"
    },
    {
        id: "5",
        title: "How can I change my personal information or passwords?",
        description: "<p>We have pages to change all of these. After you sign up for the 14 Day Free Trial you will see in the top menu bar your name  in theupper right of the screen after you start your trial. Hover over your name and there will be a dropdown menu that will have pages to change your user info, settings and billing.</p>"
    },
    {
        id: "6",
        title: "Do I have to do anything to activate the program after trying the 14 Day Free Trial?",
        description: "<p>Yes. After the 14 Day Free Trial is up you will need to subscribe to continue using the service. This is when you will need to put in credit card information and choose either the monthly or annual subscription.</p>"
    },
    {
        id: "7",
        title: "How many users can I have on the account?",
        description: "<p>There are 3 users for all accounts up to the 10,000 order tier. 5 users up to 100,000 order tier, and 8 over that.</p>"
    },
    {
        id: "8",
        title: "Can I sort/filter by a custom tag that I create?",
        description: "<p>Yes, you can create custom tags for each SKU and then either sort or filter by them to limit the products you work with.  You can assign two tags per SKU.</p>"
    },
    {
        id: "9",
        title: "What is Sell Through Rate and why do I care about it?",
        description: "<p>(Great question, we’re glad you asked!)</p> <p>Sell through rate is the number of times your inventory turns over in a rolling 90 day average. Amazon uses sell through rate as one of the key factors to determine the amount of space you can use in their warehouses. You should work to get your target a rate above 2. The average of all your products determines your inventory limits. It is very important for you to monitor this in your overall inventory. It is especially important to check this and adjust as needed in the 3rd quarter since these numbers are used by Amazon to set your limits for the important 4th quarter. A great way to get this number to increase is to move slow selling items either by reducing the price to get them to sell or recalling you inventory to sell on a different market.Take action on any item that that has been in the Amazon warehouse over 90 days. Keeping a handle on this your sell through ratecan make a huge difference in your inventory storage allotment.</p>"
    },
    {
        id: "10",
        title: "Can I export my reports?",
        description: "<p>All reports can be exported as pdf or excel files.</p>"
    }
]