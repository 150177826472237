import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  InputGroupText,
  InputGroup
} from "reactstrap"
import { useDispatch } from "react-redux"
import { withRouter, Link, useLocation, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { updatePassword } from "store/action/user"
import { useToasts } from "react-toast-notifications"
import logo from "assets/images/logo-green.png"
import "../../assets/scss/style.scss"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UpdatePassword = () => {

  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  let query = useQuery();

  useEffect(() => {
    validation.setFieldValue("email", query.get("email"))
    validation.setFieldValue("tmpCode", query.get("token"))
    document.body.classList.add("ai-login-page")
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      tmpCode: ""
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Please Enter Your New Password"),
      tmpCode: Yup.string().required("Please Enter Code."),
    }),
    onSubmit: values => {
      dispatch(updatePassword(
        { data: { ...values } },
        response => {
          addToast(response.data, {
            appearance: 'success',
            autoDismiss: true,
          })
          history.push("/login")
        },
        error => {
          addToast(error.error.displayMessage, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      ))
    },
  })

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Update Password | Active Inventory
        </title>
      </MetaTags>

      <div className="ai-grid-container">
        <div className="ai-grid-item">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "70px 20px 90px 20px",
            }}
          >
            <img src={logo} alt="" style={{ width: "220px" }} />
          </div>
          <div>
            <h2 className="ai-head-text" style={{ color: "#FFFFFF" }}>
              Reset your password?
            </h2>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Label className="form-label text-white">New Password</Label>
              <Row className="mb-3">
                <Col className="d-grid justify-content-center">
                  <Input
                    name="newPassword"
                    style={{
                      width: "452px",
                      height: "44px",
                      margin: "10px 10px 15px 12px",
                      fontSize: "15px",
                      borderRadius: "10px",
                    }}
                    value={validation.values.newPassword || ""}
                    type={passwordVisibility ? "text" : "password"}
                    placeholder="Enter New Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.newPassword &&
                        validation.errors.newPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.newPassword &&
                    validation.errors.newPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  ) : null}
                  <Input
                    name="email"
                    className="form-control"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                  <Input
                    name="tmpCode"
                    className="form-control"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tmpCode || ""}
                    invalid={
                      validation.touched.tmpCode && validation.errors.tmpCode
                        ? true
                        : false
                    }
                  />
                  {validation.touched.tmpCode && validation.errors.tmpCode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.tmpCode}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <button
                    className="ai-btn ai-head-text"
                    type="submit"
                    style={{
                      width: "452px",
                      color: "#082C4E",
                      backgroundColor: "#FFEE00",
                      font: "bold 18px novecento-sans",
                    }}
                  >
                    Change Password
                  </button>
                </Col>
              </Row>
            </Form>
            <div className="mt-1 text-center">
              <p className="text-white">
                &lt;
                <Link to="login" className="font-weight-medium text-white">
                  Back to Login
                </Link>
              </p>
              <p className="text-white pointer" onClick={() => window.open(
                'https://support.myactiveinventory.com/support/solutions',
                '_blank'
              )}>Support</p>
            </div>
          </div>
        </div>
      </div>
      <footer className="position-fixed w-100" style={{ bottom: "0px" }}>
        <p style={{ textAlign: "center" }} className="text-white">Copyright © {new Date().getFullYear()} Active Inventory</p>
        {/* <p style={{ textAlign: "center" }} className="text-white">Developed by: <a className="text-danger" href="https://tirnav.com" target="_new">Tirnav Solutions</a></p> */}
      </footer>
    </React.Fragment>
  )
}

UpdatePassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(UpdatePassword)
