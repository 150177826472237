import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import TabTitle from "components/Common/TabTitle"
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { MetaTags } from "react-meta-tags"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Container, Spinner } from "reactstrap"
import { addNewCard } from "store/action/billing"
import { Redirect,useNavigate } from 'react-router-dom';

const CARD_OPTIONS = {
    iconStyle: "default",
    hidePostalCode: true,
    style: {
        base: {
            iconColor: "black",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "black" },
            margin: "10px"
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

//add new card button component
const AddNewCardButton = ({ disableButton }) => {
    return (
        <Button
            color="primary"
            disabled={disableButton}
            className="my-2">
            {disableButton && (
                <>
                    <span
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                </>
            )}
            {disableButton ? "Adding Card..." : "Add Card"}
        </Button>
    )
}

//subscribe button component
const SubscribeButton = ({ disableButton }) => {
    return (
        <Button
            color="primary"
            disabled={disableButton}
            className="my-2 mx-2">
            {disableButton && (
                <>
                    <span
                        className="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                </>
            )}
            {disableButton ? "Subscribing..." : "Subscribe"}
        </Button>
    )
}

export default function PaymentForm() {
    const dispatch = useDispatch()
    const history = useHistory()
    const stripe = useStripe();
    const elements = useElements();
    const { addToast } = useToasts()
    const [disableButton, setDisableButton] = useState(false)
    const [paymentConfirmation, setPaymentConfirmation] = useState({
        confirm: false,
        success: false
    })
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentIntent,setPaymentIntent]=useState({});
    const [timer, setTimer] = React.useState(10);
    const location = useLocation()

    const handleSubmit = (e) => {
        setDisableButton(true)
        e.preventDefault()
        location.state.isAddNewCard ?
            dispatch(
                addNewCard(
                    responseData => {
                        setDisableButton(false)
                        localStorage.setItem("client_stripe_data", responseData.data.clientSecret)
                        localStorage.setItem("subscription_id", responseData.data.subscriptionId)
                        history.push("/billing")
                    },
                    errorData => {
                        setDisableButton(false)
                        addToast(errorData.response.data.error.displayMessage, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                )
            ) : confirmPayment()
    }

    const confirmPayment = async () => {
        let client_secrate = localStorage.getItem("client_stripe_data")
        const cardElement = elements.getElement(CardElement);
        const name = localStorage.getItem("name")
        const { paymentIntent, error } = await stripe.confirmCardPayment(
            client_secrate,
            {
                setup_future_usage: 'off_session',
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: name,
                    },
                }
            }
        )
        if(paymentIntent && paymentIntent.status === 'succeeded') {
            localStorage.removeItem("client_stripe_data")
            localStorage.setItem("isSubscriptionActive", "true")
            setPaymentIntent(paymentIntent);
            setPaymentConfirmation({
                confirm: true,
                success: true
            })
            gtag('event','purchase', {"transaction_id": paymentIntent.id,"value": ((paymentIntent.amount)/100).toFixed(2),"currency": "USD"});
            setDisableButton(false)
            setTimeout(() => {history.push("/billing")}, timer * 1000);
            
            //history.push({pathname: '/confirmation',state:{id:paymentIntent.id,amount:(paymentIntent.amount/100)}});
            //return <Redirect to={{pathname: '/confirmation',state:paymentIntent}} />
        }
        if (error) {
            setDisableButton(false)
            setErrorMessage('Payment was not succesful. Reason: '+error.message);
            setPaymentIntent(paymentIntent);
            setPaymentConfirmation({
                confirm: true,
                success: false
            })
            //setTimeout(() => {history.push("/billing")}, 10000);
        }
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>{location.state.isAddNewCard ? "Add Card" : "Subscription"} | Active Inventory</title>
            </MetaTags>
            <Container fluid>
                <div className="d-flex align-items-center justify-content-between w-100">
                    <TabTitle title={location.state.isAddNewCard ? `ADD OR CHANGE CREDIT CARD INFORMATION` : `Subscription`} />
                </div>
                <div className="d-flex flex-column">
                    {
                        paymentConfirmation.success && paymentConfirmation.confirm ? <div className="selected-plan m-auto my-3 border border-3 border-info p-5 bg-white shadow rounded-3 w-50">
                            <div className="align-center">
                                <p className={paymentConfirmation.success ? "h4 text-success my-3" : "h4 text-danger my-3"}><ins>{paymentConfirmation.success ? "We have received your payment and your subscription is been activated" : "Something went wrong!"}</ins></p>
                                <p className="h5 text-dark my-3">{paymentConfirmation.success ? "Please wait, we are setting up your subscription..." : "Please wait we redirecting you to billing page..."}</p>
                                <div className="my-3">
                                <Button color="primary" className="my-2 mx-2" onClick={() => history.push('/billing')}>
                                    Billing Page
                                </Button>
                                <div>You will be auto redirected to Billing Page in {timer} seconds.</div>
                                </div>
                                <script>
                                    <img src={`https://www.shareasale.com/sale.cfm?tracking=${paymentIntent.id}&amount=${((paymentIntent.amount)/100).toFixed(2)}&merchantID=129067&transtype=sale`} width="1" height="1"/>
                                    <script src="https://www.dwin1.com/19038.js" type="text/javascript" defer="defer"></script>
                                </script>
                            </div>
                        </div> :
                            <div className="selected-plan m-auto my-3 border border-3 border-info p-5 bg-white shadow rounded-3 w-60">
                                {
                                    location.state.isAddNewCard
                                        ? <div className="text-info h5">{`Please enter your card details below. Remember once you enter new card details, old card will be removed.`}</div>
                                        : <div className="text-info h5">{`You have subscribed ${location.state.data[0]} plan, your final payable amount is $${location.state.data[1].price}.`}</div>
                                }
                                <div className="h6 text-secondary my-3">Pay with your credit card via Stripe</div>
                                <hr />
                                <form onSubmit={handleSubmit} className="align-center m-auto">
                                    <fieldset className="FormGroup">
                                        <div className="FormRow">
                                            <CardElement options={CARD_OPTIONS} />
                                        </div>
                                    </fieldset>
                                    <hr />
                                    <div className="pt-3 d-flex justify-content-center">
                                        <Button
                                            color="primary"
                                            disabled={disableButton}
                                            className="my-2 mx-2"
                                            onClick={() => history.goBack()}
                                        >
                                            Return to Billing
                                        </Button>
                                        {
                                            location.state.isAddNewCard
                                                ? <AddNewCardButton disableButton={disableButton} />
                                                : <SubscribeButton disableButton={disableButton} />
                                        }
                                    </div>
                                    <br></br>
                                    <div className="text-danger h5">{errorMessage}</div>
                                </form>
                            </div>
                    }
                </div>
            </Container>
        </div>
    )
}

AddNewCardButton.propTypes = {
    disableButton: PropTypes.any
}

SubscribeButton.propTypes = {
    disableButton: PropTypes.any
}