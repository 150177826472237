import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useDispatch } from "react-redux"
import {
  deleteRestockSheet,
  getRestockSheetItem,
  restockHistoryData,
  restockHistoryUpdateData,
} from "store/action/stock"
import { useToasts } from "react-toast-notifications"
import TabTitle from "components/Common/TabTitle"
import DataTable from "react-data-table-component"
import InventoryLabLogo from "../../assets/images/inventory-lab-grey.svg"
import { setStartLoader, setStopLoader } from "store/loader/action"
import "../../assets/scss/custom/rdt.scss"
import "./restockReportHistory.scss"
import Pagination from "../../components/Common/Pagination"
import { BASE_URL } from "utils/url"
import { Tooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"

const RestockReportHistory = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [restockHistoryDataInfo, setRestockHistoryDataInfo] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [viewRestockData, setViewRestockData] = useState([])
  const [perPage, setPerPage] = useState(100)
  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalElements: 0,
  })
  const [productMetaData, setProductMetaData] = useState({
    totalQty: 0,
    totalSku: 0,
  })
  const [deleteSheetClick, setDeleteSheetClick] = useState(false)
  const [selectedSheetId, setSelectedSheetId] = useState(0)
  const [resetPaginationToggle] = React.useState(false)

  useEffect(() => {
    if (localStorage.getItem("marketplaceId")) {
      fetchAllData()
    }
  }, [localStorage.getItem("marketplaceId"), currentPage, perPage])

  const fetchAllData = () => {
    dispatch(
      restockHistoryData(
        { data: { pageNo: currentPage, pageSize: perPage } },
        responseData => {
          setEditModal(false)
          setRestockHistoryDataInfo([...responseData.data.content])
          setPagination({
            totalPages: responseData.data.totalPages,
            totalElements: responseData.data.totalElements,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const editModalSubmit = () => {
    dispatch(
      restockHistoryUpdateData(
        { ...selectedRow, data: viewRestockData },
        response => {
          addToast("Update Successfully", {
            appearance: "success",
            autoDismiss: true,
          })
          fetchAllData()
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const viewClick = data => {
    setSelectedRow(data)
    dispatch(
      getRestockSheetItem(
        { id: data.id },
        response => {
          setViewRestockData(response.data.items)
          setProductMetaData({
            totalQty: response.data.totalQty,
            totalSku: response.data.totalSku,
          })
          setViewModal(!viewModal)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const deleteClick = row => {
    setDeleteSheetClick(true)
    setSelectedSheetId(row.id)
  }

  const confrimCancel = () => {
    dispatch(
      deleteRestockSheet(
        { restockSheetId: selectedSheetId },
        responseData => {
          setDeleteSheetClick(false)
          addToast("Restock sheet deleted", {
            appearance: "success",
            autoDismiss: true,
          })
          fetchAllData()
        },
        errorData => {
          setDeleteSheetClick(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const editClick = data => {
    setSelectedRow(data)
    dispatch(
      getRestockSheetItem(
        { id: data.id },
        response => {
          setViewRestockData(response.data.items)
          setProductMetaData({
            totalQty: response.data.totalQty,
            totalSku: response.data.totalSku,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
    setEditModal(!editModal)
  }

  const printClick = async (data, type) => {
    dispatch(setStartLoader())
    let myHeaders = new Headers()

    myHeaders.append(
      "X-CLIENTMARKETPLACE-ID",
      localStorage.getItem("marketplaceId")
    )
    myHeaders.append(
      "Authorization",
      localStorage.getItem("token_type") +
        " " +
        localStorage.getItem("access_token")
    )
    myHeaders.append("Content-Type", "application/json")

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    await fetch(
      type === "amazon"
        ? BASE_URL + `restock/download/amazon?restockSheetId=${data.id}`
        : type === "printer"
        ? BASE_URL + `restock/print?id=${data.id}`
        : BASE_URL + `restock/download/invLab?restockSheetId=${data.id}`,
      requestOptions
    )
      .then(resp => resp.blob())
      .then(blob => {
        dispatch(setStopLoader())
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download =
          type === "amazon"
            ? "Amazon_Shipment.xlsx"
            : type === "printer"
            ? "FBARestockSheet.pdf"
            : "InventoryLab.csv"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        addToast("File downloaded successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(error => {
        console.error(error)
        dispatch(setStopLoader())
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        })
      })
  }

  const format_action = row => {
    return (
      <div className="p-0 d-flex flex-row justify-content-between align-items-center w-100">
        <div data-toggle="tooltip" title={"View"} className="mx-2 p-0">
          <i
            className="mdi mdi-view-grid-plus-outline h3 p-0 pointer"
            onClick={() => viewClick(row)}
          ></i>
        </div>
        <div data-toggle="tooltip" title={"Edit"} className="mx-2 p-0">
          <i
            className="mdi mdi-clipboard-edit-outline h3 p-0 pointer"
            onClick={() => editClick(row)}
          ></i>
        </div>
        <div data-toggle="tooltip" title={"Delete"} className="mx-2 p-0">
          <i
            className="mdi mdi-trash-can-outline h3 p-0 pointer"
            onClick={() => deleteClick(row)}
          ></i>
        </div>
        <div data-toggle="tooltip" title={"Print"} className="mx-2 p-0">
          <i
            className="mdi mdi-printer h3 p-0 pointer"
            onClick={() => printClick(row, "printer")}
          ></i>
        </div>
        <div data-toggle="tooltip" title={"Amazon"} className="mx-2 p-0">
          <i
            className="mdi mdi-amazon h3 p-0 pointer"
            onClick={() => printClick(row, "amazon")}
          ></i>
        </div>
        <div data-toggle="tooltip" title={"Inventory Lab"} className="p-0">
          <img
            src={InventoryLabLogo}
            className="inventory-lab-img"
            onClick={() => printClick(row, "inventoryLab")}
            style={{ paddingBottom: "10px", paddingTop: "5px" }}
          />
        </div>
      </div>
    )
  }

  const handleChangeQty = (e, row) => {
    if (
      e.target.value === "" ||
      parseInt(e.target.value) ||
      e.target.value === "0"
    ) {
      setViewRestockData(prevData => {
        const newData = prevData.map(item => {
          if (item.id === row.id) {
            return { ...item, qty: e.target.value }
          }
          return item
        })
        return newData
      })
    } else {
      addToast("Please enter number only.", {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }

  const handleChangeNotes = (e, row) => {
    e.preventDefault()
    const value = e.target.value
    setViewRestockData(prevData => {
      const newData = prevData.map(item => {
        if (item.id === row.id) {
          return { ...item, notes: value }
        }
        return item
      })
      return newData
    })
  }

  const format_Qty = row => {
    return viewModal ? (
      row.qty
    ) : (
      <input
        className="form-control mx-1 my-1 w-50"
        id="quantity"
        name="quantity"
        placeholder="Enter Quantity"
        value={row.qty}
        onChange={e => handleChangeQty(e, row)}
      />
    )
  }

  const format_Notes = row => {
    return viewModal ? (
      row.notes
    ) : (
      <textarea
        className="form-control"
        rows="2"
        cols="3"
        id="notes"
        name="notes"
        placeholder="Enter Notes"
        value={row.notes || ""}
        onChange={e => handleChangeNotes(e, row)}
      />
    )
  }

  const handlePageChange = e => {
    setCurrentPage(e)
  }

  const handlePerRowsChange = e => {
    setPerPage(e)
  }

  const columns = [
    {
      name: "Date Created",
      selector: row => row.date,
      cell: row => (
        <div>
          {new Date(row.date).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Restock Sheet Name",
      selector: row => row.name,
      cell: row => <div>{row.name}</div>,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      cell: row => (
        <div className="text-success font-weight-bold">{row.status}</div>
      ),
    },
    {
      name: "Actions",
      cell: row => format_action(row),
    },
  ]

  const modalColumn = [
    {
      name: "SKU",
      selector: row => <div className="ai-text-center">{row.sku}</div>,
      grow: 0,
      sortable: true,
    },
    {
      name: "ASIN",
      selector: row => (row.asin === null ? "NA" : row.asin),
      grow: 0,
      sortable: true,
    },
    {
      name: "Name",
      selector: row => <div className="ai-text-left">{row.name}</div>,
      grow: 1,
      wrap: true,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: row => row.qty,
      cell: row => format_Qty(row),
      grow: 0,
      sortable: true,
    },
    {
      name: "Notes",
      selector: row => row.notes,
      cell: row => format_Notes(row),
      grow: 0,
      sortable: true,
      width: "250px",
    },
  ]

  return (
    <React.Fragment>
      {deleteSheetClick && (
        <Modal
          isOpen={deleteSheetClick}
          size="lg"
          className="w-75 modal-wrapper"
          backdrop
          style={{
            border: 0,
            backgroundColor: "#FFFFFF",
            borderRadius: "35px",
          }}
        >
          <ModalBody
            style={{
              backgroundColor: "#FFFFFF",
              border: 0,
              borderRadius: "35px",
            }}
          >
            <div
              className="px-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                font: "bold 20px nunito-sans",
                color: "#082C4E",
              }}
            >
              <div>Delete Restock History Report</div>
              <div>
                <div
                  className="ai-modal-close-btn-dark"
                  onClick={() => setDeleteSheetClick(false)}
                >
                  <div className="text-white" style={{ fontSize: "18px" }}>
                    &#10005;
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="text-danger font-weight-bold h5">Attention!!!</p>
              <p className="h5">
                You are about to delete a Restock History Report. All the data of this report will be deleted and will not be able to be recovered. If you do not want to delete and erase this information, please cancel this action
              </p>
            </div>

            <div className="d-flex justify-content-center col-12">
              <Row className="align-items-center">
                <Col className="d-flex justify-content-center">
                  <Button
                    className="ai-action-btn-danger btn btn-danger text-white"
                    onClick={() => confrimCancel()}
                  >
                    DELETE
                  </Button>
                  <Button
                    className="ai-action-btn btn btn-primary"
                    color=""
                    onClick={() => {
                      setDeleteSheetClick(false)
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      )}

      {viewModal || editModal ? (
        <Modal
          isOpen={viewModal || editModal}
          size="xl"
          scrollable
          className="modal-wrapper"
          autoFocus={true}
          toggle={() => <></>}
          style={{
            border: 0,
            backgroundColor: "#FFFFFF",
            borderRadius: "35px",
          }}
        >
          <ModalBody
            style={{
              backgroundColor: "#FFFFFF",
              border: 0,
              borderRadius: "35px",
            }}
          >
            <div className="px-2 ai-modal-header">
              <div>{viewModal ? "View" : "Edit"} Details</div>
              <div>
                <div
                  className="ai-modal-close-btn-dark"
                  onClick={() => {
                    setViewModal(false)
                    setEditModal(false)
                  }}
                >
                  <div className="text-white" style={{ fontSize: "18px" }}>
                    &#10005;
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2 mt-3 mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <span
                  style={{ font: "bold 20px nunito-sans", color: "#082C4E" }}
                ></span>
                <span
                  style={{ font: "bold 20px nunito-sans", color: "#082C4E" }}
                >
                  Status:{" "}
                  <span className="text-success">{selectedRow.status}</span>
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                <span
                  style={{ font: "bold 20px nunito-sans", color: "#082C4E" }}
                >
                  {selectedRow.name} |{" "}
                  {new Date(selectedRow.date).toLocaleDateString("en-US")}
                </span>
                <span
                  style={{ font: "bold 20px nunito-sans", color: "#082C4E" }}
                >
                  Total Qty: {`${productMetaData.totalQty}`} |{" "}
                  <strong>Total Sku:</strong> {`${productMetaData.totalSku}`}
                </span>
              </div>
            </div>
            <div className="px-2">
              <div className="my-1 w-100">
                <div className="ai-Table">
                  <DataTable
                    columns={modalColumn}
                    data={viewRestockData}
                    responsive
                    fixedHeaderScrollHeight="60vh"
                    fixedHeader
                    dense
                    sort
                    persistTableHead
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-danger"
                onClick={() => {
                  setViewModal(false)
                  setEditModal(false)
                }}
              >
                Cancel
              </Button>
              {editModal && (
                <Button
                  className="btn-success"
                  onClick={() => editModalSubmit()}
                >
                  Submit
                </Button>
              )}
            </div>
          </ModalBody>
        </Modal>
      ) : null}

      <div className="page-content">
        <MetaTags>
          <title>Restock Report History | Active Inventory</title>
        </MetaTags>
        <Container fluid>
          <TabTitle title="Restock History" tooltip="This page shows Restock Sheets that have been finalized. Once finalized, you cannot add any new products to the sheet, but the quantity can be edited.   Under actions you can View the sheet (first button), Edit the Sheet (clipboard), Delete the Sheet (Trash can), Print the Sheet, Export to Amazon (Amazon symbol), and export to Inventory Lab (Inventory Lab symbol)."/>
          
          <div className="ai-Table">
            <DataTable
              columns={columns}
              data={restockHistoryDataInfo}
              pagination
              paginationComponent={() => (
                <Pagination
                  data={restockHistoryDataInfo}
                  rowsPerPage={perPage}
                  currentPage={currentPage}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={e => handlePerRowsChange(e)}
                  paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                  rowCount={pagination.totalElements}
                />
              )}
              paginationServer
              paginationPerPage={100}
              paginationRowsPerPageOptions={[50, 100, 200, 300, 500]}
              paginationTotalRows={pagination.totalElements}
              onChangePage={e => handlePageChange(e)}
              onChangeRowsPerPage={e => handlePerRowsChange(e)}
              paginationResetDefaultPage={resetPaginationToggle}
              persistTableHead
              responsive
              fixedHeader
              fixedHeaderScrollHeight="53vh"
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RestockReportHistory
