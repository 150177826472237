import {
  API_INVOCATION,
  RESTOCK_DATA,
  OUT_OF_STOCK_DATA,
  PRODUCT_DATA,
  RESTOCK_HISTORY_DATA,
  RESTOCK_HISTORY_DATA_UPDATE,
  PRODUCT_WITHOUT_LOADER_DATA,
  RESTOCK_HISTORY_DATA_IN_PROGRESS,
  CREATE_RESTOCK_SHEET,
  DELETE_RESTOCK_SHEET,
  DONE_RESTOCK_SHEET,
  UPDATE_RESTOCK_SHEET,
  ADD_PRODUCT,
  ADD_ITEM_TO_RESTOCK_SHEET,
  GET_RESTOCK_SHEET_ITEM,
  DELETE_ITEM_FROM_RESTOCK_SHEET,
  PRINT_RESTOCK_SHEET_ITEM,
  GET_INV_LIMIT_DATA,
  GET_ONLY_FBM_SKU_ITEM
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

/**
 *
 *  get activity details
 */
export const restockData = (resolve, reject) => {
  const payload = {
    action: RESTOCK_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.RESTOCK_DATA,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const restockSheetDataById = (_payload, resolve, reject) => {
  const payload = {
    action: RESTOCK_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.RESTOCK_DRAFT + `/${_payload.id}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const outOfStockData = (resolve, reject) => {
  const payload = {
    action: OUT_OF_STOCK_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.OUT_OF_STOCK_DATA,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const outOfStockDataDownload = (_payload, resolve, reject) => {
  const payload = {
    action: OUT_OF_STOCK_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.OUT_OF_STOCK_DATA + "/download?type=" + _payload.data.type,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const restockHistoryData = (_payload, resolve, reject) => {
  const payload = {
    action: RESTOCK_HISTORY_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.RESTOCK_DONE + `?direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const restockSheetInProgress = (_payload, resolve, reject) => {
  const payload = {
    action: RESTOCK_HISTORY_DATA_IN_PROGRESS,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.RESTOCK_DRAFT + `?direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}


export const restockHistoryUpdateData = (_payload, resolve, reject) => {
  const payload = {
    action: RESTOCK_HISTORY_DATA_UPDATE,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.RESTOCK_UPDATE + "/" + _payload.id,
    resolve,
    reject,
    data: _payload.data
  }
  return { type: API_INVOCATION, payload }
}

export const createRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: CREATE_RESTOCK_SHEET,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.CREATE_RESTOCK_SHEET + `/${_payload.name}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const productData = (_payload, resolve, reject) => {
  let sortBy = ""
  let search = ""
  let filter = ""
  if (_payload.data.param !== undefined) {
    sortBy += "&sortBy=" + _payload.data.param
  }
  if (_payload.data.search !== "" && _payload.data.search !== undefined) {
    search = `&search=${_payload.data.search}`
  }
  if ((_payload.data.status !== undefined || _payload.data.filterType !== undefined) && (_payload.data.status !== "" || _payload.data.filterType !== "")) {
    let semicolon = ""
    if (_payload.data.status && _payload.data.filterType) {
      semicolon = ";"
    }
    filter = `&filter=${_payload.data.status}${semicolon}${_payload.data.filterType}`
  }
  const payload = {
    action: PRODUCT_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.PRODUCT_DATA + `?direction=${_payload.data.direction || "DESC"}&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}${search}${filter}${sortBy}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const productDataByStatus = (_payload, resolve, reject) => {
  let semicolon = ""
  if (_payload.data.status && _payload.data.type) {
    semicolon = ";"
  }
  const payload = {
    action: PRODUCT_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.PRODUCT_STATUS + `?direction=DESC&filter=${_payload.data.status}${semicolon}${_payload.data.type}&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}&sortBy=updatedTime`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const productDataWithoutLoader = (_payload, resolve, reject) => {
  const payload = {
    action: PRODUCT_WITHOUT_LOADER_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.PRODUCT_DATA + `?direction=${_payload.data.direction || "DESC"}&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const deleteRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: DELETE_RESTOCK_SHEET,
    method: constdata.DELETE,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.DELETE_RESTOCK_SHEET + `/${_payload.restockSheetId} `,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const doneRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: DONE_RESTOCK_SHEET,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.DONE_RESTOCK_SHEET + `/${_payload.restockSheetId}/${_payload.status}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const updateRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: UPDATE_RESTOCK_SHEET,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.DONE_RESTOCK_SHEET + `/${_payload.restockSheetId}`,
    resolve,
    reject,
    data: { ..._payload }
  }
  return { type: API_INVOCATION, payload }
}

export const addItemToRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: ADD_ITEM_TO_RESTOCK_SHEET,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.ADD_ITEM_TO_RESTOCK_SHEET + `/${_payload.data.id}/add`,
    resolve,
    reject,
    data: [..._payload.data.item]
  }
  return { type: API_INVOCATION, payload }
}

export const deleteItemFromRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: DELETE_ITEM_FROM_RESTOCK_SHEET,
    method: constdata.DELETE,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.DELETE_ITEM_FROM_RESTOCK_SHEET + `/${_payload.data.id}/${_payload.data.sku}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const getRestockSheetItem = (_payload, resolve, reject) => {
  const payload = {
    action: GET_RESTOCK_SHEET_ITEM,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.ADD_ITEM_TO_RESTOCK_SHEET + `/${_payload.id}/items`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const getBuyBoxPrice = (_payload, resolve, reject) => {
  const payload = {
    action: GET_RESTOCK_SHEET_ITEM,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.GET_BUY_BOX_PRICE,
    resolve,
    reject,
    data: _payload.data
  }
  return { type: API_INVOCATION, payload }
}

export const printRestockSheet = (_payload, resolve, reject) => {
  const payload = {
    action: PRINT_RESTOCK_SHEET_ITEM,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.PRINT_RESTOCK_SHEET + `?id=${_payload.id}`,
    resolve,
    reject,
    data: _payload.data
  }
  return { type: API_INVOCATION, payload }
}

export const hideSku = (_payload, resolve, reject) => {
  const payload = {
    action: PRINT_RESTOCK_SHEET_ITEM,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.PRINT_RESTOCK_SHEET + `?id=${_payload.id}`,
    resolve,
    reject,
    data: _payload.data
  }
  return { type: API_INVOCATION, payload }
}

export const getOnlyFbmSku = (_payload, resolve, reject) => {
  const payload = {
    action: GET_ONLY_FBM_SKU_ITEM,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: consturl.GET_ONLY_FBM_SKU_ITEM + `/${_payload.restockSheetId}?showOnlyFBM=${_payload.onlyFBM}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}