import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import logo from "assets/images/logo-green.png"
import "../../assets/scss/style.scss"

const Amazon = () => {
  const history = useHistory()
  const [email, setEmail] = useState()
  const [clientMarketPlaceId, setClientMarketPlaceId] = useState()
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    document.body.classList.add("ai-login-page")
    return () => {
      document.body.classList.remove("ai-login-page")
    }
  }, [])

  useEffect(() => {
    if (Object.keys(history?.location?.state).length < 1) {
      history.push("/register")
    } else {
      setEmail(history?.location?.state?.email)
      setClientMarketPlaceId(history?.location?.state?.clientMarketPlaceId)
    }
  }, [history.location])

  const handleSubmit = event => {
    setDisableButton(true)
    event.preventDefault()
    window.location = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.c355d247-39dd-4ee0-9829-8d7b993538cc&state=${clientMarketPlaceId}_US_init&version=beta`
    //window.location = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.0199b154-30f6-411d-aaa5-4e1eb4452ea1&state=${clientMarketPlaceId}_US_init&version=beta`
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Active Inventory</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="ai-grid-container">
        <div className="ai-grid-item w-100">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "70px 20px 20px 20px",
            }}
          >
            <img src={logo} alt="" style={{ width: "220px" }} />
          </div>
          <div className="account-pages my-5 pt-sm-5 ai-amazon-card">
          <div>
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-4">
                        <h3 className="text-center m-3 ai-head-text">
                        AMAZON CONNECTION INFORMATION
                        </h3>
                        <p className="text-center m-3">
                          Email for Authorization is <br></br>
                          <b style={{font: "normal normal bold 25px/43px nunito-sans" , letterSpacing: "0.32px",color: "#082C4E"}}>{email}</b>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="p-2">
                    <div className="text-center m-3" style={{font: "normal normal normal 12px/22px nunito-sans",letterSpacing: "0.16px",color: "#082C4E", opacity: 1, padding:"0 200px 0px 200px"}}>
                      <p>
                        The trial will end automatically after 14 days. If you
                        want uninterrupted service, fill in your credit card
                        info any time during the 14 days you are trying out the
                        program and begin the journey to easy inventory
                        restocking. Just sign in, find the Account Information
                        Page under your name and add your credit card.
                      </p>
                      <p>
                        Check out our FAQ page if you have any questions. If you
                        need more help, send us a note and we will get back with
                        you.{" "}
                      </p>
                    </div>
                    <div className="row mt-3 text-center">
                      <div className="col-sm">
                        <button
                          //className={disableButton ? "btn btn-primary btn-block w-75" : "btn btn-primary"}
                          className="ai-btn ai-head-text text-white"
                          style={{fontSize:"24px"}}
                          onClick={handleSubmit}
                          type="submit"
                          disabled={disableButton}
                        >
                          {
                            disableButton &&
                            <>
                              <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          }
                          {disableButton ? "Redirecting you to the Amazon Seller site..." : "Authorize"}
                        </button>
                      </div>
                    </div>
                  </div>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Active Inventory</p>
              </div>
      </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Amazon
