import React from "react"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/bootstrap/css/bootstrap-grid.min.css"
import "../assets/bootstrap/css/bootstrap-reboot.min.css"
// import "../assets/mobirise/css/mbr-additional.scss"
import "../assets/theme/css/style.css"
import Header from "static-site/common/Header"
import Footer from "static-site/common/Footer"
import { MetaTags } from "react-meta-tags"

const UnderstandingRestockSheet = () => {
  return (
    <React.Fragment>
      <div className="static-site">
        <MetaTags>
          <title>Understanding Restock Sheet | Active Inventory</title>
        </MetaTags>
        <Header />
        <section
          data-bs-version="5.1"
          className="header8 cid-t2fEM2RzAa"
          id="header8-2k"
        >
          <div className="container" style={{ marginTop: "120px" }}>
            <div className="row align-items-center">
              <div className="col-12 col-md-4">
                <div className="image-wrapper">
                  <img src={MBRPNG} alt="" />
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="text-wrapper">
                  <p className="desc1 mbr-section-title mbr-fonts-style display-2">
                    Understanding&nbsp;
                    <br />
                    the Restock Sheet&nbsp;
                  </p>
                  <p className="desc2 mbr-fonts-style display-5">
                    Getting into the Nitty and the Gritty{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features5 cid-t2fIZmCCKl" id="features05-3u">
          <div className="container-fluid">
            <div className="card-wrapper">
              <div className="row flex-row-reverse">
                <div className="col-lg-2 align-center icon-col">
                  <span className="mbr-iconfont mobi-mbri-rocket mobi-mbri"></span>
                </div>
                <div className="col-12 col-lg-5 bg">
                  <div className="text-box">
                    <h5 className="mbr-sub-title align-left mbr-fonts-style pb-2 display-4">
                      <strong>Column 1</strong>
                    </h5>

                    <p className="mbr-text align-left mbr-fonts-style display-4">
                      <strong>Status Colors:</strong>
                      <br />
                      Red – In danger of running out of stock
                      <br />
                      Yellow – Needs to be restocked
                      <br />
                      Green – No restock needed at this time
                      <br />
                      Orange – Stock is currently over the maximum target
                      days&nbsp;
                      <br />
                      Purple - Product is new to your store in the last 30 days
                      <br />
                      <br />
                      Column 2<br />
                      <br />
                      <strong>FBA SKU</strong> – Fulfilled by Amazon Stock
                      Keeping Unit This is the code you have assigned to your
                      product for items sent to Amazon warehouses. This is used
                      to manage the physical inventory in the Amazon System. SKU
                      can be seen as the product offering (ie. how it will be
                      shipped and the pricing for the product). FBA SKU’s are
                      created by the seller.
                      <br />
                      <br />
                      <strong>Product Name</strong>&nbsp; - Title of product in
                      Amazon system.&nbsp; If the full title isn&apos;t showing,
                      hover over the title that is there and the full name will
                      pop up.
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5 img-col bg">
                  <div className="image-wrapper">
                    <img src={Column1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-wrapper">
              <div className="row flex-row-reverse">
                <div className="col-lg-2 align-center icon-col">
                  <span className="mbr-iconfont mobi-mbri-layers mobi-mbri"></span>
                </div>
                <div className="col-12 col-lg-5 bg">
                  <div className="text-box">
                    <h5 className="mbr-sub-title align-left mbr-fonts-style pb-2 display-4">
                      <strong>Column 3</strong>
                    </h5>

                    <p className="mbr-text align-left mbr-fonts-style display-4">
                      <strong>FBM SKU</strong> – Fulfilled by Merchant Stock
                      Keeping Unit
                      <br />
                      This value is the identifier for products that will be
                      sent by a merchant to a customer. FBM SKU’s are created by
                      the seller. The FBM SKU is always different than the FBA
                      SKU.
                      <br />
                      <br />
                      <strong>Product Image</strong> – Thumbnail of the main
                      image of the product.&nbsp;
                      <br />
                      <br />
                      Column 4<br />
                      <br />
                      <strong>Category</strong> – Amazon Category that is
                      assigned to your product.&nbsp;
                      <br />
                      <br />
                      <strong>Rank</strong> – (Also known as Amazon Best Sellers
                      Rank)
                      <br />
                      This is a metric that indicates how this product sells in
                      relationship to other products in the category. The lower
                      the number the better. Each category will have a range
                      that will suggest good product turnover.
                      <br />
                      <br />
                      <strong>
                        <a
                          href="understanding_rank.html"
                          className="text-primary"
                        >
                          More About Rank
                        </a>
                      </strong>
                      <br />
                      <br />
                      <strong>Tag 1 and 2</strong> – These are tags you can
                      enter to help sort and track your products. The Tag Report
                      is where you enter these values.&nbsp;
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5 img-col bg">
                  <div className="image-wrapper">
                    <img src={Column3} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-wrapper">
              <div className="row flex-row-reverse">
                <div className="col-lg-2 align-center icon-col">
                  <span className="mbr-iconfont mobi-mbri-image-gallery mobi-mbri"></span>
                </div>
                <div className="col-12 col-lg-5 bg">
                  <div className="text-box">
                    <h5 className="mbr-sub-title align-left mbr-fonts-style pb-2 display-4">
                      <strong>Column 5</strong>
                    </h5>

                    <p className="mbr-text align-left mbr-fonts-style display-4">
                      <strong>FBA Current Price</strong> – The price you have
                      set to sell the product through Amazon FBA
                      <br />
                      <br />
                      <strong>Total Fees</strong> – The total fees you will pay
                      when sold though Amazon FBA channel. This includes the
                      referral fee AND the pick and pack fee.
                      <br />
                      <br />
                      <strong>FBM Current Price</strong> – is the price you set
                      with your merchant fulfilled offering
                      <br />
                      <br />
                      <strong>FBM Fees</strong> – This is only the Amazon
                      referral fee and does not include your shipping and
                      handling cost from your location.
                      <br />
                      <br />
                      <strong>Buy Box</strong> – This is the price of the first
                      offer shown by Amazon to their customers. The item in the
                      buy box or listed first will typically get the most
                      sales.&nbsp;
                      <br />
                      <br />
                      Column 6
                      <br />
                      <br />
                      <strong>FBA Orders -&nbsp;</strong>Shows the amount sold
                      through the Amazon Warehouses for the following range of
                      days: 1-3, 1-7, 1-14, 1-30, 1-60, and 1-90 days.&nbsp;
                      <br />
                      <br />
                      <strong>Sell Through Rate</strong> –The number that Amazon
                      calculates to see how quickly your inventory is turning
                      over. It is a key data point for how Amazon determines how
                      much space you are allotted for your items. Amazon wants
                      quick moving inventory. The target number you want here is
                      2 or better. The higher the better, but if you have
                      products that consistently score below 2 you may want to
                      send them from your location instead of sending them into
                      the Amazon warehouse as FBA items. This will be especially
                      true in the third quarter where Amazon determines the
                      amount of space and items that will be allotted for the
                      all-important fourth quarter. This data point is a large
                      factor in the calculation of the IPI (Inventory
                      Performance Index) score.
                      <br />
                      &nbsp;
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5 img-col bg">
                  <div className="image-wrapper">
                    <img src={Features1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features5 cid-t2fVwa2TgQ" id="features05-3y">
          <div className="container-fluid">
            <div className="card-wrapper">
              <div className="row">
                <div className="col-lg-2 align-center icon-col">
                  <span className="mbr-iconfont mobi-mbri-rocket mobi-mbri"></span>
                </div>
                <div className="col-12 col-lg-5 bg">
                  <div className="text-box">
                    <h5 className="mbr-sub-title align-left mbr-fonts-style pb-2 display-4">
                      <strong>Column 7</strong>
                    </h5>

                    <p className="mbr-text align-left mbr-fonts-style display-4">
                      <strong>FBM Orders-&nbsp;</strong>Shows the amount sold
                      from your warehouse for the following range of days: 1-3,
                      1-7, 1-14, 1-30, 1-60, 1-90 days. This will allow you to
                      see if the volume would warrant sending it as an FBA item.
                      <br />
                      <br />
                      Column 8
                      <br />
                      <strong>Available</strong> – Amount shown in Amazon ready
                      for sales
                      <br />
                      <strong>Transfer</strong> – Items being transferred
                      internally in the Amazon Warehouse system
                      <br />
                      <strong>Inbound</strong> – Amount currently in route to
                      the Amazon warehouse from your location but not checked in
                      or received yet.
                      <br />
                      <strong>Total</strong> – is the sum of Available, Transfer
                      and Inbound.&nbsp; &nbsp;&nbsp;
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5 img-col bg">
                  <div className="image-wrapper">
                    <img src={Features1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-wrapper">
              <div className="row">
                <div className="col-lg-2 align-center icon-col">
                  <span className="mbr-iconfont mobi-mbri-photo mobi-mbri"></span>
                </div>
                <div className="col-12 col-lg-5 bg">
                  <div className="text-box">
                    <h5 className="mbr-sub-title align-left mbr-fonts-style pb-2 display-4">
                      <strong>Column 9</strong>
                    </h5>

                    <p className="mbr-text align-left mbr-fonts-style display-4">
                      <strong>Reserved</strong> - These numbers can included
                      transfers, being checked, customer orders, checked into
                      fulfillment center, recalls, etc.
                      <br />
                      <br />
                      <strong>FC Processing</strong> – Products which currently
                      are set aside to check authenticity, weight dimensions and
                      other possible checks. The process typically will take two
                      days.
                      <br />
                      <br />
                      <strong>Unfulfillable</strong> – These products are
                      damaged or in unsellable condition. You will want to
                      recall or put in an order to destroy the product in a
                      timely fashion.
                      <br />
                      <br />
                      <strong>Oversized</strong> – These items are calculated
                      based on product dimensions and weight and will allow us
                      to track the oversized inventory quantity.
                      <br />
                      <br />
                      Column 10
                      <br />
                      <strong>Days Remaining</strong> – How many days until you
                      are out of stock. This is a calculation that is based on
                      your previous sales. You can choose how many days of stock
                      you want in the Amazon warehouse on your settings page.
                      Read the example carefully to decided how you want the
                      program to calculate the amounts based on your inventory,
                      processes, and business plan.
                      <br />
                      <br />
                      <strong>Days Out of Stock</strong> –This number shows how
                      many days there were “0” in stock over the last 90 day
                      period – this number will not appear until 90 days after
                      product is in your store.
                      <br />
                      <br />
                      <strong>Daily Projected Sales (DPS)</strong> – Based on
                      your settings, the DPS will give you the average amount
                      you are selling on a daily basis.&nbsp;&nbsp;
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5 img-col bg">
                  <div className="image-wrapper">
                    <img src={Features3} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-wrapper">
              <div className="row">
                <div className="col-lg-2 align-center icon-col">
                  <span className="mbr-iconfont mobi-mbri-image-gallery mobi-mbri"></span>
                </div>
                <div className="col-12 col-lg-5 bg">
                  <div className="text-box">
                    <h5 className="mbr-sub-title align-left mbr-fonts-style pb-2 display-4">
                      <strong>Column 11</strong>
                    </h5>

                    <p className="mbr-text align-left mbr-fonts-style display-4">
                      <strong>Restock History</strong> – This column shows the
                      date and amount you sent into the warehouse based on
                      Active Inventory’s Restock reports. It will show the last
                      4 orders within a 60 day period.&nbsp; These numbers will
                      show up after you finalize your Restock Report.&nbsp; If
                      they are red, it indicates that the shipment has not yet
                      checked in as received by Amazon.
                      <br />
                      <br />
                      Column 12
                      <br />
                      <br />
                      <strong>Suggested Units</strong> – Active Inventory gives
                      you a number range of items you can choose to send in. It
                      calculates the amount of inventory to send in to the
                      Amazon warehouse to meet your Min or Max days of inventory
                      that you want to keep in stock. On the settings page you
                      will set the Min and Max amounts and they are indicated
                      here in between the parentheses (#). Active Inventory
                      gives you the ability to adjust your lead time setting or
                      the Min and Max to better reflect current shipping,
                      unpacking and processing times.
                      <br />
                      <br />
                      Column 13
                      <br />
                      <br />
                      <strong>FBA Shipment Amount</strong> – Here is where you
                      enter the amount you want to send into the Amazon
                      warehouse in this shipment.
                      <br />
                      <br />
                      Column 14
                      <br />
                      <br />
                      <strong>Notes </strong>– This space allows you to record
                      notes for the product. If there is a note, the first part
                      of the note will show, and you can mouse over for the full
                      note.&nbsp; &nbsp;
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5 img-col bg">
                  <div className="image-wrapper">
                    <img src={Features1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  )
}

export default UnderstandingRestockSheet
