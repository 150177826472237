import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import TabTitle from "components/Common/TabTitle"
import DataTable from "react-data-table-component"
import {
  cancelActiceSubscription,
  createSubscription,
  getBillingData,
  getSubscriptionPlan,
  reActiceSubscription,
  updateSubscription,
} from "store/action/billing"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import SubscriptionDetails from "components/Billing/SubscriptionDetails"
import SweetAlert from "react-bootstrap-sweetalert"
import SubscriptionPlan from "components/Billing/SubscriptionPlan"
import { useToasts } from "react-toast-notifications"
import Pagination from "../../components/Common/Pagination"

const Billing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()
  const subscription = useSelector(state => state?.Subscription?.data)
  const [resetPaginationToggle] = React.useState(false)
  const [billingData, setBillingData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [subscriptionInfo, setSubscriptionInfo] = useState({})
  const [subscriptionPlans, setSubscriptionPlans] = useState({})
  const [perPage, setPerPage] = useState(100)
  const [showPlanDetails] = useState(false)
  const [cancelSubscription, setCancelSubscription] = useState(false)
  const [, setDisableButton] = useState(false)
  const [, setChangePlan] = useState(false)
  const [changeSubscriptionPlan, setChangeSubscriptionPlan] = useState([])
  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalElements: 0,
  })

  useEffect(() => {
    fetchBillingData()
    fetchSubscriptionPlan()
  }, [])

  const fetchBillingData = () => {
    dispatch(
      getBillingData(
        { data: { pageNo: currentPage, pageSize: perPage } },
        responseData => {
          setBillingData([...responseData.data.content])
          setPagination({
            totalPages: responseData.data.totalPages,
            totalElements: responseData.data.totalElements,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const fetchSubscriptionPlan = () => {
    dispatch(
      getSubscriptionPlan(
        responseData => {
          setSubscriptionInfo(responseData.data)
          setSubscriptionPlans(responseData.data.subscriptionPlan)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const columns = [
    {
      name: "Invoice Number",
      selector: row => row.invoiceNumber,
      cell: row => <div>{row.invoiceNumber}</div>,
      sort: true,
    },
    {
      name: "Invoice Date",
      selector: row => row.invoiceDate,
      cell: row => (
        <div>{new Date(row.invoiceDate).toLocaleDateString("en-US")}</div>
      ),
      sort: true,
    },
    {
      name: "Invoice Type",
      selector: row => row.invoiceType,
      cell: row => <div>{row.invoiceType}</div>,
      sort: true,
    },
    {
      name: "Invoice Status",
      selector: row => row.invoiceStatus,
      cell: row => (
        <div
          className={
            row.invoiceStatus === "paid"
              ? "text-success font-weight-bold"
              : "text-danger font-weight-bold"
          }
        >
          {row.invoiceStatus.toUpperCase()}
        </div>
      ),
      sort: true,
    },
    {
      name: "Invoice URL",
      selector: row => row.invoiceUrl,
      cell: row => (
        <a href={`${row.invoiceUrl}`} target="_blank" rel="noreferrer">
          View Invoice
        </a>
      ),
      sort: true,
    },
    {
      name: "Total",
      selector: row => row.total,
      cell: row => <div>{`$` + row.total}</div>,
      sort: false,
    },
  ]

  const handlePlanSelection = planDetails => {
    dispatch(
      createSubscription(
        { priceId: planDetails[1]?.priceId },
        responseData => {
          setDisableButton(false)
          localStorage.setItem(
            "client_stripe_data",
            responseData.data.clientSecret
          )
          history.push({
            pathname: "/subscribe",
            state: { data: planDetails, isAddNewCard: false },
          })
        },
        errorData => {
          setDisableButton(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const changeSubscription = changeSubscriptionPlan => {
    dispatch(
      updateSubscription(
        {
          priceId: changeSubscriptionPlan[1].priceId,
          subscriptionId: subscription?.activeSubscription?.subscriptionId,
        },
        responseData => {
          setDisableButton(false)
          fetchBillingData()
        },
        errorData => {
          setDisableButton(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleAddNewCard = () => {
    history.push({
      pathname: "/add-new-card",
      state: { isAddNewCard: true },
    })
  }

  const handleChangeSubscription = () => {
    const { activeSubscription } = subscriptionInfo
    let availablePlan = Object.keys(subscriptionPlans).filter(
      item => item !== activeSubscription.subscriptionType
    )
    let planTitle = availablePlan[0]
    let tempObj = {}
    tempObj[planTitle] = subscriptionPlans[availablePlan]
    setSubscriptionPlans(tempObj)
    setChangeSubscriptionPlan([planTitle, subscriptionPlans[availablePlan]])
  }

  const confrimCancel = () => {
    dispatch(
      cancelActiceSubscription(
        responseData => {
          if (responseData.data) {
            setCancelSubscription(false)
            fetchSubscriptionPlan()
            fetchBillingData()
          }
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleCancelSubscription = () => {
    setCancelSubscription(!cancelSubscription)
  }

  const handleReactivateSubscription = subscriptionId => {
    dispatch(
      reActiceSubscription(
        { data: subscriptionId },
        responseData => {
          setCancelSubscription(false)
          fetchSubscriptionPlan()
          fetchBillingData()
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handlePageChange = e => {
    setCurrentPage(e - 1)
  }

  const handlePerRowsChange = e => {
    setPerPage(e)
  }

  return (
    <React.Fragment>
      {cancelSubscription && (
        <SweetAlert
          title={`Are you sure you want to cancel this subscription?`}
          warning
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => confrimCancel()}
          onCancel={() => setCancelSubscription(false)}
        ></SweetAlert>
      )}
      <div className="page-content">
        <MetaTags>
          <title>Billing | Active Inventory</title>
        </MetaTags>
        <Container fluid>
          <Row className="d-flex w-100 justify-content-center align-items-center">
            <Col>
              <TabTitle title="Billing" />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center p-3 m-0">
            <Col md="12" lg="12 ">
              <SubscriptionDetails
                plan={subscriptionPlans}
                changeSubscriptionPlan={changeSubscriptionPlan}
                setChangePlan={setChangePlan}
                changeSubscription={changeSubscription}
                handleChangeSubscription={handleChangeSubscription}
                handleCancelSubscription={handleCancelSubscription}
                handlePlanSelection={handlePlanSelection}
                handleReactivateSubscription={handleReactivateSubscription}
                handleAddNewCard={handleAddNewCard}
              />
            </Col>
            <Col>
              {showPlanDetails && (
                <SubscriptionPlan
                  plans={subscriptionPlans}
                  handlePlanSelection={handlePlanSelection}
                />
              )}
            </Col>
          </Row>
          {localStorage.getItem("isSubscriptionActive") === "false" ? null : (
            <div className="mt-3">
              <h5
                style={{ color: "#082C4E", fontFamily: "nunito-sans" }}
                className="fw-bold pl-1"
              >
                Invoice Details
              </h5>
              <div className="ai-Table">
                <DataTable
                  columns={columns}
                  data={billingData}
                  pagination
                  paginationComponent={() => (
                    <Pagination
                      data={billingData}
                      rowsPerPage={perPage}
                      currentPage={currentPage}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={e => handlePerRowsChange(e)}
                      paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                      rowCount={pagination.totalElements}
                    />
                  )}
                  paginationServer
                  paginationPerPage={100}
                  paginationRowsPerPageOptions={[50, 100, 200, 300, 500]}
                  paginationTotalRows={pagination.totalElements}
                  onChangePage={e => handlePageChange(e)}
                  onChangeRowsPerPage={e => handlePerRowsChange(e)}
                  paginationResetDefaultPage={resetPaginationToggle}
                  persistTableHead
                  fixedHeader
                  responsive
                  fixedHeaderScrollHeight="53vh"
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Billing
