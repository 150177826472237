import axios from 'axios';
import { BASE_URL, REFRESH_TOKEN } from 'utils/url';

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const configuration = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': [
      'Origin', 'Accept', 'X-Requested-With', 'Content-Type', 'Authorization'
    ]
  }
};

const instance = axios.create({ configuration });

instance.interceptors.request.use(async (config) => {

  let newHeaders = config.headers;
  let token = localStorage.getItem("token_type") + " " + localStorage.getItem("access_token");
  if (!config.url.includes("login") && !config.url.includes("public") && !config.url.includes("signup") && !config.url.includes("reset-password")) {
    let tokenData = parseJwt(token)
    let date = new Date();
    let milliseconds = date.getTime();
    if (tokenData.exp - 120 < milliseconds * 0.001) {
      const FormData = require('form-data');
      let data = new FormData();
      data.append('grant_type', 'refresh_token');
      data.append('refresh_token', localStorage.getItem("refresh_token"));

      let config = {
        method: 'POST',
        url: REFRESH_TOKEN,
        headers: {
          'Authorization': 'Basic ZWFzeS1jbGllbnQ6ZWFzeS1zZWNyZXQ=',
          "Content-Type": "multipart/form-data"
        },
        data: data
      };
      await axios(config)
        .then((resp) => {
          localStorage.setItem('access_token', resp.data.access_token)
          localStorage.setItem('token_type', resp.data.token_type)
          localStorage.setItem('refresh_token', resp.data.refresh_token)
          token = resp.data.token_type + " " + resp.data.access_token
        })
        .catch((error) => {
          console.log(error);
        });
    }
    newHeaders = Object.assign({}, newHeaders, { Authorization: token });
  }
  return Object.assign({}, config, { headers: newHeaders });
}, (error) => Promise.reject(error));

const getAxios = () => instance;

export default getAxios;