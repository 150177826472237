import {
    API_INVOCATION,
    GET_SETTINGS,
    UPDATE_SETTINGS
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

/**
 *
 *  get settings details
 */
export const getSettings = (resolve, reject) => {
    const payload = {
        action: GET_SETTINGS,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
            },
        },
        url: consturl.GET_SETTINGS,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

/**
 *
 *  update settings details
 */
export const updateSettings = (_payload, resolve, reject) => {
    const payload = {
        action: UPDATE_SETTINGS,
        method: constdata.PUT,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
            },
        },
        url: consturl.UPDATE_SETTINGS,
        resolve,
        reject,
        data: { ..._payload.data }
    }
    return { type: API_INVOCATION, payload }
}

