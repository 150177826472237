import * as React from "react"
import PropTypes from "prop-types"
import { css, CSSObject, FlattenSimpleInterpolation } from "styled-components"
import styled from "styled-components"
import Select from "./Select"
import FirstPage from "../../assets/images/page-first.svg"
import LastPage from "../../assets/images/page-last.svg"
import NextPage from "../../assets/images/page-next.svg"
import PrevPage from "../../assets/images/page-prev.svg"

const SMALL = 599
const MEDIUM = 959
const LARGE = 1280

const media = {
  sm: (literals, ...args) => css`
    @media screen and (max-width: ${SMALL}px) {
      ${css(literals, ...args)}
    }
  `,
  md: (literals, ...args) => css`
    @media screen and (max-width: ${MEDIUM}px) {
      ${css(literals, ...args)}
    }
  `,
  lg: (literals, ...args) => css`
    @media screen and (max-width: ${LARGE}px) {
      ${css(literals, ...args)}
    }
  `,
  custom:
    value =>
      (literals, ...args) =>
        css`
        @media screen and (max-width: ${value}px) {
          ${css(literals, ...args)}
        }
      `,
}

const defaultComponentOptions = {
  rowsPerPageText: "Rows per page:",
  rangeSeparatorText: "of",
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: "All",
}

const PaginationWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${({ theme }) => theme.pagination.style};
`

const Button = styled.button`
  position: relative;
  display: block;
  user-select: none;
  border: none;
  ${({ theme }) => theme.pagination.pageButtonsStyle};
`

const PageList = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
  ${media.sm`
    width: 100%;
    justify-content: space-around;
  `};
`

const Span = styled.span`
  flex-shrink: 1;
  user-select: none;
`

const Range = styled(Span)`
  margin: 0;
`

const RowLabel = styled(Span)`
  margin: 0 4px;
`

Pagination.propTypes = {
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  direction: PropTypes.string,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  paginationRowsPerPageOptions: PropTypes.array.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  paginationTotalRows: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage) - 1;
}

function Pagination({
  rowsPerPage,
  rowCount,
  currentPage,
  paginationRowsPerPageOptions = paginationRowsPerPageOptions,
  onChangeRowsPerPage = onChangeRowsPerPage,
  onChangePage = onChangePage,
}) {
  //const windowSize = useWindowSize();
  //const shouldShow = windowSize.width && windowSize.width > SMALL;
  const shouldShow = true
  // const isRTL = detectRTL(direction);
  const numPages = getNumberOfPages(rowCount, rowsPerPage)
  const lastIndex = ((currentPage + 1) * rowsPerPage)
  const firstIndex = lastIndex - rowsPerPage + 1
  const disabledLesser = currentPage === 0
  const disabledGreater = currentPage === numPages
  const options = { ...defaultComponentOptions }
  const range =
    currentPage === numPages
      ? `${firstIndex}-${rowCount} ${options.rangeSeparatorText} ${rowCount}`
      : `${firstIndex}-${lastIndex} ${options.rangeSeparatorText} ${rowCount}`

  const handlePrevious = React.useCallback(
    () => onChangePage(currentPage - 1),
    [currentPage, onChangePage]
  )
  const handleNext = React.useCallback(
    () => onChangePage(currentPage + 1),
    [currentPage, onChangePage]
  )
  const handleFirst = React.useCallback(() => onChangePage(0), [onChangePage])
  const handleLast = React.useCallback(
    () => onChangePage(getNumberOfPages(rowCount, rowsPerPage)),
    [onChangePage, rowCount, rowsPerPage]
  )
  const handleRowsPerPage = React.useCallback(
    e => onChangeRowsPerPage(Number(e.target.value), currentPage),
    [currentPage, onChangeRowsPerPage]
  )

  const selectOptions = paginationRowsPerPageOptions.map(num => (
    <option key={num} value={num}>
      {num}
    </option>
  ))

  if (options.selectAllRowsItem) {
    selectOptions.push(
      <option key={-1} value={rowCount}>
        {options.selectAllRowsItemText}
      </option>
    )
  }

  const select = (
    <Select
      onChange={handleRowsPerPage}
      defaultValue={rowsPerPage}
      aria-label={options.rowsPerPageText}
      className="font-weight-bold"
    >
      {selectOptions}
    </Select>
  )

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px"
      }}
    >
      <div style={{order: 0}}>
        <Button
          id="pagination-first-page"
          type="button"
          aria-label="First Page"
          aria-disabled={disabledLesser}
          onClick={handleFirst}
          disabled={disabledLesser}
          isRTL={true}
          style={{ opacity: disabledLesser ? 0.5 : 1 }}
        >
          <img src={FirstPage} className="ai-page-icon" />
        </Button>
      </div>

      <div style={{order: 1}}>
        <Button
          id="pagination-previous-page"
          type="button"
          aria-label="Previous Page"
          aria-disabled={disabledLesser}
          onClick={handlePrevious}
          disabled={disabledLesser}
          isRTL={true}
          style={{ opacity: disabledLesser ? 0.5 : 1 }}
        >
          <img src={PrevPage} className="ai-page-icon" />
        </Button>
      </div>

      <div style={{order: 2,textAlign: "center", color: "#082C4E"}} className="element">
        {!options.noRowsPerPage && shouldShow && (
          <>
            <RowLabel className="font-weight-bold">{options.rowsPerPageText}</RowLabel>
            {select}
          </>
        )}
        {shouldShow && <Range className="font-weight-bold">{range}</Range>}
      </div>
      {!shouldShow && select}
      <div style={{order: 3}}>
        <Button
          id="pagination-next-page"
          type="button"
          aria-label="Next Page"
          aria-disabled={disabledGreater}
          onClick={handleNext}
          disabled={disabledGreater}
          isRTL={true}
          style={{ opacity: disabledGreater ? 0.5 : 1 }}
        >
          <img src={NextPage} className="ai-page-icon" />
        </Button>
      </div>
      <div style={{order: 4}}>
        <Button
          id="pagination-last-page"
          type="button"
          aria-label="Last Page"
          aria-disabled={disabledGreater}
          onClick={handleLast}
          disabled={disabledGreater}
          style={{ opacity: disabledGreater ? 0.5 : 1 }}
          isRTL={true}
        >
          <img src={LastPage} className="ai-page-icon" />
        </Button>
      </div>
    </div>
  )
}

export default React.memo(Pagination)
