import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { forgotPassword } from "store/action/user"
import { useToasts } from "react-toast-notifications"
import "./authentication.scss"
import logo from "assets/images/logo-green.png"
import "../../assets/scss/style.scss"

const ForgetPasswordPage = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  useEffect(() => {
    document.body.classList.add("ai-login-page")
    return () => {
      document.body.classList.remove("ai-login-page")
    }
  }, [])

  const [disableButton, setDisableButton] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      setDisableButton(true)
      dispatch(
        forgotPassword(
          { data: { ...values } },
          response => {
            setDisableButton(false)
            addToast(response.data, {
              appearance: 'success',
              autoDismiss: true,
            })
          },
          error => {
            setDisableButton(false)
            addToast(error.response.data.error.displayMessage, {
              appearance: 'error',
              autoDismiss: true,
            })
          }
        ))
    }
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password | Active Inventory
        </title>
      </MetaTags>
      <div className="ai-grid-container">
        <div className="ai-grid-item">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "70px 20px 90px 20px",
            }}
          >
            <img src={logo} alt="" style={{ width: "220px" }} />
          </div>
          <div>
            <h2 className="ai-head-text" style={{ color: "#FFFFFF" }}>
              Forgot Password?
            </h2>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Label className="form-label text-white">Enter your email address and you will receive an email with a link for you to reset the password. The link is valid for 15 minutes</Label>
              <Row className="mb-3">
                <Col className="d-grid justify-content-center">
                  <Input
                    name="email"
                    style={{
                      width: "452px",
                      height: "44px",
                      margin: "10px 10px 15px 12px",
                      fontSize: "15px",
                      borderRadius: "10px",
                    }}
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <button
                    disabled={disableButton}
                    className="ai-btn ai-head-text"
                    type="submit"
                    style={{
                      width: "452px",
                      color: "#082C4E",
                      backgroundColor: "#FFEE00",
                      font: "bold 18px novecento-sans",
                    }}
                  >
                    {
                      disableButton &&
                      <>
                        <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    }
                    {disableButton ? "Please wait..." : "Reset Password"}
                  </button>
                </Col>
              </Row>
            </Form>
            <div className="mt-1 text-center">
              <p className="text-white">
                &lt; 
                <Link to="login" className="font-weight-medium text-white">
                Back to Login
                </Link>
              </p>
              <p className="text-white pointer" onClick={() => window.open(
                'https://support.myactiveinventory.com/support/solutions',
                '_blank'
              )}>Support</p>
            </div>
          </div>
        </div>
      </div>
      <footer className="position-fixed w-100" style={{bottom: "0px"}}>
        <p  style={{textAlign: "center"}} className="text-white">Copyright © {new Date().getFullYear()} Active Inventory</p>
        {/* <p  style={{textAlign: "center"}} className="text-white">Developed by: <a className="text-danger" href="https://tirnav.com" target="_new">Tirnav Solutions</a></p> */}
      </footer>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)
