import React from "react"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/bootstrap/css/bootstrap-grid.min.css"
import "../assets/bootstrap/css/bootstrap-reboot.min.css"
import "../assets/theme/css/style.css"
import Header from "static-site/common/Header"
import Footer from "static-site/common/Footer"
import { MetaTags } from "react-meta-tags"

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div className="static-site">
        <MetaTags>
          <title>Privacy | Active Inventory</title>
        </MetaTags>
        <Header />
        <section data-bs-version="5.1" className="cid-t2fELf8oGt" id="header8-2h">
          <div className="container" style={{ marginTop: "20px" }}>
            <div className="row align-items-center">
              <div className="col-12 col-md">
                <div className="text-wrapper">
                  <p className="desc1 mbr-section-title mbr-fonts-style display-2">
                    Privacy&nbsp;Policy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content5" id="content05-3q">
          <div className="container">
            <div className="row col text-black mt-5">
              <table className="privacy-policy-table" width="635">
                <tbody>
                  <tr>
                    <td className="privacy-policy-table-td" width="49%">
                      <p><span className="privacy-policy-p">State of Ohio</span></p>
                      <p>&nbsp;</p>
                    </td>
                    <td className="privacy-policy-table-td-1" width="50%">
                      <p className="align-right"><span className="privacy-policy-rev">Rev. 13461BC</span></p>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name-wrapper" colSpan="2" width="637">
                      <p className="align-center"><strong><span className="privacy-policy-text">PRIVACY NOTICE</span></strong></p>
                      <p className="align-center"><strong><span className="company-name-text">&nbsp;ACTIVE INVENTORY&nbsp;</span></strong></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-black px-5 my-5 privacy-policy-font">
          <p className="align-right"><span>Version Date: July 29, 2022</span></p>
          <p>&nbsp;</p>
          <ol>
            <li>
              <strong><span className="privacy-policy-font font-weight-bold">GENERAL</span></strong>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`History Source LLC ("History Source LLC" or "we" or "us" or "our") respects the privacy of everyone ("Sites' visitor" "user" or "you") that uses our website at https://myactiveinventory.com/, as well as other device or online applications related or connected thereto (collectively, the "Sites").`}</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`The following History Source LLC Privacy Notice ("Privacy Notice") is designed to inform you, as a user of the Sites, about the types of personal information that History Source LLC may gather about or collect from you in connection with your use of the Sites. It also is intended to explain the conditions under which History Source LLC uses and discloses that personal information, and your rights in relation to that personal information.`}</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">The Sites are hosted in the United States and is subject to U.S. state and federal law. If you are accessing our Sites from other jurisdictions, please be advised that you are transferring your personal information to us in the United States, and by using our Sites, you are agreeing to that transfer and use of your personal information in accordance with this Privacy Notice. You also agree to abide to the applicable laws of the State of Ohio and U.S. federal law concerning your use of the Sites and your agreements with us. If your use of the Sites would be unlawful in your jurisdiction, please do not use the Sites.</span></p>
              <p className="privacy-policy-justify"><strong><span className="privacy-policy-font font-weight-bold">Company Information</span></strong></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">History Source LLC</span><span className="privacy-policy-font"> (DBA Active Inventory)</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">1120 Wilbur Ave</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">Norton, OH 44203</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">Email: support@myactiveinventory.com</span></p>
            </li>
            <li><strong><span className="font-weight-bold privacy-policy-font">HOW WE COLLECT AND USE YOUR PERSONAL INFORMATION</span></strong>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">History Source LLC gathers personal information from users of the Sites. When you browse our Sites, subscribe to our services or contact us through various social or web forms you are voluntarily sharing personal information with us. This personal information also includes various data that we collect automatically. This may be the user&rsquo;s Internet Protocol (IP) address, operating system, browser type and the locations of the Sites the user views right before arriving at, while navigating and immediately after leaving the Sites. It may also include various technical aspects of user&rsquo;s computer or browser and users browsing habits that are collected through cookies. History Source LLC may analyze various mentioned personal information gathered from or about users to help History Source LLC better understand how the Sites are used and how to make them better. By identifying patterns and trends in usage, History Source LLC is able to better design the Sites to improve users&rsquo; experiences, both in terms of content and ease of use. From time to time, History Source LLC may also release the anonymized information gathered from the users in the aggregate, such as by publishing a report on trends in the usage of the Sites.</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">When we believe disclosure is appropriate, we may disclose your information to help investigate, prevent or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of History Source LLC, our users, our employees or others; to comply with applicable law or cooperate with law enforcement; to enforce our Terms of Use or other agreements or policies, in response to a subpoena or similar investigative demand, a court order or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us. We will notify you if permitted before undertaking such disclosures.</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">History Source LLC reserves the right to transfer all information in its possession to a successor organization in the event of a merger, acquisition, bankruptcy or other sale of all or a portion of History Source LLC&rsquo;s assets. Other than to the extent ordered by a bankruptcy or other court, the use and disclosure of all transferred information will be subject to this Privacy Notice, or to a new privacy notice if you are given notice of that new privacy notice and are given an opportunity to affirmatively opt-out of it.</span></p>
            </li>
            <li><strong><span className="privacy-policy-font font-weight-bold"> DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong>
              <p className="privacy-policy-justify"><em><span className="privacy-policy-font font-weight-bold">General Provisions</span></em></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">History Source LLC contractually prohibits its contractors, affiliates, vendors and suppliers from disclosing any personal information received from History Source LLC, other than in accordance with this Privacy Notice. These third parties may include advertisers, providers of games, utilities, widgets and a variety of other third-party applications accessible through the Sites. History Source LLC neither owns nor controls the third-party websites and applications accessible through the Sites. Thus, this Privacy Notice does not apply to personal information provided to or gathered by the third parties that operate them. Before visiting a third party, or using a third-party application, whether by means of a link on the Sites, directly through the Sites or otherwise, and before providing any personal information to any such third party, users should inform themselves of the privacy policies and practices (if any) of the third party responsible for those Sites or applications, and should take those steps necessary to, in those users&rsquo; discretion, protect their privacy. </span></p>
              <p className="privacy-policy-justify"><em><span className="privacy-policy-font font-weight-bold">Analytics</span></em></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">We may use third-party vendors, including Google, who use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize and serve ads based on your past activity on the Sites, including Google Analytics for Display Advertising. The personal information collected may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. If you do not want any personal information to be collected and used by Google Analytics, you can install an opt-out in your web browser (</span><span className="font-12"><a href="https://tools.google.com/dlpage/gaoptout/"><span className="link">https://tools.google.com/dlpage/gaoptout/</span></a></span><span className="privacy-policy-font"> ) and/or opt out from Google Analytics for Display Advertising or the Google Display Network by using Google&rsquo;s Ads Settings(</span><span className="font-12"><a href="http://www.google.com/settings/ads"><span className="link">www.google.com/settings/ads</span></a></span><span className="privacy-policy-font"> ).</span></p>
            </li>
            <li><strong><span className="privacy-policy-font font-weight-bold">SECURITY</span></strong>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">We take the security of your personal information seriously and use reasonable electronic, personnel and physical measures to protect it from loss, theft, alteration or misuse. However, please be advised that even the best security measures cannot fully eliminate all risks. We cannot guarantee that only authorized persons will view your personal information. We are not responsible for third-party circumvention of any privacy settings or security measures.</span></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">We are dedicated to protect all personal information on the Sites as is necessary. However, you are responsible for maintaining the confidentiality of your personal information by keeping your password and computer/mobile device access confidential. If you have an account with History Source LLC and believe someone has gained unauthorized access to it or your account please change your password as soon as possible. If you lose control of your account, you should notify us immediately.</span></p>
            </li>
            <li><strong><span className="privacy-policy-font font-weight-bold"> PRIVACY RIGHTS - CALIFORNIA USERS</span></strong>
              <p className="privacy-policy-justify"><em><span className="privacy-policy-font font-weight-bold">Do Not Sell My Personal Information Notice - California Consumer Privacy Act (CCPA)</span></em></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`History Source LLC has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. History Source LLC will not sell personal information in the future belonging to Sites' visitors, users and other consumers.`}</span></p>
              <p className="privacy-policy-justify"><em><span className="privacy-policy-font font-weight-bold">If you are under 18 years of age</span></em></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`If you have registered account with History Source LLC, you have the right to request the removal of unwanted personal information that you publicly post on our Sites. To request the removal of such information, please contact us using the contact information provided below. Make sure to include your account's email address and a statement that you reside in California.`}</span></p>
              <p className="privacy-policy-justify"><em><span className="privacy-policy-font font-weight-bold">{`"Shine the Light Law"`}</span></em></p>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, personal information about the personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year. If applicable, this personal information would include a list of the categories of the personal information that was shared and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to the address listed below.`}</span></p>
            </li>
            <li>
              <strong><span className="privacy-policy-font font-weight-bold">CHILDREN</span></strong>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`The Children's Online Privacy Protection Act ("COPPA") protects the online privacy of children under 13 years of age. We do not knowingly collect or maintain personal information from anyone under the age of 13, unless or except as permitted by law. Any person who provides personal information through the Sites represents to us that he or she is 13 years of age or older. If we learn that personal information has been collected from a user under 13 years of age on or through the Sites, then we will take the appropriate steps to cause this personal information to be deleted. If you are the parent or legal guardian of a child under 13 who has become a member of the Sites or has otherwise transferred personal information to the Sites, please contact History Source LLC using our contact personal information below to have that child's account terminated and personal information deleted.`}</span></p>
            </li>
            <li>
              <strong><span className="privacy-policy-font font-weight-bold">DO-NOT-TRACK NOTICE</span></strong>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">{`Most web browsers and some mobile operating systems include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. Because there is not yet a common understanding of how to interpret the DNT signal, the Sites currently do not respond to DNT browser signals or mechanisms.`}</span></p>
            </li>
            <li>
              <strong><span className="privacy-policy-font font-weight-bold"> GOVERNING LAW</span></strong>
              <p className="privacy-policy-justify font-weight-bold"><span className="privacy-policy-font">Disputes over privacy issues contained in this Privacy Notice will be governed by the law of the State of Ohio. You also agree to abide by any limitation on damages contained in our Terms of Use, or other agreement that we have with you.</span></p>
            </li>
            <li>
              <strong><span className="privacy-policy-font font-weight-bold">PRIVACY NOTICE CHANGES</span></strong>
              <p className="privacy-policy-justify"><span className="privacy-policy-font">History Source LLC may, in its sole discretion, change this Privacy Notice from time to time. Any and all changes to History Source LLC&rsquo;s Privacy Notice will be reflected on this page and the date of the new version will be stated at the top of this Privacy Notice. Unless stated otherwise, our current Privacy Notice applies to all personal information that we have about you and your account. Users should regularly check this page for any changes to this Privacy Notice. History Source LLC will always post new versions of the Privacy Notice on the Sites. However, History Source LLC may, as determined in its discretion, decide to notify users of changes made to this Privacy Notice via email or otherwise. Accordingly, it is important that users always maintain and update their contact personal information.</span></p>
            </li>
            <li><strong><span className="privacy-policy-font font-weight-bold">CONTACT</span></strong></li>
          
          <p className="privacy-policy-justify"><span className="privacy-policy-font">If you have any questions regarding our Privacy Notice, please contact us at:</span></p>
          <p className="privacy-policy-justify"><span className="privacy-policy-font">History Source LLC</span><span className="privacy-policy-font"> (DBA Active Inventory)</span></p>
          <p className="privacy-policy-justify"><span className="privacy-policy-font">1120 Wilbur Ave</span></p>
          <p className="privacy-policy-justify"><span className="privacy-policy-font">Norton, OH 44203</span></p>
          <table className="bottom-table" width="100%">
            <tbody>
              <tr>
                <td className="bottom-td" width="46%">
                  <p><span className="privacy-policy-font">Email: <a href="mailto:support@myactiveinventory.com">support@myactiveinventory.com</a></span></p>
                </td>
              </tr>
            </tbody>
          </table>
          </ol>
        </div>
          </div>
        </section>

        
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicy
