import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Container,
  Spinner,
  InputGroupText,
  InputGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import DataTable from "react-data-table-component"
import { useDispatch } from "react-redux"
import { outOfStockData } from "store/action/stock"
import { useToasts } from "react-toast-notifications"
import TabTitle from "components/Common/TabTitle"
import "../../assets/scss/custom/rdt.scss"
import "./outOfStock.scss"
import RefreshIcon from "../../assets/images/refresh.svg"
import ExcelIcon from "../../assets/images/xlsx-icon.svg"
import PDFIcon from "../../assets/images/pdf-icon.svg"
import Pagination from "../../components/Common/Pagination"
import "../../assets/scss/style.scss"
import { BASE_URL } from 'utils/url';

const OutOfStockReport = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [outOfStock, setOutOfStock] = useState([])
  const [outOfStockOriginal, setOutOfStockOriginal] = useState([])
  const [perPage, setPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(0)
  const [filterValue, setFilterValue] = useState({
    title: "",
    sku: "",
    tag: "",
    asin: "",
  })
  const [resetPaginationToggle] = useState(true)
  const [downloadLoader, setDownloadDownder] = useState({
    type: "",
    loader: false,
  })
  const [sortingFlag, setSortingFlag] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("marketplaceId")) {
      getOutOfStockData()
    }
  }, [localStorage.getItem("marketplaceId")])

  useEffect(() => {
		// when using client-side pagination we can just slice the rows set
    //const lastIndex = Math.min(((currentPage+1) * perPage),outOfStockOriginal.length);
    //const firstIndex = lastIndex - perPage;
    const lastIndex = ((currentPage + 1) * perPage)
    const firstIndex = lastIndex - perPage;
    console.log(firstIndex, Math.min(lastIndex,outOfStockOriginal.length));
    var data = outOfStockOriginal.slice(firstIndex, Math.min(lastIndex,outOfStockOriginal.length));
    setOutOfStock(data);
	}, [currentPage, perPage]);

  const handlePageChange = (e) => {
    setCurrentPage(e)
  };

  const handlePerRowsChange = (e) => {
      setPerPage(e)
      setCurrentPage(0)
  }

  useEffect(() => {
    let filterData = [...outOfStockOriginal]
    if (filterValue?.title?.trim() && filterValue?.title?.trim() !== "") {
      filterData = [
        ...filterData.filter(item =>
          item.title.toLowerCase().includes(filterValue?.title?.toLowerCase())
        ),
      ]
    }
    if (filterValue?.sku?.trim() && filterValue?.sku?.trim() !== "") {
      filterData = [
        ...filterData.filter(item =>
          item.sku.toLowerCase().includes(filterValue?.sku?.toLowerCase())
        ),
      ]
    }
    if (filterValue?.tag?.trim() && filterValue?.tag?.trim() !== "") {
      filterData = [
        ...filterData.filter(item =>
          item.tags.toLowerCase().includes(filterValue?.tag?.toLowerCase())
        ),
      ]
    }
    if (filterValue?.asin?.trim() && filterValue?.asin?.trim() !== "") {
      filterData = [
        ...filterData.filter(item =>
          item.asin.toLowerCase().includes(filterValue?.asin?.toLowerCase())
        ),
      ]
    }
    setOutOfStock(filterData)
  }, [filterValue.title, filterValue.sku, filterValue.tag, filterValue.asin])

  const getOutOfStockData = () => {
    dispatch(
      outOfStockData(
        responseData => {
          var data = responseData.data.slice(0, Math.min(perPage,responseData.data.length));
          setOutOfStock(data)
          setOutOfStockOriginal(responseData.data)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const format_DOOSITL = row => {
    return (
      <p className="w-100 m-0">
        <Row xs={12} className="d-flex justify-content-around mx-0 text-center row"
        >
          <Col xs={3} className="font-weight-bold">
            {row.oos14Day}
          </Col>
          <Col xs={3} className="font-weight-bold">
            {row.oos30Day}
          </Col>
          <Col xs={3} className="font-weight-bold">
            {row.oos60Day}
          </Col>
          <Col xs={3} className="font-weight-bold">
            {row.oos90Day}
          </Col>
        </Row>
      </p>
    )
  }

  const format_TITLE = row => {
    return (
      <div className="text-elipsis font-weight-bold">
        <span data-toggle="tooltip" title={`${row.title}`} className="font-weight-bold">
          {row.title}
        </span>
      </div>
    )
  }
  const format_TAG = row => {
    const separatedText = row.tags.split(",")
    return (
      <div>
        {separatedText == "" ? (
          ""
        ) : (
          <div className="d-flex tag-width">
            {separatedText.map((item, index) => {
              return (
                <span
                  key={index}
                  className="badge badge-secondary m-1 font-size-10"
                  data-toggle="tooltip"
                // tag={`${item}`}
                >
                  {item}
                </span>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  const format_SKU = sku => {
    return <div className="word-break">{sku}</div>
  }

  const columns = [
    {
      name: "SKU",
      selector: row => row.sku,
      cell: row => format_SKU(row.sku),
      sortable: true,
      width: "150px",
    },
    {
      name: "Asin",
      selector: row => row.asin,
      sortable: true,
      width: "120px",
    },
    {
      name: "Title",
      selector: row => row.title,
      cell: row => format_TITLE(row),
      sortable: true,
      grow: 1,
      style: {
        "justify-content": "left !important",
      },
    },
    {
      name: (
        <div>
          Current <br />
          Inventory
        </div>
      ),
      selector: row => row.currentInv,
      sortable: true,
      width: "100px",
    },
    {
      name: (
        <div className="w-100">
          <p className="mb-0 font-weight-bold text-center">
            Days Out of Stock in the Last
          </p>
          <Row className="d-flex justify-content-around mx-0 text-center">
            <Col
              sm={3}
              className="font-weight-bold px-1 pointer"
              onClick={() => customSort("oos14Day")}
            >
              14d
            </Col>
            <Col
              sm={3}
              className="font-weight-bold px-1 pointer"
              onClick={() => customSort("oos30Day")}
            >
              30d
            </Col>
            <Col
              sm={3}
              className="font-weight-bold px-1 pointer"
              onClick={() => customSort("oos60Day")}
            >
              60d
            </Col>
            <Col
              sm={3}
              className="font-weight-bold px-1 pointer"
              onClick={() => customSort("oos90Day")}
            >
              90d
            </Col>
          </Row>
        </div>
      ),
      cell: row => format_DOOSITL(row),
      grow: 1,
      width: "250px",
    },
    {
      name: "Tags",
      selector: row => row.tags,
      cell: row => format_TAG(row),
      sortable: true,
      width: "200px",
    },
  ]

  const customSort = days => {
    let prepareSortData = [...outOfStockOriginal]
    setSortingFlag(!sortingFlag)
    if (outOfStock.length > 0) {
      let sortedData = prepareSortData.sort((firstData, secondData) => {
        let comparison = 0
        if (firstData[days] > secondData[days]) {
          comparison = 1
        } else if (firstData[days] < secondData[days]) {
          comparison = -1
        }
        return sortingFlag ? comparison : comparison * -1
      })
      setOutOfStock(sortedData)
    } else {
      addToast("There are no data to sort!", {
        appearance: "info",
        autoDismiss: true,
      })
    }
  }

  const printReport = type => {
    setDownloadDownder({ type: type, loader: true })
    var myHeaders = new Headers()

    myHeaders.append(
      "X-CLIENTMARKETPLACE-ID",
      localStorage.getItem("marketplaceId")
    )
    myHeaders.append(
      "Authorization",
      localStorage.getItem("token_type") +
      " " +
      localStorage.getItem("access_token")
    )
    myHeaders.append("Content-Type", "application/json")

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      BASE_URL+`product/outOfStock/download?type=${type === "csv" ? "csv" : "pdf"
      }`,
      requestOptions
    )
      .then(resp => resp.blob())
      .then(blob => {
        setDownloadDownder({ type: type, loader: false })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = `Product_OOS.${type === "csv" ? "xlsx" : "pdf"}`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        addToast("File downloaded successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(() => {
        setDownloadDownder({ type: type, loader: false })
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        })
      })
  }

  const handleRefreshClick = type => {
    switch (type) {
      case "title":
        setFilterValue(state => {
          return {
            ...state,
            title: "",
          }
        })
        break
      case "sku":
        setFilterValue(state => {
          return {
            ...state,
            sku: "",
          }
        })
        break
      case "tag":
        setFilterValue(state => {
          return {
            ...state,
            tag: "",
          }
        })
        break
      case "asin":
        setFilterValue(state => {
          return {
            ...state,
            asin: "",
          }
        })
        break
      default:
        break
    }
  }

  const handleRefreshEntireSheet = () => {
    setFilterValue({ title: "", sku: "", tag: "", asin: "" })
    setSortingFlag(false)
    setDownloadDownder({ type: "", loader: false })
    getOutOfStockData()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Out Of Stock Report | Active Inventory</title>
        </MetaTags>
        <Container fluid>
          <TabTitle title="Out Of Stock Report" tooltip="This page indicates the number of days that an item has had “0” in stock during different time frames.  Use this to see what products you need to focus on getting restocked. "/>
          <div
            className="shadow-sm col-12 d-flex py-2 mb-3 row table-filter text-white mx-1 py-2 px-0"
            style={{ height: "70px" }}
          >
            <div className="d-flex col-lg-10 align-items-center sm-spacing">
              <div className="d-flex w-100">
                <div className="d-flex align-items-center sm-spacing text-white px-1">
                  <div>Search By</div>&nbsp;&nbsp;
                  {/* <span className="text-white">Title</span>&nbsp;&nbsp; */}
                  <div>
                    <InputGroup size="sm" className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Title"
                        className={
                          "border-1 border border-light w-100 ai-input-radius"
                        }
                        value={filterValue.title}
                        onChange={e =>
                          setFilterValue({
                            ...filterValue,
                            title: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="d-flex align-items-center sm-spacing text-white px-1">
                  {/* <div className="text-white">SKU</div>&nbsp;&nbsp; */}
                  <div>
                    <InputGroup size="sm" className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Sku"
                        className={
                          "border-1 border border-light w-100 ai-input-radius"
                        }
                        value={filterValue.sku}
                        onChange={e =>
                          setFilterValue({
                            ...filterValue,
                            sku: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="d-flex align-items-center sm-spacing text-white px-1">
                  {/* <div className="text-white">Tag</div>&nbsp;&nbsp; */}
                  <div>
                    <InputGroup size="sm" className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Tag"
                        className={
                          "border-1 border border-light w-100 ai-input-radius"
                        }
                        value={filterValue.tag}
                        onChange={e =>
                          setFilterValue({
                            ...filterValue,
                            tag: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="d-flex align-items-center sm-spacing text-white px-1">
                  {/* <div className="text-white">Asin</div>&nbsp;&nbsp; */}
                  <div>
                    <InputGroup size="sm" className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Asin"
                        className={
                          "border-1 border border-light w-100 ai-input-radius"
                        }
                        value={filterValue.asin}
                        onChange={e =>
                          setFilterValue({
                            ...filterValue,
                            asin: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex col-lg-2 align-items-center justify-content-end sm-spacing">
              <div>
                <div className="text-white d-flex justify-content-between flex-row mt-2 w-100">
                  <div
                    id="refresh"
                    name="refresh"
                    className="pointer"
                    onClick={() => handleRefreshEntireSheet()}
                  >
                    <img src={RefreshIcon} className="inventory-lab-img" />
                  </div>
                  <div
                    id="csv"
                    name="csv"
                    className="pointer mx-2"
                    onClick={() => printReport("csv")}
                  >
                    {downloadLoader.type === "csv" && downloadLoader.loader ? (
                      <Spinner color="light" size="sm">
                        Loading...
                      </Spinner>
                    ) : (
                      <img src={ExcelIcon} className="inventory-lab-img" />
                    )}
                  </div>
                  <div
                    id="pdf"
                    name="pdf"
                    className="pointer mx-2"
                    onClick={() => printReport("pdf")}
                  >
                    {downloadLoader.type === "pdf" && downloadLoader.loader ? (
                      <Spinner color="light" size="sm">
                        Loading...
                      </Spinner>
                    ) : (
                      <img src={PDFIcon} className="inventory-lab-img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ai-Table">
            <DataTable
              columns={columns}
              data={outOfStock}
              pagination
              paginationComponent={() => (
                  <Pagination
                      data={outOfStock}
                      rowsPerPage={perPage}
                      currentPage={currentPage}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                      paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                      rowCount={outOfStockOriginal.length} />
              )}
              paginationPerPage={500}
              paginationTotalRows={outOfStock.length}
              paginationRowsPerPageOptions={[50, 100, 200, 300, 500]}
              paginationResetDefaultPage={resetPaginationToggle}
              persistTableHead
              fixedHeader
              dense
              responsive
              fixedHeaderScrollHeight="53vh"
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #CEE0F0",
                borderRadius: "17px",
              }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OutOfStockReport
