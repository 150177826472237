import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Alert, Input, FormFeedback, Form } from "reactstrap"
import { Formik, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { registerDemoUser, loginUser, getUserProfile, getUserData } from "store/action/user"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import "./authentication.scss"
import logo from "assets/images/logo-green.png"

const Demo = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [disableButton, setDisableButton] = useState(false)
  const { addToast } = useToasts()
  const [demoButtonText, setDemoButtonText] = useState('GIVE ME ACCESS TO THE FREE DEMO!')
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const alphaRegExp = /^[a-zA-Z]+$/
  const passwordRegExp =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] =
    useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisibility(!repeatPasswordVisibility)
  }

  useEffect(() => {
    localStorage.clear();
    document.body.classList.add("ai-login-page")
    return () => {
      document.body.classList.remove("ai-login-page")
    }
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: "Love To",
      lastName: "See It",
      userEmail: "demo@active.com",
      password: "Active@123",
      confirmPassword: "Active@123"
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter your First Name"),
      lastName: Yup.string().required("Please enter your Last Name"),
      userEmail: Yup.string().email("Invalid email address").required("Please Enter Your Email"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter Password")
        .matches(passwordRegExp, "Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case."),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password & Confirm Password must match")
        .required("Please enter Confirm Password")
        .matches(passwordRegExp, "Confirm Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case.")
    }),
    onSubmit: values => {
      if(values.userEmail === 'demo@active.com' && values.password === 'Active@123'){
        const payload = {
          "username": values.userEmail,
          "password": values.password,
          "grant_type": "password"
        }
        dispatch(
          loginUser(
            { data: { ...payload } },
            response => {
              localStorage.setItem("token_type", response.token_type)
              localStorage.setItem("access_token", response.access_token)
              localStorage.setItem("refresh_token", response.refresh_token)
              localStorage.setItem("expires_in", response.expires_in)
              setDisableButton(false)
              dispatch(
                getUserProfile(
                  responseData => {
                    localStorage.setItem("sp",true)
                    if (responseData.data.ita) {
                      localStorage.setItem("ita",responseData.data.ita)
                      localStorage.setItem("tpd", responseData.data.tpd)
                    }
                    if (responseData.data.duser === true) {
                      localStorage.setItem("dpd", responseData.data.dpd)
                      localStorage.setItem("remainingdays",responseData.data.duser)
                      localStorage.setItem("duser", responseData.data.duser)
                    }
                    localStorage.setItem("pagepermitted",JSON.stringify(responseData.data.pagePerm))
                    localStorage.setItem("isSubscriptionActive",responseData.data.subscriptionActive)
                    localStorage.setItem("marketplaceId",responseData.data.marketplaces[0]?.marketplaceId || [])
                    localStorage.setItem("clientId", responseData.data.clientId)
                    localStorage.setItem("name", responseData.data.fullName)
                    localStorage.setItem("email", responseData.data.email)
                    localStorage.setItem("profileImageUrl",responseData.data.profileImageUrl)
                    localStorage.setItem("role", responseData.data.role)
                    localStorage.setItem("duser", responseData.data.duser)
                    if (responseData.data.role !== "SUPERUSER" && responseData.data.role !== "SUPERADMIN") {
                      localStorage.setItem("authSuccessDate",responseData.data.marketplaces[0].authSuccessDate)
                    }
                    if (responseData.data.duser === false && !responseData.data.subscriptionActive && responseData.data.role === "ADMIN") {
                      addToast(
                        "Company does not have active Subscription to use the application. Please subscribe to use the application.",
                        {
                          appearance: "error",
                          autoDismiss: false,
                        }
                      )
                      history.push("/billing")
                    } else if (!responseData.data.subscriptionActive && responseData.data.role === "USER") {
                      addToast(
                        "Company does not have active Subscription to use the application. Please ask the Admin to subscribe.",
                        {
                          appearance: "error",
                          autoDismiss: false,
                        }
                      )
                    } else if (localStorage.getItem("marketplaceId")) {
                      history.push("/restock-sheet")
                    } else if (responseData.data.role === SUPERADMIN) {
                      history.push("/dashboard")
                    } else if (responseData.data.role === SUPERUSER) {
                      history.push("/restock-sheet")
                    } else {
                      localStorage.getItem("marketplaceId") === undefined ||
                        (localStorage.getItem("marketplaceId") === null &&
                          addToast("Something went wrong! Please try again", {
                            appearance: "error",
                            autoDismiss: true,
                          }))
                    }
                  },
                  errorData => {
                    addToast(errorData.response.data.error.displayMessage, {
                      appearance: "error",
                      autoDismiss: true,
                    })
                  }
                )
              )
            },
  
            error => {
              setDisableButton(false)
              addToast(error.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
      }else{
        setDisableButton(true)
        setDemoButtonText('CREATING DEMO ACCOUNT...');
        dispatch(
          registerDemoUser(
            { data: { ...values } },
            response => {
              setDisableButton(true)
              setDemoButtonText('POPULATING DEMO DATA FOR YOUR ACCOUNT...');
              //localStorage.setItem("clientId", response.data.clientId)
              //localStorage.setItem("marketplaceId",response.data.clientMarketPlaceId)
              addToast('Demo Account created. We are populating the demo data for your account.', {
                appearance: "success",
                autoDismiss: true,
              })
              setTimeout(function () {
                dispatch(
                  loginUser(
                    { data: { 'username': values.userEmail, 'password': values.password, 'grant_type': 'password' } },
                    response => {
                      localStorage.setItem("token_type", response.token_type)
                      localStorage.setItem("access_token", response.access_token)
                      localStorage.setItem("refresh_token", response.refresh_token)
                      localStorage.setItem("expires_in", response.expires_in)
                      setDisableButton(false)
                      dispatch(
                        getUserProfile(
                          responseData => {
                            localStorage.setItem("sp", true)
                            if (responseData.data.ita) {
                              localStorage.setItem("ita", responseData.data.ita)
                              localStorage.setItem("tpd", responseData.data.tpd)
                            }
                            if (responseData.data.duser === true) {
                              localStorage.setItem("dpd", responseData.data.dpd)
                              localStorage.setItem("remainingdays", responseData.data.duser)
                              localStorage.setItem("duser", responseData.data.duser)
                            }
                            localStorage.setItem("pagepermitted", JSON.stringify(responseData.data.pagePerm))
                            localStorage.setItem("isSubscriptionActive", responseData.data.subscriptionActive)
                            localStorage.setItem("marketplaceId", responseData.data.marketplaces[0]?.marketplaceId || [])
                            localStorage.setItem("clientId", responseData.data.clientId)
                            localStorage.setItem("name", responseData.data.fullName)
                            localStorage.setItem("email", responseData.data.email)
                            localStorage.setItem("profileImageUrl", responseData.data.profileImageUrl)
                            localStorage.setItem("role", responseData.data.role)
                            localStorage.setItem("duser", responseData.data.duser)
                            localStorage.setItem("authSuccessDate", responseData.data.marketplaces[0].authSuccessDate)

                            history.push({
                              pathname: "/restock-sheet"
                            })
                          },
                          errorData => {
                            addToast(errorData.response.data.error.displayMessage, {
                              appearance: "error",
                              autoDismiss: true,
                            })
                          }
                        )
                      )
                    },

                    error => {
                      setDisableButton(false)
                      addToast(error.response.data.error.displayMessage, {
                        appearance: "error",
                        autoDismiss: true,
                      })
                    }
                  )
                )
              }, 5000);
            },
            error => {
              setDisableButton(false)
              addToast(error.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
      }
    },
  })

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Active Inventory</title>
      </MetaTags>
      <div className="ai-grid-container">
        <div className="ai-grid-item w-100">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "25px 20px 25px 20px",
            }}
          >
            <img src={logo} alt="" style={{ width: "220px" }} />
          </div>
          <div className="text-center mb-3">
            <p className=" mb-3"
              style={{
                fontFamily: "novecento-sans",
                fontWeight: "bold",
                fontSize: "80px",
                textTransform: "uppercase",
              }}
            >
              <span style={{ color: "#FFFFFF", fontSize: "80px" }} className="ai-head-text">{"SOFTWARE "}</span>
              <span style={{ color: "#FFEE00", fontSize: "80px" }} className="ai-head-text">{"DEMO"}</span>
            </p>
            <h3 style={{ fontFamily: "nunito-sans", color: "#FFFFFF" }}>
              <i> {"Pre-filled with Sample Data • 5 Day Access Pass"}</i>
            </h3>
          </div>
          <Row className="justify-content-center">
            <Col xs={10} md={8} lg={8} xl={8}>
              <Form autoComplete="off"
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                {user && user ? (
                  <Alert color="success">Register User Successfully</Alert>
                ) : null}

                {registrationError && registrationError ? (
                  <Alert color="danger">{registrationError}</Alert>
                ) : null}

                <Row className="py-2">
                  <Col>
                    <Input
                      name="firstName"
                      autoComplete="off"
                      type="text"
                      placeholder="First Name"
                      className="ai-register-input fw-bold"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                      invalid={
                        validation.touched.firstName &&
                          validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstName &&
                      validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col>
                    <Input
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      className="ai-register-input fw-bold"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        validation.touched.lastName && validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName && validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>
                    <Input
                      id="userEmail"
                      name="userEmail"
                      autoComplete="off"
                      placeholder="Email Address*"
                      className="ai-register-input fw-bold"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.userEmail || ""}
                      invalid={
                        validation.touched.userEmail && validation.errors.userEmail
                          ? true
                          : false
                      }
                    />
                    {validation.touched.userEmail && validation.errors.userEmail ? (
                      <FormFeedback type="invalid">
                        {validation.errors.userEmail}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>
                    <Input
                      name="password"
                      id="password"
                      placeholder="Password"
                      className="ai-register-input fw-bold"
                      type={passwordVisibility ? "text" : "password"}
                      autoComplete="off"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password && validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {/* <button
                    type="button"
                    id="btnToggle"
                    style={{ width: "20px", height: "14px" }}
                    className={
                      passwordVisibility ? "eye-icon open" : "eye-icon"
                    }
                    onClick={() => togglePasswordVisibility()}
                  ></button> */}
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col>
                    <Input
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      className="ai-register-input fw-bold"
                      type={repeatPasswordVisibility ? "text" : "password"}
                      autoComplete="new-password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword || ""}
                      invalid={
                        validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                          ? true
                          : false
                      }
                    />
                    {/* <button
                    type="button"
                    id="btnToggle"
                    style={{ width: "20px", height: "14px" }}
                    className={
                      passwordVisibility ? "eye-icon open" : "eye-icon"
                    }
                    onClick={() => toggleRepeatPasswordVisibility()}
                  ></button> */}
                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirmPassword}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col>
                    <button
                      className="ai-action-btn btn w-100"
                      style={{ font: "normal normal bold 40px novecento-sans  !important", fontSize: "40px !important" }}
                      type="submit"
                      disabled={disableButton}
                    >
                      {disableButton && (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mx-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Loading...</span>
                        </>
                      )}
                      {demoButtonText}
                    </button>
                  </Col>
                </Row>
              </Form>
              <Col className="box arrow-top" style={{ width: '700px', backgroundColor: '#2299AA', textAlign: 'center', letterSpacing: '0.16px', color: '#FFFFFF',
              opacity: 1, borderRadius: '25px', padding: '10px', font: 'normal normal normal 16px/22px nunito-sans'}}>
                <p>Hello Fellow FBA Users! </p>
                <p>You can use the login information above to take a look at the program. Just click on the Give Me Access link.
                  If you would prefer to take a deeper look, you can enter your name, email and password to use it for five days to get a deeper understanding of how the program works.
                </p>

                <p>Either way – take it for a spin! We think you’re really going to like it. </p>

                <p>We want you to know your valuable email details are safe and sound with us, never to be shared or used for any communication without your permission.</p>
              </Col>
            </Col>
          </Row>

        </div>
      </div>

      <footer className="position-fixed w-100" style={{ bottom: "0px" }}>
        <p style={{ textAlign: "center" }} className="text-white">Copyright © {new Date().getFullYear()} Active Inventory</p>
        {/* <p style={{ textAlign: "center" }} className="text-white">Developed by: <a className="text-danger" href="https://tirnav.com" target="_new">Tirnav Solutions</a></p> */}
      </footer>
    </React.Fragment>
  )
}

export default Demo
