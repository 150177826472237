import React from 'react';
import {
  Card,
  CardBody,
} from "reactstrap";
import LineChartComp from './LineChartComp';
import PropTypes from 'prop-types';

const LineChartCard = ({ periodData, title, chartType, xAxis }) => {
  return (
    <>
      <div className="d-sm-flex flex-wrap">
        <h4 className="card-title my-4 text-center w-100 fw-bold fs-3 ai-text-color">{title}</h4>
      </div>
      <LineChartComp periodData={periodData} chartType={chartType} xAxis={xAxis} />
    </>
  );
}

LineChartCard.propTypes = {
  periodData: PropTypes.any,
  title: PropTypes.string,
  chartType: PropTypes.string,
  xAxis: PropTypes.any,
}

export default LineChartCard;