export const API_INVOCATION = "API_INVOCATION"
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE"
export const RESET_SHOW_TOAST_MESSAGE = "RESET_SHOW_TOAST_MESSAGE"
export const START_LOADER = "START_LOADER"
export const STOP_LOADER = "STOP_LOADER"

export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"

export const REFRESH_USER = "REFRESH_USER"
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS"
export const REFRESH_USER_ERROR = "REFRESH_USER_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR"

export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR"

export const REGISTER_DEMO_USER = "REGISTER_DEMO_USER"
export const REGISTER_DEMO_USER_SUCCESS = "REGISTER_DEMO_USER_SUCCESS"
export const REGISTER_DEMO_USER_ERROR = "REGISTER_DEMO_USER_ERROR"

export const GET_COUNTRY = "GET_COUNTRY"
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS"
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR"

export const GET_STATES_FROM_COUNTRY = "GET_STATES_FROM_COUNTRY"
export const GET_STATES_FROM_COUNTRY_SUCCESS = "GET_STATES_FROM_COUNTRY_SUCCESS"
export const GET_STATES_FROM_COUNTRY_ERROR = "GET_STATES_FROM_COUNTRY_ERROR"

export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_ERROR = "GET_STATES_ERROR"

export const CLIENT_CONFIG = "CLIENT_CONFIG"
export const CLIENT_CONFIG_SUCCESS = "CLIENT_CONFIG_SUCCESS"
export const CLIENT_CONFIG_ERROR = "CLIENT_CONFIG_ERROR"

export const RESTOCK_DATA = "RESTOCK_DATA"
export const RESTOCK_DATA_SUCCESS = "RESTOCK_DATA_SUCCESS"
export const RESTOCK_DATA_ERROR = "RESTOCK_DATA_ERROR"

export const RESTOCK_SHEET_DATA = "RESTOCK_SHEET_DATA"
export const RESTOCK_SHEET_DATA_SUCCESS = "RESTOCK_SHEET_DATA_SUCCESS"
export const RESTOCK_SHEET_DATA_ERROR = "RESTOCK_SHEET_DATA_ERROR"

export const SHIPPING_QUEUE_DATA = "SHIPPING_QUEUE_DATA"
export const SHIPPING_QUEUE_DATA_SUCCESS = "SHIPPING_QUEUE_DATA_SUCCESS"
export const SHIPPING_QUEUE_DATA_ERROR = "SHIPPING_QUEUE_DATA_ERROR"

export const BILLING_DATA = "BILLING_DATA"
export const BILLING_DATA_SUCCESS = "BILLING_DATA_SUCCESS"
export const BILLING_DATA_ERROR = "BILLING_DATA_ERROR"

export const DASHBOARD_DATA = "DASHBOARD_DATA"
export const DASHBOARD_DATA_SUCCESS = "DASHBOARD_DATA_SUCCESS"
export const DASHBOARD_DATA_ERROR = "DASHBOARD_DATA_ERROR"

export const DASHBOARD_FULLFILLMENT = "DASHBOARD_FULLFILLMENT"
export const DASHBOARD_FULLFILLMENT_SUCCESS = "DASHBOARD_FULLFILLMENT_SUCCESS"
export const DASHBOARD_FULLFILLMENT_ERROR = "DASHBOARD_FULLFILLMENT_ERROR"

export const DASHBOARD_DATA_INSIGHT = "DASHBOARD_DATA_INSIGHT"
export const DASHBOARD_DATA_INSIGHT_SUCCESS = "DASHBOARD_DATA_INSIGHT_SUCCESS"
export const DASHBOARD_DATA_INSIGHT_ERROR = "DASHBOARD_DATA_INSIGHT_ERROR"

export const SUPER_ADMIN_DASHBOARD_DATA_YOY = "SUPER_ADMIN_DASHBOARD_DATA_YOY"
export const SUPER_ADMIN_DASHBOARD_DATA_YOY_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_DATA_YOY_SUCCESS"
export const SUPER_ADMIN_DASHBOARD_DATA_YOY_ERROR =
  "SUPER_ADMIN_DASHBOARD_DATA_YOY_ERROR"

export const SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES =
  "SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES"
export const SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES_SUCCESS"
export const SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES_ERROR =
  "SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES_ERROR"

export const SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING =
  "SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING"
export const SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING_SUCCESS"
export const SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING_ERROR =
  "SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING_ERROR"

export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT"
export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_SUCCESS"
export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_ERROR =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_ERROR"

export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS"
export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS_SUCCESS"
export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS_ERROR =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS_ERROR"

export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME"
export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME_SUCCESS"
export const SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME_ERROR =
  "SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME_ERROR"

export const SUPER_ADMIN_CREATE_USER = "SUPER_ADMIN_CREATE_USER"
export const SUPER_ADMIN_CREATE_USER_SUCCESS = "SUPER_ADMIN_CREATE_USER_SUCCESS"
export const SUPER_ADMIN_CREATE_USER_ERROR = "SUPER_ADMIN_CREATE_USER_ERROR"

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION"
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS"
export const CREATE_SUBSCRIPTION_ERROR = "CREATE_SUBSCRIPTION_ERROR"

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR"

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS"
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR"

export const REACTIVE_SUBSCRIPTION = "REACTIVE_SUBSCRIPTION"
export const REACTIVE_SUBSCRIPTION_SUCCESS = "REACTIVE_SUBSCRIPTION_SUCCESS"
export const REACTIVE_SUBSCRIPTION_ERROR = "REACTIVE_SUBSCRIPTION_ERROR"

export const ADD_NEW_CARD = "ADD_NEW_CARD"
export const ADD_NEW_CARD_SUCCESS = "ADD_NEW_CARD_SUCCESS"
export const ADD_NEW_CARD_ERROR = "ADD_NEW_CARD_ERROR"

export const SUBSCRIPTION_PLAN_DATA = "SUBSCRIPTION_PLAN_DATA"
export const SUBSCRIPTION_PLAN_DATA_SUCCESS = "SUBSCRIPTION_PLAN_DATA_SUCCESS"
export const SUBSCRIPTION_PLAN_DATA_ERROR = "SUBSCRIPTION_PLAN_DATA_ERROR"

export const GET_STRIPE_PUBLIC_KEY_DATA = "GET_STRIPE_PUBLIC_KEY_DATA"
export const GET_STRIPE_PUBLIC_KEY_DATA_SUCCESS =
  "GET_STRIPE_PUBLIC_KEY_DATA_SUCCESS"
export const GET_STRIPE_PUBLIC_KEY_DATA_ERROR =
  "GET_STRIPE_PUBLIC_KEY_DATA_ERROR"

export const SHIPPING_ITEM_DATA = "SHIPPING_ITEM_DATA"
export const SHIPPING_ITEM_DATA_SUCCESS = "SHIPPING_ITEM_DATA_SUCCESS"
export const SHIPPING_ITEM_DATA_ERROR = "SHIPPING_ITEM_DATA_ERROR"

export const GET_TAG = "GET_TAG"
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS"
export const GET_TAG_ERROR = "GET_TAG_ERROR"

export const GET_TAG_WITHOUT_LOADER = "GET_TAG_WITHOUT_LOADER"
export const GET_TAG_WITHOUT_LOADERG_SUCCESS = "GET_TAGG_WITHOUT_LOADER_SUCCESS"
export const GET_TAGG_WITHOUT_LOADER_ERROR = "GET_TAG_WITHOUT_LOADERG_ERROR"

export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR"

export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS"
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR"

export const ADD_TAG = "ADD_TAG"
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS"
export const ADD_TAG_ERROR = "ADD_TAG_ERROR"

export const PRODUCT_STATUS_CHANGE = "PRODUCT_STATUS_CHANGE"
export const PRODUCT_STATUS_CHANGE_SUCCESS = "PRODUCT_STATUS_CHANGE_SUCCESS"
export const PRODUCT_STATUS_CHANGE_ERROR = "PRODUCT_STATUS_CHANGE_ERROR"

export const PRODUCT_TAG = "PRODUCT_TAG"
export const PRODUCT_TAG_SUCCESS = "PRODUCT_TAG_SUCCESS"
export const PRODUCT_TAG_ERROR = "PRODUCT_TAG_ERROR"

export const UPLOAD_PROFILE_PHOTO = "UPLOAD_PROFILE_PHOTO"

export const UPLOAD_PRODUCT_FILE = "UPLOAD_PRODUCT_FILE"
export const UPLOAD_INVENTORY_LAB_FILE = "UPLOAD_INVENTORY_LAB_FILE"
export const UPLOAD_PRODUCT_FILE_SUCCESS = "UPLOAD_PRODUCT_FILE_SUCCESS"
export const UPLOAD_PRODUCT_FILE_ERROR = "UPLOAD_PRODUCT_FILE_ERROR"

export const DOWNLOAD_PRODUCT_FILE = "DOWNLOAD_PRODUCT_FILE"
export const DOWNLOAD_PRODUCT_FILE_SUCCESS = "DOWNLOAD_PRODUCT_FILE_SUCCESS"
export const DOWNLOAD_PRODUCT_FILE_ERROR = "DOWNLOAD_PRODUCT_FILE_ERROR"

export const FILTER_PRODUCTS_BY_NAME = "FILTER_PRODUCTS_BY_NAME"
export const FILTER_PRODUCTS_BY_NAME_SUCCESS = "FILTER_PRODUCTS_BY_NAME_SUCCESS"
export const FILTER_PRODUCTS_BY_NAME_ERROR = "FILTER_PRODUCTS_BY_NAME_ERROR"

export const FILTER_PRODUCTS_BY_SKU = "FILTER_PRODUCTS_BY_SKU"
export const FILTER_PRODUCTS_BY_SKU_SUCCESS = "FILTER_PRODUCTS_BY_SKU_SUCCESS"
export const FILTER_PRODUCTS_BY_SKU_ERROR = "FILTER_PRODUCTS_BY_SKU_ERROR"

export const FILTER_PRODUCTS_BY_TAG = "FILTER_PRODUCTS_BY_TAG"
export const FILTER_PRODUCTS_BY_TAG_SUCCESS = "FILTER_PRODUCTS_BY_TAG_SUCCESS"
export const FILTER_PRODUCTS_BY_TAG_ERROR = "FILTER_PRODUCTS_BY_TAG_ERROR"

export const SEARCH_PRODUCT_SHEET = "SEARCH_PRODUCT_SHEET"
export const SEARCH_PRODUCT_SHEET_SUCCESS = "SEARCH_PRODUCT_SHEET_SUCCESS"
export const SEARCH_PRODUCT_SHEET_ERROR = "SEARCH_PRODUCT_SHEET_ERROR"

export const PRODUCT_EXPIRY_DATE = "PRODUCT_EXPIRY_DATE"
export const PRODUCT_EXPIRY_DATE_SUCCESS = "PRODUCT_EXPIRY_DATE_SUCCESS"
export const PRODUCT_EXPIRY_DATE_ERROR = "PRODUCT_EXPIRY_DATE_ERROR"

export const PRODUCT_TAX_CODE = "PRODUCT_TAX_CODE"
export const PRODUCT_TAX_CODE_SUCCESS = "PRODUCT_TAX_CODE_SUCCESS"
export const PRODUCT_TAX_CODE_ERROR = "PRODUCT_TAX_CODE_ERROR"

export const PRODUCT_UPDATE = "PRODUCT_UPDATE"

export const AUTO_HIDE_PRODUCT = "AUTO_HIDE_PRODUCT"
export const AUTO_HIDE_PRODUCT_SUCCESS = "AUTO_HIDE_PRODUCT_SUCCESS"
export const AUTO_HIDE_PRODUCT_ERROR = "AUTO_HIDE_PRODUCT_ERROR"

export const OUT_OF_STOCK_DATA = "OUT_OF_STOCK_DATA"
export const OUT_OF_STOCK_DATA_SUCCESS = "OUT_OF_STOCK_DATA_SUCCESS"
export const OUT_OF_STOCK_DATA_ERROR = "RESTOCK_DATA_ERROR"

export const RESTOCK_HISTORY_DATA = "RESTOCK_HISTORY_DATA"
export const RESTOCK_HISTORY_DATA_SUCCESS = "RESTOCK_HISTORY_DATA_SUCCESS"
export const RESTOCK_HISTORY_DATA_ERROR = "RESTOCK_HISTORY_DATA_ERROR"

export const RESTOCK_HISTORY_DATA_IN_PROGRESS = "RESTOCK_HISTORY_DATA"
export const RESTOCK_HISTORY_DATA_IN_PROGRESS_SUCCESS =
  "RESTOCK_HISTORY_DATA_IN_PROGRESS_SUCCESS"
export const RESTOCK_HISTORY_DATA_IN_PROGRESS_ERROR =
  "RESTOCK_HISTORY_DATA_IN_PROGRESS_ERROR"

export const RESTOCK_HISTORY_DATA_UPDATE = "RESTOCK_HISTORY_DATA_UPDATE"
export const RESTOCK_HISTORY_DATA_UPDATE_SUCCESS =
  "RESTOCK_HISTORY_DATA_UPDATE_SUCCESS"
export const RESTOCK_HISTORY_DATA_UPDATE_ERROR = "RESTOCK_HISTORY_DATA_ERROR"

export const CREATE_RESTOCK_SHEET = "CREATE_RESTOCK_SHEET_UPDATE"
export const CREATE_RESTOCK_SHEET_SUCCESS = "CREATE_RESTOCK_SHEET_SUCCESS"
export const CREATE_RESTOCK_SHEET_ERROR = "CREATE_RESTOCK_SHEET_ERROR"

export const PRODUCT_DATA = "PRODUCT_DATA"
export const PRODUCT_DATA_SUCCESS = "PRODUCT_DATA_SUCCESS"
export const PRODUCT_DATA_ERROR = "PRODUCT_DATA_ERROR"

export const ADD_ITEM_TO_RESTOCK_SHEET = "ADD_ITEM_TO_RESTOCK_SHEET"
export const ADD_ITEM_TO_RESTOCK_SHEET_SUCCESS =
  "ADD_ITEM_TO_RESTOCK_SHEET_SUCCESS"
export const ADD_ITEM_TO_RESTOCK_SHEET_ERROR = "ADD_ITEM_TO_RESTOCK_SHEET_ERROR"

export const DELETE_ITEM_FROM_RESTOCK_SHEET = "DELETE_ITEM_FROM_RESTOCK_SHEET"
export const DELETE_ITEM_FROM_RESTOCK_SHEET_SUCCESS =
  "DELETE_ITEM_FROM_RESTOCK_SHEET_SUCCESS"
export const DELETE_ITEM_FROM_RESTOCK_SHEET_ERROR =
  "DELETE_ITEM_FROM_RESTOCK_SHEET_ERROR"

export const GET_RESTOCK_SHEET_ITEM = "GET_RESTOCK_SHEET_ITEM"
export const GET_RESTOCK_SHEET_ITEM_SUCCESS = "GET_RESTOCK_SHEET_ITEM_SUCCESS"
export const GET_RESTOCK_SHEET_ITEM_ERROR = "GET_RESTOCK_SHEET_ITEM_ERROR"

export const PRINT_RESTOCK_SHEET_ITEM = "PRINT_RESTOCK_SHEET_ITEM"
export const PRINT_RESTOCK_SHEET_ITEM_SUCCESS =
  "PRINT_RESTOCK_SHEET_ITEM_SUCCESS"
export const PRINT_RESTOCK_SHEET_ITEM_ERROR = "PRINT_RESTOCK_SHEET_ITEM_ERROR"

export const GET_ONLY_FBM_SKU_ITEM = "GET_ONLY_FBM_SKU_ITEM"
export const GET_ONLY_FBM_SKU_ITEM_SUCCESS = "GET_ONLY_FBM_SKU_ITEM_SUCCESS"
export const GET_ONLY_FBM_SKU_ITEM_ERROR = "GET_ONLY_FBM_SKU_ITEM_ERROR"

export const GET_BUY_BOX_PRICE = "GET_BUY_BOX_PRICE"
export const GET_BUY_BOX_PRICE_SUCCESS = "GET_BUY_BOX_PRICE_SUCCESS"
export const GET_BUY_BOX_PRICE_ERROR = "GET_BUY_BOX_PRICE_ERROR"

export const PRODUCT_WITHOUT_LOADER_DATA = "PRODUCT_WITHOUT_LOADER_DATA"
export const PRODUCT_WITHOUT_LOADER_DATA_SUCCESS =
  "PRODUCT_WITHOUT_LOADER_DATA_SUCCESS"
export const PRODUCT_WITHOUT_LOADER_DATA_ERROR =
  "PRODUCT_WITHOUT_LOADER_DATA_ERROR"

export const DONE_RESTOCK_SHEET = "DONE_RESTOCK_SHEET"
export const DONE_RESTOCK_SHEET_SUCCESS = "DONE_RESTOCK_SHEET_SUCCESS"
export const DONE_RESTOCK_SHEET_ERROR = "DONE_RESTOCK_SHEET_ERROR"

export const UPDATE_RESTOCK_SHEET = "UPDATE_RESTOCK_SHEET"
export const UPDATE_RESTOCK_SHEET_SUCCESS = "UPDATE_RESTOCK_SHEET_SUCCESS"
export const UPDATE_RESTOCK_SHEET_ERROR = "UPDATE_RESTOCK_SHEET_ERROR"

export const DELETE_RESTOCK_SHEET = "DELETE_RESTOCK_SHEET"
export const DELETE_RESTOCK_SHEET_SUCCESS = "DELETE_RESTOCK_SHEET_SUCCESS"
export const DELETE_RESTOCK_SHEET_ERROR = "DELETE_RESTOCK_SHEET_ERROR"

export const USER_PROFILE = "USER_PROFILE"
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS"
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR"

export const USER_DATA = "USER_DATA"
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS"
export const USER_DATA_ERROR = "USER_DATA_ERROR"

export const USERS_LIST = "USERS_LIST"
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS"
export const USERS_LIST_ERROR = "USERS_LIST_ERROR"

export const SUPER_USERS_LIST = "SUPER_USERS_LIST"
export const SUPER_USERS_LIST_SUCCESS = "SUPER_USERS_LIST_SUCCESS"
export const SUPER_USERS_LIST_ERROR = "SUPER_USERS_LIST_ERROR"

export const USERS_LIST_WITHOUTLOADER = "USERS_LIST_WITHOUTLOADER"
export const USERS_LIST_WITHOUTLOADER_SUCCESS =
  "USERS_LIST_WITHOUTLOADER_SUCCESS"
export const USERS_LIST_WITHOUTLOADER_ERROR = "USERS_LIST_WITHOUTLOADER_ERROR"

export const USERS_CREATE = "USERS_CREATE"
export const USERS_CREATE_SUCCESS = "USERS_CREATE_SUCCESS"
export const USERS_CREATE_ERROR = "USERS_CREATE_ERROR"

export const GET_USER_BY_ID = "GET_USERS_BY_ID"
export const GET_USER_BY_ID_SUCCESS = "GET_USERS_BY_ID_SUCCESS"
export const GET_USER_BY_ID_ERROR = "GET_USERS_BY_ID_ERROR"

export const GET_SUPER_USER_BY_ID = "GET_SUPER_USERS_BY_ID"
export const GET_SUPER_USER_BY_ID_SUCCESS = "GET_SUPER_USERS_BY_ID_SUCCESS"
export const GET_SUPER_USER_BY_ID_ERROR = "GET_SUPER_USERS_BY_ID_ERROR"

export const USERS_EDIT = "USERS_EDIT"
export const USERS_EDIT_SUCCESS = "USERS_EDIT_SUCCESS"
export const USERS_EDIT_ERROR = "USERS_EDIT_ERROR"

export const USERS_DELETE = "USERS_DELETE"
export const USERS_DELETE_SUCCESS = "USERS_DELETE_SUCCESS"
export const USERS_DELETE_ERROR = "USERS_DELETE_ERROR"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD"

export const SET_CATEGORY_BASED_PRODUCT = "SET_CATEGORY_BASED_PRODUCT"
export const SET_CATEGORY_BASED_PRODUCT_SUCCESS =
  "SET_CATEGORY_BASED_PRODUCT_SUCCESS"
export const SET_CATEGORY_BASED_PRODUCT_ERROR =
  "SET_CATEGORY_BASED_PRODUCT_ERROR"

export const GET_SEARCH_PRODUCT_LIST = "GET_SEARCH_PRODUCT_LIST"
export const GET_SEARCH_PRODUCT_LIST_SUCCESS = "GET_SEARCH_PRODUCT_LIST_SUCCESS"
export const GET_SEARCH_PRODUCT_LIST_ERROR = "GET_SEARCH_PRODUCT_LIST_ERROR"
export const RESET_SEARCH_PRODUCT_LIST = "RESET_SEARCH_PRODUCT_LIST"

export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT"
export const SET_CURRENT_PRODUCT_SUCCESS = "SET_CURRENT_PRODUCT_SUCCESS"
export const SET_CURRENT_PRODUCT_ERROR = "SET_CURRENT_PRODUCT_ERROR"

export const SET_CATEGORY = "SET_CATEGORY"
export const SET_CATEGORY_SUCCESS = "SET_CATEGORY_SUCCESS"
export const SET_CATEGORY_ERROR = "SET_CATEGORY_ERROR"

export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST"
export const SET_CATEGORY_LIST_SUCCESS = "SET_CATEGORY_LIST_SUCCESS"
export const SET_CATEGORY_LIST_ERROR = "SET_CATEGORY_LIST_ERROR"

export const ADD_FAVORITE_PRODUCT = "ADD_FAVORITE_PRODUCT"
export const ADD_FAVORITE_PRODUCT_SUCCESS = "ADD_FAVORITE_PRODUCT_SUCCESS"
export const ADD_FAVORITE_PRODUCT_ERROR = "ADD_FAVORITE_PRODUCT_ERROR"

export const GET_FAVORITE_PRODUCT = "GET_FAVORITE_PRODUCT"
export const GET_FAVORITE_PRODUCT_SUCCESS = "GET_FAVORITE_PRODUCT_SUCCESS"
export const GET_FAVORITE_PRODUCT_ERROR = "GET_FAVORITE_PRODUCT_ERROR"

export const ADD_ADDRESS = "ADD_ADDRESS"
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS"
export const ADD_ADDRESS_ERROR = "ADD_ADDRESS_ERROR"

export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS"
export const DELETE_ADDRESS_ERROR = "DELETE_ADDRESS_ERROR"

export const GET_ALL_ADDRESS = "GET_ALL_ADDRESS"
export const GET_ALL_ADDRESS_SUCCESS = "GET_ALL_ADDRESS_SUCCESS"
export const GET_ALL_ADDRESS_ERROR = "GET_ALL_ADDRESS_ERROR"

export const EDIT_ADDRESS = "EDIT_ADDRESS"
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS"
export const EDIT_ADDRESS_ERROR = "EDIT_ADDRESS_ERROR"

export const EDIT_ADDRESS_DATA = "EDIT_ADDRESS_DATA"

export const SET_BANNER = "SET_BANNER"
export const SET_BANNER_SUCCESS = "SET_BANNER_SUCCESS"
export const SET_BANNER_ERROR = "SET_BANNER_ERROR"

export const ADD_TO_CART = "ADD_TO_CART"
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR"

export const ADD_TO_CART_ITEM_INC = "ADD_TO_CART_ITEM_INC"
export const ADD_TO_CART_ITEM_INC_SUCCESS = "ADD_TO_CART_ITEM_INC_SUCCESS"
export const ADD_TO_CART_ITEM_INC_ERROR = "ADD_TO_CART_ITEM_INC_ERROR"

export const ADD_TO_CART_ITEM_DEC = "ADD_TO_CART_ITEM_DEC"
export const ADD_TO_CART_ITEM_DEC_SUCCESS = "ADD_TO_CART_ITEM_DEC_SUCCESS"
export const ADD_TO_CART_ITEM_DEC_ERROR = "ADD_TO_CART_ITEM_DEC_ERROR"

export const ADD_TO_CART_SAVE_ON_CHECKOUT = "ADD_TO_CART_SAVE_ON_CHECKOUT"
export const ADD_TO_CART_SAVE_ON_CHECKOUT_SUCCESS =
  "ADD_TO_CART_SAVE_ON_CHECKOUT_SUCCESS"
export const ADD_TO_CART_SAVE_ON_CHECKOUT_ERROR =
  "ADD_TO_CART_SAVE_ON_CHECKOUT_ERROR"

export const DELETE_CART = "DELETE_CART"
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS"
export const DELETE_CART_ERROR = "DELETE_CART_ERROR"

export const GET_USER_CART_DETAILS = "GET_USER_CART_DETAILS"
export const GET_USER_CART_DETAILS_SUCCESS = "GET_USER_CART_DETAILS_SUCCESS"
export const GET_USER_CART_DETAILS_ERROR = "GET_USER_CART_DETAILS_ERROR"

export const ADD_PRODUCT_REVIEW = "ADD_PRODUCT_REVIEW"
export const ADD_PRODUCT_REVIEW_SUCCESS = "ADD_PRODUCT_REVIEW_SUCCESS"
export const ADD_PRODUCT_REVIEW_ERROR = "ADD_PRODUCT_REVIEW_ERROR"

export const GET_PRODUCT_REVIEW = "GET_PRODUCT_REVIEW"
export const GET_PRODUCT_REVIEW_SUCCESS = "GET_PRODUCT_REVIEW_SUCCESS"
export const GET_PRODUCT_REVIEW_ERROR = "GET_PRODUCT_REVIEW_ERROR"

export const SET_MODAL = "SET_MODAL"
export const SET_MODAL_ERROR = "SET_MODAL_ERROR"

export const VALIDATE_COUPON_CODE = "VALIDATE_COUPON_CODE"
export const VALIDATE_COUPON_CODE_SUCCESS = "VALIDATE_COUPON_CODE_SUCCESS"
export const VALIDATE_COUPON_CODE_ERROR = "VALIDATE_COUPON_CODE_ERROR"

export const CONTACT_US = "CONTACT_US"
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS"
export const CONTACT_US_ERROR = "CONTACT_US_ERROR"

export const OPEN_LOGIN = "OPEN_LOGIN"
export const CLOSE_LOGIN = "CLOSE_LOGIN"

export const CLEAR_CART_DATA = "CLEAR_CART_DATA"

export const OPEN_SIGNUP = "OPEN_SIGNUP"
export const CLOSE_SIGNUP = "CLOSE_SIGNUP"

export const OPEN_LOCATION = "OPEN_LOCATION"
export const CLOSE_LOCATION = "CLOSE_LOCATION"

export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE"
export const EDIT_USER_PROFILE_SUCCESS = "EDIT_USER_PROFILE_SUCCESS"
export const EDIT_USER_PROFILE_ERROR = "EDIT_USER_PROFILE_ERROR"

export const OPEN_FINETUNE_LOCATION = "OPEN_FINETUNE_LOCATION"
export const CLOSE_FINETUNE_LOCATION = "CLOSE_FINETUNE_LOCATION"

export const RESET_USER_PROFILE = "RESET_USER_PROFILE"

export const GET_RANGE = "GET_RANGE"
export const GET_RANGE_SUCCESS = "GET_RANGE_SUCCESS"
export const GET_RANGE_ERROR = "GET_RANGE_ERROR"

export const SET_USER_LOCATION = "SET_USER_LOCATION"

export const GET_FAQ = "GET_FAQ"
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS"
export const GET_FAQ_ERROR = "GET_FAQ_ERROR"

export const GET_ORDER_LIST = "GET_ORDER_LIST"
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS"
export const GET_ORDER_LIST_ERROR = "GET_ORDER_LIST_ERROR"

export const GET_APP_LINK = "GET_APP_LINK"
export const GET_APP_LINK_ERROR = "GET_APP_LINK_ERROR"
export const GET_APP_LINK_SUCCESS = "GET_APP_LINK_SUCCESS"

export const CHECK_PAYMENT_STATUS = "CHECK_PAYMENT_STATUS"
export const CHECK_PAYMENT_STATUS_SUCCESS = "CHECK_PAYMENT_STATUS_SUCCESS"
export const CHECK_PAYMENT_STATUS_ERROR = "CHECK_PAYMENT_STATUS_ERROR"

export const CART_CHECKOUT_SAVE = "CART_CHECKOUT_SAVE"
export const CART_CHECKOUT_SAVE_SUCCESS = "CART_CHECKOUT_SAVE_SUCCESS"
export const CART_CHECKOUT_SAVE_ERROR = "CART_CHECKOUT_SAVE_ERROR"

export const CHECK_DELIVERY = "CHECK_DELIVERY"
export const CHECK_DELIVERY_SUCCESS = "CHECK_DELIVERY_SUCCESS"
export const CHECK_DELIVERY_ERROR = "CHECK_DELIVERY_ERROR"

export const DELIVERY_ADDRESS_SELECT = "DELIVERY_ADDRESS_SELECT"
export const BILLING_ADDRESS_SELECT = "BILLING_ADDRESS_SELECT"

export const GUEST_CART_SYNC_START = "GUEST_CART_SYNC_START"
export const GUEST_CART_SYNC_FINISH = "GUEST_CART_SYNC_FINISH"
export const GUEST_CART_SYNC_RESET = "GUEST_CART_SYNC_RESET"

export const OPEN_ADDRESS_MODAL = "OPEN_ADDRESS_MODAL"
export const CLOSE_ADDRESS_MODAL = "CLOSE_ADDRESS_MODAL"

export const REPAY_ORDER = "REPAY_ORDER"
export const REPAY_ORDER_SUCCESS = "REPAY_ORDER_SUCCESS"
export const REPAY_ORDER_ERROR = "REPAY_ORDER_ERROR"

export const USER_SUGGESTION = "USER_SUGGESTION"

export const SEND_NOTIFICATION = "SEND_NOTIFICATION"
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS"
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR"

export const NOTIFICATION_CREATE = "NOTIFICATION_CREATE"
export const NOTIFICATIONS_CREATE_SUCCESS = "NOTIFICATIONS_CREATE_SUCCESS"
export const NOTIFICATION_CREATE_ERROR = "NOTIFICATION_CREATE_ERROR"

export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE"
export const NOTIFICATIONS_DELETE_SUCCESS = "NOTIFICATIONS_DELETE_SUCCESS"
export const NOTIFICATION_DELETE_ERROR = "NOTIFICATION_DELETE_ERROR"

export const NOTIFICATION_BY_ID = "NOTIFICATION_BY_ID"
export const NOTIFICATIONS_BY_ID_SUCCESS = "NOTIFICATIONS_BY_ID_SUCCESS"
export const NOTIFICATION_BY_ID_ERROR = "NOTIFICATION_BY_ID_ERROR"

export const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE"
export const NOTIFICATIONS_UPDATE_SUCCESS = "NOTIFICATIONS_UPDATE_SUCCESS"
export const NOTIFICATION_UPDATE_ERROR = "NOTIFICATION_UPDATE_ERROR"

export const GET_ALL_NOTIFICATION_FOR_USER_BY_ID ="GET_ALL_NOTIFICATION_FOR_CLIENT"
export const GET_ALL_NOTIFICATION_FOR_USER_SUCCESS_BY_ID ="GET_ALL_NOTIFICATION_FOR_CLIENT_SUCCESS"
export const GET_ALL_NOTIFICATION_FOR_USER_ERROR_BY_ID = "GET_ALL_NOTIFICATION_FOR_CLIENT_ERROR"

export const NOTIFICATION_UPDATE_FOR_USER = "NOTIFICATION_UPDATE"
export const NOTIFICATIONS_UPDATE_FOR_USER_SUCCESS = "NOTIFICATIONS_UPDATE_SUCCESS"
export const NOTIFICATION_UPDATE_FOR_USER_ERROR = "NOTIFICATION_UPDATE_ERROR"

export const CLIENT_INFO="CLIENT_INFO"