import React, { useState } from "react"
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import "../../assets/scss/custom/rdt.scss"
import "./dashboard.scss"
import Pagination from "../../components/Common/Pagination"
import { Row, Col } from "reactstrap"

const TopSellingProducts = () => {

    const topSellingProducts = useSelector((state) => state?.Dashboard?.data?.topSelling)
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);

    const columns = [
        {
            name: "Image",
            selector: row => row.itemImage,
            cell: row => format_Image(row.itemImage),
            grow: 0
        },
        {
            name: 'Asin',
            selector: row => row.asin,
            cell: row => format_Asin(row.asin),
        },
        {
            name: "Name",
            selector: row => row.itemName,
            cell: row => format_Name(row.itemName),
            grow: 5
        },
        {
            name: "Quantity",
            selector: row => row.qty,
            cell: row => format_Qty(row.qty)
        }
    ]

    const format_Asin = (asin) => {
        return (
            <div>{asin}</div>
        )
    }

    const format_Image = (image) => {
        return (
            <img src={image} className="product-image" alt="product image" />
        )
    }

    const format_Name = (name) => {
        return (
            <div>{name}</div>
        )
    }

    const format_Qty = (qty) => {
        return (
            <div>{qty}</div>
        )
    }

    const handlePageChange = (e) => {
        setCurrentPage(e)
    };

    const handlePerRowsChange = (e) => {
        setPerPage(e)
    }

    return (
        <div className='d-flex flex-column mt-5 mb-15'>
            <Row>
                <Col xs="12">
                    <div className="d-sm-flex align-items-center justify-content-start">
                        <h4
                            style={{
                                font: "normal normal bold 20px/20px novecento-sans",
                                letterSpacing: "0.32px",
                                color: "#082C4E",
                            }}
                        >
                            TOP SELLING PRODUCTS <span
                                style={{
                                    font: "normal normal  20px/20px novecento-sans",
                                    letterSpacing: "0.32px",
                                    color: "#082C4E",
                                }}>(30 Days)</span>
                        </h4>
                    </div>
                </Col>
            </Row>
            <div className="ai-Table">
                <DataTable
                    columns={columns}
                    data={topSellingProducts}
                    pagination
                    paginationComponent={() => (
                        <Pagination
                            data={topSellingProducts}
                            rowsPerPage={perPage}
                            currentPage={currentPage}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                            paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                            rowCount={topSellingProducts.length} />
                    )}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    persistTableHead
                    fixedHeader
                    dense
                    responsive
                />
            </div>
        </div>
    )
}

export default TopSellingProducts