import React, { useState } from "react"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/bootstrap/css/bootstrap-grid.min.css"
import "../assets/bootstrap/css/bootstrap-reboot.min.css"
import "../assets/theme/css/style.css"
import Header from "static-site/common/Header"
import Footer from "static-site/common/Footer"
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap"
import { faqData } from "constants/faq"
import { MetaTags } from "react-meta-tags"
import { useEffect } from "react"

const FAQPage = () => {

  const [toggle, setToggle] = useState("")
  const handleToggle = (value) => {
    setToggle(value)
  }
  return (
    <React.Fragment>
      <div className="static-site">
        <MetaTags>
          <title>FAQ | Active Inventory</title>
        </MetaTags>
        <Header />
        <section
          data-bs-version="5.1"
          className="header8 cid-t2fENLbOks"
          id="header8-2q"
        >
          <div className="container" style={{ marginTop: "120px" }}>
            <div className="row align-items-center">
              <div className="col-12 col-md-4">
                <div className="image-wrapper">
                  <img src={MBR696} alt="" />
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="text-wrapper">
                  <p className="desc1 mbr-section-title mbr-fonts-style display-2">
                    FAQ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="image1 cid-t2glpNK0H4" id="image01-41">
          <div className="container">
            <UncontrolledAccordion>
              {
                faqData.map((faq) => {
                  return <AccordionItem key={faq.id}>
                    <AccordionHeader targetId={faq.id}>
                      <strong>{faq.title}</strong>
                    </AccordionHeader>
                    <AccordionBody accordionId={faq.id}>
                      <span dangerouslySetInnerHTML={{ __html: faq.description }}></span>
                    </AccordionBody>
                  </AccordionItem>
                })
              }
            </UncontrolledAccordion>
          </div>
        </section>


        <Footer />
      </div>
    </React.Fragment>
  )
}

export default FAQPage
