import React from "react"
import MetaTags from "react-meta-tags"
import { Row, Col } from "reactstrap"
import Restock from "./Restock"

const FBARestockSheet = () => {
  return (
    <React.Fragment>
      <div className="page-content" style={{paddingTop:'1rem'}}>
        <MetaTags>
          <title>FBA Restock Sheet | Active Inventory</title>
        </MetaTags>
        <div>
          <Row xs={12}>
            <Col lg="12">
              <Restock />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FBARestockSheet
