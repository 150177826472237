import React from "react"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/bootstrap/css/bootstrap-grid.min.css"
import "../assets/bootstrap/css/bootstrap-reboot.min.css"
// import "../assets/mobirise/css/mbr-additional.scss"
import "../assets/theme/css/style.css"
import Header from "static-site/common/Header"
import Footer from "static-site/common/Footer"
import TermsAndCondition from "components/Common/TermsAndCondition"
import { MetaTags } from "react-meta-tags"

const TermService = () => {
  return (
    <React.Fragment>
      <div className="static-site">
        <MetaTags>
          <title>Terms And Service | Active Inventory</title>
        </MetaTags>
        <Header />
        <section data-bs-version="5.1" className="cid-t2fELf8oGt" id="header8-2h">
          <div className="container" style={{ marginTop: "20px" }}>
            <div className="row align-items-center">
              <div className="col-12 col-md">
                <div className="text-wrapper">
                  <p className="desc1 mbr-section-title mbr-fonts-style display-2">
                  Terms of Service
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TermsAndCondition />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default TermService
