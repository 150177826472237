import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Alert, Input, Label, Form, FormFeedback } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  getCountry,
  getStatesFromCountry,
  registerUser,
  getClientInfo
} from "store/action/user"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import TermsAndCondition from "components/Common/TermsAndCondition"
import "./authentication.scss"
import { useLocation } from 'react-router-dom';

const Register = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [disableButton, setDisableButton] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("US")
  const { addToast } = useToasts()
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const alphaRegExp = /^[a-zA-Z]+$/
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false)
  const [client, setClient] = useState({});
  const [validClientId, setValidClientId] = useState(false);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get('clientId');

  /*
  const clientId = localStorage.getItem('clientId');
  const clientMarketplaceId = localStorage.getItem('marketplaceId');
  const email = localStorage.getItem('email');
  const firstName = localStorage.getItem('name').split(' ')[0];
  const lastName = localStorage.getItem('name').split(' ')[1]; */
  
  useEffect(() => {
    dispatch(
      getStatesFromCountry(
        { data: selectedCountry },
        responseData => {
          let tempObj = []
          for (let i in responseData.data) {
            tempObj.push({
              stateCode: i,
              stateName: responseData.data[i],
            })
          }
          setStates(tempObj)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }, [])

  useEffect(() => {
    dispatch(
      getCountry(
        responseData => {
          let tempObj = []
          for (let i in responseData.data) {
            tempObj.push({
              countryCode: i,
              countryName: responseData.data[i]
            }
            )
          }
          setCountries(tempObj);
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )

    if(clientId !=='de5be7fd-bb82-4fee-95cf-4eeead74a60a'){
      dispatch(
        getClientInfo(
          { clientId: clientId },
          responseData => {
            setClient(responseData.data)
            setValidClientId(true)
            localStorage.clear();
          },
          errorData => {
            /* addToast(errorData.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            }) */
          }
        )
      )
    }
    
  }, [])

  useEffect(() => {
    dispatch(
      getStatesFromCountry(
        { data: selectedCountry },
        responseData => {
          let tempObj = []
          for (let i in responseData.data) {
            tempObj.push({
              stateCode: i,
              stateName: responseData.data[i],
            })
          }
          setStates(tempObj)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }, [selectedCountry])

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisibility(!repeatPasswordVisibility)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: client.email || "",
      firstname: client.firstName || "",
      lastname: client.lastName || "",
      companyName: "",
      password: client.email || "",
      confirm_password: client.email || "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      termsofservice: false,
      useragreement: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().test('skip', null, function(value) {
        if (clientId) {
          return true; // Skip validation
        }
        return Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Password must be of minimum 8 characters long")
        .matches(
          passwordRegExp,
          "Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case."
        );
      }),
      //confirm_password: Yup.string().required("Please Enter Confirm Password"),
      firstname: Yup.string().required("Please Enter Firstname"),
      lastname: Yup.string().required("Please Enter Lastname"),
      companyName: Yup.string().required("Please Enter Company Name"),
      confirm_password: Yup.string().test('skip', null, function(value) {
        if (clientId) {
          return true; // Skip validation
        }
        return Yup.string()
        .required("Please Enter Confirm Password")
        .min(6, "Password should be 6 characters long.")
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        });
      }),
      address: Yup.string().required("Please Enter Address"),
      city: Yup.string()
        .required("Please Enter City")
        .matches(alphaRegExp, "Only alphabet allowed"),
      country: Yup.string()
        .required("Please Enter Country")
        .matches(alphaRegExp, "Only alphabet allowed"),
      state: Yup.string()
        .required("Please Enter State")
        .matches(alphaRegExp, "Only alphabet allowed"),
      zip: Yup.string().required("Please Enter Zip"),
      phone: Yup.string()
        .max(10, "Maximum 10 digits allowed")
        .matches(phoneRegExp, "Phone number is not valid"),
      termsofservice: Yup.bool().oneOf(
        [true],
        "Accept Terms Of Service is required"
      ),
    }),
    onSubmit: values => {
      setDisableButton(true)
      const signUpObj = {
        clientName: values.companyName,
        userEmail: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        password: values.password,
        address1: values.address,
        region: "US",
        marketplaceName: values.companyName + " Amazon",
        city: values.city,
        state: values.state,
        country: values.country,
        phoneNumber: values.phone,
        zipcode: values.zip,
        marketPlaceType: "Amazon",
        clientId: client.clientId,
        clientMarketplaceId: client.clientMarketplaceId,
      }
      dispatch(
        registerUser(
          { data: { ...signUpObj } },
          response => {
            setDisableButton(false)
            localStorage.setItem("clientId", response.data.clientId)
            localStorage.setItem(
              "marketplaceId",
              response.data.clientMarketPlaceId
            )
            history.push({
              pathname: "/seller",
              state: {
                email: values.email,
                clientMarketPlaceId: response.data.clientMarketPlaceId,
              },
            })
          },
          error => {
            setDisableButton(false)
            addToast(error.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    },
  })

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Active Inventory</title>
      </MetaTags>
      <div className="py-5" style={{ backgroundColor: "#082C4E" }}>
        <div className="text-center">
          <p
            style={{
              fontFamily: "novecento-sans",
              fontWeight: "bold",
              fontSize: "50px",
              textTransform: "uppercase",
            }}
          >
            <span style={{ color: "#FFFFFF" }}>{"Start your "}</span>
            <span style={{ color: "#FFEE00" }}>{"14-day free trial"}</span>
          </p>
          <h3 style={{ fontFamily: "nunito-sans", color: "#FFFFFF" }}>
            <i> {"100% Risk Free • No Card Required"}</i>
          </h3>
        </div>
        <Row className="justify-content-center mt-2">
          <Col xs={6}>
            <Form autoComplete="off"
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              {user && user ? (
                <Alert color="success">Register User Successfully</Alert>
              ) : null}

              {registrationError && registrationError ? (
                <Alert color="danger">{registrationError}</Alert>
              ) : null}

              <Row className="py-2">
                <Col>
                  <Input
                    name="firstname"
                    type="text"
                    autoComplete="off"
                    placeholder="First Name"
                    className="ai-register-input fw-bold"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstname || client.firstName || ""}
                    disabled= {client.firstName?true:false}
                    invalid={
                      validation.touched.firstname &&
                      validation.errors.firstname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstname &&
                  validation.errors.firstname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstname}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <Input
                    name="lastname"
                    type="text"
                    autoComplete="off"
                    placeholder="Last Name"
                    className="ai-register-input fw-bold"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastname || client.lastName || ""}
                    disabled= {client.lastName?true:false}
                    invalid={
                      validation.touched.lastname && validation.errors.lastname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastname && validation.errors.lastname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastname}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="py-2">
                <Col>
                  <Input
                    name="companyName"
                    type="text"
                    autoComplete="off"
                    placeholder="Company Name"
                    className="ai-register-input fw-bold"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.companyName || ""}
                    invalid={
                      validation.touched.companyName &&
                      validation.errors.companyName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.companyName &&
                  validation.errors.companyName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.companyName}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="py-2">
                <Col>
                  <Input
                    id="email"
                    name="email"
                    autoComplete="username"
                    placeholder="Email@url.com"
                    className="ai-register-input fw-bold"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || client.email || ""}
                    disabled= {client.email?true:false}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              {!validClientId && (
                <>
                <Row className="py-2">
                <Col>
                  <Input
                    name="password"
                    placeholder="Password"
                    className="ai-register-input fw-bold"
                    type={passwordVisibility ? "text" : "password"}
                    autoComplete="new-password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  <button
                    type="button"
                    id="btnToggle"
                    style={{ width: "20px", height: "14px" }}
                    className={
                      passwordVisibility ? "eye-icon open" : "eye-icon"
                    }
                    onClick={() => togglePasswordVisibility()}
                  ></button>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <Input
                    name="confirm_password"
                    placeholder="Password"
                    className="ai-register-input fw-bold"
                    type={repeatPasswordVisibility ? "text" : "password"}
                    autoComplete="new-password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.confirm_password || ""}
                    invalid={
                      validation.touched.confirm_password &&
                      validation.errors.confirm_password
                        ? true
                        : false
                    }
                  />
                  <button
                    type="button"
                    id="btnToggle"
                    style={{ width: "20px", height: "14px" }}
                    className={
                      passwordVisibility ? "eye-icon open" : "eye-icon"
                    }
                    onClick={() => toggleRepeatPasswordVisibility()}
                  ></button>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
                </>
              )}
              
              <Row className="py-2">
                <Col>
                  <Input
                    name="address"
                    type="text"
                    placeholder="Address*"
                    className="ai-register-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address || ""}
                    invalid={
                      validation.touched.address && validation.errors.address
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address && validation.errors.address ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="py-2">
                <Col>
                  <Input
                    name="country"
                    type="select"
                    className="ai-register-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.country || ""}
                    defaultValue={validation.values.country || ""}
                    invalid={
                      validation.touched.country && validation.errors.country
                        ? true
                        : false
                    }
                  >
                    <option value="0">Country</option>
                    {countries.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.countryCode}
                          label={item.countryName}
                        >
                          {item.countryName}
                        </option>
                      )
                    })}
                  </Input>
                  {validation.touched.state && validation.errors.state ? (
                    <FormFeedback type="invalid">
                      {validation.errors.state}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <Input
                    name="state"
                    type="select"
                    className="ai-register-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.state || ""}
                    defaultValue={validation.values.state || ""}
                    invalid={
                      validation.touched.state && validation.errors.state
                        ? true
                        : false
                    }
                  >
                    <option value="0">State</option>
                    {states.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.stateCode}
                          label={item.stateName}
                        >
                          {item.countryName}
                        </option>
                      )
                    })}
                  </Input>
                  {validation.touched.state && validation.errors.state ? (
                    <FormFeedback type="invalid">
                      {validation.errors.state}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="py-2">
                <Col>
                  <Input
                    name="city"
                    type="text"
                    placeholder="City*"
                    className="ai-register-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.city || ""}
                    invalid={
                      validation.touched.city && validation.errors.city
                        ? true
                        : false
                    }
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <FormFeedback type="invalid">
                      {validation.errors.city}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col>
                  <Input
                    name="zip"
                    type="text"
                    rows="4"
                    placeholder="Zip*"
                    className="ai-register-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.zip || ""}
                    invalid={
                      validation.touched.zip && validation.errors.zip
                        ? true
                        : false
                    }
                  />
                  {validation.touched.zip && validation.errors.zip ? (
                    <FormFeedback type="invalid">
                      {validation.errors.zip}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="py-2">
                <Col>
                  <Input
                    name="phone"
                    type="text"
                    rows="4"
                    placeholder="Phone (Optional)"
                    className="ai-register-input"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="pt-2 pb-2">
                <Col className="mb-2">
                  <div
                    className="h-100 w-100 shadow-sm p-2"
                    style={{ background: "#EFF2F7" }}
                  >
                    <div style={{ overflow: "auto", height: "200px" }}>
                      <TermsAndCondition />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="text-left pb-1">
                <Col>
                  <Input
                    type="checkbox"
                    className="form-check-Input m-0"
                    id="formrow-customCheck"
                    name="termsofservice"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.termsofservice}
                    invalid={
                      validation.touched.termsofservice &&
                      validation.errors.termsofservice
                        ? true
                        : false
                    }
                  />
                  <Label
                    className="form-check-Label ml-4"
                    htmlFor="formrow-customCheck"
                    style={{ color: "#FFFFFF" }}
                  >
                    I have read and agree to the Terms of services
                  </Label>
                  {validation.touched.termsofservice &&
                  validation.errors.termsofservice ? (
                    <FormFeedback type="invalid">
                      {validation.errors.termsofservice}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="py-1">
                <Col className="p-0">
                  <button
                    className="ai-action-btn-register w-100"
                    style={{ height: "60px", fontSize: "30px !important" }}
                    type="submit"
                    disabled={disableButton}
                  >
                    {disableButton && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mx-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                    {disableButton
                      ? "CREATING ACCOUNT..."
                      : "GIVE ME ACCESS TO MY 14-DAY TRIAL!"}
                  </button>
                </Col>
              </Row>
            </Form>
            <Row className="text-center py-3">
              <Col>
                <p style={{ color: "#FFFFFF" }}>
                  Already have an account ?{" "}
                  <Link
                    to="/login"
                    className="font-weight-medium "
                    style={{ color: "#2299AA" }}
                  >
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* =================================================== */}
        <div>
          {/* <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={8}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h1 className="text-primary line-height-17">
                          {"Start your 14-day free trial"}
                        </h1>
                        <p className="line-height-17">
                          {"100% Risk Free • No Card Required"}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-5">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="row mb-3">
                         <Label className="col-sm-2 col-form-label">
                          First Name*
                        </Label>
                        <Col sm={4}>
                          <Input
                            name="firstname"
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstname || ""}
                            invalid={
                              validation.touched.firstname &&
                              validation.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstname &&
                          validation.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Label className="col-sm-2 col-form-label">
                          Last Name*
                        </Label>
                        <Col sm={4}>
                          <Input
                            name="lastname"
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastname || ""}
                            invalid={
                              validation.touched.lastname &&
                              validation.errors.lastname
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastname &&
                          validation.errors.lastname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>

                      <div className="row mb-3">
                         <Label className="col-sm-2 col-form-label">
                          Company*
                        </Label>
                        <Col sm={10}>
                          <Input
                            name="companyName"
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.companyName || ""}
                            invalid={
                              validation.touched.companyName &&
                              validation.errors.companyName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.companyName &&
                          validation.errors.companyName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.companyName}
                            </FormFeedback>
                          ) : null}
                        </Col> 
                      </div>

                      <div className="row mb-3">
                         <Label className="col-sm-2 col-form-label">
                          Email-Login*
                        </Label>
                        <Col sm={10}>
                          <Input
                            id="email"
                            name="email"
                            autoComplete="username"
                            className="form-control"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col> 
                      </div>

                      <div className="row mb-3">
                         <Label className="col-sm-2 col-form-label">
                          Password*
                        </Label>
                        <div className="col-sm-4 mb-4">
                          <InputGroup>
                            <Input
                              name="password"
                              type={passwordVisibility ? "text" : "password"}
                              autoComplete="new-password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            <InputGroupText
                              className="pointer"
                              onClick={() => togglePasswordVisibility()}
                            >
                              <span className="m-auto border">
                                <i
                                  className={
                                    passwordVisibility
                                      ? "mdi mdi-eye"
                                      : "mdi mdi-eye-off"
                                  }
                                ></i>
                              </span>
                            </InputGroupText>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>
                        <Label className="col-sm-2 col-form-label">
                          Confirm*
                        </Label>
                        <div className="col-sm-4 mb-4">
                          <InputGroup>
                            <Input
                              name="confirm_password"
                              type={
                                repeatPasswordVisibility ? "text" : "password"
                              }
                              autoComplete="new-password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ""}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            <InputGroupText
                              className="pointer"
                              onClick={() => toggleRepeatPasswordVisibility()}
                            >
                              <span className="m-auto border">
                                <i
                                  className={
                                    repeatPasswordVisibility
                                      ? "mdi mdi-eye"
                                      : "mdi mdi-eye-off"
                                  }
                                ></i>
                              </span>
                            </InputGroupText>
                            {validation.touched.confirm_password &&
                            validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirm_password}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <Label className="col-sm-2 col-form-label">
                          Address*
                        </Label>
                        <Col sm={10}>
                          <Input
                            name="address"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </Col> 
                      </div>

                      <div className="row mb-3">
                        <Label className="col-sm-2 col-form-label">
                          Country*
                        </Label>
                        <Col sm={4}>
                          <Input
                            name="country"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country || ""}
                            defaultValue={validation.values.country || ""}
                            invalid={
                              validation.touched.country &&
                              validation.errors.country
                                ? true
                                : false
                            }
                          >
                            <option value="0">Select Country</option>
                            {countries.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.countryCode}
                                  label={item.countryName}
                                >
                                  {item.countryName}
                                </option>
                              )
                            })}
                          </Input>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Label className="col-sm-2 col-form-label">
                          State*
                        </Label>
                        <Col sm={4}>
                          <Input
                            name="state"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.state || ""}
                            defaultValue={validation.values.state || ""}
                            invalid={
                              validation.touched.state &&
                              validation.errors.state
                                ? true
                                : false
                            }
                          >
                            <option value="0">Select State</option>
                            {states.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.stateCode}
                                  label={item.stateName}
                                >
                                  {item.countryName}
                                </option>
                              )
                            })}
                          </Input>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>

                      <div className="row mb-3">
                         <Label className="col-sm-2 col-form-label">City*</Label>
                        <Col sm={4}>
                          <Input
                            name="city"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                            invalid={
                              validation.touched.city && validation.errors.city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Label className="col-sm-2 col-form-label">Zip*</Label>
                        <Col sm={4}>
                          <Input
                            name="zip"
                            type="text"
                            rows="4"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zip || ""}
                            invalid={
                              validation.touched.zip && validation.errors.zip
                                ? true
                                : false
                            }
                          />
                          {validation.touched.zip && validation.errors.zip ? (
                            <FormFeedback type="invalid">
                              {validation.errors.zip}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>

                      <div className="row mb-3">
                         <Label className="col-sm-2 col-form-label">
                          Phone (Optional)
                        </Label>
                        <Col sm={4}>
                          <Input
                            name="phone"
                            type="text"
                            rows="4"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </Col> 
                      </div>

                      <div className="row mb-3">
                        <Label className="col-form-label">
                          Terms of Service
                        </Label>
                        <Col className="mb-2">
                          <div
                            className="h-100 w-100 shadow-sm p-2 border rounded"
                            style={{ background: "#EFF2F7" }}
                          >
                            <div style={{ overflow: "auto", height: "200px" }}>
                              <TermsAndCondition />
                            </div>
                          </div>
                        </Col>
                        <div className="mb-3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="formrow-customCheck"
                              name="termsofservice"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.termsofservice}
                              invalid={
                                validation.touched.termsofservice &&
                                validation.errors.termsofservice
                                  ? true
                                  : false
                              }
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="formrow-customCheck"
                            >
                              I have read and agree to the Terms of services
                            </Label>
                            {validation.touched.termsofservice &&
                            validation.errors.termsofservice ? (
                              <FormFeedback type="invalid">
                                {validation.errors.termsofservice}
                              </FormFeedback>
                            ) : null}
                          </div> 
                        </div>
                      </div>
                      <div className="row mb-3">
                         <div className="col-sm"></div>
                        <div className="col-sm d-flex justify-content-center">
                          <button
                            className="btn btn-primary btn-block w-100"
                            type="submit"
                            disabled={disableButton}
                          >
                            {disableButton && (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm mx-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Loading...</span>
                              </>
                            )}
                            {disableButton ? "Creating account..." : "Register"}
                          </button>
                        </div>
                        <div className="col-sm"></div> 
                      </div>
                      <div className="row mb-3 d-flex align-items-center">
                         <p>
                          Complete Registration to put in your Amazon
                          information
                        </p> 
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                  <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Active Inventory.</p> 
              </div>
            </Col>
          </Row>
        </Container>*/}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Register
