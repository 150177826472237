import React, { useEffect, useRef, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  Button,
  FormFeedback,
  Modal,
  ModalBody
} from "reactstrap"
import { useDispatch } from "react-redux"
import { getSettings, updateSettings } from "store/action/settingPage"
import { useToasts } from "react-toast-notifications"
import { useFormik } from "formik"
import TabTitle from "components/Common/TabTitle"
import * as Yup from "yup"
import "./settings.scss"

const Settings = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [settingsData, setSettingsData] = useState({})
  const [radioValue, setRadioValue] = useState("NORMAL")
  const [inventoryStatus, setInventoryStatus] = useState("controlByUser")
  const [taxCode, setTaxCode] = useState("M")
  const [showInfoMessage, setShowInfoMessage] = useState(false)
  const margin_ref = useRef(null)

  /*   const restockCalculationModal = [
    "Aggressive – Formula will focus the most weight upon sales in the last two weeks",
    "Normal – Formula weighs all six categories very similarly",
    "Conservative – Formula puts the most weight upon sales in the 30 ,60 , and 90 day amounts",
  ] */

  useEffect(() => {
    var x = document
      .getElementsByClassName("form-horizontal")[0]
      ?.querySelectorAll(".mb-3")
    x.forEach(item => {
      item.classList.remove("mb-3")
    })
    margin_ref && margin_ref?.current?.classList.remove("mb-3")
  }, [margin_ref])

  useEffect(() => {
    dispatch(
      getSettings(
        responseData => {
          setSettingsData(responseData.data)
          setRadioValue(responseData.data.calculationFormula || "NORMAL")
          setInventoryStatus(
            responseData.data.itemControlledManually === 1
              ? "controlByUser"
              : "autoControl" || "controlByUser"
          )
          setTaxCode(responseData.data.taxCodeControlBy || "M")
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...settingsData },
    validationSchema: Yup.object({
      leadTime: Yup.number()
        .positive()
        .min(1, "Must be greater than 0")
        .required("Please Enter Lead Time"),
      minDays: Yup.number()
        .positive()
        .min(1, "Must be greater than 0")
        .required("Please Enter Minimum Days"),
      maxDays: Yup.number()
        .positive()
        .min(1, "Must be greater than 0")
        .required("Please Enter Maximum Days"),
      daysOutOfStock: Yup.number()
        .positive()
        .min(1, "Must be greater than 0")
        .required("Please Enter Days Out of Stock"),
      daysWithOutSales: Yup.number()
        .positive()
        .min(
          Yup.ref("daysOutOfStock"),
          "Must be greater or equal to days out of stock"
        )
        .required("Please Enter Days Without Sales"),
      inventoryLevelOversized: Yup.number()
        .positive()
        .min(1, "Must be greater than 0")
        .required("Please Enter Oversized Inventory Level"),
      inventoryLevelStd: Yup.number()
        .positive()
        .min(1, "Must be greater than 0")
        .required("Please Enter Standard Inventory Level"),
    }),
    onSubmit: values => {
      dispatch(
        updateSettings(
          {
            data: {
              daysOutOfStock: Number(values.daysOutOfStock),
              daysWithOutSales: Number(values.daysWithOutSales),
              inventoryLevelOversized: Number(values.inventoryLevelOversized),
              inventoryLevelStd: Number(values.inventoryLevelStd),
              leadTime: Number(values.leadTime),
              maxDays: Number(values.maxDays),
              minDays: Number(values.minDays),
              calculationFormula: radioValue,
              itemControlledManually:inventoryStatus === "controlByUser" ? 1 : 0,
              taxCodeControlBy: taxCode
            },
          },
          responseData => {
            addToast(responseData.data, {
              appearance: "success",
              autoDismiss: true,
            })
          },
          errorData => {
            addToast(errorData.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    },
  })

  const leadTimeSettingTooltip = `This is the average time it will take after Restock Sheet is "Finalized” for products to be pulled, prepped and sent into Amazon and ready for sale. The Lead Time Setting should include the average transit time to FBA warehouse and the typical time for Amazon to unpack and transfer your inventory. The program will take your Lead Time Setting into account when calculating the amount of inventory to restock.Default is 14 days.`

  const targetDaysInStocks = `These two settings will set the range for the suggested restocking amount. Do you send weekly inventory to Amazon? Then these days can be slightly lower. Do you send monthly to the FBA Warehouses?  Then the Target Days in Stock should be a wider range so you don’t run out. The Daily Projected Sales will be used in conjunction with these two values to make a prediction of the amount of stock you need in FBA. The further apart you set these, the greater the min and max suggestions will be. Default is set to Min 21 days and Max 35 days.`

  //currently this tool tip is not in use
  // const calculationFormula = `Aggressive formula will focus on sales in the last two week. Normal will take a more Average approach weighing all the six categories very similarly. The Conservative calculation puts the most weight in the 30 60 and 90 day trends`

  const inventoryLevelsByAmazon = `Amazon sets limits of the amount of inventory you can have in their warehouses. Each quarter they revise the limit and it shows up in a tab on your Inventory Planning page. Head to your Amazon seller central page to get these numbers.  Here’s how to find them:
      1. Hover over “Inventory” and click on “Inventory Planning”.    
      2. Click on the “Performance” Tab. 
On the bottom right you will see Restock Limits click on it and the tab shift up showing the standard-sized storage and the second will be oversized. 
You want to take the “Maximum Inventory Level” and put in the standard box above. 
In the second column again take the “Maximum Inventory Level” and put in for Oversized box above.  This will allow us to track how close you are to your limit. 
These Restock Limit Numbers are also available on your Manage FBA Shipment page.`

  return (
    <React.Fragment>
      <div className="page-content page-wrapper">
        {showInfoMessage && (
          <Modal
            isOpen={showInfoMessage}
            size="lg"
            className="w-100 modal-wrapper"
            autoFocus={true}
            toggle={() => <></>}
            style={{
              border: 0,
              backgroundColor: "#082C4E",
              borderRadius: "35px",
            }}>
            <ModalBody
              style={{
                backgroundColor: "#082C4E",
                border: 0,
                borderRadius: "35px",
              }}
            >
              <Row className="d-flex justify-content-end w-100 m-0 text-center">
                <div
                  className="ai-modal-close-btn"
                  onClick={() => setShowInfoMessage(false)}
                >
                  <div style={{ fontSize: "18px" }}>&#10005;</div>
                </div>
              </Row>
              <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
                <div
                  className="text-white"
                  style={{ font: "bold 15px nunito-sans" }}
                >
                  Active Inventory allows products to be hidden from view on the restock sheet if you no longer want to restock this item. This can be done by the user by choosing the Manual button.   The Auto button allows the program to automatically hide the product after it is out of stock and has not had sales for a certain number of days.   You can customize the amount days before an item is hidden in the Inventory Status Settings.
                </div>
              </Row>
              <div className="d-flex justify-content-center col-12">
                <Row className="align-items-center">
                  <Col className="d-flex justify-content-center">
                    <Button
                      className="ai-action-btn btn btn-primary"
                      color=""
                      onClick={() => setShowInfoMessage(false)}>
                      CLOSE
                    </Button>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>
        )}
        <MetaTags>
          <title>Settings | Active Inventory</title>
        </MetaTags>
        <Container>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <TabTitle title="Settings" />
            <Row style={{ justifyContent: "center" }}>
              <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                <h4
                  className="fw-bold pt-3 pb-3"
                  style={{
                    backgroundColor: "red",
                    borderRadius: "30px",
                    fontFamily: "novecento-sans",
                    color: "#FFEFF0",
                  }}
                >
                  NOTICE: IMPORTANT VALUES BELOW**
                </h4>
                <p
                  className="ai-settings-text fw-normal pt-3 pl-2 pr-2 pb-4"
                  style={{ fontSize: "18px", letterSpacing: " 0.16px" }}
                >
                  **Set these numbers carefully as they will have large impact on
                  restock suggestions the program gives you. Please work your way
                  to the bottom of the page to see all the values you can set.
                  <b>
                    {" "}
                    If you choose to not set any values, then the default settings
                    will get you started.
                  </b>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center pt-4 pb-2">
                  <p className="ai-head-text mb-1" style={{ fontSize: "27px" }}>
                    INVENTORY STATUS SETTINGS
                  </p>
                  <span data-toggle="tooltip">
                    <a className="bx bx-info-circle cursor-pointer ms-2" onClick={() => { setShowInfoMessage(true) }} ></a>
                  </span>
                </div>
                <Container
                  style={{
                    backgroundColor: "#F3F7FB",
                    borderRadius: "15px",
                  }}
                >
                  <Row className="justify-content-center">
                    <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>

                      <FormGroup ref={margin_ref}>
                        <Row className="justify-content-center">
                          <Label
                            className="ai-head-text pt-5 pb-1 m-0"
                            style={{ fontSize: "20px" }}
                            for="leadtimesetting"
                          >
                            Lead Time Settings
                            <span
                              data-toggle="tooltip"
                              title={`${leadTimeSettingTooltip}`}
                            >
                              <i className="bx bx-info-circle cursor-pointer"></i>
                            </span>
                          </Label>
                        </Row>
                        <Row className="justify-content-center">
                          <Input
                            name="leadTime"
                            className="ai-input-box"
                            placeholder="Lead Time Settings"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.leadTime || ""}
                            invalid={
                              validation.touched.leadTime &&
                                validation.errors.leadTime
                                ? true
                                : false
                            }
                          />
                        </Row>
                        <Row>
                          {validation.touched.leadTime &&
                            validation.errors.leadTime ? (
                            <FormFeedback type="invalid" className="mx-2">
                              {validation.errors.leadTime}
                            </FormFeedback>
                          ) : null}
                          <label
                            style={{ fontSize: "15px" }}
                            className="ai-settings-text fw-normal fst-italic pt-2 mb-0 mx-2"
                          >
                            {"System Default: 14 Days"}
                          </label>
                        </Row>
                      </FormGroup>
                      <FormGroup
                        row
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Col xs={8} md={8} lg={8}>
                          <Label
                            className="ai-head-text pt-5 pb-0 m-0"
                            style={{ fontSize: "20px" }}
                            for="targetdaysinstocks"
                          >
                            Target Days in Stock
                            <span
                              data-toggle="tooltip"
                              title={`${targetDaysInStocks}`}
                            >
                              <i className="bx bx-info-circle cursor-pointer ms-2"></i>
                            </span>
                          </Label>
                          <Row className="justify-content-center">
                            <div className="ai-setting-container pt-0 pb-0">
                              <div className="ai-setting-item">
                                <Row
                                  style={{ height: "34px" }}
                                  className="justify-content-center"
                                >
                                  <Label
                                    className="ai-head-text m-0"
                                    style={{ fontSize: "15px" }}
                                    for="targetdaysinstocks"
                                  >
                                    Min
                                  </Label>
                                </Row>
                                <Row className="justify-content-center">
                                  <Input
                                    name="minDays"
                                    className="ai-input-box"
                                    placeholder="Min Target Days in Stocks"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.minDays || ""}
                                    invalid={
                                      validation.touched.minDays &&
                                        validation.errors.minDays
                                        ? true
                                        : false
                                    }
                                  />
                                </Row>
                                <Row className="justify-content-center">
                                  {validation.touched.minDays &&
                                    validation.errors.minDays ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="mx-2"
                                    >
                                      {validation.errors.minDays}
                                    </FormFeedback>
                                  ) : null}
                                  <label
                                    style={{ fontSize: "15px" }}
                                    className="ai-settings-text fw-normal fst-italic pt-2 mb-0 mx-2"
                                  >
                                    {"System Default: 21 Days"}
                                  </label>
                                </Row>
                              </div>
                              <div className="ai-setting-item">
                                <Row
                                  style={{ height: "34px" }}
                                  className="justify-content-center"
                                >
                                  <Label
                                    className="ai-head-text m-0"
                                    style={{ fontSize: "15px" }}
                                    for="targetdaysinstocks"
                                  >
                                    Max
                                  </Label>
                                </Row>
                                <Row className="justify-content-center">
                                  <Input
                                    name="maxDays"
                                    className="ai-input-box"
                                    placeholder="Max Target Days in Stocks"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.maxDays || ""}
                                    invalid={
                                      validation.touched.maxDays &&
                                        validation.errors.maxDays
                                        ? true
                                        : false
                                    }
                                  />
                                </Row>
                                <Row className="justify-content-center">
                                  {validation.touched.maxDays &&
                                    validation.errors.maxDays ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="mx-2"
                                    >
                                      {validation.errors.maxDays}
                                    </FormFeedback>
                                  ) : null}
                                  <label
                                    style={{ fontSize: "15px" }}
                                    className="ai-settings-text fw-normal fst-italic pt-2 mb-0 mx-2"
                                  >
                                    {"System Default: 35 Days"}
                                  </label>
                                </Row>
                              </div>
                            </div>
                          </Row>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="m-0">
                        <Label
                          className="ai-head-text pt-5"
                          style={{ fontSize: "20px" }}
                          for="inventorylevelssetbyamzon"
                        >
                          Restock Calculation Models
                        </Label>
                      </FormGroup>
                      <div className="pb-4">
                        <Label
                          style={{ fontSize: "20px" }}
                          className="ai-settings-text fw-normal"
                        >
                          Restock models use a combination of 3, 7, 14, 30, 60,
                          90 sales time frames
                        </Label>
                      </div>
                      <div className="ai-setting-container">
                        <div className="ai-setting-item">
                          <Row className="d-flex justify-content-center">
                            <input
                              type="radio"
                              id="aggressive"
                              name="fav_language"
                              className="restock-models m-0"
                              style={{ width: "18px", height: "18px" }}
                              checked={radioValue === "AGGRESSIVE"}
                              value="AGGRESSIVE"
                              onChange={() => setRadioValue("AGGRESSIVE")}
                            />
                          </Row>
                          <Row>
                            <label
                              htmlFor="aggressive"
                              style={{ fontSize: "17px" }}
                              className="ai-settings-text mb-0 p-3"
                            >
                              Aggresive
                            </label>
                          </Row>
                          <Row>
                            <p
                              style={{ fontSize: "17px" }}
                              className="ai-settings-text fw-normal "
                            >
                              Formula will focus the most weight upon sales in
                              the last two weeks
                            </p>
                          </Row>
                        </div>
                        <div className="ai-setting-item">
                          <Row className="d-flex justify-content-center">
                            <input
                              type="radio"
                              id="normal"
                              name="fav_language"
                              className="restock-models m-0"
                              style={{ width: "18px", height: "18px" }}
                              checked={radioValue === "NORMAL"}
                              value="NORMAL"
                              onChange={() => setRadioValue("NORMAL")}
                            />
                          </Row>
                          <Row>
                            <label
                              htmlFor="normal"
                              style={{ fontSize: "17px" }}
                              className="ai-settings-text mb-0 p-3"
                            >
                              Normal
                            </label>
                          </Row>
                          <Row>
                            <p
                              style={{ fontSize: "17px" }}
                              className="ai-settings-text fw-normal"
                            >
                              Formula weighs all six time frames very similarly
                            </p>
                          </Row>
                        </div>
                        <div className="ai-setting-item">
                          <Row className="d-flex justify-content-center">
                            <input
                              type="radio"
                              id="conservative"
                              name="fav_language"
                              className="restock-models m-0"
                              style={{ width: "18px", height: "18px" }}
                              onChange={() => setRadioValue("CONSERVATIVE")}
                              checked={radioValue === "CONSERVATIVE"}
                              value="CONSERVATIVE"
                            />
                          </Row>
                          <Row>
                            <label
                              htmlFor="conservative"
                              style={{ fontSize: "17px" }}
                              className="ai-settings-text mb-0 p-3"
                            >
                              Conservative
                            </label>
                          </Row>
                          <Row>
                            <p
                              style={{ fontSize: "17px" }}
                              className="ai-settings-text fw-normal"
                            >
                              Formula puts the most weight upon sales in the 30,
                              60, and 90 day time frames
                            </p>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>

                <Container
                  style={{
                    backgroundColor: "#F3F7FB",
                    borderRadius: "15px",
                    marginTop: "50px",
                  }}
                >
                  <FormGroup row className="d-flex justify-content-center">
                    <Col xs={9} md={9} lg={9} style={{ textAlign: "center" }}>
                      <Row>
                        <Label
                          className="ai-head-text pt-4 pb-2"
                          style={{ fontSize: "20px" }}
                          for="inventorylevelssetbyamzon"
                        >
                          PRODUCT VISIBILITY
                        </Label>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="controlByUser"
                            name="inventorySettings"
                            style={{ width: "18px", height: "18px" }}
                            checked={inventoryStatus === "controlByUser"}
                            value="controlByUser"
                            onChange={() => setInventoryStatus("controlByUser")}
                          />
                          <label
                            htmlFor="controlByUser"
                            style={{ fontSize: "17px" }}
                            className="ai-settings-text fw-normal mb-0 p-3"
                          >
                            <b>Manual:</b> All items controlled manually by user
                            (Hidden/Viewable)
                          </label>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="autoControl"
                            name="inventorySettings"
                            style={{ width: "18px", height: "18px" }}
                            checked={inventoryStatus === "autoControl"}
                            value="autoControl"
                            onChange={() => setInventoryStatus("autoControl")}
                          />
                          <label
                            htmlFor="autoControl"
                            style={{ fontSize: "17px" }}
                            className="ai-settings-text fw-normal mb-0 p-3"
                          >
                            <b>Auto:</b> Program will auto hide items when the
                            following is met:
                          </label>
                        </div>
                      </Row>
                      <Row className="pb-5">
                        <Col>
                          <Row>
                            <label
                              className="ai-head-text pt-1 pb-1 m-0"
                              style={{ fontSize: "20px" }}
                            >
                              Days out of stock
                            </label>
                          </Row>
                          <Row className="justify-content-center">
                            <Input
                              name="daysOutOfStock"
                              className="ai-input-box"
                              placeholder="Days out of stock"
                              type="text"
                              disabled={inventoryStatus === "controlByUser"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.daysOutOfStock || ""}
                              invalid={
                                validation.touched.daysOutOfStock &&
                                  validation.errors.daysOutOfStock
                                  ? true
                                  : false
                              }
                            />
                          </Row>
                          <Row>
                            <label
                              style={{ fontSize: "15px" }}
                              className="ai-settings-text fw-normal fst-italic pt-2 mb-0 mx-2"
                            >
                              {"System Default: 60 Days"}
                            </label>
                            {validation.touched.daysOutOfStock &&
                              validation.errors.daysOutOfStock ? (
                              <p className="mx-5 text-danger">
                                {validation.errors.daysOutOfStock}
                              </p>
                            ) : null}
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <label
                              className="ai-head-text pt-1 pb-1 m-0"
                              style={{ fontSize: "20px" }}
                            >
                              Days without sales
                            </label>
                          </Row>
                          <Row className="justify-content-center">
                            <Input
                              name="daysWithOutSales"
                              className="ai-input-box"
                              placeholder="Days without sales"
                              type="text"
                              disabled={inventoryStatus === "controlByUser"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.daysWithOutSales || ""}
                              invalid={
                                validation.touched.daysWithOutSales &&
                                  validation.errors.daysWithOutSales
                                  ? true
                                  : false
                              }
                            />
                          </Row>
                          <Row>
                            <label
                              style={{ fontSize: "15px" }}
                              className="ai-settings-text fw-normal fst-italic pt-2 mb-0 mx-2"
                            >
                              {"System Default: 90 Days"}
                            </label>

                            {validation.touched.daysWithOutSales &&
                              validation.errors.daysWithOutSales ? (
                              <p className="mx-5 text-danger">
                                {validation.errors.daysWithOutSales}
                              </p>
                            ) : null}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Container>

                <Container
                  style={{
                    backgroundColor: "#F3F7FB",
                    borderRadius: "15px",
                    marginTop: "50px",
                  }}
                >
                  <FormGroup row className="d-flex justify-content-center">
                    <Col xs={9} md={9} lg={9} style={{ textAlign: "center" }}>
                      <Row>
                        <Label
                          className="ai-head-text pt-4 pb-2"
                          style={{ fontSize: "20px" }}
                          for="inventorylevelssetbyamzon"
                        >
                          PRODUCT TAX CODE
                        </Label>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="controlByUser"
                            name="inventoryTaxCodeSettings"
                            style={{ width: "18px", height: "18px" }}
                            checked={taxCode === "M"}
                            value="M"
                            onChange={() => setTaxCode("M")}
                          />
                          <label
                            htmlFor="controlByUser"
                            style={{ fontSize: "17px" }}
                            className="ai-settings-text fw-normal mb-0 p-3"
                          >
                            <b>Manual:</b> Items Tax Code is controlled manually by user in Product Inventory Page
                          </label>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="radio"
                            id="autoControl"
                            name="inventoryTaxCodeSettings"
                            style={{ width: "18px", height: "18px" }}
                            checked={taxCode === "A"}
                            value="A"
                            onChange={() => setTaxCode("A")}
                          />
                          <label
                            htmlFor="autoControl"
                            style={{ fontSize: "17px" }}
                            className="ai-settings-text fw-normal mb-0 p-3"
                          >
                            <b>Auto:</b> Tax Code for all new items is automatically set to A_GEN_TAX
                          </label>
                        </div>
                      </Row>
                    </Col>
                  </FormGroup>
                </Container>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button
                style={{
                  width: "300px",
                  height: "50px",
                  borderRadius: "25px",
                  fontFamily: "novecento-sans",
                  fontSize: "20px",
                  textTransform: "uppercase",
                }}
                type="submit"
                className="fw-bold mt-3 mb-5 p-2"
              >
                Save Settings
              </Button>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
