import React from "react"
import UserProfile from "../pages/Authentication/user-profile"
import Users from "../pages/Users/index"
import RestockExplained from "pages/RestockPageExplained"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import UpdatePassword from "../pages/Authentication/UpdatePassword"

// // Inventory Dashboard
import InventortDashboard from "../pages/Inventort-Dashboard"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Static Pages
import StaticSite from "static-site"
import FAQPage from "static-site/pages/faq"
import AffiliatePage from "static-site/pages/affiliate"
import Signup from "static-site/pages/signup"
import Support from "static-site/pages/support"

import FBARestock from "pages/FBARestock"
import Amazon from "pages/Authentication/Amazon"
import OutOfStockReport from "pages/OutOfStockReport"
import RestockReportHistory from "pages/RestockReportHistory"
import ProductReport from "pages/ProductReport"
import AmazonAuthRedirect from "../pages/Authentication/amazon-auth-redirect"
import AmazonAuthSuccessRedirect from "../pages/Authentication/amazon-auth-success"
import Settings from "pages/SettingsPage"
import Billing from "pages/Billing"
import TermService from "static-site/pages/termsService"
import UnderstandingRestockSheet from "static-site/pages/understandingrestocksheet"
import ShippingQueue from "pages/ShippingQueue/ShippingQueue"
import RestockSheet from "pages/RestockSheet"
import StripeContainer from "components/Payment/StripeContainer"
import PrivacyPolicy from "static-site/pages/privacyPolicy"
import Client from "pages/Client/Client"
import Notification from "pages/Notification/Notification"
import Demo from "pages/Authentication/Demo"
import Popup from "pages/Authentication/Popup"

const authProtectedRoutes = [
  { path: "/out-of-stock", component: OutOfStockReport },
  { path: "/fba-restock-sheet", component: FBARestock },
  { path: "/restock-explained", component: RestockExplained },
  { path: "/dashboard", component: InventortDashboard },
  { path: "/auth", component: AmazonAuthRedirect },
  { path: "/auth/success", component: AmazonAuthSuccessRedirect },
  { path: "/restock-report-history", component: RestockReportHistory },
  { path: "/restock-sheet", component: RestockSheet },
  { path: "/shipping-queue", component: ShippingQueue },
  { path: "/profile", component: UserProfile },
  { path: "/users", component: Users },
  { path: "/products", component: ProductReport },
  { path: "/billing", component: Billing },
  { path: "/settings", component: Settings },
  { path: "/subscribe", component: StripeContainer },
  { path: "/add-new-card", component: StripeContainer },
  { path: "/clients", component: Client },
  { path: "/notification", component: Notification },
]

const publicRoutes = [
  { path: "/auth", component: AmazonAuthRedirect },
  { path: "/auth/success", component: AmazonAuthSuccessRedirect },
  { path: "/seller", component: Amazon },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: UpdatePassword },
  { path: "/14-days-trial", component: Register },
  { path: "/demo-signup", component: Demo },
  { path: "/popup", component: Popup },

  //maintenance pages
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  //Static-Pages
  { path: "/", component: Login },
  { path: "/faq", component: FAQPage },
  { path: "/affiliate", component: AffiliatePage },
  // { path: "/signup", component: Signup },
  { path: "/support", component: Support },
  { path: "/termservice", component: TermService },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/understandingrestocksheet", component: UnderstandingRestockSheet },
]

export { authProtectedRoutes, publicRoutes }
