import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Images
import { clientConfig } from "store/action/user";
import { useToasts } from "react-toast-notifications";
import logo from "assets/images/logo-green.png"
import "../../assets/scss/style.scss"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AmazonAuthRedirect = () => {

  const dispatch = useDispatch();
  const { addToast } = useToasts()
  const history = useHistory()

  const [configError, setConfigError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [authRenew, setAuthRenew] = useState(false)

  let query = useQuery();

  useEffect(() => {
    document.body.classList.add("ai-login-page")

    const data = {
      "spapi_oauth_code": query.get("spapi_oauth_code"),
      "client_marketplace_id": query.get("state"), //state or client_market_place_id
      "selling_partner_id": query.get("selling_partner_id")
    }
    const param = query.get("state").split('_').length > 1 ? query.get("state").split('_')[2].toLowerCase() : '';
    dispatch(
      clientConfig(
        { data },
        response => {
          setLoading(false)
          setConfigError(false)
          let url = '/auth/success' + ('renew' === param ? '?q=renew' : '');
          history.push(url);
        },
        error => {
          setLoading(false)
          setConfigError(true)
          addToast(error.response.data.error.displayMessage, {
            appearance: 'error',
            autoDismiss: true,
          })
        }))
  }, [])

  //auth?spapi_oauth_code=rhjachrunyellkkarckj&state=dbcd9d0e-c518-4735-b77b-d81c20845cfe&selling_partner_id=a14prchpaj1ks7
  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>Amazon Authorization</title>
        </MetaTags>
        <div className="ai-grid-container">
          <div className="ai-grid-item w-100">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "70px 20px 20px 20px",
              }}
            >
              <img src={logo} alt="" style={{ width: "220px" }} />
            </div>
            <div className="account-pages my-5 pt-sm-5">
              <div>
                <Row>
                  <Col xs={12}>
                    <div className="text-primary p-4">
                      <h3 className="text-center m-3 ai-head-text text-white">
                        AMAZON AUTHORIZATION INFORMATION
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="p-2">
                <div className="text-center m-3" style={{ font: "normal normal normal 12px/22px nunito-sans", letterSpacing: "0.16px", color: "#082C4E", opacity: 1, padding: "0 200px 0px 200px" }}>
                  {loading ? <div className="d-flex justify-content-center w-100 text-white">Please Wait for while....</div> : <div className="d-flex justify-content-center w-100">
                    {configError
                      ? <div className="text-danger h4 my-2">
                        <div className="text-center m-3" style={{ font: "normal normal bold 80px novecento-sans", letterSpacing: "0.16px", color: "#FE6571", opacity: 1, padding: "0 200px 0px 200px" }}>ERROR!</div>
                        Something went wrong !!
                      </div>
                      : <div className="mbr-text mbr-fonts-style display-7 mh-100">
                        <p className="text-center">Amazon Account configuration successfull!!</p>
                        <div className="text-center m-3" style={{ font: "normal normal bold 80px novecento-sans", letterSpacing: "0.16px", color: "#FFEE00", opacity: 1, padding: "0 200px 0px 200px" }}>SUCCESS!</div>
                        <p className="text-center">
                          It may take up to 24 hours for the data to populate in
                          the app. We will send you an email with the transfer is
                          complete.
                        </p>
                        <p className="text-center">
                          After it is complete, you can explore the
                          features that will save you time and make your FBA
                          shipment decisions quick and easy.
                        </p>
                      </div>
                    }
                  </div>}
                </div>
                <div className="row mt-3 text-center">
                  <div className="col-sm">
                    {/* <button
                          //className={disableButton ? "btn btn-primary btn-block w-75" : "btn btn-primary"}
                          className="ai-btn ai-head-text text-white"
                          style={{fontSize:"24px"}}
                          onClick={handleSubmit}
                          type="submit"
                          disabled={disableButton}
                        >
                          {
                            disableButton &&
                            <>
                              <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          }
                          {disableButton ? "Redirecting you to the Amazon Seller site..." : "Authorize"}
                        </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AmazonAuthRedirect
