import React from 'react';
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import { useEffect } from 'react';

const NonAuthLayout = (props) => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default withRouter(NonAuthLayout)
