import TabTitle from "components/Common/TabTitle";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MetaTags } from "react-meta-tags";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Card, CardBody, Col, Container, Input, InputGroup, InputGroupText } from "reactstrap";
import { getSuperAdimnClientByName, getSuperAdimnClientByStatus, getSuperAdimnClientData } from "store/action/dashboard";
import { BASE_URL } from 'utils/url';
import { setStartLoader, setStopLoader } from "store/loader/action"

const dateOptions = { month: "2-digit", year: "numeric", day: "2-digit" }

const Client = () => {

    const { addToast } = useToasts()
    const dispatch = useDispatch()
    const [superAdminClientData, setSuperAdminClientData] = useState([])
    const [filterValue, setFilterValue] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(100);
    const [pagination, setPagination] = useState({ totalPages: 0, totalElements: 0 })
    const [clientStatus, setClientStatus] = useState("All")

    const status = [
        { lable: "Active", value: true },
        { lable: "Inactive", value: false }
    ]

    useEffect(() => {
        getSuperAdminClient()
    }, [])

    useEffect(() => {
        switch (clientStatus) {
            case "true":
                getSuperAdminClientsByStatus()
                break;
            case "false":
                getSuperAdminClientsByStatus()
                break;
            case "all":
                getSuperAdminClient()
                break;
            default: break;
        }
    }, [clientStatus])

    const downloadClientData = (status,search) => {
        dispatch(setStartLoader())
    let myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      localStorage.getItem("token_type") +
        " " +
        localStorage.getItem("access_token")
    )
    myHeaders.append("Content-Type", "application/json")

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
        BASE_URL+`admin/client/download?search=${search}&status=${status}`,
      requestOptions
    )
      .then(resp => resp.blob())
      .then(blob => {
        dispatch(setStopLoader())
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "Client.xlsx"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        addToast("File downloaded successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(error => {
        dispatch(setStopLoader())
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        })
      })
    }

    const getSuperAdminClient = () => {
        dispatch(
            getSuperAdimnClientData(
                responseData => {
                    setSuperAdminClientData(responseData.data.content)
                    setPagination({
                        totalPages: responseData.data.totalPages,
                        totalElements: responseData.data.totalElements,
                    })
                },
                errorData => {
                    addToast(errorData.response.data.error.displayMessage, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            )
        )
    }

    const exportClient = (status,name) => {
        dispatch(
            getSuperAdimnClientData(
                responseData => {
                    setSuperAdminClientData(responseData.data.content)
                    setPagination({
                        totalPages: responseData.data.totalPages,
                        totalElements: responseData.data.totalElements,
                    })
                },
                errorData => {
                    addToast(errorData.response.data.error.displayMessage, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            )
        )
    }

    const getSuperAdminClientsByStatus = () => {
        dispatch(
            getSuperAdimnClientByStatus(
                { isActive: clientStatus, currentPage: currentPage, perPage: perPage },
                responseData => {
                    setSuperAdminClientData(responseData.data.content)
                    setPagination({
                        totalPages: responseData.data.totalPages,
                        totalElements: responseData.data.totalElements,
                    })
                },
                errorData => {
                    addToast(errorData.response.data.error.displayMessage, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            )
        )
    }

    const handleSearchClientData = (key) => {
        if (["Enter"].includes(key)) {
            if (filterValue.length > 2) {
                dispatch(
                    getSuperAdimnClientByName(
                        { search: filterValue, currentPage: currentPage, perPage: perPage },
                        responseData => {
                            setSuperAdminClientData(responseData.data.content)
                            setPagination({
                                totalPages: responseData.data.totalPages,
                                totalElements: responseData.data.totalElements,
                            })
                        },
                        errorData => {
                            addToast(errorData.response.data.error.displayMessage, {
                                appearance: 'error',
                                autoDismiss: true,
                            })
                        }
                    )
                )
            }
        }
    }

    const column = [
        {
            name: 'Company Name',
            selector: row => row.name,
            sortable: true,
            width: "200px",
            minWidth: "auto", 
            grow: 1
        },
        {
            name: 'Name',
            selector: row => row.adminName,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Email',
            selector: row => row.adminEmail,
            sortable: true,
            width: "250px"
        },
        {
            name: 'Phone',
            selector: row => row.phoneNumber,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Address',
            selector: row => row.address1,
            width: "150px"
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            width: "100px"
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
            width: "55px"
        },
        {
            name: 'Postcode',
            selector: row => row.zipcode,
            sortable: true,
            width: "80px"
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            width: "80px"
        },
        {
            name: "Account Start Date",
            selector: row => row.accountTrialStartDate,
            cell: row => new Date(row.accountTrialStartDate).toLocaleDateString("en-US", dateOptions),
            sortable: true
        },
        {
            name: "Subscription End Date",
            selector: row => row.subscriptionEndDate,
            cell: row => new Date(row.subscriptionEndDate).toLocaleDateString("en-US", dateOptions),
            sortable: true
        },
        {
            name: "Paid Subscription",
            selector: row => row.paidSubscriptionActive,
            cell: row => {
                return <span className={row.paidSubscriptionActive ? `text-success font-weight-bold` : `text-danger font-weight-bold`}>{row.paidSubscriptionActive ? "Active" : row.active?"Trial":"Inactive"}</span>
            },
            sortable: true
        }
    ]

    const handlePageChange = (e) => {
        setCurrentPage(e - 1)
    };

    const handlePerRowsChange = (e) => {
        setPerPage(e)
    }

    const handleClearFilter = () => {
        setFilterValue("")
        getSuperAdminClient()
    }

    const handleRefresh = () => {
        setFilterValue("")
        setClientStatus("All")
        getSuperAdminClient()
    }

    const handleExport = () => {
        downloadClientData(clientStatus,filterValue);
    }

    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Clients | Active Inventory</title>
                </MetaTags>
                <Container fluid className="ml-1 mr-1">
                    <TabTitle title="Clients" />

                    <div className="shadow-sm d-flex py-2 mb-1 row rounded bg-purple text-white mx-1 px-2 text-white">
                        <div className="d-flex col-lg-9 align-items-center sm-spacing">
                            <div className="d-flex w-100">
                                <div className="d-flex align-items-center sm-spacing text-white px-1">
                                    <span>Filter By</span>&nbsp;
                                    <Input
                                        id="exampleSelectMulti"
                                        name="selectMulti"
                                        className="w-50"
                                        type="select"
                                        placeholder="Select status"
                                        bsSize="sm"
                                        value={clientStatus}
                                        onChange={(e) => setClientStatus(e.target.value)}
                                    >
                                        <option value="all" onChange={() => setClientStatus("all")}>All</option>
                                        {
                                            status.map((data, index) => {
                                                return <option key={index} value={data.value}>{data.lable}</option>
                                            })
                                        }
                                    </Input>
                                </div>
                                <div className="d-flex align-items-center sm-spacing text-white px-1">
                                <span style={{ width: "70px" }}>Search By&nbsp;</span>
                                    <InputGroup size="sm" className="d-flex align-items-center" style={{ width: "350px" }}>
                                        <input
                                            type="text"
                                            placeholder='Enter Company Name/Email to search'
                                            className={filterValue?.length > 0 ? "border-1 border w-75 border-light rounded-start" : "border-1 border border-light w-100 rounded"}
                                            value={filterValue}
                                            onChange={(e) => setFilterValue(e.target.value)}
                                            onKeyDown={(e) => handleSearchClientData(e.key)}
                                        />
                                        {
                                            filterValue?.length > 0 && <InputGroupText className="bg-white float-right cancel-icon border-white border-0"  style={{height:"23px"}}>
                                                <i className="mdi mdi-close" onClick={() => handleClearFilter()}></i>
                                            </InputGroupText>
                                        }
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex col-lg-3 align-items-center justify-content-end sm-spacing">
                            <div className="text-white mx-1 pointer">
                                <span className="pointer"  data-toggle="tooltip" title="Refresh" onClick={() => handleRefresh()}>Refresh</span>
                            </div>
                            <div className="text-white"> | </div>
                            <div className="text-white mx-1 pointer">
                                <span className="pointer" data-toggle="tooltip" title="Export to CSV" onClick={() => handleExport()}>Export</span>
                            </div>
                        </div>
                    </div>
                    <Col className="col pl-1 pr-1">
                        <Card>
                            <CardBody className="p-2">
                                <DataTable
                                    columns={column}
                                    data={superAdminClientData}
                                    pagination
                                    paginationServer
                                    paginationPerPage={100}
                                    paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                                    paginationTotalRows={pagination.totalElements}
                                    onChangePage={(e) => handlePageChange(e)}
                                    onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                                    persistTableHead
                                    fixedHeader
                                    responsive
                                    fixedHeaderScrollHeight="157vh"
                                    dense
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </div>
        </>
    )
}

export default Client;