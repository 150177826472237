import { DASHBOARD_DATA_INSIGHT_SUCCESS, SUPER_ADMIN_DASHBOARD_DATA_CLIENT_SUCCESS, SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES_SUCCESS, SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING_SUCCESS, SUPER_ADMIN_DASHBOARD_DATA_YOY_SUCCESS } from "../actionType"

const INITIAL_STATE = {
    data: null,
    superadmin_client_data: null,
    superadmin_yoy_data: null,
    superadmin_revenue_sub_type: null,
    superadmin_subscriber_ending: null,
    market_placeId: localStorage.getItem("marketplaceId"),
    client_id: localStorage.getItem("clientId")
}
const Dashboard = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_DATA_INSIGHT_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
            }
        case SUPER_ADMIN_DASHBOARD_DATA_CLIENT_SUCCESS:
            return {
                ...state,
                superadmin_client_data: action.payload.data,
            }
        case SUPER_ADMIN_DASHBOARD_DATA_YOY_SUCCESS:
            return {
                ...state,
                superadmin_yoy_data: action.payload.data,
            }
        case SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES_SUCCESS:
            return {
                ...state,
                superadmin_revenue_sub_type: action.payload.data,
            }
        case SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING_SUCCESS:
            return {
                ...state,
                superadmin_subscriber_ending: action.payload.data,
            }
        default:
            return state
    }
}
export default Dashboard
