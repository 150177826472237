import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupText,
  Input,
  Col,
  Row,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { productData } from "store/action/stock"
import {
  getAllProductTag,
  removeProductTag,
  statusProductTag,
  addTagsProdcut,
  uploadProducts,
  filterProductsBySku,
  setProductExpiryDate,
  autoHideProduct,
  searchAndFilterData,
  importInventoryLabData,
  updateAmazonTaxCode,
  updateProductFields,
} from "store/action/product"
import { useToasts } from "react-toast-notifications"
import TabTitle from "components/Common/TabTitle"
import DataTable from "react-data-table-component"
import { setStartLoader, setStopLoader } from "store/loader/action"
import { MultiSelect } from "react-multi-select-component"
import Select from "react-select"
import SweetAlert from "react-bootstrap-sweetalert"
import { AsyncTypeahead, Menu, MenuItem, Hint } from "react-bootstrap-typeahead"
import Switch from "react-switch"
import "../../assets/scss/custom/rdt.scss"
import "./productReport.scss"
import "../../assets/scss/style.scss"
import RefreshIcon from "../../assets/images/refresh.svg"
import UploadIcon from "../../assets/images/upload.svg"
import DownloadIcon from "../../assets/images/download.svg"
import Pagination from "../../components/Common/Pagination"
import InventoryLabLogo from "../../assets/images/inventory-lab-white.svg"
import { BASE_URL } from 'utils/url';

const keyMapping = {
  title: "itemName",
  sku: "sku",
  tag: "tags.name",
}

const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" }

const ProductReport = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [product, setProduct] = useState([])
  const [productOriginal, setProductOriginal] = useState([])
  const [disable, setDisable] = useState(true)
  const [removeTag, setRemoveTag] = useState(false)
  const [productId, setProductId] = useState()
  const [productsTag, setProductsTag] = useState([])
  const [removedTag, setRemovedTag] = useState({})
  const [filterValue, setFilterValue] = useState({
    title: "",
    sku: "",
    tag: "",
  })
  const [tageName, setTagName] = useState("")
  const [rowID, setRowID] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [selected, setSelected] = useState([])
  const [perPage, setPerPage] = useState(100)
  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalElements: 0,
  })
  const [, setLoder] = useState({ type: "", loader: false })
  const [, setIsFilterData] = useState(false)
  const [urlData, setUrlData] = useState({ isFromUrl: false, sku: "" })
  const [searchParam, setSearchParam] = useState({ type: "", value: "" })
  const [hideItemClick, setHideItemClick] = useState(false)
  const [rowStatus, setRowStatus] = useState(false)
  const [refreshClicked, setRefreshClicked] = useState(false)
  const [columnSort, setColumnSort] = useState({ column: "", direction: "" })
  const [manuallyHiddenProduct, setManuallyHiddenProduct] = useState([])

  const hideTooltip = `White - Item is visible (unhidden). Blue -Item has been hidden by "Auto Hide" and will become visible (unhidden)  again if new inventory is added. Red - Item has been manually hidden and will not be visible (unhidden) again unless manually set to white (unhidden)`

  const options = [
    { label: "Active", value: "status:active" },
    { label: "Inactive", value: "status:inactive" },
    { label: "FBA", value: "type:fba" },
    { label: "FBM", value: "type:fbm" },
  ]

  const taxCodeOptions = [
    { value: "A_GEN_NOTAX", label: "A_GEN_NOTAX" },
    { value: "A_GEN_TAX", label: "A_GEN_TAX" },
    { value: "A_GEN_RENTAL", label: "A_GEN_RENTAL" },
    { value: "A_BOOKS_GEN", label: "A_BOOKS_GEN" },
    { value: "A_BOOKS_RELIG", label: "A_BOOKS_RELIG" },
    { value: "A_BOOKS_RENTAL", label: "A_BOOKS_RENTAL" },
    { value: "A_BUNDLE_PERDCL", label: "A_BUNDLE_PERDCL" },
    { value: "A_NEWS_104PLUS", label: "A_NEWS_104PLUS" },
    { value: "A_NEWS_52WKLY", label: "A_NEWS_52WKLY" },
    { value: "A_NEWS_26BIWKLY", label: "A_NEWS_26BIWKLY" },
    { value: "A_NEWS_12MTHLY", label: "A_NEWS_12MTHLY" },
    { value: "A_NEWS_4QTLY", label: "A_NEWS_4QTLY" },
    { value: "A_PERDCL_104PLUS", label: "A_PERDCL_104PLUS" },
    { value: "A_PERDCL_52WKLY", label: "A_PERDCL_52WKLY" },
    { value: "A_PERDCL_52W-4Q", label: "A_PERDCL_52W-4Q" },
    { value: "A_PERDCL_SEMIANNL", label: "A_PERDCL_SEMIANNL" },
    { value: "A_PERDCL_ANNUAL", label: "A_PERDCL_ANNUAL" },
    { value: "A_CLSFD_52WKLY", label: "A_CLSFD_52WKLY" },
    { value: "A_CLSFD_52W-4Q", label: "A_CLSFD_52W-4Q" },
    { value: "A_CLSFD_SEMIANNL", label: "A_CLSFD_SEMIANNL" },
    { value: "A_CLSFD_ANNUAL", label: "A_CLSFD_ANNUAL" },
    { value: "A_CLTH_GEN", label: "A_CLTH_GEN" },
    { value: "A_CLTH_BUCKLS", label: "A_CLTH_BUCKLS" },
    { value: "A_CLTH_COMPON", label: "A_CLTH_COMPON" },
    { value: "A_CLTH_ATHL", label: "A_CLTH_ATHL" },
    { value: "A_CLTH_CSTUMS", label: "A_CLTH_CSTUMS" },
    { value: "A_CLTH_FORMAL", label: "A_CLTH_FORMAL" },
    { value: "A_CLTH_FUR", label: "A_CLTH_FUR" },
    { value: "A_CLTH_HANDKE", label: "A_CLTH_HANDKE" },
    { value: "A_CLTH_HBAGS", label: "A_CLTH_HBAGS" },
    { value: "A_CLTH_IFUR", label: "A_CLTH_IFUR" },
    { value: "A_CLTH_BOOKBAG", label: "A_CLTH_BOOKBAG" },
    { value: "A_CLTH_PROTECTIVE", label: "A_CLTH_PROTECTIVE" },
    { value: "A_CLTH_UMBRELLA", label: "A_CLTH_UMBRELLA" },
    { value: "A_CLTH_HAIRACCESSORIES", label: "A_CLTH_HAIRACCESSORIES" },
    { value: "A_CLTH_BATH", label: "A_CLTH_BATH" },
    { value: "A_COLLECTIBLE_COIN", label: "A_COLLECTIBLE_COIN" },
    { value: "A_SPORT_ATHLSHOES", label: "A_SPORT_ATHLSHOES" },
    { value: "A_SPORT_ASUPPORT", label: "A_SPORT_ASUPPORT" },
    { value: "A_SPORT_BIKEHLMT", label: "A_SPORT_BIKEHLMT" },
    { value: "A_SPORT_MISCSPORTS1", label: "A_SPORT_MISCSPORTS1" },
    { value: "A_SPORT_SKISUIT", label: "A_SPORT_SKISUIT" },
    { value: "A_SPORT_WINTERSPORTBOOTS", label: "A_SPORT_WINTERSPORTBOOTS" },
    { value: "A_FOOD_GEN", label: "A_FOOD_GEN" },
    { value: "A_FOOD_CNDY", label: "A_FOOD_CNDY" },
    { value: "A_FOOD_CNDYFL", label: "A_FOOD_CNDYFL" },
    { value: "A_FOOD_BKTGN76-90", label: "A_FOOD_BKTGN76-90" },
    { value: "A_FOOD_BKTGN50-75", label: "A_FOOD_BKTGN50-75" },
    { value: "A_FOOD_BKTCDY50-90", label: "A_FOOD_BKTCDY50-90" },
    { value: "A_FOOD_JUICE0-50", label: "A_FOOD_JUICE0-50" },
    { value: "A_FOOD_JUICE51-99", label: "A_FOOD_JUICE51-99" },
    { value: "A_FOOD_CARBSFTDK", label: "A_FOOD_CARBSFTDK" },
    { value: "A_FOOD_CARBWTR", label: "A_FOOD_CARBWTR" },
    { value: "A_FOOD_NCARBWTR", label: "A_FOOD_NCARBWTR" },
    { value: "A_FOOD_PETFOOD", label: "A_FOOD_PETFOOD" },
    { value: "A_FOOD_WINE", label: "A_FOOD_WINE" },
    { value: "A1_ANIMAL14", label: "A1_ANIMAL14" },
    { value: "A_SCHL_SUPPLS", label: "A_SCHL_SUPPLS" },
    { value: "A_SCHL_GRAPHCALC", label: "A_SCHL_GRAPHCALC" },
    { value: "A_SCHL_INSTRUMENT", label: "A_SCHL_INSTRUMENT" },
    { value: "A_COMP_COMPUTER", label: "A_COMP_COMPUTER" },
    { value: "A_COMP_GAMPER", label: "A_COMP_GAMPER" },
    { value: "A_COMP_PDA", label: "A_COMP_PDA" },
    { value: "A_COMP_PERIPH", label: "A_COMP_PERIPH" },
    { value: "A_COMP_PRTSUP", label: "A_COMP_PRTSUP" },
    { value: "A_COMP_PRINT", label: "A_COMP_PRINT" },
    { value: "A_COMP_SOFTOP", label: "A_COMP_SOFTOP" },
    { value: "A_COMP_SOFTRC", label: "A_COMP_SOFTRC" },
    { value: "A_COMP_SCANNER", label: "A_COMP_SCANNER" },
    { value: "A_COMP_STORAGEMEDIA", label: "A_COMP_STORAGEMEDIA" },
    { value: "A_COMP_EDUSOFT", label: "A_COMP_EDUSOFT" },
    { value: "A_WARR_PARTSNSVCE", label: "A_WARR_PARTSNSVCE" },
    { value: "A_SERV_INSTALL", label: "A_SERV_INSTALL" },
    { value: "A_EGOODS_SOFT", label: "A_EGOODS_SOFT" },
    { value: "A_EGOODS_MISC1", label: "A_EGOODS_MISC1" },
    { value: "A_EGOODS_DIGITALMUSIC", label: "A_EGOODS_DIGITALMUSIC" },
    { value: "A_EGOODS_DIGITALBOOKS", label: "A_EGOODS_DIGITALBOOKS" },
    { value: "A_EGOODS_DIGITALGAMES", label: "A_EGOODS_DIGITALGAMES" },
    { value: "A_EGOODS_ONLINEGAMINGSUBS", label: "A_EGOODS_ONLINEGAMINGSUBS" },
    { value: "A_EGOODS_DIGITALNEWS", label: "A_EGOODS_DIGITALNEWS" },
    { value: "A_EGOODS_DIGITALNEWSSUBS", label: "A_EGOODS_DIGITALNEWSSUBS" },
    { value: "A_EGOODS_DIGITALPERDCL", label: "A_EGOODS_DIGITALPERDCL" },
    {
      value: "A_EGOODS_DIGITALPERDCLSUBS",
      label: "A_EGOODS_DIGITALPERDCLSUBS",
    },
    {
      value: "A_EGOODS_DIGITALBOOK_RENTAL",
      label: "A_EGOODS_DIGITALBOOK_RENTAL",
    },
    {
      value: "A_EGOODS_DIGITALAUDIOBOOKS",
      label: "A_EGOODS_DIGITALAUDIOBOOKS",
    },
    { value: "A_HLTH_OTCMED", label: "A_HLTH_OTCMED" },
    { value: "A_HLTH_SPFCOTCMED", label: "A_HLTH_SPFCOTCMED" },
    { value: "A_HLTH_SPFCORALHYG", label: "A_HLTH_SPFCORALHYG" },
    { value: "A_HLTH_CONTACTSOLN", label: "A_HLTH_CONTACTSOLN" },
    { value: "A_HLTH_SUNSCRN", label: "A_HLTH_SUNSCRN" },
    { value: "A_HLTH_BANDKIT", label: "A_HLTH_BANDKIT" },
    { value: "A_HLTH_THRMTR", label: "A_HLTH_THRMTR" },
    { value: "A_HLTH_FEMHYG", label: "A_HLTH_FEMHYG" },
    { value: "A_HLTH_TISSUETOW", label: "A_HLTH_TISSUETOW" },
    { value: "A_HLTH_INCONT", label: "A_HLTH_INCONT" },
    { value: "A_HLTH_CONTRCEPV", label: "A_HLTH_CONTRCEPV" },
    { value: "A_HLTH_FAMPLANTEST", label: "A_HLTH_FAMPLANTEST" },
    { value: "A_HLTH_BABYSUPPLS", label: "A_HLTH_BABYSUPPLS" },
    { value: "A_HLTH_DIETSUPMT", label: "A_HLTH_DIETSUPMT" },
    { value: "A_HLTH_MONITOR", label: "A_HLTH_MONITOR" },
    { value: "A_HLTH_DIABSUPPLS", label: "A_HLTH_DIABSUPPLS" },
    { value: "A_HLTH_PROSTHETIC", label: "A_HLTH_PROSTHETIC" },
    { value: "A_HLTH_MOBILITY", label: "A_HLTH_MOBILITY" },
    { value: "A_HLTH_MEDHAIRLOSS", label: "A_HLTH_MEDHAIRLOSS" },
    { value: "A_HLTH_TOOTHPASTEFL", label: "A_HLTH_TOOTHPASTEFL" },
    { value: "A_HLTH_HEARINGAID_BATT", label: "A_HLTH_HEARINGAID_BATT" },
    { value: "A_HLTH_COSMETIC", label: "A_HLTH_COSMETIC" },
    { value: "A_HLTH_DISPOSABLE_PACK", label: "A_HLTH_DISPOSABLE_PACK" },
    { value: "A_BABY_BRSTPUMP", label: "A_BABY_BRSTPUMP" },
    { value: "A_BABY_CLOTH", label: "A_BABY_CLOTH" },
    { value: "A_BABY_CARSEAT", label: "A_BABY_CARSEAT" },
    { value: "A_BABY_DIAPER", label: "A_BABY_DIAPER" },
    { value: "A_HOUSEHOLD_LINEN", label: "A_HOUSEHOLD_LINEN" },
    { value: "A_HOUSEHOLD_PAPERPRODS", label: "A_HOUSEHOLD_PAPERPRODS" },
    { value: "A_EMERGENCY_SUPPLIES", label: "A_EMERGENCY_SUPPLIES" },
    { value: "A_AUTOMOTIVE_MOTOROIL", label: "A_AUTOMOTIVE_MOTOROIL" },
  ]

  useEffect(() => {
    let url = window.location.href
    const [urlName, sku] = url.includes("?") ? url.split("?") : []
    const [skuTitle, skuNumber] = url.includes("=") ? sku.split("=") : []
    if (skuNumber !== "" && skuNumber !== undefined) {
      setUrlData({
        isFromUrl: true,
        sku: skuNumber,
      })
      if (urlData.sku !== "") {
        setFilterValue({ ...filterValue, sku: urlData.sku })
        handleFilterBySKU()
      }
    } else if (selected.length > 0 || searchParam.type !== "") {
      searchAndFilter()
    } else if (
      selected.length === 0 &&
      skuNumber === undefined &&
      columnSort.column === ""
    ) {
      getAllDetails()
    }
  }, [
    selected,
    urlData.sku,
    localStorage.getItem("marketplaceId"),
    currentPage,
    perPage,
    urlData.isFromUrl,
  ])

  useEffect(() => {
    if (columnSort.column !== "" && currentPage !== 0) {
      handleSort(columnSort.column, columnSort.direction)
    }
  }, [currentPage, perPage])

  useEffect(() => {
    const pagePermitted = JSON.parse(localStorage.getItem("pagepermitted"))
    if (
      pagePermitted?.INVENTORY_STATUS?.access &&
      pagePermitted?.INVENTORY_STATUS?.access === "WRITE"
    ) {
      setDisable(false)
    }
  }, [localStorage.getItem("pagepermitted")])

  useEffect(() => {
    searchAndFilter()
  }, [refreshClicked])

  const getAllDetails = () => {
    if (localStorage.getItem("marketplaceId")) {
      dispatch(
        productData(
          { data: { pageNo: currentPage, pageSize: perPage } },
          responseData => {
            setProduct([...responseData.data.content])
            setProductOriginal([...responseData.data.content])
            setPagination({
              totalPages: responseData.data.totalPages,
              totalElements: responseData.data.totalElements,
            })
            setStopLoader()
          },
          errorData => {
            setStopLoader()
            addToast(errorData.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    }
  }

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true

  const format_TAGS = (row, index) => {
    return (
      <div
        className="d-flex justify-content-around flex-column tags tag-wrapper w-100"
        style={{ width: "100% !important" }}
      >
        <div className="d-flex flex-row my-1 tag-div-container">
          {Object.keys(row.tags).map((data, index) => {
            return (
              <div
                key={index}
                className="badge mx-1 my-1"
                style={{
                  borderRadius: "14px",
                  backgroundColor: "transparent",
                  border: "1px solid #BCD2E5",
                }}
              >
                {row?.tags[data]}
                <i
                  className="mdi mdi-close h6 text-black pointer"
                  onClick={() => removeTagClick(data, row.id)}
                ></i>
              </div>
            )
          })}
        </div>
        {disable || Object.keys(row.tags).length > 4 ? null : (
          <div className="text-success">
            {
              <AsyncTypeahead
                allowNew={productsTag.length === 0}
                id="tag"
                filterBy={filterBy}
                isLoading={false}
                labelKey="name"
                minLength={3}
                flip={true}
                dropup
                onInputChange={e => handleTagName(e, row.id)}
                onSearch={<></>}
                onChange={e => handleInsertCustomTag(e, row, index)}
                options={productsTag}
                placeholder="Add or Search Tag Here..."
                renderInput={({
                  inputRef,
                  referenceElementRef,
                  ...inputProps
                }) => (
                  <input
                    {...inputProps}
                    ref={node => {
                      inputRef(node)
                      referenceElementRef(node)
                    }}
                    value={row.id === rowID ? tageName : ""}
                    className="form-control"
                  />
                )}
                renderMenuItemChildren={(option, props) => (
                  <div>
                    <span>{option.name}</span>
                  </div>
                )}
              />
            }
          </div>
        )}
      </div>
    )
  }
  
  const handleProductUpdateDropdown = (e,name, id) => {
    console.log(e.value, name)
    dispatch(
      updateProductFields(
        {
          data: {
            id: id,
            name: name,
            value: e ? e.value : "",
          },
        },
        response => {
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleProductUpdate = (e,index, id) => {
    dispatch(
      updateProductFields(
        {
          data: {
            id: id,
            name: e ? e.target.name : "",
            value: e ? e.target.value : "",
          },
        },
        response => {
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const format_TaxCode = (row, index) => {
    return (
      <Select
        options={taxCodeOptions}
        defaultValue={taxCodeOptions.filter(
          option => option.label === row.taxCode
        )}
        onChange={e => handleProductUpdateDropdown(e, "taxCode",row.id)}
        menuPlacement={"bottom"}
        menuPosition={"fixed"}
        width="auto"
        placeholder="Amazon Tax Code"
        isClearable="true"
        menuPortalTarget={document.body}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999, width: "250" }),
          menu: provided => ({ ...provided, zIndex: 9999, width: "250" }),
          control: provided => ({ ...provided, width: "250 !important" }),
          container: provided => ({ ...provided, width: "250 !important" }),
        }}
      />
    )
  }

  const handleTagName = (e, id) => {
    setTagName(e)
    setRowID(id)
    dispatch(
      getAllProductTag(
        { tagName: e },
        responseData => {
          let tempProductsTag = responseData.data.map(tag => {
            return {
              id: tag.id,
              name: tag.tagName,
            }
          })
          setProductsTag(tempProductsTag)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const setTagInLocalData = (value, tagId, index) => {
    let tempProduct = [...product]
    let tempProductTag = tempProduct[index].tags
    tempProductTag[tagId] = value
    setProduct([...tempProduct])
    setProductOriginal([...tempProduct])
  }

  const handleInsertCustomTag = async (e, row, index) => {
    if (e.length > 0) {
      let isSameAsEnteredTag = false
      let tempValue = e[0].name
      let tagTobeEntered = productsTag.filter(tag => tempValue === tag.name)
      Object.values(row.tags).map(tagName => {
        if (tagName === tempValue) {
          isSameAsEnteredTag = true
        }
      })
      if (tempValue !== "") {
        Object.values(row.tags).map(tagName => {
          if (tagName === tempValue) {
            isSameAsEnteredTag = true
          }
        })
        if (!isSameAsEnteredTag && tempValue) {
          dispatch(
            addTagsProdcut(
              {
                productId: row.id,
                data: {
                  name: tempValue,
                  id: tagTobeEntered.length > 0 ? tagTobeEntered[0].id : "",
                },
              },
              response => {
                if (response.data) {
                  setTagInLocalData(tempValue, response.data, index)
                  setTagName("")
                  setProductsTag([])
                  addToast("Tag added successfully!", {
                    appearance: "success",
                    autoDismiss: true,
                  })
                }
              },
              errorData => {
                addToast(errorData.response.data.error.displayMessage, {
                  appearance: "error",
                  autoDismiss: true,
                })
              }
            )
          )
        } else {
          setTagName("")
          setProductsTag([])
          addToast("Tag is already entered!", {
            appearance: "info",
            autoDismiss: true,
          })
        }
      }
    }
  }

  const removeExpiryDate = (e, row) => {
    const { value } = e.target
    const [year, month, date] =
      value !== undefined ? value.split("-") : ["", "", ""]
    let expDate = value !== undefined ? `${month} - ${date} - ${year}` : ""
    dispatch(
      setProductExpiryDate(
        { data: { id: row.id, expDate: "" } },
        response => {
          let tempData = [...productOriginal]
          let filteredData = tempData.filter(item => item.id === row.id)
          filteredData[0]["expiryDate"] = value !== undefined ? expDate : ""
          //setProduct([...productOriginal, ...filteredData])
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const removePurchaseDate = (e, row) => {
    const { value } = e.target
    const [year, month, date] =
      value !== undefined ? value.split("-") : ["", "", ""]
    let expDate = value !== undefined ? `${month} - ${date} - ${year}` : ""

    dispatch(
      updateProductFields(
        {
          data: {
            id: row.id,
            name: "purchaseDate",
            value: "",
          },
        },
        response => {
          let tempData = [...productOriginal]
          let filteredData = tempData.filter(item => item.id === row.id)
          filteredData[0]["purchaseDate"] = value !== undefined ? expDate : ""

          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleExpiryDateSelect = (e, row) => {
    const { value } = e.target
    const [year, month, date] =
      value !== undefined ? value.split("-") : ["", "", ""]
    let expDate = value !== undefined ? `${month}-${date}-${year}` : ""
    let date_regex = /^(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])\-(19|20)\d{2}$/
    if (["Enter"].includes(e.key)) {
      if (!date_regex.test(expDate) && value !== undefined) {
        value !== "" &&
          addToast(
            "Please enter valid value. This field is incomplete or has and invalid date!",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
      } else {
        dispatch(
          setProductExpiryDate(
            { data: { id: row.id, expDate: expDate } },
            response => {
              let tempData = [...productOriginal]
              let filteredData = tempData.filter(item => item.id === row.id)
              filteredData[0]["expiryDate"] =
                value !== undefined ? `${month}/${date}/${year}` : ""
              //setProduct([...productOriginal, ...filteredData])
              addToast(response.data, {
                appearance: "success",
                autoDismiss: true,
              })
            },
            errorData => {
              addToast(errorData.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
      }
    }
  }

  const handlePurchaseDate = (e, row) => {
    const { value } = e.target
    const [year, month, date] =
      value !== undefined ? value.split("-") : ["", "", ""]
    let expDate = value !== undefined ? `${month}-${date}-${year}` : ""
    let date_regex = /^(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])\-(19|20)\d{2}$/
    if (["Enter"].includes(e.key)) {
      if (!date_regex.test(expDate) && value !== undefined) {
        value !== "" &&
          addToast(
            "Please enter valid value. This field is incomplete or has and invalid date!",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
      } else {
        dispatch(
          updateProductFields(
            {
              data: {
                id: row.id,
                name: "purchaseDate",
                value: expDate,
              },
            },
            response => {
              let tempData = [...productOriginal]
              let filteredData = tempData.filter(item => item.id === row.id)
              filteredData[0]["purchaseDate"] =
                value !== undefined ? `${month}/${date}/${year}` : ""
    
              addToast(response.data, {
                appearance: "success",
                autoDismiss: true,
              })
            },
            errorData => {
              addToast(errorData.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
      }
    }
  }

  const handleAutoHide = (id, checked) => {
    dispatch(
      autoHideProduct(
        { data: { id: id, flag: checked } },
        response => {
          if (response.data === "OK") {
            addToast("Product updated successfully!", {
              appearance: "success",
              autoDismiss: true,
            })
          }
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const format_IMAGE = row => {
    return (
      <>
        {row.mainImage ? (
          <img src={row.mainImage} className="product-image-wrapper" />
        ) : (
          ""
        )}
      </>
    )
  }

  const format_Hide = row => {
    let isProductManuallyHidden = false
    //check if product is manually hidden by user
    if (manuallyHiddenProduct.includes(row.id)) {
      isProductManuallyHidden = true
    }
    return (
      <>
        <div title={
              row.active
                ? ""
                : row.itemHideType === "M"
                  ? "Manual Hide"
                  : "Auto Hide"
            }>
          <Switch
            id="material-switch"
            className="react-switch hide-show-switch"
            checked={"" !== row.itemHideDate || !row.active}
            //checked={!row.active}
            onChange={() => changeStatus(row)}
            onColor={
              isProductManuallyHidden
                ? "#d42424"
                : row.itemHideType === "M"
                  ? "#d42424"
                  : "#4959a6"
            }
            offColor="#ebe6e6"
            handleDiameter={10}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={40}
          />
        </div>
        <div className="check-input">
          <Input
            type="checkbox"
            defaultChecked={!row.autoHide}
            onClick={e => handleAutoHide(row.id, e.target.checked)}
          />{" "}
        </div>
        <div className="check-text">Turn Off Auto Hide</div>
      </>
    )
  }

  const format_Auto_Hide = row => {
    return (
      <div className="m-auto pb-4">
        <Input
          type="checkbox"
          defaultChecked={!row.autoHide}
          onClick={e => handleAutoHide(row.id, e.target.checked)}
        />
      </div>
    )
  }

  const format_Amazon_STATUS = row => {
    return (
      <div
        className={
          row.status === "active"
            ? "text-success font-weight-bold"
            : "text-danger font-weight-bold"
        }
      >
        {row.status}
      </div>
    )
  }

  const format_expDate = (row, index) => {
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" }
    return row.expiryDate ? (
      <div className="d-flex">
        <div>{row.expiryDate}</div>
        <i
          className="mdi mdi-close ms-2 pointer"
          data-toggle="tooltip"
          title="Remove Expiration Date"
          onClick={e => removeExpiryDate(e, row)}
        ></i>
      </div>
    ) : (
      <input
        type="date"
        name={`date-${index}`}
        id={`date-input-${index}`}
        className="p-1 w-100 tag-wrapper"
        style={{ borderRadius: "5px", border: "1px solid #BCD2E5" }}
        min={new Date().toISOString().split("T")[0]}
        onKeyDown={e => handleExpiryDateSelect(e, row)}
      />
    )
  }

  const format_SKU = sku => {
    return (
      <div data-toggle="tooltip" title={`${sku}`} className="break-word">
        {sku}
      </div>
    )
  }

  const format_listPrice = (row, index) => {
    return (
      <div style={{ width: "100% !important" }}>
        <Input
          name="listPrice"
          type="number"
          className="ai-input-restock"
          style={{border: "1px solid #BCD2E5", borderRadius:"9px"}}
          onBlur={e => handleProductUpdate(e, index, row.id)}
          value={row.listPrice}
          onChange={(e) => {
            const { name, value } = e.target;
            setProduct(prevState => {
              const updatedRows = [...prevState];
              updatedRows[index] = { ...updatedRows[index], [name]: value };
              return updatedRows;
            });
          }}
        />
      </div>
    )
  }

  const format_cost = (row, index) => {
    return (
      <div style={{ width: "100% !important" }}>
        <Input
          name="cost"
          type="number"
          className="ai-input-restock"
          style={{border: "1px solid #BCD2E5", borderRadius:"9px"}}
          onBlur={e => handleProductUpdate(e, index, row.id)}
          value={row.cogs}
          onChange={(e) => {
            const { name, value } = e.target;
            setProduct(prevState => {
              const updatedRows = [...prevState];
              updatedRows[index] = { ...updatedRows[index], [name]: value };
              return updatedRows;
            });
          }}
        />
      </div>
    )
  }

  const format_supplier = (row, index) => {
    return (
      <div style={{ width: "100% !important" }}>
        <Input
          name="supplier"
          type="text"
          className="ai-input-restock"
          style={{border: "1px solid #BCD2E5", borderRadius:"9px"}}
          onBlur={e => handleProductUpdate(e, index, row.id)}
          value={row.supplier}
          onChange={(e) => {
            const { name, value } = e.target;
            setProduct(prevState => {
              const updatedRows = [...prevState];
              updatedRows[index] = { ...updatedRows[index], [name]: value };
              return updatedRows;
            });
          }}
        />
      </div>
    )
  }

  const format_purchaseDate = (row, index) => {
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" }
    return row.purchaseDate ? (
      <div className="d-flex">
        <div>{row.purchaseDate}</div>
        <i
          className="mdi mdi-close ms-2 pointer"
          data-toggle="tooltip"
          title="Remove Purchase Date"
          onClick={e => removePurchaseDate(e, row)}
        ></i>
      </div>
    ) : (
      <input
        type="date"
        name={`date-${index}`}
        id={`date-input-${index}`}
        className="p-1 w-100 tag-wrapper"
        style={{ borderRadius: "5px", border: "1px solid #BCD2E5" }}
        max={new Date().toISOString().split("T")[0]}
        onKeyDown={e => handlePurchaseDate(e, row)}
      />
    )
  }

  const format_condition = (row, index) => {
    return (
      <>
      <Select
      name="condition"
      options={[
          { value: "New", label: "New" },
          { value: "Used", label: "Used" },
          { value: "Collectible", label: "Collectible"},
          { value: "Refurbished", label: "Refurbished"}
      ]}
      /* defaultValue={taxCodeOptions.filter(
        option => option.label === 
      )} */
        defaultValue={[
          { value: "New", label: "New" },
          { value: "Used", label: "Used" },
          { value: "Collectible", label: "Collectible"},
          { value: "Refurbished", label: "Refurbished"}
      ].filter(
          option => option.label === row.condition
        )}
      onChange={e => handleProductUpdateDropdown(e, "condition", row.id)}
      menuPlacement={"bottom"}
      menuPosition={"fixed"}
      width="auto"
      placeholder="Condition"
      isClearable="true"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999, width: "250" }),
        menu: provided => ({ ...provided, zIndex: 9999, width: "250" }),
        control: provided => ({ ...provided, width: "250 !important" }),
        container: provided => ({ ...provided, width: "250 !important" }),
      }}
    />
    </>
    )
  }

  const format_notes = (row, index) => {
    return (
      <div
        style={{ width: "100% !important" }}>
        <Input
          name="notes"
          type="textarea"
          className="ai-input-restock"
          style={{border: "1px solid #BCD2E5", borderRadius:"9px"}}
          onBlur={e => handleProductUpdate(e, index, row.id)}
          value={row.notes || ""}
          onChange={(e) => {
            const { name, value } = e.target;
            setProduct(prevState => {
              const updatedRows = [...prevState];
              updatedRows[index] = { ...updatedRows[index], [name]: value };
              return updatedRows;
            });
          }}
        />
      </div>
    )
  }

  const columns = [
    {
      name: (
        <div className="d-flex flex-column">
          <span data-toggle="tooltip" title={`${hideTooltip}`}>
            <i className="bx bx-info-circle text-primary font-weight-bold me-4 ms-2 h5"></i>
          </span>
          <p className="font-weight-bold mx-1 my-2">Hide</p>
        </div>
      ),
      selector: row => row.active,
      cell: row => format_Hide(row),
      grow: 0,
      width: "70px",
      sortable: true,
      sortField: "isActive",
    },
    {
      name: (
        <div>
          Amazon
          <br />
          Status
        </div>
      ),
      selector: row => row.status,
      cell: row => format_Amazon_STATUS(row),
      grow: 0,
      width: "75px",
      sortable: true,
      sortField: "status",
    },
    {
      name: "Image",
      selector: row => row.mainImage,
      cell: row => format_IMAGE(row),
      grow: 0,
      width: "85px",
    },
    {
      name: "SKU",
      selector: row => row.sku,
      cell: row => format_SKU(row.sku),
      style: {justifyContent: "left"},
      className:"ai-left-align",
      grow: 0,
      width: "100px",
      sortable: true,
      sortField: "sku",
    },
    {
      name: "Asin",
      selector: row => row.asin,
      grow: 0,
      sortable: true,
      sortField: "asin",
    },
    {
      name: "Title",
      selector: row => row.name,
      sortable: true,
      style: {justifyContent: "left"},
      sortField: "itemName",
      className:"ai-left-align",
      cell: row => (
        <div data-toggle="tooltip" data-placement="top" title={`${row.name}`} style={{justifyContent: "left", flexWrap: "wrap", alignContent: "flex-start"}}>
          {`${row.name}`}
        </div>
      ),
      wrap: true,
      grow: 0,
      width: "240px",
    },
    {
      name: (
        <div>
          First
          <br />
          Available
          <br />
          Date
        </div>
      ),
      selector: row => row.firstAvailableDate,
      cell: row =>
        new Date(row.firstAvailableDate).toLocaleDateString(
          "en-US",
          dateOptions
        ),
      grow: 0,
      width: "90px",
      sortable: true,
      sortField: "firstAvailableDate",
    },
    {
      name: "Inventory",
      selector: row => row.inventory,
      grow: 0,
      width: "90px",
      sortable: true,
      sortField: "inventory",
    },
    {
      name: <div>Expiration Date</div>,
      selector: row => row.expiryDate,
      cell: (row, index) => format_expDate(row, index),
      sortable: true,
      sortField: "expiryDate",
      grow: 0,
      width: "100px",
    },
    {
      name: (
        <div className="d-flex align-items-center">
          Tag{" "}
          <span
            data-toggle="tooltip"
            title="You can add maximum 5 tags and minimum 3 characters per tag!"
          >
            <i className="bx bx-info-circle text-primary font-weight-bold mt-2 ms-2 h5"></i>
          </span>
        </div>
      ),
      selector: row => row.Status,
      cell: (row, index) => format_TAGS(row, index),
      minWidth: "350px",
      grow: 1,
    },
    {
      name: <div className="d-flex align-items-center">Tax Code</div>,
      selector: row => row.Status,
      cell: (row, index) => format_TaxCode(row, index),
      minWidth: "200px",
      width: "250px",
      grow: 1,
      sortable: true,
    },
    {
      name: (
        <div>
          Item Hide
          <br />
          Date
        </div>
      ),
      selector: row => row.itemHideDate,
      cell: row =>
        row.itemHideDate !== ""
          ? new Date(row.itemHideDate).toLocaleDateString("en-US", dateOptions)
          : "N/A",
      grow: 0,
      sortable: true,
      sortField: "itemHideDate",
    },
    {
      name: (
        <div>
          List
          <br />
          Price
        </div>
      ),
      selector: row => row.listPrice,
      //   cell: row => (row.listPrice !== "" ? row.listPrice : ""),
      cell: (row, index) => format_listPrice(row, index),
      grow: 0,
      minWidth: "75px",
      sortable: true,
      sortField: "listPrice",
    },
    {
      name: <div>Cost</div>,
      selector: row => row.cogs,
      //   cell: row => (row.cogs !== "" ? row.cogs : ""),
      cell: (row, index) => format_cost(row, index),
      grow: 0,
      minWidth: "75px",
      sortable: true,
      sortField: "cogs",
    },
    {
      name: <div>Supplier</div>,
      selector: row => row.supplier,
      //   cell: row => (row.supplier !== "" ? row.supplier : ""),
      cell: (row, index) => format_supplier(row, index),
      grow: 0,
      sortable: true,
      sortField: "supplier",
    },
    {
      name: (
        <div>
          Purchase
          <br />
          Date
        </div>
      ),
      selector: row => row.purchaseDate,
      //cell: (row) => row.purchaseDate !== "" ? new Date(row.purchaseDate).toLocaleDateString('en-US', dateOptions) : "N/A",
      cell: (row, index) => format_purchaseDate(row, index),
      grow: 0,
      sortable: true,
      minWidth: "85px",
      sortField: "purchaseDate",
    },
    {
      name: <div>Condition</div>,
      selector: row => row.condition,
      //   cell: row => (row.condition !== "" ? row.condition : ""),
      cell: (row, index) => format_condition(row, index),
      grow: 0,
      sortable: true,
      minWidth: "180px",
      sortField: "condition",
    },
    {
      name: <div>Condition Notes</div>,
      selector: row => row.notes,
      //   cell: row => (row.notes !== "" ? row.notes : ""),
      cell: (row, index) => format_notes(row, index),
      grow: 0,
      sortable: true,
      minWidth: "200px",
      sortField: "notes",
    },
    {
      name: <div>Restock Notes</div>,
      selector: row => row.restockNotes,
      cell: row =>row.restockNotes !== ""? row.restockNotes: "",
      grow: 0,
      sortable: false,
      minWidth: "200px",
      sortField: "restockNotes",
    },
  ]

  const changeStatus = row => {
    console.log('asd');
    setRowID(row.id)
    setRowStatus(row.active)
    if (row.inventory > 0 && row.active) {
      console.log('click')
      setHideItemClick(true)
    } else {
      dispatch(
        statusProductTag(
          { productId: row.id, status: row.active },
          response => {
            if (response.data) {
              setManuallyHiddenProduct(prevState => [...prevState, row.id])
              let tempData = [...productOriginal]
              let filteredDataIndex = tempData.findIndex(
                item => item.id === row.id
              )
              let filteredData = tempData[filteredDataIndex]
              filteredData["active"] = !row.active
              productOriginal.splice(filteredDataIndex, 1, filteredData)
              setProduct([...productOriginal])
            }
          },
          errorData => {
            addToast(errorData.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    }
  }

  const confirmHideClick = () => {
    setManuallyHiddenProduct(prevState => [...prevState, rowID])
    setHideItemClick(false)
    dispatch(
      statusProductTag(
        { productId: rowID, status: rowStatus },
        response => {
          if (response.data) {
            let tempData = [...productOriginal]
            let filteredDataIndex = tempData.findIndex(
              item => item.id === rowID
            )
            let filteredData = tempData[filteredDataIndex]
            filteredData["active"] = !rowStatus
            productOriginal.splice(filteredDataIndex, 1, filteredData)
            setProduct([...productOriginal])
          }
        },
        errorData => {
          setHideItemClick(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const cancelHideItem = () => {
    setHideItemClick(false)
  }

  const removeTagClick = (removeId, productId) => {
    setRemoveTag(!removeTag)
    setRemovedTag(removeId)
    setProductId(productId)
  }

  const onDeleteConfirm = () => {
    dispatch(
      removeProductTag(
        { productId, tagId: removedTag },
        () => {
          setRemoveTag(!removeTag)
          setRemovedTag("")
          setProductId("")
          addToast("Tag deleted successfully!", {
            appearance: "success",
            autoDismiss: true,
          })
          getAllDetails()
        },
        errorData => {
          setRemoveTag(!removeTag)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleDownload = () => {
    dispatch(setStartLoader())
    let myHeaders = new Headers()

    myHeaders.append(
      "X-CLIENTMARKETPLACE-ID",
      localStorage.getItem("marketplaceId")
    )
    myHeaders.append(
      "Authorization",
      localStorage.getItem("token_type") +
      " " +
      localStorage.getItem("access_token")
    )
    myHeaders.append("Content-Type", "application/json")

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    let seprator = ""

    //for search
    let search = ""
    if (
      filterValue.title !== "" ||
      filterValue.tag !== "" ||
      filterValue.sku !== "" ||
      searchParam.type !== ""
    ) {
      let filterOthers = []
      Object.keys(filterValue).map(item => {
        if (filterValue[item] !== "") {
          filterOthers.push(`${keyMapping[item]}: ${filterValue[item]}`)
        }
      })
      search = `search = ${filterOthers.join(",")}`
    }
    //for filter
    let filter = ""
    let status = ""
    let filterType = ""
    if (selected.length > 0) {
      let statusFilter = [
        ...selected.filter(item => item.value.toLowerCase().includes("status")),
      ].map(item => item.value)
      let typeFilter = [
        ...selected.filter(item => item.value.toLowerCase().includes("type")),
      ].map(item => item.value)
      status =
        statusFilter.length > 0
          ? `status = ${statusFilter.join(",").replaceAll("status:", "")}`
          : ""
      filterType =
        typeFilter.length > 0
          ? `type = ${typeFilter.join(",").replaceAll("type:", "")}`
          : ""

      let semicolon = ""
      if (status && filterType) {
        semicolon = ";"
      }
      filter = `filter = ${status}${semicolon}${filterType}`
    }

    if (search && filter) {
      seprator = "&"
    }

    fetch(
      BASE_URL+`product/download?${search !== "" ? search : ""
      }${seprator}${filter !== "" ? filter : ""}`,
      requestOptions
    )
      .then(resp => resp.blob())
      .then(blob => {
        dispatch(setStopLoader())
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "Products.xlsx"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        addToast("File downloaded successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(() => {
        dispatch(setStopLoader())
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        })
      })
  }

  const handleUpload = e => {
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append("file", file)
    dispatch(
      uploadProducts(
        formData,
        () => {
          setLoder({ type: "Upload", loader: false })
          getAllDetails()
        },
        errorData => {
          setLoder({ type: "Upload", loader: false })
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
    e.target.value = ""
  }

  const handleUploadInventoryLab = e => {
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append("file", file)
    dispatch(
      importInventoryLabData(
        formData,
        () => {
          setLoder({ type: "Upload", loader: false })
          getAllDetails()
        },
        errorData => {
          setLoder({ type: "Upload", loader: false })
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
    e.target.value = ""
  }

  const handlePageChange = e => {
    setCurrentPage(e)
  }

  const handlePerRowsChange = e => {
    setPerPage(e)
  }

  const handleTitleChange = e => {
    if (["Enter"].includes(e.key) && e.target.value.length >= 3) {
      searchAndFilter(filterValue, "itemName")
    } else if (["Enter"].includes(e.key) && e.target.value.length < 3) {
      addToast("Please enter minimum 3 characters to search", {
        appearance: "info",
        autoDismiss: true,
      })
    }
  }

  const handleSkuChange = e => {
    if (["Enter"].includes(e.key) && e.target.value.length >= 3) {
      searchAndFilter(filterValue, "sku")
    } else if (["Enter"].includes(e.key) && e.target.value.length < 3) {
      addToast("Please enter minimum 3 characters to search", {
        appearance: "info",
        autoDismiss: true,
      })
    }
  }
  const handleFilterBySKU = () => {
    dispatch(
      filterProductsBySku(
        {
          data: {
            query: urlData.isFromUrl ? urlData.sku : filterValue.sku,
            pageNo: currentPage,
            pageSize: 100,
          },
        },
        response => {
          setIsFilterData(true)
          setProduct([...response.data.content])
          setPagination({
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
          })
        },
        errorData => {
          setIsFilterData(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleTagChange = e => {
    if (["Enter"].includes(e.key) && e.target.value.length >= 3) {
      searchAndFilter(filterValue, "tags.name")
    } else if (["Enter"].includes(e.key) && e.target.value.length < 3) {
      addToast("Please enter minimum 3 characters to search", {
        appearance: "info",
        autoDismiss: true,
      })
    }
  }

  const searchAndFilter = (value, type) => {
    //if filter value is not null then call API with search param else call with filter param
    let status = ""
    let filterType = ""
    let sendData = {
      pageNo: currentPage,
      pageSize: perPage,
      sortBy: "updatedTime",
      direction: "DESC",
    }
    if (
      filterValue.title !== "" ||
      filterValue.tag !== "" ||
      filterValue.sku !== ""
    ) {
      setSearchParam({
        type: type || searchParam.type,
        value: value || searchParam.value,
      })
    }
    let filterOthers = []
    Object.keys(filterValue).map(item => {
      if (filterValue[item] !== "") {
        filterOthers.push(`${keyMapping[item]}:${filterValue[item]}`)
      }
    })
    if (selected.length > 0) {
      let statusFilter = [
        ...selected.filter(item => item.value.toLowerCase().includes("status")),
      ].map(item => item.value)
      let typeFilter = [
        ...selected.filter(item => item.value.toLowerCase().includes("type")),
      ].map(item => item.value)
      status =
        statusFilter.length > 0
          ? `status=${statusFilter.join(",").replaceAll("status:", "")}`
          : ""
      filterType =
        typeFilter.length > 0
          ? `type=${typeFilter.join(",").replaceAll("type:", "")}`
          : ""
    }
    if (
      (value !== undefined && type !== undefined) ||
      selected.length > 0 ||
      searchParam.type !== ""
    ) {
      dispatch(
        searchAndFilterData(
          {
            //type and value are coming from onChange event
            //if they are undefined take its value from searchParam
            data: {
              sendData,
              search: filterOthers.join(","),
              status,
              filterType,
            },
          },
          response => {
            setIsFilterData(true)
            setProduct([...response.data.content])
            setProductOriginal([...response.data.content])
            setPagination({
              totalPages: response.data.totalPages,
              totalElements: response.data.totalElements,
            })
          },
          errorData => {
            setIsFilterData(false)
            addToast(errorData.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    }
  }

  const handleRefreshClick = type => {
    setRefreshClicked(!refreshClicked)
    switch (type) {
      case "title":
        setFilterValue(state => {
          return {
            ...state,
            title: "",
          }
        })
        break
      case "sku":
        setFilterValue(state => {
          return {
            ...state,
            sku: "",
          }
        })
        break
      case "tag":
        setFilterValue(state => {
          return {
            ...state,
            tag: "",
          }
        })
        break
      default:
        break
    }
  }

  const handleEntireSheetRefresh = () => {
    setSelected([])
    setPerPage(100)
    setCurrentPage(0)
    setFilterValue({ title: "", sku: "", tag: "" })
    setColumnSort({ column: "", direction: "" })
    setSearchParam({ type: "", value: "" })
  }

  const handleSort = (column, sortDirection) => {
    setColumnSort({ column: column, direction: sortDirection })
    let status = ""
    let filterType = ""
    if (selected.length > 0) {
      let statusFilter = [
        ...selected.filter(item => item.value.toLowerCase().includes("status")),
      ].map(item => item.value)
      let typeFilter = [
        ...selected.filter(item => item.value.toLowerCase().includes("type")),
      ].map(item => item.value)
      status =
        statusFilter.length > 0
          ? `status=${statusFilter.join(",").replaceAll("status:", "")}`
          : ""
      filterType =
        typeFilter.length > 0
          ? `type=${typeFilter.join(",").replaceAll("type:", "")}`
          : ""
    }
    let filterOthers = []
    Object.keys(filterValue).map(item => {
      if (filterValue[item] !== "") {
        filterOthers.push(`${keyMapping[item]}:${filterValue[item]}`)
      }
    })
    dispatch(
      productData(
        {
          data: {
            pageNo: currentPage,
            pageSize: perPage,
            param: column?.sortField,
            direction: sortDirection,
            search: filterOthers.join(","),
            status,
            filterType,
          },
        },
        responseData => {
          setProduct([...responseData.data.content])
          setProductOriginal([...responseData.data.content])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  //for overridding default table style
  const customStyles = {
    table: {
      style: {
        overflowX: "unset !important",
        overflowY: "unset !important",
      },
    },
    tableWrapper: {
      style: {
        overflowX: "unset !important",
        overflowY: "unset !important",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "unset !important",
        overflowY: "unset !important",
      },
    },
  }

  return (
    <React.Fragment>
      {hideItemClick && (
        <SweetAlert
          title={`Are you sure you want to hide this item?`}
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => confirmHideClick()}
          onCancel={() => cancelHideItem()}
        ></SweetAlert>
      )}
      {removeTag && (
        <Modal
          isOpen={removeTag}
          className="w-100 modal-wrapper remove-tag-modal-wrapper"
          backdrop
          style={{
            border: 0,
            backgroundColor: "#082C4E",
            borderRadius: "35px",
          }}
          fullscreen="lg"
          size="lg"
          autoFocus={true}
          toggle={() => <></>}
        >
          <ModalBody 
            style={{
              backgroundColor: "#082C4E",
              border: 0,
              borderRadius: "35px",
            }}>
              <Row className="d-flex justify-content-end w-100 m-0 text-center">
              <div className="ai-modal-close-btn" onClick={() => setRemoveTag(!removeTag)}>
                <div style={{ fontSize: "18px" }}>&#10005;</div>
              </div>
            </Row>
            <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
              <div
                className="text-white"
                style={{ font: "bold 30px novecento-sans" }}
              >
                Are you sure you want to delete tag?
              </div>
            </Row>
            <div className="d-flex justify-content-center col-12">
              <Row className="align-items-center">
                <Col className="d-flex justify-content-center">
                  <Button
                    className={"ai-action-btn-danger btn btn-primary text-white"}
                    onClick={() => onDeleteConfirm()}>
                    Delete
                  </Button>
                  <Button
                    className="ai-action-btn btn btn-primary"
                    color=""
                    onClick={() => setRemoveTag(!removeTag)}>
                    CANCEL
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      )}
      <div className="page-content">
        <MetaTags>
          <title>Inventory Status | Active Inventory</title>
        </MetaTags>

        <TabTitle title="Inventory Status" />
        <div className="shadow-sm col-12 d-flex py-2 mb-3 rounded row bg-purple text-white mx-1 py-2 px-0">
          <div className="d-flex col-lg-10 align-items-center sm-spacing">
            <div className="d-flex w-100">
              <div className="d-flex align-items-center sm-spacing text-white px-1">
                <div>Search&nbsp;by</div>&nbsp;&nbsp;
                <div className="text-white">
                  <InputGroup size="sm" className="d-flex align-items-center input-wrapper">
                    <input
                      type="text"
                      className={"border-1 w-100 border border-light ai-input-radius clear-padding"}
                      value={filterValue.title || ""}
                      readOnly={urlData.isFromUrl}
                      placeholder="Title"
                      onChange={e =>
                        setFilterValue({...filterValue,title: e.target.value,})
                      }
                      onKeyDown={e => handleTitleChange(e)}
                    />
                    {(filterValue.title !== "" && !urlData.isFromUrl) &&
                      <button className="clear-button" onClick={() => handleRefreshClick("title")}>
                        <i className="fas fa-times"></i>
                      </button>
                    }
                  </InputGroup>
                </div>
              </div>
              <div className="d-flex align-items-center sm-spacing text-white px-1">
                <div className="text-white w-100">
                  <InputGroup size="sm" className="d-flex align-items-center input-wrapper">
                    <input
                      type="text"
                      className={"border-1 w-100 border border-light ai-input-radius clear-padding"}
                      value={filterValue.sku || ""}
                      placeholder="SKU/Asin"
                      readOnly={urlData.isFromUrl}
                      onChange={e => {
                        setFilterValue({ ...filterValue, sku: e.target.value })
                      }}
                      onKeyDown={e => handleSkuChange(e)}
                    />
                    {(filterValue.sku !== "" && !urlData.isFromUrl) &&
                      <button className="clear-button" onClick={() => handleRefreshClick("sku")}>
                        <i className="fas fa-times"></i>
                      </button>
                    }
                  </InputGroup>
                </div>
              </div>
              <div className="d-flex align-items-center sm-spacing text-white px-1">
                <div className="text-white w-100">
                  <InputGroup size="sm" className="d-flex align-items-center input-wrapper">
                    <input
                      type="text"
                      className={"border-1 w-100 border border-light ai-input-radius clear-padding"}
                      value={filterValue.tag || ""}
                      readOnly={urlData.isFromUrl}
                      placeholder="Tag Name"
                      onChange={e =>
                        setFilterValue({ ...filterValue, tag: e.target.value })
                      }
                      onKeyDown={e => handleTagChange(e)}
                    />
                    {(filterValue.tag !== "" && !urlData.isFromUrl) &&
                      <button className="clear-button" onClick={() => handleRefreshClick("tag")}>
                        <i className="fas fa-times"></i>
                      </button>
                    }
                  </InputGroup>
                </div>
              </div>
              <div className="d-flex align-items-center sm-spacing text-white px-1">
                <div className="d-flex align-items-center">
                  <span className="text-white">Filter&nbsp;By</span>&nbsp;&nbsp;
                  <MultiSelect
                    className="multiselect-z-index text-dark multiselect-padding multiselect-width ai-input-radius"
                    disableSearch
                    disabled={urlData.isFromUrl}
                    shouldToggleOnHover
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex col-lg-2 align-items-center justify-content-end sm-spacing">
            {urlData.isFromUrl ? null : (
              <div className="text-white d-flex justify-content-between flex-row mt-2 w-100">
                <div
                  id="refresh"
                  name="refresh"
                  className="pointer"
                  title={"Clears searches and filters"}
                  onClick={() => handleEntireSheetRefresh()}
                >
                  <img
                    src={RefreshIcon}
                    title={"Clears searches and filters"}
                    className="inventory-lab-img"
                  />
                </div>
                <div>
                  <label
                    data-toggle="tooltip"
                    title={"To bulk edit Expiration Dates and Tags"}
                    htmlFor="download"
                    className="pointer"
                    onClick={e => handleDownload(e)}
                  >
                    <img
                      src={DownloadIcon}
                      title={"To bulk edit Expiration Dates and Tags"}
                      className="inventory-lab-img"
                    />
                  </label>
                </div>
                <div>
                  <input
                    type="file"
                    id="upload"
                    hidden
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={e => handleUpload(e)}
                    onClick={e => (e.target.value = "")}
                  />
                  <label
                    htmlFor="upload"
                    className="pointer"
                    data-toggle="tooltip"
                    title={"To bulk edit Expiration Dates and Tags"}
                  >
                    <img
                      src={UploadIcon}
                      title={"To bulk edit Expiration Dates and Tags"}
                      className="inventory-lab-img"
                    />
                  </label>
                </div>
                <div>
                  <input
                    type="file"
                    id="uploadInventoryLab"
                    hidden
                    accept=".csv"
                    onChange={e => handleUploadInventoryLab(e)}
                    onClick={e => (e.target.value = "")}
                  />
                  <label
                    htmlFor="uploadInventoryLab"
                    className="pointer"
                    data-toggle="tooltip"
                    title={"To import data from Inventory Lab"}
                  >
                    <img
                      src={InventoryLabLogo}
                      title={"To import data from Inventory Lab"}
                      className="inventory-lab-img"
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column ai-Table">
          <DataTable
            columns={columns}
            data={product}
            pagination
            paginationServer
            paginationComponent={() => (
              <Pagination
                data={product}
                rowsPerPage={perPage}
                currentPage={currentPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={e => handlePerRowsChange(e)}
                paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                rowCount={pagination.totalElements}
              />
            )}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
            paginationTotalRows={pagination.totalElements}
            onChangePage={e => handlePageChange(e)}
            onChangeRowsPerPage={e => handlePerRowsChange(e)}
            fixedHeader
            responsive
            fixedHeaderScrollHeight="60vh"
            onSort={handleSort}
            sortServer
            dense
            persistTableHead
            customStyles={
              filterValue.title !== "" ||
              filterValue.sku !== "" ||
              filterValue.tag !== "" ||
              selected.length > 0
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductReport
