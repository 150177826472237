import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Card,
    CardBody,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import TabTitle from "components/Common/TabTitle"
import DataTable from 'react-data-table-component';
import { getShipmentSheetItem, shippingQueueData } from "store/action/shippingQueue";
import "../../assets/scss/custom/rdt.scss"
import "./shippingQueue.scss"
import Pagination from "../../components/Common/Pagination"

const ShippingQueue = () => {
    const dispatch = useDispatch()
    const { addToast } = useToasts()

    const [shippingQueueInfo, setShippingQueueInfo] = useState([])
    const [viewModal, setViewModal] = useState(false)
    const [perPage, setPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(0)
    const [viewRestockData, setViewRestockData] = useState([])
    const [selectedRow, setSelectedRow] = useState({})
    const [pagination, setPagination] = useState({
        totalPages: 0,
        totalElements: 0
    })
    const [resetPaginationToggle,] = React.useState(false);

    useEffect(() => {
        if (localStorage.getItem("marketplaceId")) {
            fetchAllData()
        }
    }, [localStorage.getItem("marketplaceId"), currentPage, perPage])

    const fetchAllData = () => {
        dispatch(
            shippingQueueData(
                { data: { pageNo: currentPage, pageSize: perPage } },
                responseData => {
                    setShippingQueueInfo([...responseData.data.content])
                    setPagination({
                        totalPages: responseData.data.totalPages,
                        totalElements: responseData.data.totalElements,
                    })
                },
                errorData => {
                    addToast(errorData.response.data.error.displayMessage, {
                        appearance: "error",
                        autoDismiss: true,
                    })
                }
            )
        )
    }

    const getShipmentSheetItemById = (data) => {
        dispatch(
            getShipmentSheetItem(
                { id: data.shipmentId },
                response => {
                    setViewRestockData(response.data)
                },
                errorData => {
                    addToast(errorData.response.data.error.displayMessage, {
                        appearance: "error",
                        autoDismiss: true,
                    })
                }
            )
        )
    }

    const columns = [
        {
            name: 'Shipment Id',
            selector: row => row.shipmentId,
            cell: row => (
                <div>
                    {row.shipmentId}
                </div>
            ),
            sort: true,
            width: "160px"
        },
        {
            name: 'Shipment Name',
            selector: row => row.shipmentName,
            cell: row => (
                <div className="ai-text-center">
                    {row.shipmentName}
                </div>
            ),
            sort: true,
            width: "160px"
        },
        {
            name: "Shipping Queue Date",
            selector: row => row.shippingQueueDate,
            cell: row => (
                <div>
                    {new Date(row.shippingQueueDate).toLocaleDateString("en-US")}
                </div>
            ),
            sort: true,
        },
        {
            name: (<div>Destination Fullfillment Center</div>),
            selector: row => row.destinationFulfillmentCenterId,
            cell: row => (
                <div>
                    {row.destinationFulfillmentCenterId}
                </div>
            ),
            sort: true
        },
        {
            name: 'Total SKU',
            selector: row => row.totalSkuInShipment,
            cell: row => (
                <div>
                    {row.totalSkuInShipment}
                </div>
            ),
            sort: true,
            grow: 0,
            width: "75px"
        },
        {
            name: (
                <>
                    <div style={{ display: "grid", gridTemplateRows: 'auto auto', justifyItems: 'center', justifyContent: 'center' }}>
                        <div className="row1">Total Shipped</div>
                        <div className="row2">Total Received</div>
                    </div>
                </>
            ),
            selector: row => row.totalQtyShipped,
            sort: true,
            cell: row => (
                <>
                    <div style={{ display: "grid", gridTemplateRows: 'auto auto', justifyItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <div className="row1">Shipped:{`${row.totalQtyShipped}`}</div>
                        <div className="row2">Received:{`${row.totalQtyReceived}`}</div>
                    </div>
                </>
            ),
            grow: 1
        },
        {
            name: (<div>Shipment Status</div>),
            sort: true,
            selector: row => row.shipmentStatus,
            cell: row => (
                <div>
                    {row.shipmentStatus}
                </div>
            ),
            width: "80px"
        },
        {
            name: 'View Shipment',
            cell: row => (
                <>
                    <button onClick={() => viewClick(row)} style={{ fontWeight: 'bolder', color: 'red', color: '#2299AA !important', border: 0, backgroundColor: 'transparent', textDecoration: 'underline' }}>View Shipment</button>
                </>
            ),
            grow: 0,
            width: "160px"
        }
    ]


    const modalColumn = [
        {
            name: "SKU",
            selector: row => row.sku,
            sortable: true,
            cell: row => (
                <div>
                    {row.sku}
                </div>
            )
        },
        {
            name: "FN SKU",
            selector: row => row.fnSku,
            sortable: true,
            cell: row => (
                <div>
                    {row.fnSku}
                </div>
            )
        },
        {
            name: "Owner",
            selector: row => row.prepOwner,
            sortable: true,
            cell: row => (
                <div>
                    {row.prepOwner}
                </div>
            )
        },
        {
            name: "Quantity InCase",
            selector: row => row.qtyInCase,
            sortable: true,
            cell: row => (
                <div>
                    {row.qtyInCase}
                </div>
            )
        },
        {
            name: "Quantity Shipped",
            selector: row => row.qtyShipped,
            sortable: true,
            cell: row => (
                <div>
                    {row.qtyShipped}
                </div>
            )
        },
        {
            name: "Quantity Received",
            selector: row => row.qtyReceived,
            sortable: true,
            cell: row => (
                <div>
                    {row.qtyReceived}
                </div>
            )
        },
    ]

    const viewClick = data => {
        setSelectedRow(data)
        getShipmentSheetItemById(data)
        setViewModal(!viewModal)
    }

    const handlePageChange = (e) => {
        setCurrentPage(e)
    };

    const handlePerRowsChange = (e) => {
        setPerPage(e)
    }

    return (
        <React.Fragment>
            {viewModal ? (
                <Modal
                    isOpen={viewModal}
                    size="xl"
                    scrollable
                    className="modal-wrapper"
                    autoFocus={true}
                    toggle={() => <></>}
                    style={{ border: 0, backgroundColor: "#FFFFFF", borderRadius: "35px" }}
                >
                    <ModalBody style={{ backgroundColor: "#FFFFFF", border: 0, borderRadius: "35px" }}>
                        <div className="px-2 ai-modal-header" >
                            <div>{viewModal ? "View" : "Edit"} Details</div>
                            <div>
                                <div className="ai-modal-close-btn-dark" onClick={() => setViewModal(false)}>
                                    <div className="text-white" style={{ fontSize: "18px" }}>&#10005;</div>
                                </div>
                            </div>
                        </div>

                        <div className="px-2 mt-3 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <span style={{ font: "bold 20px nunito-sans", color: "#082C4E" }}>Shipment Name: {selectedRow.shipmentName}</span>
                                <span style={{ font: "bold 20px nunito-sans", color: "#082C4E" }}>Shipment Id: {selectedRow.shipmentId}</span>
                            </div>
                            <div className="my-1 w-100">
                                <div className="ai-Table">
                                    <DataTable
                                        columns={modalColumn}
                                        data={viewRestockData}
                                        persistTableHead
                                        fixedHeader
                                        responsive
                                        fixedHeaderScrollHeight="80vh"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : null}
            <div className="page-content">
                <MetaTags>
                    <title>Shipping Queue | Active Inventory</title>
                </MetaTags>
                <Container fluid>
                    <TabTitle title="Shipping Queue" tooltip="This page shows the shipments sent into Amazon and their current status in the Amazon system.  You can see the Restock Sheet you created for the shipment by clicking  on the icon under View Shipment."/>
                    <div className="ai-Table">
                        <DataTable
                            columns={columns}
                            data={shippingQueueInfo}
                            pagination
                            paginationServer
                            paginationComponent={() => (
                                <Pagination
                                    data={shippingQueueInfo}
                                    rowsPerPage={perPage}
                                    currentPage={currentPage}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                                    paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                                    rowCount={pagination.totalElements} />
                            )}
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[50, 100, 200, 300, 500]}
                            paginationTotalRows={pagination.totalElements}
                            onChangePage={(e) => handlePageChange(e)}
                            onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                            paginationResetDefaultPage={resetPaginationToggle}
                            persistTableHead
                            fixedHeader
                            responsive
                            fixedHeaderScrollHeight="53vh"
                            dense
                        />
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ShippingQueue;
