import {
    API_INVOCATION,
    DASHBOARD_DATA,
    DASHBOARD_DATA_INSIGHT,
    DASHBOARD_FULLFILLMENT,
    SUPER_ADMIN_CREATE_USER,
    SUPER_ADMIN_DASHBOARD_DATA_CLIENT,
    SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME,
    SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS,
    SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES,
    SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING,
    SUPER_ADMIN_DASHBOARD_DATA_YOY,
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

export const getYerOverSale = (resolve, reject) => {
    const payload = {
        action: DASHBOARD_DATA,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.DASHBOARD_DATA,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getFullFillment = (resolve, reject) => {
    const payload = {
        action: DASHBOARD_FULLFILLMENT,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.DASHBOARD_FULLFILLMENT,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getDashboardDataInsight = (resolve, reject) => {
    const payload = {
        action: DASHBOARD_DATA_INSIGHT,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.DASHBOARD_INSIGHT_DATA,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getSuperAdimnYoyData = (resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_DASHBOARD_DATA_YOY,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_YOY,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getSuperAdimnRevenueSubTypesData = (resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_DASHBOARD_DATA_REVENUE_SUB_TYPES,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_REVENUE_SUB_TYPE,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}
export const getSuperAdimnSubscriberEndingData = (resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_DASHBOARD_DATA_SUBSCRIBER_ENDING,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_SUB_ENDING_IN_MONTH,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getSuperAdimnClientData = (resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_DASHBOARD_DATA_CLIENT,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_CLIENT_ALL,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getSuperAdimnClientByStatus = (_payload, resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_STATUS,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_CLIENT_BY_STATUS + `?direction=DESC&isActive=${_payload.isActive}&pageNo=${_payload.currentPage}&pageSize=${_payload.perPage}&sortBy=updatedTime`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getSuperAdimnClientByName = (_payload, resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_DASHBOARD_DATA_CLIENT_BY_NAME,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_CLIENT_BY_NAME + `?direction=DESC&search=${_payload.search}&pageNo=${_payload.currentPage}&pageSize=${_payload.perPage}&sortBy=updatedTime`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const createSuperUser = (_payload, resolve, reject) => {
    const payload = {
        action: SUPER_ADMIN_CREATE_USER,
        method: constdata.POST,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUPER_ADMIN_CREATE_USER,
        resolve,
        reject,
        data: { ..._payload.data }
    }
    return { type: API_INVOCATION, payload }
}