import { RESTOCK_DATA_SUCCESS } from "../actionType"

const INITIAL_STATE = {
  restock: null,
}
const stock = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESTOCK_DATA_SUCCESS:
      return {
        ...state,
        restock: action.payload.data,
      }
    default:
      return state
  }
}
export default stock
