import { BILLING_DATA_SUCCESS, SUBSCRIPTION_PLAN_DATA_SUCCESS } from "../actionType"

const INITIAL_STATE = {
    data: null
}
const Subscription = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PLAN_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
            }
        default:
            return state
    }
}
export default Subscription
