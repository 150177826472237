import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Container,
  Input,
  Form,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
} from "reactstrap"
import { useDispatch } from "react-redux"
import {
  createUsers,
  editUsers,
  getAllUsers,
  getUsersById,
  updateUserStatusData,
  resetPasswordWithEmail,
  getAllUsersWithoutLoader,
  getAllSuperUsersData,
  getSuperUsersById,
} from "store/action/user"
import SweetAlert from "react-bootstrap-sweetalert"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import TabTitle from "components/Common/TabTitle"
import DataTable from "react-data-table-component"
import "../../assets/scss/custom/rdt.scss"
import "../../assets/scss/style.scss"
import "./users.scss"
import { SUPERADMIN } from "utils/constants"
import Switch from "react-switch"
import Pagination from "../../components/Common/Pagination"
import UpArrow from "../../assets/images/up-arrow.svg"
import NoProfilePhoto from "../../assets/images/no-photo.svg"
import { DOWNLOAD_PROFILE_PHOTO } from "../../utils/url"

const Users = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const passwordRegExp =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
  const [selectedUser, setSelectedUser] = useState({})
  const [formValues, setFormValues] = useState({})
  const [permissions, setPermissions] = useState(null)
  const [userStatus, setUserStatus] = useState(-1)
  const [editUserClicked, setEditUserClicked] = useState(false)
  const [addUserClicked, setAddUserClicked] = useState(false)
  const [userId, setUserId] = useState("")
  const [users, setUsers] = useState([])
  const [validPermission, setValidPermission] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [filterUsers, setFilterUsers] = useState([])
  const [resetPaginationToggle] = useState(false)
  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalElements: 0
  })
  const [perPage, setPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(0)
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] =
    useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisibility(!repeatPasswordVisibility)
  }

  useEffect(() => {
    localStorage.getItem("role") === SUPERADMIN
      ? getAllSuperUsers()
      : getAllClientUsers()
  }, [])

  const getAllClientUsers = () => {
    dispatch(
      getAllUsers(
        responseData => {
          setUsers([...responseData.data])
          setFilterUsers([...responseData.data])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handlePageChange = (e) => {
    setCurrentPage(e)
  };

  const getAllSuperUsers = () => {
    dispatch(
      getAllSuperUsersData(
        responseData => {
          setUsers([...responseData.data])
          setFilterUsers([...responseData.data])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  useEffect(() => {
    let formValues = {}
    setPermissions(JSON.parse(localStorage.getItem("pagepermitted")))
    Object.keys(JSON.parse(localStorage.getItem("pagepermitted"))).map(
      permission => {
        formValues[`${permission}`] = "WRITE"
        formValues[`${permission}Visible`] = permission !== "DASHBOARD"
      }
    )
    setFormValues(formValues)
  }, [localStorage.getItem("pagepermitted")])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: editUserClicked || addUserClicked ? false : true,
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      phone: "",
      ...formValues,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      firstname: Yup.string().required("Please Enter Firstname"),
      lastname: Yup.string().required("Please Enter Lastname"),
      password: !editUserClicked && Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter Password")
        .matches(passwordRegExp,"Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case."),
      confirmPassword: !editUserClicked && Yup.string()
        .oneOf([Yup.ref("password"), null], "Password & Confirm Password must match")
        .required("Please enter Confirm Password")
        .matches(passwordRegExp,"Confirm Password must be contain one Special Character, One Numeric, One Upper Case, One Lower Case.")
    }),
    onSubmit: values => {
      console.log('inside onsubmit')
      const signUpObj = {
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        password: values.password,
        phoneNumber: values.phone,
        pagePermission: {},
      }
      if (editUserClicked) {
        signUpObj.address1 = "NA"
        delete signUpObj.password
      }
      if (editUserClicked) {
        Object.entries(formValues).map(item => {
          if ((item[0] === "DASHBOARDVisible" && item[1] === true) || (item[0] === "RESTOCK_SHEETVisible" && item[1] === true)) {
            setValidPermission(true)
          }
        })
        Object.keys(formValues).map(value => {
          if (!value.includes("Visible")) {
            signUpObj["pagePermission"][`${value}`] = {
              visible: formValues[`${value}Visible`],
              access: formValues[value],
            }
          }
        })
        //if (validPermission) {
          setDisableButton(true)
          dispatch(
            editUsers(
              { data: { ...signUpObj, userId: userId } },
              response => {
                setDisableButton(false)
                validation.resetForm()
                setEditUserClicked(false)
                dispatch(
                  getAllUsersWithoutLoader(
                    responseData => {
                      addToast(response.data, {
                        appearance: "success",
                        autoDismiss: true,
                      })
                      setUsers([...responseData.data])
                      setFilterUsers([...responseData.data])
                    },
                    errorData => {
                      setDisableButton(false)
                      addToast(errorData.response.data.error.displayMessage, {
                        appearance: "error",
                        autoDismiss: true,
                      })
                    }
                  )
                )
              },
              error => {
                addToast(error.response.data.error.displayMessage, {
                  appearance: "error",
                  autoDismiss: true,
                })
              }
            )
          )
        //}
      } else {
        Object.entries(formValues).map(item => {
          if (
            (item[0] === "DASHBOARDVisible" && item[1] === true) ||
            (item[0] === "RESTOCK_SHEETVisible" && item[1] === true)
          ) {
            setValidPermission(true)
          }
        })
        Object.keys(formValues).map(value => {
          if (!value.includes("Visible")) {
            signUpObj["pagePermission"][`${value}`] = {
              visible: formValues[`${value}Visible`],
              access: formValues[value],
            }
          }
        })
        //if (validPermission) {
          setDisableButton(true)
          dispatch(
            createUsers(
              { data: { ...signUpObj } },
              response => {
                setDisableButton(false)
                setAddUserClicked(false)
                validation.resetForm()
                localStorage.getItem("role") === SUPERADMIN
                  ? getAllSuperUsers()
                  : getAllClientUsers()
              },
              error => {
                setDisableButton(false)
                setAddUserClicked(true)
                addToast(error.response.data.error.displayMessage, {
                  appearance: "error",
                  autoDismiss: true,
                })
              }
            )
          )
        //}
      }
    },
  })

  const format_action = row => {
    return (
      <div className="p-0 d-flex flex-row justify-content-between align-items-center w-100">
        {localStorage.getItem("role") !== SUPERADMIN && (
          <div
            data-toggle="tooltip"
            title={"Reset Password"}
            className="cursor-pointer mx-2 p-0"
            onClick={() => resetPassword(row)}
          >
            <i className="mdi mdi-lock-reset h3 p-0 pointer"></i>
          </div>
        )}
        <div
          data-toggle="tooltip"
          title={"Edit User"}
          className="cursor-pointer mx-2 p-0"
          onClick={() => editUser(row)}
        >
          <i className="mdi mdi-account-edit-outline h3 p-0 pointer"></i>
        </div>
      </div>
    )
  }

  const resetPassword = row => {
    dispatch(
      resetPasswordWithEmail(
        { data: { email: row.email } },
        response => {
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        error => {
          addToast(error.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const getClientUserById = userId => {
    dispatch(
      getUsersById(
        { data: { userId } },
        response => {
          setUserId(userId)
          const resp = response.data
          let tempFormValues = { ...formValues }
          Object.keys(resp?.pagePerm).map(item => {
            tempFormValues[`${item}`] = resp?.pagePerm[item].access
            tempFormValues[`${item}Visible`] = resp?.pagePerm[item].visible
          })
          setFormValues({ ...tempFormValues })
          validation.setFieldValue("firstname", resp.firstName)
          validation.setFieldValue("lastname", resp.lastName)
          validation.setFieldValue("email", resp.email)
          validation.setFieldValue("phone", resp.phoneNumber)
          setEditUserClicked(true)
          console.table(formValues);
        },
        error => {
          addToast(error.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }
  const getSuperUserById = userId => {
    dispatch(
      getSuperUsersById(
        { data: { userId } },
        response => {
          setUserId(userId)
          const resp = response.data
          // let tempFormValues = { ...formValues }
          // Object.keys(resp?.pagePerm).map(item => {
          //     tempFormValues[`${item}`] = resp?.pagePerm[item].access
          //     tempFormValues[`${item}Visible`] = resp?.pagePerm[item].visible
          // })
          // setFormValues({ ...tempFormValues })
          validation.setFieldValue("firstname", resp.firstName)
          validation.setFieldValue("lastname", resp.lastName)
          validation.setFieldValue("email", resp.email)
          validation.setFieldValue("phone", resp.phoneNumber)
          setEditUserClicked(true)
        },
        error => {
          addToast(error.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const format_status = row => {
    return (
      <span
        className="w-75"
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          fontSize: 13,
          paddingRight: 2,
        }}
      >
        <Switch
          id="material-switch"
          className="react-switch"
          checked={row.active}
          onChange={() => updateUserStatus(row, Number(row.active) ? 0 : 1)}
          onColor="#00FF00"
          offColor="#FF0000"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={50}
          title={Number(row.active) ? "Active" : "Inactive"}
        />
        <span>
          {row.active ? (
            <span
              className="px-2"
              style={{ color: "green", fontWeight: "bold" }}
            >
              Active
            </span>
          ) : (
            <span className="px-2" style={{ color: "red", fontWeight: "bold" }}>
              InActive
            </span>
          )}
        </span>
      </span>
    )
  }

  const editUser = row => {
    const userId = row.id
    localStorage.getItem("role") === SUPERADMIN
      ? getSuperUserById(userId)
      : getClientUserById(userId)
  }

  const updateUserStatus = (row, status) => {
    setSelectedUser(row)
    setUserId(row.id)
    setUserStatus(status)
  }

  const columns = [
    {
      name: "Profile Photo",
      selector: row => row.firstName,
      sortable: true,
      cell: row => <div className="rounded-circle media user-name" style={{ width: '60px !important', height: "60px", margin: "10px" }}>
        <img src={"" !== row.profileImage ? DOWNLOAD_PROFILE_PHOTO + row.profileImage : NoProfilePhoto} alt="profile" style={{ width: "60px" }} />
      </div>,
    },
    {
      name: "First Name",
      selector: row => row.firstName,
      sortable: true,
      cell: row => <div>{row.firstName}</div>,
    },
    {
      name: "Last Name",
      selector: row => row.lastName,
      sortable: true,
      cell: row => <div>{row.lastName}</div>,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
      cell: row => <div>{row.email}</div>,
    },
    {
      name: "Status",
      selector: row => row.active,
      cell: row => format_status(row),
    },
    {
      name: "Actions",
      cell: row => format_action(row),
    },
  ]

  const cancelEdit = () => {
    setEditUserClicked(false)
    validation.resetForm()
  }

  const cancelAddUser = () => {
    setAddUserClicked(false)
    validation.resetForm()
  }

  const confirmUpdateUserStatus = () => {
    dispatch(
      updateUserStatusData(
        { data: { userId, userStatus } },
        response => {
          setUserStatus(-1)
          dispatch(
            getAllUsers(
              responseData => {
                setUserStatus(-1)
                setUsers([...responseData.data])
                setFilterUsers([...responseData.data])
                addToast(response.data, {
                  appearance: "success",
                  autoDismiss: true,
                })
              },
              errorData => {
                addToast(errorData.response.data.error.displayMessage, {
                  appearance: "error",
                  autoDismiss: true,
                })
              }
            )
          )
        },
        error => {
          addToast(error.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const setFormValuesFun = (permission, value) => {
    let tempFormValues = { ...formValues }
    tempFormValues[permission] = value
    setFormValues({ ...tempFormValues })
  }

  const onChange = async e => {
    let userOrignalData = [...filterUsers]
    var searchData = userOrignalData.filter(user => {
      if (
        user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        user.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        user.email.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return user
      }
    })
    setUsers(searchData)
  }

  return (
    <React.Fragment>
      {(1 == userStatus || 0 == userStatus) && (
        <SweetAlert
          title={`Are you sure you want to mark User: ${selectedUser.email
            } as ${userStatus == 1 ? "active" : "inactive"}?`}
          warning
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => confirmUpdateUserStatus()}
          onCancel={() => setUserStatus(-1)}
        ></SweetAlert>
      )}
      {editUserClicked ? (
        <Modal
          isOpen={editUserClicked}
          size="lg"
          className="w-100 modal-wrapper"
          style={{
            border: 0,
            backgroundColor: "#082C4E",
            borderRadius: "35px",
          }}
        >
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <ModalBody
              style={{
                backgroundColor: "#082C4E",
                border: 0,
                borderRadius: "35px",
              }}
            >
              <Col className="col-12">
                <div className="account-pages">
                  <Row className="d-flex justify-content-end w-100 m-0 text-center">
                    <div
                      className="ai-modal-close-btn"
                      onClick={() => cancelEdit()}
                    >
                      <div style={{ fontSize: "18px" }}>&#10005;</div>
                    </div>
                  </Row>
                  <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
                    <div
                      className="text-white"
                      style={{ font: "bold 40px novecento-sans" }}
                    >
                      EDIT USER
                    </div>
                  </Row>
                  <Row className="d-flex justify-content-center w-100 m-0 row">
                    <Col md={12}>
                      <Row className="pb-3">
                        <Col>
                          <Input
                            id="firstname"
                            name="firstname"
                            type="text"
                            className="ai-adduser-input"
                            placeholder="First Name*"
                            onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            value={validation.values.firstname}
                            invalid={
                              validation.errors.firstname ? true : false
                            }
                          />
                          {validation.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Input
                            id="lastname"
                            name="lastname"
                            type="text"
                            className="ai-adduser-input"
                            placeholder="Last Name*"
                            onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            value={validation.values.lastname}
                            invalid={
                              validation.errors.lastname ? true : false
                            }
                          />
                          {validation.errors.lastname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="pb-3">
                        <Col>
                          <Input
                            id="email"
                            name="email"
                            className="ai-adduser-input"
                            type="email"
                            placeholder="Email*"
                            onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.errors.email ? true : false}
                          />
                          {validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="pb-4">
                        <Col>
                          <Input
                            name="phone"
                            type="text"
                            placeholder="Phone (Optional)"
                            className="ai-adduser-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                          />
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-center text-center w-100 pt-3">
                        <h5 className="text-white">USER ACCESS SETTINGS</h5>
                      </Row>
                      <Row className="d-flex justify-content-center text-center w-100 pt-3">
                        <Col xs={10} md={12} lg={10}>
                          {permissions &&
                            Object.keys(permissions).map((permission, key) => {
                              return (
                                <Row
                                  className="d-flex justify-content-center align-items-center"
                                  key={key}
                                >
                                  {
                                    <>
                                      <Col xs={4} md={5} lg={4} className="text-white" style={{ textAlign: "end", paddingRight: "5%" }}>
                                        {permissions[permission].pageName}
                                      </Col>
                                      <Col xs={3} md={3} lg={2} className="d-flex justify-content-center text-white 2">
                                        {console.log(`${permission}`, formValues[`${permission}`])}
                                        {permission === "DASHBOARD" || permission === "PRODUCT_OOS" || permission === "SHIPPING_QUEUE" ? null : (
                                          <>
                                            <input
                                              type="radio"
                                              className="form-check-Input m-1"
                                              name={permission}
                                              id={`${permission}Edit`}
                                              onChange={e => { }}
                                              onClick={() =>
                                                setFormValuesFun(permission, "WRITE")
                                              }
                                              value="WRITE"
                                              checked={
                                                formValues[`${permission}`] ===
                                                "WRITE"
                                              }
                                            />
                                            Edit/View
                                          </>
                                        )}
                                      </Col>
                                      <Col xs={3} md={2} lg={2} className="d-flex justify-content-center text-white 3">
                                        <input
                                          type="radio"
                                          className="form-check-Input m-1"
                                          name={permission}
                                          id={`${permission}View`}
                                          onChange={e => { }}
                                          onClick={() =>
                                            setFormValuesFun(permission, "READ")
                                          }
                                          value="READ"
                                          checked={
                                            formValues[`${permission}`] === "READ"
                                          }
                                        />
                                        View
                                      </Col>

                                      <Col xs={3} md={2} lg={2} className="d-flex justify-content-center text-white 3">
                                        <input
                                          type="radio"
                                          className="form-check-Input m-1"
                                          name={permission}
                                          id={`${permission}Visible`}
                                          onChange={e => { }}
                                          //onClick={() => setFormValues({...formValues,[`${permission}Visible`]:!formValues[`${permission}Visible`],})}
                                          onClick={() => setFormValuesFun(permission, "NONE")}
                                          checked={
                                            formValues[`${permission}`] === "NONE"
                                          }
                                        />
                                        {
                                          validation.values[
                                          `${permission}Visible`
                                          ]
                                        }
                                        None
                                      </Col>
                                    </>
                                  }
                                  <hr style={{ border: "1px solid #2299AA", margin: "5px", }} />
                                </Row>
                              )
                            }
                            )}
                        </Col>
                      </Row>
                      <Row className="text-center mt-3">
                        <Col>
                          <p className="mb-3 text-danger">
                            Note: Dashboard contains sensitive financial
                            information users may see if you choose view
                          </p>
                        </Col>
                      </Row>
                      <div
                        className="d-flex justify-content-center pb-3 pt-4"
                        style={{
                          flexFlow: "row wrap",
                          gridTemplateColumns: "auto auto",
                          gap: "20px",
                        }}
                      >
                        <div className="text-center">
                          <button
                            style={{
                              width: "250px",
                              minWidth: "150px",
                              height: "45px",
                              borderRadius: "25px",
                              color: "#FFFFFF",
                              backgroundColor: "#2299AA",
                              border: "0",
                              font: "bold 18px novecento-sans",
                            }}
                            type="submit"
                            disabled={disableButton}
                          >
                            {disableButton && (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm mx-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Loading...</span>
                              </>
                            )}
                            {disableButton ? "SAVING..." : "SAVE USER"}
                          </button>

                        </div>
                        <div className="text-center">
                          <button
                            style={{
                              width: "250px",
                              minWidth: "150px",
                              height: "45px",
                              borderRadius: "25px",
                              color: "#FFFFFF",
                              backgroundColor: "#FE6571",
                              border: "0",
                              font: "bold 18px novecento-sans",
                            }}
                            onClick={() => cancelEdit()}
                            type="button"
                          >
                            CANCEL
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </ModalBody>
          </Form>
        </Modal>
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>
            {localStorage.getItem("role") === SUPERADMIN
              ? "Super Users"
              : "Users"}{" "}
            Page | Active Inventory
          </title>
        </MetaTags>
        <Container fluid>
          <TabTitle
            title={
              localStorage.getItem("role") === SUPERADMIN
                ? "Super Users"
                : "Users"
            }
          />
          <div
            className="shadow-sm col-12 d-flex align-items-center row table-filter text-white mb-3"
            style={{ height: "70px" }}
          >
            <div className="d-flex align-items-center sm-spacing text-white px-3 col-7">
              <div
                className="pl-3"
                style={{ fontFamily: "nunito-sans", fontSize: "15px" }}
              >
                Search by:
              </div>
              &nbsp;&nbsp;
              {/* <span className="text-white">Title</span>&nbsp;&nbsp; */}
              <div>
                <InputGroup size="sm" className="d-flex align-items-center">
                  <input
                    type="search"
                    className={"border-1 border border-light pl-3"}
                    style={{
                      width: "400px",
                      height: "36px",
                      borderRadius: "25px",
                    }}
                    onChange={onChange}
                    placeholder="Name/Email"
                  />
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-end col-5">
              <div className="unlimited-profile-container">
                <span style={{ font: "normal normal normal 25px verveine", textAlign: "center" }}>Unlimited User <br />Profiles Available!</span>
                <img src={UpArrow} className="inventory-lab-img" />
                <Button
                  className="ai-action-btn btn btn-primary"
                  color=""
                  onClick={() => {
                    setAddUserClicked(!addUserClicked),
                      setPasswordVisibility(false),
                      setRepeatPasswordVisibility(false)

                      let formValues = {}
                      Object.keys(permissions).map(
                        permission => {
                          formValues[`${permission}`] = "READ"
                        }
                      )
                      setFormValues(formValues)
                  }}
                >
                  ADD USER<span className="mdi mdi-18px mdi-plus"></span>
                </Button>
              </div>
            </div>
          </div>
          <div className="ai-Table">
            <DataTable
              columns={columns}
              data={users}
              fixedHeader
              responsive
              fixedHeaderScrollHeight="55vh"
              pagination
              paginationPerPage={100}
              paginationTotalRows={users.length}
              paginationRowsPerPageOptions={[50, 100, 200, 300, 500]}
              paginationResetDefaultPage={resetPaginationToggle}
              persistTableHead
              paginationComponent={() => (
                <Pagination
                  data={users}
                  rowsPerPage={perPage}
                  currentPage={currentPage}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={e => handlePerRowsChange(e)}
                  paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                  rowCount={users.length}
                />
              )}
            />
          </div>
          {addUserClicked && (
            <Modal
              isOpen={addUserClicked}
              size="lg"
              className="w-100 modal-wrapper"
              style={{
                border: 0,
                backgroundColor: "#082C4E",
                borderRadius: "35px",
              }}
            >
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  console.log('submit')
                  validation.handleSubmit()
                  return false
                }}
                autoComplete="off"
              >
                <ModalBody
                  style={{
                    backgroundColor: "#082C4E",
                    border: 0,
                    borderRadius: "35px",
                  }}
                >
                  <Col className="col-12">
                    <div className="account-pages">
                      <Row className="d-flex justify-content-end w-100 m-0 text-center">
                        <div
                          className="ai-modal-close-btn"
                          onClick={() => cancelAddUser()}
                        >
                          <div style={{ fontSize: "18px" }}>&#10005;</div>
                        </div>
                      </Row>
                      <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
                        <div
                          className="text-white"
                          style={{
                            color: "#FFEE00 !important",
                            font: "bold 80px novecento-sans",
                          }}
                        >
                          +
                        </div>
                        <div
                          className="text-white"
                          style={{ font: "bold 40px novecento-sans" }}
                        >
                          ADD USER
                        </div>
                      </Row>
                      <Row className="d-flex justify-content-center w-100 m-0">
                        <Col md={12}>
                          <Row className="pb-3">
                            <Col>
                              <Input
                                id="firstname"
                                name="firstname"
                                type="text"
                                className="ai-adduser-input"
                                placeholder="First Name*"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstname}
                                invalid={
                                  validation.touched.firstname &&
                                    validation.errors.firstname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstname &&
                                validation.errors.firstname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </Col>
                            <Col>
                              <Input
                                id="lastname"
                                name="lastname"
                                type="text"
                                className="ai-adduser-input"
                                placeholder="Last Name*"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastname}
                                invalid={
                                  validation.touched.lastname &&
                                    validation.errors.lastname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastname &&
                                validation.errors.lastname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="pb-3">
                            <Col>
                              <Input
                                id="email"
                                name="email"
                                className="ai-adduser-input"
                                placeholder="Email*"
                                type="email"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="pb-4">
                            <Col>
                              <Input
                                name="phone"
                                type="text"
                                rows="4"
                                className="ai-adduser-input"
                                onChange={validation.handleChange}
                                placeholder="Phone (Optional)"
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                              />
                            </Col>
                          </Row>
                          <Row className="pb-3">
                            <Col>
                                <Input
                                  id="password"
                                  name="password"
                                  type={
                                    passwordVisibility ? "text" : "password"
                                  }
                                  autoComplete="new-password"
                                  className="ai-register-input ai-adduser-input fw-bold"
                                  placeholder="Password*"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  invalid={
                                    validation.touched.password &&
                                      validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                <button
                                  type="button"
                                  id="btnToggle"
                                  style={{ width: "20px", height: "14px", top: "50%", right:"15px", transform:"translateY(-50%)" }}
                                  className={
                                    passwordVisibility ? "eye-icon open" : "eye-icon"
                                  }
                                  onClick={() => togglePasswordVisibility()}
                                ></button>
                                {/* <InputGroupText
                                    className="pointer"
                                    onClick={() => togglePasswordVisibility()}
                                  >
                                    <span className="m-auto border">
                                      <i
                                        className={
                                          passwordVisibility
                                            ? "mdi mdi-eye"
                                            : "mdi mdi-eye-off"
                                        }
                                      ></i>
                                    </span>
                                  </InputGroupText> */}
                                {validation.touched.password &&
                                  validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                            </Col>
                          </Row>
                          <Row className="pb-3">
                            <Col>
                                <Input
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  type={
                                    repeatPasswordVisibility
                                      ? "text"
                                      : "password"
                                  }
                                  autoComplete="new-password"
                                  className="ai-register-input ai-adduser-input fw-bold"
                                  placeholder="Confirm Password*"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.confirmPassword || ""
                                  }
                                  invalid={
                                    validation.touched.confirmPassword &&
                                      validation.errors.confirmPassword
                                      ? true
                                      : false
                                  }
                                />
                                
                                <button
                                  type="button"
                                  id="btnToggle"
                                  style={{ width: "20px", height: "14px", top: "50%", right:"15px", transform:"translateY(-50%)" }}
                                  className={
                                    repeatPasswordVisibility ? "eye-icon open" : "eye-icon"
                                  }
                                  onClick={() => toggleRepeatPasswordVisibility()}
                                  />
                                {/* <InputGroupText
                                    className="pointer"
                                    onClick={() =>
                                      toggleRepeatPasswordVisibility()
                                    }
                                  >
                                    <span className="m-auto border">
                                      <i
                                        className={
                                          repeatPasswordVisibility
                                            ? "mdi mdi-eye"
                                            : "mdi mdi-eye-off"
                                        }
                                      ></i>
                                    </span>
                                  </InputGroupText> */}
                                {validation.touched.confirmPassword &&
                                  validation.errors.confirmPassword ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confirmPassword}
                                  </FormFeedback>
                                ) : null}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-center text-center w-100 pt-3">
                            <h5 className="text-white">USER ACCESS SETTINGS</h5>
                          </Row>
                          <Row className="d-flex justify-content-center text-center w-100 pt-3">
                            <Col xs={10} md={12} lg={10}>
                              {permissions &&
                                Object.keys(permissions).map(
                                  (permission, key) => {
                                    return (
                                      <Row
                                        className="d-flex justify-content-center align-items-center"
                                        key={key}
                                      >
                                        {
                                          <>
                                            <Col xs={4} md={5} lg={4} className="text-white" style={{ textAlign: "end", paddingRight: "5%" }}>
                                              {permissions[permission].pageName}
                                            </Col>
                                            <Col xs={3} md={3} lg={2} className="d-flex justify-content-center text-white 2">
                                              {permission === "DASHBOARD" || permission === "PRODUCT_OOS" || permission === "SHIPPING_QUEUE" ? null : (
                                                <>
                                                  <input
                                                    type="radio"
                                                    className="form-check-Input m-1"
                                                    name={permission}
                                                    id={`${permission}Edit`}
                                                    onChange={e => { }}
                                                    onClick={() => setFormValuesFun(permission, "WRITE")}
                                                    value="WRITE"
                                                    checked={formValues[`${permission}`] === "WRITE"}
                                                  />
                                                  Edit/View
                                                </>
                                              )}
                                            </Col>
                                            <Col xs={3} md={2} lg={2} className="d-flex justify-content-center text-white 3">
                                              <input
                                                type="radio"
                                                className="form-check-Input m-1"
                                                name={permission}
                                                id={`${permission}View`}
                                                onChange={e => { }}
                                                onClick={() => setFormValuesFun(permission, "READ")}
                                                value="READ"
                                                checked={formValues[`${permission}`] === "READ"}
                                              />
                                              View
                                            </Col>
                                            <Col xs={2} md={2} lg={2} className="d-flex justify-content-center text-white 4">
                                              <input
                                                type="radio"
                                                className="form-check-Input m-1"
                                                name={permission}
                                                id={`${permission}Visible`}
                                                onChange={e => { }}
                                                //onClick={() =>setFormValues({...formValues,[`${permission}Visible`]:!formValues[`${permission}Visible`],})}
                                                onClick={() => setFormValuesFun(permission, "NONE")}
                                                checked={formValues[`${permission}`] === "NONE"}
                                              />
                                              {
                                                validation.values[
                                                `${permission}Visible`
                                                ]
                                              }
                                              None
                                            </Col>
                                          </>
                                        }
                                        <hr style={{ border: "1px solid #2299AA", margin: "5px", }} />
                                      </Row>
                                    )
                                  }
                                )}
                            </Col>
                          </Row>
                          <Row className="text-center mt-3">
                            <Col>
                              <p className="mb-3 text-danger">
                                Note: Dashboard contains sensitive financial
                                information users may see if you choose view
                              </p>
                            </Col>
                          </Row>
                          <div
                            className="d-flex justify-content-center pb-3 pt-4"
                            style={{
                              flexFlow: "row wrap",
                              gridTemplateColumns: "auto auto",
                              gap: "20px",
                            }}
                          >
                            <div className="text-center">
                              <button
                                style={{
                                  width: "250px",
                                  minWidth: "150px",
                                  height: "45px",
                                  borderRadius: "25px",
                                  color: "#FFFFFF",
                                  backgroundColor: "#2299AA",
                                  border: "0",
                                  font: "bold 18px novecento-sans",
                                }}
                                type="submit"
                                disabled={disableButton}
                              >
                                {disableButton && (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm mx-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </>
                                )}
                                {disableButton
                                  ? "CREATING USER..."
                                  : "CREATE USER"}
                              </button>
                            </div>
                            <div className="text-center">
                              <button
                                style={{
                                  width: "250px",
                                  minWidth: "150px",
                                  height: "45px",
                                  borderRadius: "25px",
                                  color: "#FFFFFF",
                                  backgroundColor: "#FE6571",
                                  border: "0",
                                  font: "bold 18px novecento-sans",
                                }}
                                onClick={() => cancelAddUser()}
                                type="button"
                              >
                                CANCEL
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </ModalBody>
              </Form>
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
