import React, { useState } from "react"
import {
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import "./restockPageExplained.scss"
const RestockExplained = () => {
  const [open, setOpen] = useState("")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content main-wrapper text-center">
        <Row className="d-flex justify-content-center">
          <Col>
            <h1 className="heading fw-bold mb-3" style={{textAlign: "center" ,font: "normal normal bold 35px/58px novecento-sans", letterSpacing: "0.48px", color: "#082C4E", textTransform: "uppercase", opacity: 1}}>Restock Sheet Explained</h1>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="text-left" xs="11" md="11" lg="11 ">
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="0">
                  <h4 className="ai-static-bold-text fw-bold m-0">Status Colors</h4>
                </AccordionHeader>
                <AccordionBody accordionId="0">
                <ol className="h5 my-2 pl-4 ai-static-text">
                  <li className="my-1 ai-static-text">Red &ndash; Close to or Out of Stock</li>
                  <li className="my-1 ai-static-text">Yellow &ndash; Needs to be restocked</li>
                  <li className="my-1 ai-static-text">
                    Green &ndash; No restock needed at this time
                  </li>
                  <li className="my-1 ai-static-text">Grey&ndash; Overstocked</li>
                  <li className="my-1 ai-static-text">Blue &ndash; New Item / No Sales Yet</li>
                  <li className="my-1 ai-static-text">White &ndash; Limited sales</li>
                </ol>
                <Row className="my-3">
                  <h4 className="ai-static-bold-text fw-bold m-0">Hide</h4>
                  <h5 className="ai-static-text">
                    Move button to right to hide this item. To unhide the item you
                    will need to go under the “Inventory” tab and go to the
                    “Inventory Status” page. Useful when you will no longer be
                    sending stock of an item.
                  </h5>
                </Row>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="1">
                  <h4 className="ai-static-bold-text fw-bold m-0">FBM SKU</h4>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Fulfilled by Merchant Stock Keeping Unit - This value is
                      the identifier for products that will be sent by a
                      merchant to a customer. FBM SKU&apos;s are created by the
                      seller.
                    </li>
                    <li className="my-2  ai-static-text">
                      ASIN - Amazon Standard Identification Number
                    </li>
                    <li className="my-2 ai-static-text">
                      Product Name from the Amazon listing.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">
                  <h4 className="ai-static-bold-text fw-bold m-0">FBA SKU</h4>
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Fulfilled by Amazon Stock Keeping Unit &ndash; This is the
                      Amazon code for items sent to their warehouses. This is
                      used to manage the physical inventory in the Amazon
                      System. SKU can be seen as the product offering (ie. how
                      it will be shipped and the pricing for the product). FBA
                      SKU’s are created by the seller and should be unique for
                      each item.
                    </li>
                    <li className="my-2 ai-static-text">
                      Product Image &ndash; Thumbnail of the main image of the
                      product.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">
                  <h4 className="ai-static-bold-text fw-bold m-0">Category</h4>
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      The Amazon Category that this unique product is being
                      grouped in the Amazon system.
                    </li>
                    <li className="my-2 ai-static-text">
                      Rank &ndash; (Also known as Amazon Best Sellers Rank) This
                      is a metric that indicates how this product sells in
                      relationship to other products in the category. The lower
                      the number the better. Each category will have a range
                      that will suggest good product turnover.
                    </li>
                    <ol type="A">
                      <li className="my-2 ai-static-text">
                        Rank Resource: See the following link for a U.S. Amazon
                        Sales Rank Chart &ndash; it is updated monthly{" "}
                        <a href="http://cleartheshelf.com/amazon-sales-rank-chart/">
                          http://cleartheshelf.com/amazon-sales-rank-chart/{" "}
                        </a>
                      </li>
                      <li className="my-2 ai-static-text">
                        You will need to decide what level of sales rank you
                        will want to work with depending on the categories. The
                        lower the rank, the more competition you will most
                        likely encounter. Higher ranks may have less competition
                        but fewer sales. You will have to find the sweet spot
                        that works for your business plan.
                      </li>
                      <li className="my-2 ai-static-text">
                        Two cautions &ndash; Rank is a snapshot and can change
                        quickly up or down. Second caution, if the rank is in a
                        sub-category, it becomes much more difficult to
                        determine any sense of the selling rate. Two apps, Keepa
                        and CamelCamelCamel show the history of the rank and can
                        help in making purchasing decisions for a seller.
                      </li>
                    </ol>
                    <li className="my-2 ai-static-text">
                      Tags &ndash; These are tags you can enter to help sort and
                      track your products. The Inventory Status page is where
                      you enter these values. Tags are created on the Inventory
                      Status Page under the Inventory heading.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="4">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    Current Price
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="4">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Current Price is the price you have set to sell the
                      product through Amazon FBA.
                    </li>
                    <li className="my- ai-static-text">
                      Total Fees &ndash; Fees you will pay when sold though
                      Amazon FBA channel. This includes the referral fee and the
                      pick and pack fee .
                    </li>
                    <li className="my-2 ai-static-text">
                      FBM Current Price &ndash; The price you set with your
                      merchant fulfilled offering.
                    </li>
                    <li className="my-2 ai-static-text">
                      FBM Fees &ndash; This is the Amazon referral fee and does
                      not include your shipping and handling cost from your
                      location.
                    </li>
                    <li className="my-2 ai-static-text">
                      . Buy Box &ndash; This is the price of the first offer
                      shown by Amazon to their customers. The item in the Buy
                      Box will typically get the most sales. The Buy Box is
                      determined by Amazon&apos;s algorithm. . A few factors
                      that contribute to the Buy Box are price and location of
                      the item in relation to the buyer.
                    </li>
                    <li className="my-2 ai-static-text">
                      Orders FBM - Shows the amount sold from your warehouse,
                      Fulfilled by Merchant, for the following range of days:
                      1-3, 1-7, 1-14, 1-30, 1-60, 1-90 days. This will allow you
                      to see if the volume would warrant adding it as a FBA
                      item.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                {/* <AccordionHeader targetId="5">Orders FBM</AccordionHeader>
              <AccordionBody accordionId="5">
                <p className="h5 text-black my-2">
                  Show the amount sold from your warehouse, Fulfilled by
                  Merchant, for the following range of days: 1-3, 1-7, 1-14,
                  1-30, 1-60, 1-90 days. This will allow you to see if the
                  volume would warrant sending it as an FBA item.
                </p>
              </AccordionBody> */}
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="6">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    FBA Orders
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="6">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Shows the amount sold through the FBA for the following
                      range of days: 1-3, 1-7, 1-14, 1-30, 1-60, and 1-90 days.
                    </li>
                    <li className="my-2 ai-static-text">
                      STR -The Sell Through Rate is your sold and shipped units
                      over the past 90 days divided by the average number of
                      units in stock in Amazon Fulfillment centers during that
                      period. Amazon likes to see the value to be 2 or higher.
                      This value is one major factor of several that Amazon uses
                      to assign warehouse space to individual sellers. The Sell
                      Through Rate we calculate is for individual items and is
                      different than the amount Amazon calculates for all your
                      total inventory combined. Our number gives you an
                      indication to let you know if a particular item is moving
                      at a rate above or below the target rate of 2. If the
                      turnover rate is below 2, you can keep an eye on that
                      item. If you need to increase your STR to increase your
                      storage allotment, you can then consider removing items
                      your FBA inventory that are not selling quickly enough.
                      For Active Inventory days out of stock will skew the
                      results of the formula, so we will not calculate the rate
                      if you have more than 3 days out of stock in the last 90
                      days.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="7">
                  <h4 className="ai-static-bold-text fw-bold m-0">Available</h4>
                </AccordionHeader>
                <AccordionBody accordionId="7">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Available - Amount shown in Amazon that are ready for
                      purchase.
                    </li>
                    <li className="my-2 ai-static-text">
                      Transfer &ndash; Items being transferred internally in the
                      Amazon Warehouse system.
                    </li>
                    <li className="my-2 ai-static-text">
                      Inbound &ndash; Amount currently in route to the Amazon
                      warehouse from your location but not checked in or
                      received yet. Active Inventory tracks inbound amounts from
                      time a shipment is developed. Amazon, in Seller Central,
                      does not show inbound until farther into the shipping
                      process.
                    </li>
                    <li className="my-2 ai-static-text">
                      Total &ndash; Is the sum of Available, Transfer and
                      Inbound.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="8">
                  <h4 className="ai-static-bold-text fw-bold m-0">Orders</h4>
                </AccordionHeader>
                <AccordionBody accordionId="8">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Orders - Items being processed for customer orders.
                    </li>
                    <li className="my-2 ai-static-text">
                      FC Processing &ndash; Products that are currently set
                      aside to check authenticity, weight dimensions and other
                      possible checks. The process typically will take two days
                      but can take much longer.
                    </li>
                    <li className="my-2 ai-static-text">
                      Unfulfillable &ndash; These products are damaged or in
                      unsellable condition. You will want to recall or put in an
                      order to destroy the product in a timely fashion to avoid
                      unnecessary storage fees.
                    </li>
                    <li className="my-2 ai-static-text">
                      Oversized &ndash; These items are calculated based on
                      product dimensions and weight and will allow you to be
                      aware of your oversized units.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="9">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    Days Remaining
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="9">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      This is a calculation that is based on your previous
                      sales. It will suggest how many days you have until you
                      run out of stock. You can choose how many days you want in
                      stock at the Amazon warehouse on your settings page.
                    </li>
                    <li className="my-2 ai-static-text">
                      Days Out of Stock &ndash; This number shows how many days
                      there where “0” in stock over the last 90-day period.
                    </li>
                    <li className="my-2 ai-static-text">
                      Weekly Projected Sales (WPS) &ndash; Based on your
                      settings, the WPS will give you the average amount you are
                      selling on a weekly basis.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="10">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    Restock History
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="10">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Date/Amount - shows the last four dates and amounts you
                      sent into Amazon based on Active Inventory&apos;s Restock
                      Page. You need to finalize your sheets for this amount to
                      populate in the restock sheet. This is data generated in
                      Active Inventory not pulled from Amazon API.
                    </li>
                    <li className="my-2 ai-static-text">
                      Sent/Received &ndash; Sent is the total of items in
                      shipments which are currently in route to the Amazon
                      Warehouses. Received is the total amount that Amazon have
                      been account for in receiving at the warehouses, but not
                      yet set to available status. This amount come from Amazon
                      API.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="11">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    Suggested Units
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="11">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Active Inventory gives you a range of item quantity you
                      can choose to send in. It calculates the amount of
                      inventory to send into the Amazon warehouse to meet your
                      Min or Max days of inventory that you want to keep in
                      stock. On the settings page you will set the Min and Max
                      amounts and they are indicated here in the parentheses
                      (#). Active Inventory gives you the ability to adjust your
                      lead time setting or the Min and Max to better reflect
                      current shipping, unpacking, transferring, and processing
                      times.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="12">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    FBA Shipment Amount
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="12">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Here is where you enter the amount you want to send into
                      the Amazon warehouse in this shipment. If you have a value
                      in this field when you Finalize your sheet it can be later
                      adjusted on the Finalized sheet. For example, if you plan
                      to send 10 items in, but find your actual stock in your
                      warehouse is 8 you can adjust your Finalized sheet down to
                      8. You cannot add additional SKUs to the Finalized Sheets
                      but can adjust the amounts to reflect accurately what was
                      sent in the shipment.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="13">
                  <h4 className="ai-static-bold-text fw-bold m-0">Notes</h4>
                </AccordionHeader>
                <AccordionBody accordionId="13">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Allows you to put notes on this product. If there is a
                      note, the first part of the note will show, and you can
                      mouse over for the full note. This can be handy for item
                      locations, dates, quantity on hand or any other relevant
                      information that you want to keep track of for an ASIN.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="14">
                  <h4 className="ai-static-bold-text fw-bold m-0">
                    Additional Items
                  </h4>
                </AccordionHeader>
                <AccordionBody accordionId="14">
                  <ol className="h5 ai-static-text my-2">
                    <li className="my-2 ai-static-text">
                      Click any column title that is blue to sort
                    </li>
                    <li className="my-2 ai-static-text">
                      Each Column has an (i) button in the upper right corner
                      with a quick explanation of the data in the column.
                    </li>
                    <li className="my-2 ai-static-text">
                      In the Upper left you can filter by Status.
                    </li>
                    <li className="my-2 ai-static-text">
                      Delete Sheet &ndash; Will remove the sheet from the “In
                      Progress” que if you want to start over. If you choose to
                      delete a sheet, it is not recoverable.
                    </li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
              {/* <AccordionItem>
              <AccordionHeader targetId="15">
                FBA Restock Sheet Entry Page
              </AccordionHeader>
              <AccordionBody accordionId="15">
                <strong>This is the first item&#39;s accordion body.</strong>
                You can modify any of this with custom CSS or overriding our
                default variables. It&#39;s also worth noting that just about
                any HTML can go within the <code>.accordion-body</code>, though
                the transition does limit overflow.
              </AccordionBody>
            </AccordionItem> */}
            </Accordion>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default RestockExplained
