import {
  API_INVOCATION,
  SEND_NOTIFICATION,
  NOTIFICATION_CREATE,
  NOTIFICATION_DELETE,
  NOTIFICATION_BY_ID,
  NOTIFICATION_UPDATE,
  NOTIFICATION_UPDATE_FOR_USER,
  GET_ALL_NOTIFICATION_FOR_USER_BY_ID,
} from "../../actionType"

import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

export const getAllNotification = (_payload, resolve, reject) => {
  const payload = {
    action: SEND_NOTIFICATION,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url:
      localStorage.getItem("role") === constdata.SUPERADMIN
        ? consturl.GET_ALL_NOTIFICATION
        : consturl.GET_ALL_NOTIFICATION_FOR_USER,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const createNotifications = (_payload, resolve, reject) => {
  const payload = {
    action: NOTIFICATION_CREATE,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url: consturl.CREATE_NOTIFICATION,
    resolve,
    reject,
    data: { ..._payload.data },
  }

  return { type: API_INVOCATION, payload }
}

export const deleteNotificationData = (_payload, resolve, reject) => {
  console.log(_payload)
  const payload = {
    action: NOTIFICATION_DELETE,
    method: constdata.DELETE,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url: consturl.DELETE_NOTIFICATION + `/` + _payload.data,
    resolve,
    reject,
    data: { ..._payload.data },
  }
  return { type: API_INVOCATION, payload }
}

export const getNotificationById = (_payload, resolve, reject) => {
  const payload = {
    action: NOTIFICATION_BY_ID,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url: consturl.GET_SINGLE_NOTIFICATION + `/${_payload.data}`,
    resolve,
    reject,
    data: { ..._payload.data },
  }

  return { type: API_INVOCATION, payload }
}

export const updateNotificationData = (_payload, resolve, reject) => {
  console.log(_payload)
  const payload = {
    action: NOTIFICATION_UPDATE,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url: consturl.UPDATE_NOTIFIACTION + `/${_payload.data.id}`,
    resolve,
    reject,
    data: { ..._payload.data.dataObj },
  }

  return { type: API_INVOCATION, payload }
}

export const updateUserNotification = (_payload, resolve, reject) => {
  console.log(_payload);
  const payload = {
    action: NOTIFICATION_UPDATE_FOR_USER,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url:consturl.UPDATE_NOTIFICATION_FOR_USER+ `/${_payload.data.userId}`+`/${_payload.data.notificationId}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const getAllNotificationForUser = (_payload, resolve, reject) => {
  const payload = {
    action: GET_ALL_NOTIFICATION_FOR_USER_BY_ID,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
      },
    },
    url: consturl.GET_ALL_NOTIFICATION_FOR_USER+ `/${_payload.data}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}