import React from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"
import YTDSales from "../../assets/images/increasing.png"
import OutOfOstock from "../../assets/images/out-of-stock.png"
import InStock from "../../assets/images/shopping-cart.png"
import ProductStatus from "../../assets/images/clipboard.png"

import "./dashboard.scss"

const TopCard = () => {
  const cardData = useSelector(state => state?.Dashboard?.data)
  return (
    <div>
      <div className="ai-db-container">
        <div className="ai-db-item" style={{ background: "#2299AA" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={YTDSales} alt="sales" className="ai-db-img" />
          </div>
          <div>
            <p className="ai-db-text pt-1" style={{ fontSize: "14px" }}>
              YTD Sales
            </p>
            <h1
              className="ai-db-text"
              style={{
                paddingTop: "30px",
              }}
            >
              {cardData?.ytdSales}
            </h1>
          </div>
        </div>
        <div className="ai-db-item" style={{ background: "#FE6571" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={OutOfOstock} alt="sales" className="ai-db-img" />
          </div>
          <div className="d-flex flex-column">
            <p className="ai-db-text pt-1" style={{ fontSize: "14px" }}>
              Total Out of Stock Product
            </p>
            <h1 className="ai-db-text" style={{ paddingTop: "30px" }}>
              {cardData?.inventory.OOS}
            </h1>
          </div>
        </div>
        <div className="ai-db-item" style={{ background: "#9BB6E5" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={InStock} alt="sales" className="ai-db-img" />
          </div>
          <div className="d-flex flex-column">
            <p className="ai-db-text pt-1" style={{ fontSize: "14px" }}>
              Total Instock Product
            </p>
            <h1 className="ai-db-text" style={{ paddingTop: "30px" }}>
              {cardData?.inventory.IS}
            </h1>
          </div>
        </div>
        <div className="ai-db-item" style={{ background: "#22A5E5" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={ProductStatus} alt="sales" className="ai-db-img" />
          </div>
          <div className="d-flex flex-column">
            <p className="ai-db-text pt-1" style={{ fontSize: "14px" }}>
              Product Status
            </p>
            <h1 className="ai-db-text" style={{ paddingTop: "12px" }}>
              <span className="ai-db-text" style={{ fontSize: "30px" }}>
                Active
              </span>{" "}
              : {cardData?.status.active}
            </h1>
            <h1 className="ai-db-text">
              <span className="ai-db-text">Inactive</span> :{" "}
              {cardData?.status.inactive}
            </h1>
          </div>
        </div>
      </div>

      {/* <Row className="d-flex justify-content-between">
        <Col xs={12} md={6} lg={3}>
          <Card className="border border-1 border-primary shadow-sm h-75">
            <CardBody className="d-flex flex-row align-items-center justify-content-around">
              <div><i className="mdi mdi-text-box-outline h1 text-info"></i></div> 
              <div>
                <img
                  src={YTDSales}
                  alt="sales"
                  className="bg-info"
                  style={{ width: "2.438rem", height: "2.031rem" }}
                />
              </div>
              <div className="d-flex flex-column">
                <p className="h6 font-weight-bold">YTD Sales</p>
                <p className="h6">{cardData?.ytdSales}</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border border-1 border-primary shadow-sm h-75">
            <CardBody className="d-flex flex-row align-items-center justify-content-around">
              <div>
                <i className="mdi mdi-cart-remove h1 text-danger"></i>
              </div>
              <div className="d-flex flex-column">
                <p className="h6 font-weight-bold">
                  Total Out of Stock Product
                </p>
                <p className="h6">{cardData?.inventory.OOS}</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border border-1 border-primary shadow-sm h-75">
            <CardBody className="d-flex flex-row align-items-center justify-content-around">
              <div>
                <i className="mdi mdi-cart-check h1 text-primary"></i>
              </div>
              <div className="d-flex flex-column">
                <p className="h6 font-weight-bold">Total Instock Product</p>
                <p className="h6">{cardData?.inventory.IS}</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border border-1 border-primary shadow-sm h-75">
            <CardBody className="d-flex flex-row align-items-center justify-content-around">
              <div>
                <i className="mdi mdi-list-status h1 text-warning"></i>
              </div>
              <div className="d-flex flex-column">
                <p className="h6 font-weight-bold">Product Status</p>
                <p className="h6">
                  <span className="text-success font-weight-bold">Active</span>{" "}
                  - {cardData?.status.active}
                </p>
                <p className="h6">
                  <span className="text-danger font-weight-bold">Inactive</span>{" "}
                  - {cardData?.status.inactive}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </div>
  )
}

export default TopCard
