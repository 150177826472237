import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import footerLogo from "../../assets/images/logo-white.png"
import "./Layout.css"
const Footer = () => {
  const isActiveSubscription =
    localStorage.getItem("isSubscriptionActive") &&
    localStorage.getItem("isSubscriptionActive") === "true"

  const footerData = {
    firstCol: [
      {
        id: 1,
        title: "DASHBOARD",
        name: "Dashboard",
        link: isActiveSubscription ? "/dashboard" : "#",
        target: "_self",
      },
    ],
    secondCol: [
      {
        id: 2,
        title: "RESTOCK",
        name: "Restock Sheet",
        link: isActiveSubscription ? "/restock-sheet" : "#",
        target: "_self",
      },
      {
        id: 3,
        name: "Restock Guide",
        link: "/restock-explained",
        target: "_blank",
      },
    ],
    thirdCol: [
      {
        id: 4,
        title: "HISTORY",
        name: "Restock History",
        link: isActiveSubscription ? "/restock-report-history" : "#",
        target: "_self",
      },
      {
        id: 5,
        name: "Shipping Que",
        link: isActiveSubscription ? "/shipping-queue" : "#",
        target: "_self",
      },
    ],
    fourthCol: [
      {
        id: 6,
        title: "INVENTORY",
        name: "Inventory Status",
        link: isActiveSubscription ? "/products" : "#",
        target: "_self",
      },
      {
        id: 7,
        name: "Out of Stock Report",
        link: isActiveSubscription ? "/out-of-stock" : "#",
        target: "_self",
      },
    ],
    fifthCol: [
      {
        id: 8,
        title: "SUPPORT",
        name: "Become Affiliate",
        link: "/affiliate",
        target: "_blank",
      },
      {
        id: 9,
        name: "Knowledge Center",
        link: "https://support.myactiveinventory.com/support/solutions",
        target: "_blank",
      },
      {
        //====================account--profile
        id: 10,
        name: "Account",
        link: "/profile",
        target: "_blank",
      },
    ],
  }

  return (
    <div
      className="w-100 footer-wrapper"
      style={{ backgroundColor: "#082C4E", bottom: 0, position: "initial" }}
    >
      <div>
        <div className="ai-footer-container pl-4 pr-3">
          <div
            id="img"
            className="ai-footer-item"
            style={{ width: "220px", marginLeft: "30px" }}
          >
            <img src={footerLogo} alt="active_inventory_logo" />
          </div>
          {Object.keys(footerData).map((item, index) => {
            return (
              <div
                className=" ai-footer-text ai-footer-item mx-1 p-0"
                key={index}
              >
                {footerData[item].map((items, index1) => {
                  return (
                    <Fragment key={items.id}>
                      <span className="my-1" key={items.id}>
                        <p
                          className="fw-bold ai-footer-text "
                          style={{
                            fontFamily: "novecento-sans",
                            opacity: 1,
                            color: "#2299AA",
                          }}
                        >
                          {items.title}
                        </p>
                        <a
                          className="nav-link text-white footer-text footer-bottom-border h3 text-decoration-none cursor-pointer"
                          href={items.link}
                          target={items.target}
                          rel="noreferrer"
                        >
                          {items.name}{" "}
                        </a>
                      </span>
                    </Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="col-12 d-block text-center mt-5">
          <p className="mb-0">
            <a href="https://myactiveinventory.com/terms-of-service/" target="_blank" rel="noreferrer" className="fw-medium" style={{color: "#2299AA", fontFamily: "nunito-sans", fontSize: "16px"}}>
              Terms of Service
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://myactiveinventory.com/privacy-policy/" target="_blank" rel="noreferrer" className="fw-medium" style={{color: "#2299AA", fontFamily: "nunito-sans", fontSize: "16px"}}>
              Privacy Policy
            </a>
          </p>
          <br />
          <p className="text-white mb-0 ai-footer-text">
            © Copyright {new Date().getFullYear()} Active Inventory - All Rights
            Reserved
            {/* <span style={{fontSize: "15px", float: "right"}}>Developed by: <a href="https://tirnav.com" target="_new" className="text-danger">Tirnav Solutions</a></span> */}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
