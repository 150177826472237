import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Modal, ModalBody, Button } from "reactstrap"
import * as Yup from "yup"
import "../../assets/scss/custom/rdt.scss"
import "../../assets/scss/style.scss"

const Popup = () => {
  const [addUserClicked, setAddUserClicked] = useState(false)

  const cancelAddUser = () => {
    setAddUserClicked(false)
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center text-center"
      style={{ width: "1260px", height: "680px", backgroundColor: "#74889B" }}
    >
      <div
        className="d-flex justify-content-center text-center"
        style={{
          width: "1200px",
          height: "600px",
          padding: "170px 0",
          backgroundColor: "#082C4E",
          borderRadius: "70px",
        }}
      >
        <Row>
          <div
            // className="position-absolute top-0 end-0"
            // style={{ marginLeft: "auto" }}
            style={{
              position: "absolute",
              end: "0",
              top: "75px",
            }}
            onClick={() => cancelAddUser()}
          >
            <div style={{ fontSize: "18px", color: "#FFFFFF" }}>&#10005;</div>
          </div>
          <Row>
            <Col>
              <p
                style={{
                  font: "normal normal normal 70px verveine",
                  color: "#FFEE00",
                  margin: "0",
                }}
              >
                Heads up!
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1
                style={{
                  font: "normal normal bold 80px novecento-sans",
                  color: "#FFFFFF",
                }}
              >
                Your free trial ends in 7 days
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                style={{
                  width: "700px",
                  minWidth: "150px",
                  height: "55px",
                  borderRadius: "25px",
                  color: "#FFFFFF",
                  backgroundColor: "#2299AA",
                  border: "0",
                  font: "bold 30px novecento-sans",
                }}
                type="submit"
              >
                activate my paid subscription now
              </button>
            </Col>
          </Row>
        </Row>
        <Modal
          /* isOpen={editUserClicked} */ size="lg"
          className="w-100 modal-wrapper"
        >
          <ModalBody>
            <Row>
              <Row>
                <Col>
                  <p style={{ color: "#FFEE00" }}>Heads up!</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 style={{ color: "#FFFFFF" }}>
                    Your free trial ends in 7 days
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    style={{
                      width: "500px",
                      minWidth: "150px",
                      height: "45px",
                      borderRadius: "25px",
                      color: "#FFFFFF",
                      backgroundColor: "#2299AA",
                      border: "0",
                      font: "bold 18px novecento-sans",
                    }}
                    type="submit"
                  >
                    activate my paid subscription now
                  </button>
                </Col>
              </Row>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}

export default Popup
