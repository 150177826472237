import React, { useEffect, useRef, useState } from "react"

import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
} from "reactstrap"
import TabTitle from "components/Common/TabTitle"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import "./style.css"
import Parser from "html-react-parser"
import {
  createNotifications,
  deleteNotificationData,
  getAllNotification,
  getNotificationById,
  updateNotificationData,
} from "store/action/Notification"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { SUPERADMIN } from "utils/constants"

const SendNotification = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [title, setTitle] = useState("")
  const [beginningDateTime, setBeginningDateTime] = useState("")
  const [endingDateTime, setEndingDateTime] = useState("")
  const [announcement, setAnnouncement] = useState(false)
  const [sendEmail, setSendEmail] = useState("")
  const [editorBody, setEditorBody] = useState("")
  const [deleteNotification, setDeleteNotification] = useState("")
  const [editNotification, setEditNotification] = useState("")
  const [notifications, setNotifications] = useState([])
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [editformModal, setEditformModal] = useState(false)
  const [editFormData, setEditFormData] = useState({
    id: "",
    title: "",
    beginningDateTime: "",
    endingDateTime: "",
    announcement: "",
    sendEmail: "",
    editorBody: "",
  })

  useEffect(() => {
    getAllNotifications()
  }, [])

  const getAllNotifications = () => {
    dispatch(
      getAllNotification(
        {data : localStorage.getItem('clientId')},
        responseData => {
          setNotifications([...responseData.data])
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const toggleDelete = id => {
    setModalDelete(!modalDelete)
    setDeleteNotification(id)
  }

  const cancelDelete = () => {
    setModalDelete(false)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const toggleEditFormModal = id => {
    if (id === null) {
      console.log(id)
      setEditformModal(!editformModal)
      setEditNotification(id)
      resetFormData()
    } else {
      console.log(id)
      setEditformModal(!editformModal)
      setEditNotification(id)
      dispatch(
        getNotificationById(
          { data: id },
          response => {
            setTitle(response.data.title)
            setBeginningDateTime(response.data.beginningDateTime)
            setEndingDateTime(response.data.endingDateTime)
            setAnnouncement(response.data.announcementStream)
            setSendEmail(response.data.sendEmail)
            setEditorBody(response.data.emailContent)
            setEditFormData({
              id: response.data.id,
              editorBody: response.data.emailContent,
              announcement: response.data.announcementStream,
              sendEmail: response.data.sendEmail,
            })
          },
          error => {
            addToast(error.response.data.error.displayMessage, {
              appearance: "error",
              autoDismiss: true,
            })
          }
        )
      )
    }
  }

  const cancelEdit = () => {
    setEditformModal(false)
  }

  const changeTitle = e => {
    setTitle(e.target.value)
    console.log(title)
    document.getElementById("titleErr").innerHTML = ""
  }

  const changeBeginningDateTime = e => {
    setBeginningDateTime(e.target.value)
    console.log(beginningDateTime)
  }

  const changeEndingDateTime = e => {
    setEndingDateTime(e.target.value)
    console.log(endingDateTime)
  }

  const handleSubmitForm = e => {
    e.preventDefault()
    if (title === "" && title.length === 0) {
      document.getElementById("titleErr").innerHTML = "Title Cannot be Empty"
      return false
    } else if (editorBody === "" && editorBody.length === 0) {
      document.getElementById("editorErr").innerHTML = "Body Cannot be Empty"
      return false
    }
    if (endingDateTime < beginningDateTime) {
      document.getElementById("endingDate").innerHTML =
        "Ending Date Should be greater than Beginning Date"
      return false
    }
    const dataObj = {
      title: title,
      emailContent: editorBody,
      announcementStream: announcement,
      sendEmail: sendEmail,
      beginningDateTime: beginningDateTime,
      endingDateTime: endingDateTime,
    }
    dispatch(
      createNotifications(
        { data: dataObj },
        response => {
          getAllNotifications()
          resetFormData()
          setEditformModal(false)
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        error => {
          if (error.response.data.error.additionalInfo.message.length !== 0) {
            document.getElementById("endingDate").innerHTML =
              error.response.data.error.additionalInfo.message
          }
          addToast(error.response.data.error.additionalInfo.message, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleCKEditorChange = (e, editor) => {
    let data = editor.getData()
    setEditorBody(data)
    document.getElementById("editorErr").innerHTML = ""
  }

  const handleDelete = () => {
    console.log(deleteNotification)
    console.log("above is selected ID")
    dispatch(
      deleteNotificationData(
        { data: deleteNotification },
        response => {
          getAllNotifications()
          setModalDelete(false)
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        error => {
          addToast(error.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleEditForm = e => {
    e.preventDefault()

    if (title === "" && title.length === 0) {
      document.getElementById("titleErr").innerHTML = "Title Cannot be Empty"
      return false
    } else if (editorBody === "" && editorBody.length === 0) {
      document.getElementById("editorErr").innerHTML = "Body Cannot be Empty"
      return false
    } else if (endingDateTime < beginningDateTime) {
      document.getElementById("endingDate").innerHTML =
        "Ending Date Should be greater than Beginning Date"
      return false
    }
    const dataObj = {
      title: title,
      emailContent: editorBody,
      announcementStream: announcement,
      sendEmail: sendEmail,
      beginningDateTime: beginningDateTime,
      endingDateTime: endingDateTime,
    }
    dispatch(
      updateNotificationData(
        { data: { dataObj, id: editNotification } },
        response => {
          console.log(response.data)
          getAllNotifications()
          resetFormData()
          setEditformModal(false)
          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        error => {
          if (error.response.data.error.additionalInfo.message.length !== 0) {
            document.getElementById("endingDate").innerHTML =
              error.response.data.error.additionalInfo.message
          }
          addToast(error.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const resetFormData = () => {
    setTitle("")
    setBeginningDateTime("")
    setEndingDateTime("")
    setAnnouncement(false)
    setSendEmail(false)
    setEditorBody("")
  }

  const handleFormSubmission = e => {
    if (editNotification !== null) {
      handleEditForm(e)
    } else {
      handleSubmitForm(e)
    }
  }

  const form = notification => {
    return (
      <Modal
        isOpen={editformModal}
        toggle={() => toggleEditFormModal(null)}
        style={{ height: "50%", maxWidth: "800px", width: "100%" }}
        scrollable
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader
          toggle={cancelEdit}
          charcode="close"
          style={{ paddingBottom: 0, paddingTop: 5 }}
        >
          {notification}
        </ModalHeader>
        <ModalBody style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Form className="form-horizontal" onSubmit={handleFormSubmission}>
            <Row>
              <Col>
                <Card style={{ marginBottom: 5 }}>
                  <FormGroup className="my-2 mx-3 mb-1">
                    <Label for="title">Title</Label>
                    <span className="requiredMark">*</span>
                    <Input
                      type="text"
                      value={title}
                      placeholder="Enter Title Here"
                      name="title"
                      id="title"
                      onChange={changeTitle}
                      required
                    />
                    <div className="error">
                      <p
                        id="titleErr"
                        style={{
                          color: "red",
                          paddingTop: "10px ",
                          marginBottom: 0,
                        }}
                      ></p>
                    </div>
                  </FormGroup>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <Label for="title">Body</Label>
                    <span className="requiredMark">*</span>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorBody}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "outdent",
                          "indent",
                          "|",
                          "insertTable",
                          "blockQuote",
                          "undo",
                          "redo",
                        ],
                      }}
                      onChange={handleCKEditorChange}
                    />
                    <div className="error">
                      <p
                        id="editorErr"
                        style={{
                          color: "red",
                          paddingLeft: "10px ",
                        }}
                      ></p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Card>
                  <CardBody
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <p>Add to Announcement Stream</p>
                    <FormGroup check inline>
                      <Label check for="yes1">
                        Yes
                      </Label>
                      <Input
                        type="radio"
                        id="yes1"
                        name="announcement"
                        defaultChecked
                        checked={announcement ? true : false}
                        onClick={() => setAnnouncement(true)}
                      />
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check for="no1">
                        No
                      </Label>
                      <Input
                        type="radio"
                        id="no1"
                        name="announcement"
                        checked={!announcement ? true : false}
                        onClick={() => setAnnouncement(false)}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card>
                  <CardBody
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <p>Send an Email copy</p>
                    <FormGroup check inline>
                      <Label check for="yes2">
                        Yes
                      </Label>
                      <Input
                        type="radio"
                        id="yes2"
                        name="testEmail"
                        checked={sendEmail ? true : false}
                        onClick={() => setSendEmail(true)}
                      />
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check for="no2">
                        No
                      </Label>
                      <Input
                        type="radio"
                        id="yes2"
                        name="testEmail"
                        checked={!sendEmail ? true : false}
                        onClick={() => setSendEmail(false)}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <FormGroup>
                      <div style={{ display: "flex" }}>
                        <div style={{ paddingTop: "6px" }}>
                          <Label for="beginningDateTime">Start Date:</Label>
                          <span className="requiredMark">*</span>
                        </div>
                        <div style={{ marginLeft: "25px" }}>
                          <Input
                            type="datetime-local"
                            value={beginningDateTime}
                            name="beginningDateTime"
                            id="beginningDateTime"
                            onChange={changeBeginningDateTime}
                            required
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div style={{ display: "flex" }}>
                        <div style={{ paddingTop: "6px" }}>
                          <Label for="endingDateTime">End Date:</Label>
                          <span className="requiredMark">*</span>
                        </div>
                        <div style={{ marginLeft: "32px" }}>
                          <Input
                            type="datetime-local"
                            value={endingDateTime}
                            name="endingDateTime"
                            id="endingDateTime"
                            onChange={changeEndingDateTime}
                            required
                          />
                        </div>
                        <div className="error">
                          <p
                            id="endingDate"
                            style={{
                              color: "red",
                              paddingLeft: "10px ",
                            }}
                          ></p>
                        </div>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <CardFooter style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Button type="submit" color="primary" size="sm">
                    Save
                  </Button>
                </div>
                <div>
                  <Button
                    type="reset"
                    color="primary ms-3"
                    size="sm"
                    onClick={resetFormData}
                  >
                    Reset
                  </Button>
                </div>
                {sendEmail && (
                  <div>
                    <Button
                      type="button"
                      color="primary ms-3"
                      size="sm"
                      onClick={toggle}
                    >
                      Send Email
                    </Button>
                  </div>
                )}
              </div>
            </CardFooter>
          </Form>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <>
      <React.Fragment>
        <div className="page-content page-wrapper">
          <Container className="container-wrapper" fluid>
            {localStorage.getItem("role") === SUPERADMIN ? (
              <div style={{ float: "right" }}>
                <Button
                  type="submit"
                  color="primary ms-3 "
                  size="sm"
                  onClick={() => toggleEditFormModal(null)}
                >
                  Add Notification
                </Button>
              </div>
            ) : (
              ""
            )}

            <TabTitle title="Notification" />
            <Row className="">
              <Col
                className=" page-wrapper"
                sm="12"
                md={{ size: 8, offset: 2 }}
              >
                {notifications.map(data => (
                  <div className="flex-container" key={data.id}>
                    <div className="flex-item">
                      <Card>
                        <CardFooter>
                          <Label for="Subject" style={{ paddingRight: 5 }}>
                            Subject:
                          </Label>
                          {data.title}
                        </CardFooter>
                        <CardBody>{Parser(data.emailContent)}</CardBody>
                        <CardFooter style={{ padding: 0, float: "right" }}>
                          <Row>
                            {localStorage.getItem("role") === SUPERADMIN ? (
                              <Col sm="5">
                                <div className="spacing">
                                  <strong>Add to Announcement Stream:</strong>
                                  {data.announcementStream ? " Yes " : " No "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <div>
                                    <strong>Send an Email copy:</strong>
                                    {data.sendEmail ? " Yes " : " No "}
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                            <Col sm="5">
                              <div className="spacing">
                                <p>
                                  <strong>Start Date:</strong>{" "}
                                  {data.beginningDateTime}
                                </p>
                                <p>
                                  <strong>End Date:</strong>
                                  {data.endingDateTime}
                                </p>
                              </div>
                            </Col>
                            {localStorage.getItem("role") === SUPERADMIN ? (
                              <Col cm="2">
                                <div className="edit-delete-btn">
                                  <Button
                                    title="Edit"
                                    className="edit"
                                    color="ms-1"
                                    style={{ border: "none", padding: 0 }}
                                    onClick={() => toggleEditFormModal(data.id)}
                                  >
                                    <div className="icon">
                                      <i className="fas fa-edit fa-1x"></i>
                                    </div>
                                  </Button>
                                  <Button
                                    title="Delete"
                                    className="delete"
                                    color="ms-1"
                                    style={{ border: "none", padding: 0 }}
                                    onClick={() => toggleDelete(data.id)}
                                  >
                                    <div className="icon">
                                      <i className="fa fa-trash fa-1x"></i>
                                    </div>
                                  </Button>
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </CardFooter>
                      </Card>
                    </div>
                  </div>
                ))}

                <Modal
                  isOpen={modalDelete}
                  toggle={toggleDelete}
                  className="modal-delete"
                >
                  <ModalHeader toggle={toggleDelete} charcode="close">
                    Delete
                  </ModalHeader>
                  <ModalBody>Are you sure you want to delete?</ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={() => handleDelete()}>
                      Confirm
                    </Button>
                    <Button color="primary" onClick={() => cancelDelete()}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                {editNotification !== null
                  ? form("Edit Notification")
                  : form("Add Notification")}
              </Col>
            </Row>

            <Row>
              <Modal
                isOpen={modal}
                toggle={toggle}
                style={{ height: "50%", maxWidth: "800px", width: "100%" }}
                scrollable
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
              >
                <ModalHeader toggle={toggle} charcode="close">
                  {title}
                </ModalHeader>
                <ModalBody>{Parser(editorBody)}</ModalBody>
                <ModalFooter>
                  <Button color="primary" size="sm" onClick={toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

export default SendNotification
