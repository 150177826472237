import {
    ADD_NEW_CARD,
    API_INVOCATION,
    BILLING_DATA,
    CANCEL_SUBSCRIPTION,
    CREATE_SUBSCRIPTION,
    DASHBOARD_DATA,
    GET_STRIPE_PUBLIC_KEY_DATA,
    REACTIVE_SUBSCRIPTION,
    SUBSCRIPTION_PLAN_DATA,
    UPDATE_SUBSCRIPTION,
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

export const getStripePublicKey = (resolve, reject) => {
    const payload = {
        action: GET_STRIPE_PUBLIC_KEY_DATA,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.GET_STRIPE_PUBLIC_KEY,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getSubscriptionPlan = (resolve, reject) => {
    const payload = {
        action: SUBSCRIPTION_PLAN_DATA,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SUBSCRIPTION_PLAN_DATA,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getBillingData = (_payload, resolve, reject) => {
    const payload = {
        action: BILLING_DATA,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.BILLING_DATA + `?direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const addNewCard = (resolve, reject) => {
    const payload = {
        action: ADD_NEW_CARD,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.ADD_NEW_CARD,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const createSubscription = (_payload, resolve, reject) => {
    const payload = {
        action: CREATE_SUBSCRIPTION,
        method: constdata.POST,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.CREATE_SUBSCRIPTION + `?priceId=${_payload.priceId}`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const updateSubscription = (_payload, resolve, reject) => {
    const payload = {
        action: UPDATE_SUBSCRIPTION,
        method: constdata.PUT,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.CREATE_SUBSCRIPTION + `?priceId=${_payload.priceId}&subscriptionId=${_payload.subscriptionId}`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const cancelActiceSubscription = (resolve, reject) => {
    const payload = {
        action: CANCEL_SUBSCRIPTION,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.CANCEL_SUBSCRIPTION,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const reActiceSubscription = (_payload, resolve, reject) => {
    const payload = {
        action: REACTIVE_SUBSCRIPTION,
        method: constdata.POST,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.REACTIVE_SUBSCRIPTION + `?subscriptionId=${_payload.data}`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}