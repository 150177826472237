import {
    API_INVOCATION,
    SHIPPING_ITEM_DATA,
    SHIPPING_QUEUE_DATA,
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

/**
 *
 *  get shipping queue data
 */
export const shippingQueueData = (_payload, resolve, reject) => {
    const payload = {
        action: SHIPPING_QUEUE_DATA,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SHIPPING_QUEUE + `?direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}

export const getShipmentSheetItem = (_payload, resolve, reject) => {
    const payload = {
        action: SHIPPING_ITEM_DATA,
        method: constdata.GET,
        apiConfig: {
            headers: {
                Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
                'X-CLIENT-ID': localStorage.getItem("clientId"),
                'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
            },
        },
        url: consturl.SHIPPING_ITEM + `/${_payload.id}/items`,
        resolve,
        reject
    }
    return { type: API_INVOCATION, payload }
}
