import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  createRestockSheet,
  deleteRestockSheet,
  restockSheetInProgress,
} from "store/action/stock"
import { useToasts } from "react-toast-notifications"
import TabTitle from "components/Common/TabTitle"
import DataTable from "react-data-table-component"
import { useHistory } from "react-router-dom"
import "./restockSheet.scss"
import "../../assets/scss/custom/rdt.scss"
import { SUPERUSER } from "utils/constants"
import Parser from "html-react-parser"
import { useRef } from "react"
import Pagination from "../../components/Common/Pagination"
import {
  getAllNotification,
  getAllNotificationForUser,
  updateUserNotification,
} from "store/action/Notification"
import UpArrow from "../../assets/images/up-arrow-rs.svg"

const RestockSheet = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()

  const [restockSheetDraftData, setRestockSheetDraftData] = useState([])
  const [, setSelectedRow] = useState({})
  const [newSheetClick, setNewSheetClick] = useState(false)
  const [newSheetName, setNewSheetName] = useState("")
  const [resetPaginationToggle] = React.useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedSheetId, setSelectedSheetId] = useState(0)
  const [perPage, setPerPage] = useState(100)
  const [deleteSheetClick, setDeleteSheetClick] = useState(false)
  const [pagination, setPagination] = useState({
    totalPages: 0,
    totalElements: 0,
  })
  const [notifications, setNotifications] = useState([])
  const [modalNotification, setModalNotification] = useState(false)

  const clientData = useSelector(state => state?.Dashboard)

  useEffect(() => {
    if (clientData.market_placeId !== null && clientData?.client_id !== null) {
      //localStorage.setItem("marketplaceId", clientData?.market_placeId)
      //localStorage.setItem("clientId", clientData?.client_id)
    }
  }, [])

  useEffect(() => { }, [])

  useEffect(() => {
    if (localStorage.getItem("marketplaceId")) fetchAllData()
  }, [currentPage, perPage, localStorage.getItem("marketplaceId")])

  /* useEffect(() => {
    if (!localStorage.getItem("isNotification")) {
      getAllNotificationsFromUser(clientData.client_id)
    }
  }, []) */

  const toggleNotification = id => {
    setModalNotification(!modalNotification)
  }

  const close = () => {
    setModalNotification(false)
    closeNotification(notifications[0].id)
  }

  const closeNotification = notificationId => {
    console.log(notificationId)
    dispatch(
      updateUserNotification(
        {
          data: {
            userId: localStorage.getItem("clientId"),
            notificationId: notificationId,
          },
        },
        responseData => { },
        error => { }
      )
    )
  }

  const getAllNotificationsFromUser = id => {
    console.log(id)
    dispatch(
      getAllNotificationForUser(
        { data: id },
        responseData => {
          setNotifications([...responseData.data])
          localStorage.setItem("isNotification", true)
          if (responseData.data.length > 0) {
            setModalNotification(true)
          }
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const fetchAllData = () => {
    dispatch(
      restockSheetInProgress(
        {
          data: {
            pageNo: currentPage,
            pageSize: perPage,
          },
        },
        responseData => {
          setRestockSheetDraftData([...responseData.data.content])
          setPagination({
            totalPages: responseData.data.totalPages,
            totalElements: responseData.data.totalElements,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const viewClick = data => {
    setSelectedRow(data)
    history.push({
      pathname: "/fba-restock-sheet",
      state: { id: data.id, data: data, date: data.date },
    })
  }

  const deleteClick = row => {
    setDeleteSheetClick(true)
    setSelectedSheetId(row.id)
  }

  const confrimCancel = () => {
    dispatch(
      deleteRestockSheet(
        { restockSheetId: selectedSheetId },
        responseData => {
          setDeleteSheetClick(false)
          addToast("Restock sheet deleted", {
            appearance: "success",
            autoDismiss: true,
          })
          fetchAllData()
        },
        errorData => {
          setDeleteSheetClick(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const format_action = row => {
    return (
      <div className="p-0 d-flex flex-row justify-content-between align-items-center w-100">
        <div data-toggle="tooltip" title={"View"} className="mx-2 p-0">
          <i
            className="mdi mdi-square-edit-outline h3 p-0 pointer ai-icon-color"
            onClick={() => viewClick(row)}
          ></i>
        </div>
        <div data-toggle="tooltip" title={"Delete"} className="mx-2 p-0">
          <i
            className="mdi mdi-trash-can-outline h3 p-0 pointer ai-icon-color"
            onClick={() => deleteClick(row)}
          ></i>
        </div>
      </div>
    )
  }

  const handleNewSheetClick = () => {
    setNewSheetClick(!newSheetClick)
  }

  const handleCreateNewSheet = () => {
    dispatch(
      createRestockSheet(
        { name: newSheetName, status: "INPROGRESS" },
        response => {
          setNewSheetClick(false)
          fetchAllData()
          addToast(`Sheet (${response.data.name}) created Successfully`, {
            appearance: "success",
            autoDismiss: true,
          })
          setTimeout(() => {
            history.push({
              pathname: "/fba-restock-sheet",
              state: { id: response.data.id, data: response.data },
            })
          }, 1500)
        },
        errorData => {
          setNewSheetClick(false)
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const columns = [
    {
      name: "Date",
      selector: row => row.date,
      cell: row => <div>{new Date(row.date).toLocaleDateString("en-US")}</div>,
      sortable: true,
    },
    {
      name: "Restock Sheet Name",
      selector: row => row.name,
      cell: row => <div>{row.name}</div>,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      cell: row => (
        <div className="font-weight-bold ai-font-color-restock-status" style={{ color: "#2299AA !important", font: "bold nunito-sans" }}>{row.status}</div>
      ),
    },
    {
      name: "Actions",
      cell: row => format_action(row),
    },
  ]

  const handlePageChange = e => {
    setCurrentPage(e - 1)
  }

  const handlePerRowsChange = e => {
    setPerPage(e)
  }

  return (
    <React.Fragment>
      {notifications !== null ? (
        <Modal
          isOpen={modalNotification}
          toggle={toggleNotification}
          className="modal-delete"
          centered
          backdrop="static"
        >
          <ModalHeader toggle={toggleNotification} onClick={close}>Notification</ModalHeader>
          <ModalBody>
            {notifications.map(data => (
              <div key={data.id}>
                <p>
                  <strong>Subject:</strong> {data.title}
                </p>
                {Parser(data.emailContent)}
              </div>
            ))}
          </ModalBody>
          <ModalFooter>
            {notifications.map(data => (
              <Col sm="10" key={data.id} style={{ paddingLeft: 0 }}>
                <div>
                  <p>
                    <strong>Start Date:</strong> {data.beginningDateTime}
                  </p>
                  <p>
                    <strong>End Date:</strong>
                    {data.endingDateTime}
                  </p>
                </div>
              </Col>
            ))}
            <Button color="primary" onClick={close}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {deleteSheetClick && (
        <Modal
          isOpen={deleteSheetClick}
          size="lg"
          className="w-100 modal-wrapper"
          autoFocus={true}
          toggle={() => <></>}
          style={{
            border: 0,
            backgroundColor: "#082C4E",
            borderRadius: "35px",
          }}>
          <ModalBody
            style={{
              backgroundColor: "#082C4E",
              border: 0,
              borderRadius: "35px",
            }}
          >
            <Row className="d-flex justify-content-end w-100 m-0 text-center">
              <div
                className="ai-modal-close-btn"
                onClick={() => setDeleteSheetClick(false)}
              >
                <div style={{ fontSize: "18px" }}>&#10005;</div>
              </div>
            </Row>
            <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
              <div
                className="text-white"
                style={{ font: "bold 30px novecento-sans" }}
              >
                DELETE RESTOCK SHEET
              </div>
            </Row>

            <div className="text-danger font-weight-bold h4">Attention!!!</div>
            <div className="h5 text-white">
              {`You are about to delete the restock sheet. All the data of this restock sheet will be deleted. If you do not want to delete and erase this information, please cancel this action.`}
            </div>
            <div className="d-flex justify-content-center col-12">
              <Row className="align-items-center">
                <Col className="d-flex justify-content-center">
                  <Button
                    className="ai-action-btn-danger btn btn-primary text-white"
                    color=""
                    onClick={() => confrimCancel()}
                  >
                    DELETE
                  </Button>
                  <Button
                    className="ai-action-btn btn btn-primary"
                    color=""
                    onClick={() => setDeleteSheetClick(false)}
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      )}
      {newSheetClick && (
        <Modal
          isOpen={newSheetClick}
          size="md"
          className="w-100 modal-wrapper"
          autoFocus={true}
          toggle={() => <></>}
          style={{
            border: 0,
            backgroundColor: "#082C4E",
            borderRadius: "35px",
          }}>
          <ModalBody
            style={{
              backgroundColor: "#082C4E",
              border: 0,
              borderRadius: "35px",
            }}
          >
            <Row className="d-flex justify-content-end w-100 m-0 text-center">
              <div
                className="ai-modal-close-btn"
                onClick={() => setNewSheetClick(false)}
              >
                <div style={{ fontSize: "18px" }}>&#10005;</div>
              </div>
            </Row>
            <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
              <div
                className="text-white"
                style={{ font: "bold 30px novecento-sans" }}
              >
                NEW RESTOCK SHEET
              </div>
            </Row>
            <div className="d-flex justify-content-center align-items-center w-100 text-white">
              <label htmlFor="leadtimesetting" className="ai-head-text text-white" style={{ "fontSize": "20px" }}>
                Enter Sheet Name 
              </label>
            </div><div className="d-flex justify-content-center align-items-center w-100 m-0 p-0">
              <label htmlFor="leadtimesetting" className="m-0 p-0" style={{ "fontSize": "10px", color: "red" }}>Only alphanumeric, space, underscore(_) & hypehn(-) allowed in the Sheet Name</label>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100 text-white">
              <input
                id="sheet-name"
                name="sheet-name"
                className="form-control ai-input-box w-50"
                value={newSheetName}
                onChange={e => {

                  const inputValue = e.target.value;
                  const pattern = /^[a-zA-Z0-9\s-_]*$/;
                  
                  if (pattern.test(inputValue)) {
                    setNewSheetName(inputValue);
                  }
                  }
                } 
              />
            </div>
            <div className="d-flex justify-content-center col-12">
              <Row className="align-items-center">
                <Col className="d-flex justify-content-center">
                  <Button
                    className="ai-action-btn btn btn-primary"
                    color=""
                    onClick={() => handleCreateNewSheet()}
                    disabled={newSheetName === ""}
                  >
                    CREATE
                  </Button>
                  <Button
                    className="ai-action-btn btn btn-primary"
                    color=""
                    onClick={() => {
                      setNewSheetClick(false);
                      setNewSheetName("");
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      )}

      <div className="page-content">
        <MetaTags>
          <title>Restock Sheet | Active Inventory</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="d-sm-flex align-items-center justify-content-center">
                <h4
                  style={{
                    textAlign: "center",
                    font: "normal normal bold 32px/39px novecento-sans",
                    letterSpacing: "0.32px",
                    color: "#082C4E",
                    textTransform: "uppercase",
                  }}
                >
                  Restock Sheet
                </h4>
              </div>
            </Col>
          </Row>
          <Row xs="12">
            <Col xs="6" className="sm-flex" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
              <h4
                  style={{
                    textAlign: "center",
                    font: "normal normal bold 32px/39px novecento-sans",
                    letterSpacing: "0.32px",
                    color: "#082C4E",
                    textTransform: "uppercase",
                  }}
                >
                  Welcome {localStorage.getItem('name')}
                </h4>
            </Col>
              {localStorage.getItem("role") !== SUPERUSER && (
              <Col xs="6">
                <div className="sm-flex" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  <span style={{ font: "normal normal normal 25px verveine", textAlign: "center" }}>Create a New Sheet<br />to Get Started!</span>
                  <img src={UpArrow} className="inventory-lab-img" style={{transform:"rotate(300deg)"}}/>
                  <Button
                    className="sm-buttons"
                    style={{ borderRadius: "25px", backgroundColor: "#FFEE00", color: "#082C4E", font: "bold 14px novecento-sans", border: 0, textTransform: "uppercase", letterSpacing: "0.24px" }}
                    color="primary"
                    onClick={() => handleNewSheetClick()}
                  >
                    New <span className="mdi mdi-18px mdi-plus"></span>
                  </Button>
                  <Button
                    className="sm-buttons"
                    style={{ borderRadius: "25px", backgroundColor: "#2299AA", color: "#FFFFFF", font: "normal normal bold 14px novecento-sans", textTransform: "uppercase", letterSpacing: "0.24px", border: 0 }}
                    color="primary"
                    onClick={() => history.push("/restock-report-history")}
                  >
                    View Finalized Sheets
                  </Button>
                </div>
              </Col>
              )}
          </Row>
          <div className="ai-Table">
            <DataTable
              columns={columns}
              data={restockSheetDraftData}
              pagination
              paginationServer
              paginationComponent={() => (
                <Pagination
                  data={restockSheetDraftData}
                  rowsPerPage={perPage}
                  currentPage={currentPage}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                  paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
                  rowCount={pagination.totalElements} />
              )}
              paginationPerPage={100}
              paginationRowsPerPageOptions={[50, 100, 200, 300, 500]}
              paginationTotalRows={pagination.totalElements}
              onChangePage={e => handlePageChange(e)}
              onChangeRowsPerPage={e => handlePerRowsChange(e)}
              paginationResetDefaultPage={resetPaginationToggle}
              persistTableHead
              fixedHeader
              responsive
              fixedHeaderScrollHeight="55vh"
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RestockSheet
