import { LOGIN_USER_SUCCESS, LOGOUT_USER_SUCCESS, USERS_CREATE_SUCCESS, USER_DATA_SUCCESS, USER_PROFILE_SUCCESS } from "../actionType"

const INITIAL_STATE = {
  user: null,
  authorized: false,
  client: null
}
const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        authorized: false
      }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        authorized: true
      }
    case USER_DATA_SUCCESS:
      return {
        ...state,
        client: action.payload,
      }
    default:
      return state
  }
}
export default user
