import {
  API_INVOCATION,
  ADD_TAG,
  GET_TAG,
  GET_TAG_WITHOUT_LOADER,
  PRODUCT_STATUS_CHANGE,
  PRODUCT_TAG,
  UPLOAD_PRODUCT_FILE,
  UPLOAD_INVENTORY_LAB_FILE,
  DOWNLOAD_PRODUCT_FILE,
  FILTER_PRODUCTS_BY_NAME,
  FILTER_PRODUCTS_BY_SKU,
  FILTER_PRODUCTS_BY_TAG,
  PRODUCT_EXPIRY_DATE,
  SEARCH_PRODUCT_SHEET,
  AUTO_HIDE_PRODUCT,
  PRODUCT_TAX_CODE,
  PRODUCT_UPDATE,
  UPLOAD_PROFILE_PHOTO,
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

/**
 *
 *  get tags for product
 */
export const getAllProductTag = (_payload, resolve, reject) => {
  const payload = {
    action: GET_TAG_WITHOUT_LOADER,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url: consturl.GET_TAG + `?q=${_payload.tagName}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const getAllProductTagWithoutLoader = (resolve, reject) => {
  const payload = {
    action: GET_TAG_WITHOUT_LOADER,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url: consturl.GET_TAG,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}
/**
 *
 *  add tag for product
 */
export const addProductTag = (_payload, resolve, reject) => {
  const payload = {
    action: ADD_TAG,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url: consturl.TAG.replace(":productId", _payload.productId),
    resolve,
    reject,
    data: { ..._payload.data },
  }
  return { type: API_INVOCATION, payload }
}

/**
 *
 *  add tag for product
 */
export const removeProductTag = (_payload, resolve, reject) => {
  const payload = {
    action: ADD_TAG,
    method: constdata.DELETE,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.TAG.replace(":productId", _payload.productId) +
      "/" +
      _payload.tagId,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

/**
 *
 *  add tag for product
 */
export const statusProductTag = (_payload, resolve, reject) => {
  const payload = {
    action: PRODUCT_STATUS_CHANGE,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.PRODUCT_ACTIVE_INACTIVE.replace(
        ":productId",
        _payload.productId
      ) +
      "/" +
      _payload.status,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const addTagsProdcut = (_payload, resolve, reject) => {
  const payload = {
    action: PRODUCT_TAG,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url: consturl.ADD_TAG_PRODUCT.replace(":productId", _payload.productId),
    resolve,
    reject,
    data: { ..._payload.data },
  }
  return { type: API_INVOCATION, payload }
}

export const uploadProducts = (_payload, resolve, reject) => {
  const payload = {
    action: UPLOAD_PRODUCT_FILE,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
        "content-type": "multipart/form-data",
      },
    },
    url: consturl.UPLOAD_PRODUCT_FILE,
    resolve,
    reject,
    data: _payload,
  }
  return { type: API_INVOCATION, payload }
}

export const importInventoryLabData = (_payload, resolve, reject) => {
  const payload = {
    action: UPLOAD_INVENTORY_LAB_FILE,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
        "content-type": "multipart/form-data",
      },
    },
    url: consturl.UPLOAD_INVENTORY_LAB_FILE,
    resolve,
    reject,
    data: _payload,
  }
  return { type: API_INVOCATION, payload }
}

export const downloadProducts = (resolve, reject) => {
  const payload = {
    action: DOWNLOAD_PRODUCT_FILE,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
      responseType: "blob",
    },
    url: consturl.DOWNLOAD_PRODUCT_FILE,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const filterProductsByName = (_payload, resolve, reject) => {
  const payload = {
    action: FILTER_PRODUCTS_BY_NAME,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.FILTER_PRODUCTS_BY_NAME +
      `/${_payload.data.query}?direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const filterProductsBySku = (_payload, resolve, reject) => {
  const payload = {
    action: FILTER_PRODUCTS_BY_SKU,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.FILTER_PRODUCTS_BY_SKU +
      `/${_payload.data.query}?direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const filterProductsByTag = (_payload, resolve, reject) => {
  const payload = {
    action: FILTER_PRODUCTS_BY_TAG,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.FILTER_PRODUCTS_BY_TAG +
      `?q=${_payload.data.query}&direction=DESC&pageNo=${_payload.data.pageNo}&pageSize=${_payload.data.pageSize}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const searchAndFilterData = (_payload, resolve, reject) => {
  let search = ""
  let filter = ""
  if (_payload.data.search !== "") {
    search = `&search=${_payload.data.search}`
  }
  if (_payload.data.status !== "" || _payload.data.filterType !== "") {
    let semicolon = ""
    if (_payload.data.status && _payload.data.filterType) {
      semicolon = ";"
    }
    filter = `&filter=${_payload.data.status}${semicolon}${_payload.data.filterType}`
  }
  const payload = {
    action: SEARCH_PRODUCT_SHEET,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.PRODUCT_DATA +
      `?direction=${_payload.data.sendData.direction}&pageNo=${_payload.data.sendData.pageNo}&pageSize=${_payload.data.sendData.pageSize}${search}${filter}&sortBy=${_payload.data.sendData.sortBy}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const setProductExpiryDate = (_payload, resolve, reject) => {
  const payload = {
    action: PRODUCT_EXPIRY_DATE,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.PRODUCT_EXPIRY_DATE +
      `?productId=${_payload.data.id}&expirydate=${_payload.data.expDate}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const updateAmazonTaxCode = (_payload, resolve, reject) => {
  const payload = {
    action: PRODUCT_TAX_CODE,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url:
      consturl.PRODUCT_TAX_CODE +
      `?productId=${_payload.data.id}&taxCode=${_payload.data.taxCode}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const updateProductFields = (_payload, resolve, reject) => {
  const payload = {
    action: PRODUCT_UPDATE,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        "X-CLIENT-ID": localStorage.getItem("clientId"),
      },
    },
    url: consturl.PRODUCT_UPDATE +`?productId=${_payload.data.id}&name=${_payload.data.name}&value=${_payload.data.value}`,resolve,reject,
  }
  return { type: API_INVOCATION, payload }
}

export const autoHideProduct = (_payload, resolve, reject) => {
  const payload = {
    action: AUTO_HIDE_PRODUCT,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
      },
    },
    url:
      consturl.AUTO_HIDE_PRODUCT + `/${_payload.data.id}/${_payload.data.flag}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

export const uploadPhoto = (_payload, resolve, reject) => {
  const payload = {
    action: UPLOAD_PROFILE_PHOTO,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        // "X-CLIENTMARKETPLACE-ID": localStorage.getItem("marketplaceId"),
        // "X-CLIENT-ID": localStorage.getItem("clientId"),
        "content-type": "multipart/form-data",
      },
    },
    url: consturl.UPLOAD_PROFILE_PHOTO,
    resolve,
    reject,
    data: _payload,
  }
  return { type: API_INVOCATION, payload }
}
