import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import footerLogo from "../../assets/images/logo-white.png"
import "../../components/HorizontalLayout/Layout.css"
const Footer = () => {


  const footerData =
  {
    firstCol: [
      {
        id: "1",
        name: "Why Active Inventory",
        link: "/"
      },
      {
        id: "2",
        name: "Pricing",
        link: "pricing"
      },
      {
        id: "3",
        name: "Knowledge Database",
        link: "https://support.myactiveinventory.com/support/solutions"
      },
      {
        id: "4",
        name: "Screen Images",
        link: "/screenshots"
      },
      {
        id: "5",
        name: "Demo Videos",
        link: "/videos"
      }
    ],
    secondCol: [
      {
        id: "7",
        name: "Our Story",
        link: "/ourstory"
      },
      {
        id: "8",
        name: "Suggestions",
        link: "/suggestion"
      },
      {
        id: "9",
        name: "Become an affiliate",
        link: "/affiliate"
      }
    ],
    thirdCol: [
      {
        id: "10",
        name: "Login",
        link: "/login"
      },
      {
        id: "11",
        name: "Free 14 Day Trial",
        link: "/14-days-trial"
      },
      {
        id: "12",
        name: "Sign Up",
        link: "/14-days-trial"
      },
      {
        id: "13",
        name: "Terms of Service",
        link: "/termservice"
      },
      {
        id: "14",
        name: "Privacy Policy",
        link: "/privacy-policy"
      },
    ]
  }
  return (
    <div className="footer-wrapper" style={{ backgroundColor: "#082c4e" }}>
      <Container>
        <Row
          md="4"
          xs="1"
        >
          <Col className="mt-3">
            <a href="/">
              <img src={footerLogo} alt="" height="55" width="140" />
            </a>
          </Col>
          {
            Object.keys(footerData).map((item, index) => {
              return (
                <Col className="d-flex flex-column" key={index}>
                  {
                    footerData[item].map((items) => {
                      {
                        return (
                          items.name === 'Knowledge Database'
                            ? <span className="my-1" key={items.id}>
                              <a className="nav-link text-white footer-bottom-border footer-text h3 cursor-pointer" href="https://support.myactiveinventory.com/support/solutions" target="_blank" rel="noreferrer">Knowledge Database</a>
                            </span>
                            :
                            <span className="my-1" key={items.id}>
                              <Link
                                key={items.name}
                                to={items.link}
                                className="nav-link footer-text footer-bottom-border text-white h3 text-decoration-none cursor-pointer my-2">
                                {items.name}
                              </Link>
                            </span>
                        )
                      }
                    })
                  }
                </Col>
              )
            })
          }
        </Row>
        <div className="col-12 d-flex justify-content-center my-5">
          <p className="text-white mb-0 copyright-text">
            © Copyright {new Date().getFullYear()} Active Inventory - All Rights Reserved
          </p>
        </div>
      </Container>
    </div>
  )
}

export default Footer
