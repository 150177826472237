import React, { useEffect, useState } from "react"
import { useHistory, useLocation, withRouter } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';
import logo from "assets/images/logo-green.png"
import { useToasts } from "react-toast-notifications";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AmazonAuthSuccessRedirect = () => {

  const { addToast } = useToasts()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [authRenew, setAuthRenew] = useState(false)

  let query = useQuery();
  useEffect(() => {
    document.body.classList.add("ai-login-page")
    console.log("Renwe" + query.get("q"));
    setAuthRenew("renew" === query.get("q"));
  }, [])

  return (

    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>Amazon Authorization</title>
        </MetaTags>
        <div className="ai-grid-container">
          <div className="ai-grid-item w-100">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "70px 20px 20px 20px",
              }}
            >
              <img src={logo} alt="" style={{ width: "220px" }} />
            </div>
            <div className="account-pages my-5 pt-sm-5">
              <div>
                <Row>
                  <Col xs={12}>
                    <div className="text-primary p-4">
                      <h3 className="text-center m-3 ai-head-text text-white">
                        AMAZON AUTHORIZATION INFORMATION
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="p-2">
                <div className="text-center m-3" style={{ font: "normal normal normal 12px/22px nunito-sans", letterSpacing: "0.16px", color: "#082C4E", opacity: 1, padding: "0 200px 0px 200px" }}>
                <div className="text-center m-3" style={{ font: "normal normal bold 80px novecento-sans", letterSpacing: "0.16px", color: "#FFEE00", opacity: 1, padding: "0 200px 0px 200px" }}>SUCCESS!</div>
                  {authRenew
                    ? <div className="mbr-text mbr-fonts-style display-7 mh-100">
                      <p className="text-center text-white">
                        You have successfully renew your Amazon token. Please continue to use the application.
                      </p>
                      <div className="row mt-3 text-center">
                        <div className="col-sm">
                          <button
                            //className={disableButton ? "btn btn-primary btn-block w-75" : "btn btn-primary"}
                            className="ai-btn ai-head-text"
                            style={{ fontSize: "24px", width: "500px", backgroundColor: "#FFEE00", color: "#082C4E !important" }}
                            type="submit"
                            onClick={() => window.location = '/profile'}
                          >
                            TAKE ME BACK TO THE APPLICATION
                          </button>
                        </div>
                      </div>
                    </div>
                    : <div className="mbr-text mbr-fonts-style display-7 mh-100 text-white">
                      <p className="text-center">
                        It may take up to 24 hours for the data to populate in
                        the app. We will send you an email with the transfer is
                        complete.
                      </p>
                      <p className="text-center">
                        After it is complete, you can explore the
                        features that will save you time and make your FBA
                        shipment decisions quick and easy.
                      </p>
                    </div>
                  }
                </div>
                <div className="row mt-3 text-center">
                  <div className="col-sm">
                    {/* <button
                    //className={disableButton ? "btn btn-primary btn-block w-75" : "btn btn-primary"}
                    className="ai-btn ai-head-text text-white"
                    style={{fontSize:"24px"}}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={disableButton}
                  >
                    {
                      disableButton &&
                      <>
                        <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    }
                    {disableButton ? "Redirecting you to the Amazon Seller site..." : "Authorize"}
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AmazonAuthSuccessRedirect)
