import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Loader from "./loader/loaderReducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"
import Billing from "./reducer/Billing"
import Subscription from "./reducer/Subscription"
import User from "./reducer/User"
import stock from "./reducer/Stock"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  User,
  stock,
  Loader,
  Billing,
  Subscription,
})

export default rootReducer
