import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const SelectControl = styled.select`
  cursor: pointer;
  height: 24px;
  max-width: 100%;
  user-select: none;
  padding-left: 8px;
  padding-right: 2px;
  margin-right: 3px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  appearance: none;
  direction: ltr;
  flex-shrink: 0;
  &::-ms-expand {
    display: none;
  }
  &:disabled::-ms-expand {
    background: #f60;
  }
  &:hover {
    background-color: rgb(206, 224, 240);
  }
  option {
    color: initial;
  }
`

const SelectWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  font-size: inherit;
  color: inherit;
  margin-top: 1px;
  svg {
    top: 0;
    right: 0;
    color: inherit;
    position: absolute;
    fill: currentColor;
    width: 24px;
    height: 24px;
    display: inline-block;
    user-select: none;
    pointer-events: none;
  }
`

const Select = ({ defaultValue, onChange, ...rest }) => (
  <>
    <SelectControl onChange={onChange} defaultValue={defaultValue} {...rest} />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M7 10l5 5 5-5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </>
)

Select.propTypes = {
  onChange: e => {},
  defaultValue: PropTypes.string.isRequired,
  children: React.Children,
}

export default Select
