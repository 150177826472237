import {
  API_INVOCATION,
  LOGOUT_USER,
  LOGIN_USER,
  REGISTER_USER,
  CLIENT_CONFIG,
  USER_PROFILE,
  USERS_LIST,
  USERS_CREATE,
  USERS_EDIT,
  GET_USERS_BY_ID,
  GET_USER_BY_ID,
  USERS_DELETE,
  USER_DATA,
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  REFRESH_USER,
  USERS_LIST_WITHOUTLOADER,
  GET_STATES,
  GET_COUNTRY,
  GET_STATES_FROM_COUNTRY,
  SUPER_USERS_LIST,
  GET_SUPER_USER_BY_ID,
  USER_SUGGESTION,
  CLIENT_INFO,
  REGISTER_DEMO_USER
} from "../../actionType"
import * as constdata from "../../../utils/constants"
import * as consturl from "../../../utils/url"

/**
 *
 *  get activity details
 */
export const loginUser = (_payload, resolve, reject) => {
  const data = _payload.data
  const formData = new FormData()
  const dataKey = Object.keys(data)
  for (let i = 0; i < dataKey.length; i++) {
    formData.append(dataKey[i], data[dataKey[i]])
  }

  const payload = {
    action: LOGIN_USER,
    method: constdata.POST,
    apiConfig: {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Basic ZWFzeS1jbGllbnQ6ZWFzeS1zZWNyZXQ=",
      },
    },
    url: consturl.LOGIN,
    resolve,
    reject,
    data: formData,
  }
  return { type: API_INVOCATION, payload }
}

export const refreshToken = (_payload, resolve, reject) => {
  const data = _payload
  const formData = new FormData()
  const dataKey = Object.keys(data)
  for (let i = 0; i < dataKey.length; i++) {
    formData.append(dataKey[i], data[dataKey[i]])
  }
  const payload = {
    action: REFRESH_USER,
    method: constdata.POST,
    apiConfig: {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Basic ZWFzeS1jbGllbnQ6ZWFzeS1zZWNyZXQ=",
      },
    },
    url: consturl.REFRESH_TOKEN,
    resolve,
    reject,
    data: formData,
  }
  return { type: API_INVOCATION, payload }
}

export const forgotPassword = (_payload, resolve, reject) => {
  const payload = {
    action: FORGET_PASSWORD,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: "Basic ZWFzeS1jbGllbnQ6ZWFzeS1zZWNyZXQ=",
      },
    },
    url: consturl.FORGOT_PASSWORD + "/" + _payload.data.email,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const updatePassword = (_payload, resolve, reject) => {
  const payload = {
    action: FORGET_PASSWORD,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: "Basic ZWFzeS1jbGllbnQ6ZWFzeS1zZWNyZXQ=",
      },
    },
    url: consturl.FORGOT_PASSWORD,
    resolve,
    reject,
    data: _payload.data
  }
  return { type: API_INVOCATION, payload }
}

export const changePassword = (_payload, resolve, reject) => {
  const payload = {
    action: CHANGE_PASSWORD,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.CHANGE_PASSWORD,
    resolve,
    reject,
    data: _payload.data,
  }
  return { type: API_INVOCATION, payload }
}

export const logOutUser = (resolve, reject) => {
  const payload = {
    action: LOGOUT_USER,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.LOGOUT,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

export const getCountry = (resolve, reject) => {
  const payload = {
    action: GET_COUNTRY,
    method: constdata.GET,
    apiConfig: {},
    url: consturl.GET_COUNTRY,
    resolve,
    reject
  }
  console.log(payload);
  return { type: API_INVOCATION, payload }
}

export const getStatesFromCountry = (_payload, resolve, reject) => {
  const payload = {
    action: GET_STATES_FROM_COUNTRY,
    method: constdata.GET,
    apiConfig: {},
    url: consturl.GET_STATES + `?countryCode=${_payload.data}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

/**
 *  register user
 */
export const registerUser = (_payload, resolve, reject) => {
  const payload = {
    action: REGISTER_USER,
    method: constdata.POST,
    apiConfig: {},
    url: consturl.SIGNUP,
    resolve,
    reject,
    data: _payload.data,
  }
  return { type: API_INVOCATION, payload }
}

/**
 *  register user
 */
export const registerDemoUser = (_payload, resolve, reject) => {
  const payload = {
    action: REGISTER_DEMO_USER,
    method: constdata.POST,
    apiConfig: {},
    url: consturl.DEMO_SIGNUP,
    resolve,
    reject,
    data: _payload.data,
  }
  return { type: API_INVOCATION, payload }
}

export const getClientInfo = (_payload, resolve, reject) => {
  const payload = {
    action: CLIENT_INFO,
    method: constdata.GET,
    apiConfig: {},
    url: consturl.CLIENT_INFO + `${_payload.clientId}`,
    resolve,
    reject
  }
  return { type: API_INVOCATION, payload }
}

/**
 *  register user
 */
export const clientConfig = (_payload, resolve, reject) => {
  const payload = {
    action: CLIENT_CONFIG,
    method: constdata.POST,
    apiConfig: {},
    url: consturl.CLIENT_CONFIG,
    resolve,
    reject,
    data: _payload.data,
  }
  return { type: API_INVOCATION, payload }
}

/**
 *  get activity details
 */
export const getUserProfile = (resolve, reject) => {
  const payload = {
    action: USER_PROFILE,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.USER_PROFILE,
    resolve,
    reject,
  }

  return { type: API_INVOCATION, payload }
}

export const getUserData = (resolve, reject) => {
  const payload = {
    action: USER_DATA,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.USER_DATA,
    resolve,
    reject,
  }

  return { type: API_INVOCATION, payload }
}

/**
 *  get activity details
 */
export const getAllUsers = (resolve, reject) => {
  const payload = {
    action: USERS_LIST,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: localStorage.getItem("role") === constdata.SUPERADMIN ? consturl.GET_ALL_SUPER_USERS : consturl.USER,
    resolve,
    reject,
  }

  return { type: API_INVOCATION, payload }
}

export const getAllSuperUsersData = (resolve, reject) => {
  const payload = {
    action: SUPER_USERS_LIST,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.GET_ALL_SUPER_USERS,
    resolve,
    reject,
  }

  return { type: API_INVOCATION, payload }
}

/**
 *  get activity details
 */
export const getAllUsersWithoutLoader = (resolve, reject) => {
  const payload = {
    action: USERS_LIST_WITHOUTLOADER,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: localStorage.getItem("role") === constdata.SUPERADMIN ? consturl.GET_ALL_SUPER_USERS : consturl.USER,
    resolve,
    reject,
  }

  return { type: API_INVOCATION, payload }
}

/**
 *  create users
 */
export const createUsers = (_payload, resolve, reject) => {
  const payload = {
    action: USERS_CREATE,
    method: constdata.POST,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: localStorage.getItem("role") === constdata.SUPERADMIN ? consturl.SUPER_ADMIN_CREATE_USER : consturl.USER,
    resolve,
    reject,
    data: { ..._payload.data }
  }

  return { type: API_INVOCATION, payload }
}

/**
 *  get user by id
 */
export const getUsersById = (_payload, resolve, reject) => {
  const payload = {
    action: GET_USER_BY_ID,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.GET_USER_BY_ID + `/${_payload.data.userId}`,
    resolve,
    reject,
    data: { ..._payload.data }
  }

  return { type: API_INVOCATION, payload }
}

export const getSuperUsersById = (_payload, resolve, reject) => {
  const payload = {
    action: GET_SUPER_USER_BY_ID,
    method: constdata.GET,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.GET_SUPER_USER_BY_ID + `/${_payload.data.userId}`,
    resolve,
    reject,
    data: { ..._payload.data }
  }

  return { type: API_INVOCATION, payload }
}

/**
 *  edit users
 */
export const editUsers = (_payload, resolve, reject) => {
  const payload = {
    action: USERS_EDIT,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: localStorage.getItem("role") === constdata.SUPERADMIN
      ? (consturl.EDIT_SUPER_USER + `/${_payload.data.userId}`)
      : (consturl.EDIT_USER + `/${_payload.data.userId}`),
    resolve,
    reject,
    data: { ..._payload.data }
  }

  return { type: API_INVOCATION, payload }
}

/**
 *  delete user
 */
export const updateUserStatusData = (_payload, resolve, reject) => {
  const payload = {
    action: USERS_DELETE,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
        'X-CLIENT-ID': localStorage.getItem("clientId"),
        'X-CLIENTMARKETPLACE-ID': localStorage.getItem("marketplaceId"),
      },
    },
    url: localStorage.getItem("role") === constdata.SUPERADMIN
      ? consturl.DELETE_SUPER_USER + `/${_payload.data.userId}`
      : consturl.DELETE_USER + `/${_payload.data.userId}/active/`+_payload.data.userStatus,
    resolve,
    reject,
    data: { ..._payload.data }
  }
  return { type: API_INVOCATION, payload }
}

/**
 *  reset password email
 */
export const resetPasswordWithEmail = (_payload, resolve, reject) => {
  const payload = {
    action: RESET_PASSWORD,
    method: constdata.PUT,
    apiConfig: {
      headers: {
        Authorization: localStorage.getItem("token_type") + " " + localStorage.getItem("access_token"),
      },
    },
    url: consturl.RESET_PASSWORD + `/${_payload.data.email}`,
    resolve,
    reject,
  }
  return { type: API_INVOCATION, payload }
}

/**
 *  Send User Suggestion
 */
export const submitSuggestion = (_payload, resolve, reject) => {
  const payload = {
    action: USER_SUGGESTION,
    method: constdata.POST,
    apiConfig: {},
    url: consturl.USER_SUGGESTION,
    resolve,
    reject,
    data: { ..._payload.data.suggestion }
  }
  return { type: API_INVOCATION, payload }
}